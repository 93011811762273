import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
    // Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { Table, TablePrimaryCell } from '../../../../components/Metrics/MetricTable';
import Utils from '../../../../components/Utils';

import { SimpleCountType } from '../../../../services/trialfire/types/SimpleCountType';
import { useHatDashboardPageContext } from '../../../../hooks/Pages/HatDashboardPage';

interface HatChannelTrafficTableRow {
    channel: string;
    totalUniqueTraffic: number;
    totalAdInfluencedTraffic: number;
    percentAdInfluenced: number;
}

/**
 * Hat Channel Traffic Table Hook
 *
 */

interface UseHatChannelTrafficTableState {
    rows: HatChannelTrafficTableRow[];
    setRows: Dispatch<SetStateAction<HatChannelTrafficTableRow[]>>;
}

const useHatChannelTrafficTable = (): UseHatChannelTrafficTableState => {
    const { simpleCounts } = useHatDashboardPageContext();

    const totalUniqueTrafficTotalVisitsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_UNIQUE_TRAFFIC_TOTAL_VISITS];
    const totalUniqueTrafficDirectSimpleCountQuery = simpleCounts[SimpleCountType.TOTAL_UNIQUE_TRAFFIC_DIRECT];
    const totalUniqueTrafficOrganicSearchSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_UNIQUE_TRAFFIC_ORGANIC_SEARCH];
    const totalUniqueTrafficPaidSearchSimpleCountQuery = simpleCounts[SimpleCountType.TOTAL_UNIQUE_TRAFFIC_PAID_SEARCH];
    const totalUniqueTrafficReferralSimpleCountQuery = simpleCounts[SimpleCountType.TOTAL_UNIQUE_TRAFFIC_REFERRAL];
    const totalUniqueTrafficSocialSimpleCountQuery = simpleCounts[SimpleCountType.TOTAL_UNIQUE_TRAFFIC_SOCIAL];

    const totalAdInfluencedTrafficTotalVisitsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_TOTAL_VISITS];
    const totalAdInfluencedTrafficDirectSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_DIRECT];
    const totalAdInfluencedTrafficOrganicSearchSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_ORGANIC_SEARCH];
    const totalAdInfluencedTrafficPaidSearchSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_PAID_SEARCH];
    const totalAdInfluencedTrafficReferralSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_REFERRAL];
    const totalAdInfluencedTrafficSocialSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_SOCIAL];

    const [rows, setRows] = useState<HatChannelTrafficTableRow[]>([]);

    useEffect(() => {
        let _rows: HatChannelTrafficTableRow[] = [];

        _rows.push({
            channel: 'STV/OLV/Display',
            totalUniqueTraffic: totalAdInfluencedTrafficTotalVisitsSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedTraffic: totalAdInfluencedTrafficTotalVisitsSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            channel: 'Direct',
            totalUniqueTraffic: totalUniqueTrafficDirectSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedTraffic: totalAdInfluencedTrafficDirectSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            channel: 'Paid Search',
            totalUniqueTraffic: totalUniqueTrafficPaidSearchSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedTraffic: totalAdInfluencedTrafficPaidSearchSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            channel: 'Social',
            totalUniqueTraffic: totalUniqueTrafficSocialSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedTraffic: totalAdInfluencedTrafficSocialSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            channel: 'Organic Search',
            totalUniqueTraffic: totalUniqueTrafficOrganicSearchSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedTraffic: totalAdInfluencedTrafficOrganicSearchSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            channel: 'Referral',
            totalUniqueTraffic: totalUniqueTrafficReferralSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedTraffic: totalAdInfluencedTrafficReferralSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.forEach((_row: HatChannelTrafficTableRow, _index: number) => {
            if (_row.channel === 'STV/OLV/Display') {
                _rows[_index].percentAdInfluenced = 100;
            } else {
                _rows[_index].percentAdInfluenced = Math.round(
                    _row.totalUniqueTraffic > 0
                        ? (_row.totalAdInfluencedTraffic / (_row.totalAdInfluencedTraffic + _row.totalUniqueTraffic)) *
                              100
                        : 0
                );
            }
        });

        setRows(_rows);
    }, [
        totalUniqueTrafficTotalVisitsSimpleCountQuery?.data,
        totalUniqueTrafficDirectSimpleCountQuery?.data,
        totalUniqueTrafficOrganicSearchSimpleCountQuery?.data,
        totalUniqueTrafficPaidSearchSimpleCountQuery?.data,
        totalUniqueTrafficReferralSimpleCountQuery?.data,
        totalUniqueTrafficSocialSimpleCountQuery?.data,

        totalAdInfluencedTrafficTotalVisitsSimpleCountQuery?.data,
        totalAdInfluencedTrafficDirectSimpleCountQuery?.data,
        totalAdInfluencedTrafficOrganicSearchSimpleCountQuery?.data,
        totalAdInfluencedTrafficPaidSearchSimpleCountQuery?.data,
        totalAdInfluencedTrafficReferralSimpleCountQuery?.data,
        totalAdInfluencedTrafficSocialSimpleCountQuery?.data,
    ]);

    return { rows, setRows };
};

/**
 * Hat Channel Traffic Table
 */

interface HatChannelTrafficTableProps {
    rows: HatChannelTrafficTableRow[];
}

const HatChannelTrafficTable: FC<HatChannelTrafficTableProps> = ({
    rows,
}: HatChannelTrafficTableProps): JSX.Element => {
    return (
        <TableContainer
            sx={{
                borderCollapse: 'inherit',
                borderColor: (theme) => theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Channel</TableCell>
                        <TableCell>Total Visits</TableCell>
                        <TableCell>Influenced Visits</TableCell>
                        <TableCell>% Influenced</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((item: HatChannelTrafficTableRow, index: number) => (
                        <TableRow key={index}>
                            <TablePrimaryCell>{item.channel}</TablePrimaryCell>
                            <TableCell>{Utils.formatValue(item.totalUniqueTraffic)}</TableCell>
                            <TableCell>{Utils.formatValue(item.totalAdInfluencedTraffic)}</TableCell>
                            <TableCell>{Utils.formatValue(item.percentAdInfluenced, 'percent')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export type { UseHatChannelTrafficTableState };
export { useHatChannelTrafficTable };

export type { HatChannelTrafficTableRow };
export type { HatChannelTrafficTableProps };
export default HatChannelTrafficTable;
