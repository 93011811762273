import { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import ApiService from '../../ApiService';
import { CampaignAdGroup } from '../../types/Campaign';
import { Dealer } from '../../types/Dealer';

const SelectAdGroup = ({
    adGroup,
    setAdGroup,
    advertiser,
    yearMonth,
    setSize,
}: {
    adGroup: CampaignAdGroup | null;
    setAdGroup: (adGroup: CampaignAdGroup | null) => void;
    advertiser: Dealer | null;
    yearMonth: string;
    setSize: (size: string) => void;
}) => {
    const [adGroups, setAdGroups] = useState<CampaignAdGroup[]>([]);

    useEffect(() => {
        function getCampaignAdGroups() {
            if (!advertiser || !advertiser?.id || !yearMonth) {
                setAdGroups([]);
                return;
            }
            ApiService.getCampaignAdGroupsByDealerAfterDate(advertiser.id, yearMonth)
                .then((response) => {
                    setAdGroups(response?.data);
                    setAdGroup(response?.data?.[0] || null);
                })
                .catch(() => {
                    setAdGroups([]);
                });
        }
        getCampaignAdGroups();
    }, [advertiser, yearMonth]);

    return (
        <Autocomplete
            sx={{
                width: '224px',
            }}
            value={adGroup}
            size="small"
            options={adGroups}
            id="select-adGroup"
            getOptionLabel={(option) => option?.name || ''}
            disabled={!advertiser || !advertiser?.id || !yearMonth}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value) => {
                setSize('All');
                setAdGroup(value);
            }}
            renderInput={(params) => <TextField {...params} label="Ad Group" />}
        />
    );
};

export default SelectAdGroup;
