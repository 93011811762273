import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutocompleteInputChangeReason } from '@mui/material';
import { useInfiniteQuery, useQuery } from 'react-query';
import axios from 'axios';

import { AgencyCriteria } from '../types/Criteria/AgencyCriteria';
import { Agency } from '../types/Agency';
import { InfiniteAutocompleteProps } from './InfiniteAutocomplete';
import { AxiosInfinitePageableResponse, PageableRequest, PageableResponse } from '../types/AxiosPageableResponse';
import { PAGEABLE_SIZE } from '../types/AxiosPageableResponse';
import InfiniteAutocomplete from './InfiniteAutocomplete';
import Utils from './Utils';

/**
 * Agency Autocomplete Utilities
 */

const getOptionKey = (option: Agency | any): string | number => {
    return option?.id as number;
};

const getOptionLabel = (option: Agency | any): string => {
    return option?.name || '';
};

/**
 * Agency Autocomplete Requests
 */

const defaultPageable: PageableRequest = {
    page: 1,
    size: PAGEABLE_SIZE,
    sort: 'asc',
    sortBy: 'name',
};

const fetch = async (
    criteria: AgencyCriteria,
    { page = 1, ...pageable }: PageableRequest
): Promise<PageableResponse<Agency>> => {
    const response = await axios.get('/api/agency/all', {
        headers: { XBusy: 'false' },
        params: {
            ...criteria,
            ...pageable,
            page: page - 1,
            ids: criteria?.ids?.length ? criteria.ids.join(',') : undefined,
        },
    });
    return response.data;
};

const fetchInitial = async (ids: number[] | number | null | undefined): Promise<any> => {
    const _ids: number[] = Array.isArray(ids) ? ids : ids != null ? [ids] : [];

    if (_ids.length === 0) {
        return [];
    }

    const { content } = await fetch({ ids: _ids }, { page: 1, size: 1000 });

    return content;
};

const fetchInfinite = async (
    criteria: AgencyCriteria,
    { page = 1, ...pageable }: PageableRequest
): Promise<AxiosInfinitePageableResponse<Agency>> => {
    const { content } = await fetch(criteria, { page, ...pageable });

    return {
        items: content,
        nextPage: content.length === pageable.size ? page + 1 : undefined,
    };
};

const fetchMass = async (criteria: AgencyCriteria): Promise<any> => {
    const { content } = await fetch(criteria, { page: 1, size: 1000 });

    return content;
};

/**
 * Agency Autocomplete
 */

interface AgencyAutocompleteProps<
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> extends Omit<
        InfiniteAutocompleteProps<Agency, Multiple, DisableClearable, FreeSolo>,
        'options' | 'value' | 'getOptionKey' | 'getOptionLabel'
    > {
    onLoading?: () => void;
    criteria?: AgencyCriteria;
    value: Multiple extends true ? number[] : number | null;
}

const AgencyAutocomplete = <
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>({
    onLoading = () => {},
    onChange,
    ...props
}: AgencyAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
    const { debounce } = Utils;

    const [options, setOptions] = useState<Agency[]>([]);
    const [value, setValue] = useState<
        InfiniteAutocompleteProps<Agency, Multiple, DisableClearable, FreeSolo>['value']
    >((props.multiple ? [] : null) as InfiniteAutocompleteProps<Agency, Multiple, DisableClearable, FreeSolo>['value']);
    const [inputValue, setInputValue] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isMassSelected, setIsMassSelected] = useState<boolean>(false);

    const criteria = useMemo((): AgencyCriteria => {
        return {
            ...props?.criteria,
            name: inputValue,
        };
    }, [props.criteria, inputValue]);

    // Fetch initial value if IDs are provided
    const { data: initialValue, isLoading: isLoadingInitialValue } = useQuery({
        queryKey: ['infinite/agencies/initial', props.value],
        queryFn: () => fetchInitial(props.value),
        enabled: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (initialValue && initialValue.length > 0) {
            if (props.multiple) {
                setValue(initialValue);
            } else {
                setValue(initialValue[0]);
            }
        }
    }, [initialValue, props.multiple]);

    const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, refetch } = useInfiniteQuery({
        queryKey: ['infinite/agencies', criteria],
        queryFn: ({ pageParam }) => fetchInfinite(criteria, { page: pageParam || 1, size: defaultPageable.size }),
        getNextPageParam: (lastPage) => lastPage.nextPage,
        // staleTime: 1000 * 60 * 5,
        enabled: isOpen,
        refetchOnWindowFocus: false,
    });

    const { refetch: refetchMass, isLoading: isLoadingMass } = useQuery({
        queryKey: ['infinite/agencies/mass', criteria],
        queryFn: () => fetchMass(criteria),
        enabled: false,
        refetchOnWindowFocus: false,
    });

    const handleOnChange = useCallback(
        (
            newValue: InfiniteAutocompleteProps<Agency, Multiple, DisableClearable, FreeSolo>['value'] | undefined
        ): void => {
            setValue(newValue);
            onChange(newValue as any);
        },
        [onChange]
    );

    const debouncedOnInputChange = useRef(
        debounce((newInputValue: string): void => {
            refetch();
        }, 1000)
    ).current;

    const isLoading = useMemo((): boolean => {
        return isFetching || isFetchingNextPage || isLoadingInitialValue || isLoadingMass;
    }, [isFetching, isFetchingNextPage, isLoadingInitialValue, isLoadingMass]);

    const handleOnInputChange = (
        event: React.SyntheticEvent,
        newInputValue: string,
        reason: AutocompleteInputChangeReason
    ): void => {
        if (newInputValue.length > 0) {
            setInputValue(newInputValue);
            debouncedOnInputChange(newInputValue);
        }
    };

    const handleOnScroll = useCallback((): void => {
        if (!isMassSelected) {
            if (hasNextPage && !isFetchingNextPage) {
                fetchNextPage();
            }
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, isMassSelected]);

    useEffect((): void => {
        setOptions(data?.pages.flatMap((page) => page.items) || []);
    }, [data]);

    useEffect((): void => {
        if (isLoading === true) {
            onLoading();
        }
    }, [isLoading, onLoading]);

    useEffect(() => {
        if (isOpen === false) {
            setInputValue('');
        }
    }, [isOpen]);

    useEffect(() => {
        setIsMassSelected(false);
    }, [criteria]);

    const handleOnFetchMass = async (): Promise<ReadonlyArray<Agency>> => {
        const { data } = await refetchMass();

        setIsMassSelected(true);

        return data;
    };

    return (
        <InfiniteAutocomplete
            disableCloseOnSelect={props.multiple}
            openOnFocus={props.multiple}
            selectOnFocus={props.multiple}
            blurOnSelect={false}
            clearOnBlur={false}
            label="Agency"
            {...props}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            options={options}
            value={value}
            loading={isLoading}
            onChange={handleOnChange}
            inputValue={inputValue}
            onInputChange={handleOnInputChange}
            onScroll={handleOnScroll}
            onFetchMass={handleOnFetchMass}
            onOpen={(): void => setIsOpen(true)}
            onClose={(): void => setIsOpen(false)}
        />
    );
};

export type { AgencyAutocompleteProps };
export { defaultPageable as defaultAgencyPageable };
export default AgencyAutocomplete;
