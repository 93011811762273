import { useEffect, useMemo, useState } from 'react';
// import { Dealer } from '../../types/Dealer';
import { DealerImportSite as Resource } from '../../types/DealerImportSite';

interface ResourceListCriteria {
    searchText: string;
    agencyId: number | null;
    advertiserId: number | null;
}

interface UseResourceListFilterState {
    filteredAdvertiserImportSites: Resource[];
    advertiserImportSiteListCriteria: ResourceListCriteria;
    setAdvertiserImportSiteListCriteria: (resourceListCriteria: ResourceListCriteria) => void;
    resetAdvertiserImportSiteListCriteria: () => void;
    advertiserImportSites: Resource[];
    setAdvertiserImportSites: (resources: Resource[]) => void;
}

const initialResourceListCriteriaState: ResourceListCriteria = {
    searchText: '',
    agencyId: null,
    advertiserId: null,
};

const useResourceListFilter = (
    initialResources: Resource[] = [],
    initialResourceListCriteria: ResourceListCriteria = initialResourceListCriteriaState
): UseResourceListFilterState => {
    const [resources, setResources] = useState<Resource[]>(initialResources);
    const [resourceListCriteria, setResourceListCriteria] = useState<ResourceListCriteria>(initialResourceListCriteria);

    const filteredResources = useMemo((): Resource[] => {
        return resources.filter((resource: Resource) => {
            const matchesAgencyId =
                resourceListCriteria.agencyId !== null
                    ? resource.dealer?.agencyId === resourceListCriteria.agencyId
                    : true;

            const matchesAdvertiserId =
                resourceListCriteria.advertiserId !== null
                    ? resource.dealer?.id === resourceListCriteria.advertiserId
                    : true;

            return matchesAgencyId && matchesAdvertiserId;
        });
    }, [resources, resourceListCriteria]);

    useEffect(() => {
        setResources(initialResources);
    }, [initialResources]);

    const resetResourceListCriteria = (): void => {
        setResourceListCriteria(initialResourceListCriteriaState);
    };

    return {
        filteredAdvertiserImportSites: filteredResources,
        advertiserImportSiteListCriteria: resourceListCriteria,
        setAdvertiserImportSiteListCriteria: setResourceListCriteria,
        resetAdvertiserImportSiteListCriteria: resetResourceListCriteria,
        advertiserImportSites: resources,
        setAdvertiserImportSites: setResources,
    };
};

export type {
    UseResourceListFilterState as UseAdvertiserImportSiteListFilterState,
    ResourceListCriteria as AdvertiserImportSiteListCriteria,
};
export { useResourceListFilter as useAdvertiserImportSiteListFilter };
