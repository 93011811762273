import { FC } from 'react';
import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { TabPanelProps } from '../../../../components/TabPanel';
import { usePrintContext } from '../../../../hooks/usePrint';
import { useHatDashboardPageContext } from '../../../../hooks/Pages/HatDashboardPage';
import HatChannelTrafficTab from './HatChannelTrafficTab';
import HatConversionTrafficTab from './HatConversionTrafficTab';
import HatTabs, { TABS } from './HatTabs';
import TabPanel from '../../../../components/TabPanel';

const OffsetTabPanel: FC<TabPanelProps> = styled((props: TabPanelProps): JSX.Element => {
    const { print } = usePrintContext();

    return (
        <TabPanel
            // Negative top margin offset to be inline with view button group
            sx={{ mt: print ? 0 : -6.5 }}
            {...props}
        />
    );
})(({ theme }) => ({}));

const HatTabPanels: FC = (): JSX.Element => {
    const { hatCriteria, tabs } = useHatDashboardPageContext();
    const { activeTab, handleChangeTab } = tabs;

    if (!hatCriteria) {
        return <></>;
    }

    return (
        <Box>
            <HatTabs value={activeTab} onChange={handleChangeTab} />

            <Box>
                <OffsetTabPanel index={TABS.CHANNEL_TRAFFIC} value={activeTab}>
                    <HatChannelTrafficTab />
                </OffsetTabPanel>
                <OffsetTabPanel index={TABS.CONVERSION_TRAFFIC} value={activeTab}>
                    <HatConversionTrafficTab />
                </OffsetTabPanel>
            </Box>
        </Box>
    );
};

export default HatTabPanels;
