import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import CampaignStatusComponent from '../CampaignStatusComponent';
import CampaignType from '../../../components/CampaignType';
import Utils from '../../../components/Utils';
import { Campaign } from '../../../types/Campaign';
import theme from '../../../theme';
import { useContext } from 'react';
import { UserContext } from '../../../App';
import { LinkOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export default function CampaignDetailsHeader(props: { campaign: Campaign }) {
    const { campaign } = props;
    const { userContext } = useContext(UserContext);
    const navigate = useNavigate();

    return (
        <>
            <Table
                sx={{
                    '& .MuiTableCell-root': {
                        px: 3,
                        py: 2,
                    },
                }}
            >
                <TableHead
                    sx={{
                        '& .MuiTableCell-root': {
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        },
                    }}
                >
                    <TableRow>
                        <TableCell>Advertiser / Campaign</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Campaign Type</TableCell>
                        <TableCell>Start / End Date</TableCell>
                        {userContext.isAdmin() && <TableCell>Fixed CPM</TableCell>}
                        <TableCell>Budget</TableCell>
                        {userContext.isAdmin() && <TableCell>DSP Budget</TableCell>}
                        <TableCell>Impression Goal</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <span
                                style={{
                                    color: theme.palette.text.secondary,
                                    fontWeight: 600,
                                }}
                            >
                                {campaign.dealer?.dealerName}
                                {(userContext.isAgency() || userContext.isAdmin()) && (
                                    <Tooltip title="Go to Advertiser ">
                                        <IconButton
                                            color="primary"
                                            sx={{ padding: '4px', marginLeft: 1, marginBottom: '1px' }}
                                            onClick={() => navigate('/advertisers/' + campaign.dealerId)}
                                        >
                                            <LinkOutlined />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </span>
                            {(userContext.isAdmin() || userContext.isAgency()) && (
                                <>
                                    <br />
                                    {campaign.campaignName}
                                </>
                            )}
                        </TableCell>

                        <TableCell sx={{ verticalAlign: 'top' }}>
                            <CampaignStatusComponent deliveryStatus={campaign.deliveryStatus} />
                        </TableCell>

                        <TableCell sx={{ verticalAlign: 'top' }}>
                            <CampaignType
                                mediaType={campaign.mediaType}
                                campaignType={campaign.campaignType}
                                strategyType={campaign.strategyType}
                            />
                        </TableCell>
                        <TableCell sx={{ verticalAlign: 'top' }}>
                            {Utils.formatESTDateLong(campaign.startDate)} - {Utils.formatESTDateLong(campaign.endDate)}
                        </TableCell>
                        {userContext.isAdmin() && (
                            <TableCell sx={{ verticalAlign: 'top' }}>
                                {Utils.formatCurrency(campaign.campaignFee.agencyFixedCPM, 2)}
                            </TableCell>
                        )}

                        <TableCell sx={{ verticalAlign: 'top' }}>{Utils.formatCurrency(campaign.budget)}</TableCell>
                        {userContext.isAdmin() && (
                            <TableCell sx={{ verticalAlign: 'top' }}>
                                {Utils.formatCurrency(campaign.dspBudget, 2)}
                            </TableCell>
                        )}
                        <TableCell sx={{ verticalAlign: 'top' }}>
                            {campaign.impressionGoal ? campaign.impressionGoal.toLocaleString() : null}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );
}
