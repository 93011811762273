import { FC } from 'react';
import { useHatConversionTrafficTable } from '../Tables/HatConversionTrafficTable';
import Column from '../../../../components/Column';
import HatConversionTrafficTable from '../Tables/HatConversionTrafficTable';

const HatConversionTrafficTableView: FC = (): JSX.Element => {
    const { rows } = useHatConversionTrafficTable();

    return (
        <Column gap={3}>
            <HatConversionTrafficTable rows={rows} />
        </Column>
    );
};

export default HatConversionTrafficTableView;
