import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';

import { AttributionPerformance } from '../types/CampaignPerformance';
import {
    AttributionStatus,
    AttributionType,
    PolkDealer,
    PolkDealerDealer,
    PolkSalesAnalysis,
    PolkSalesAnalysisSet,
    // PolkSalesWorkflow,
} from '../types/Polk';
import { Performance } from '../types/CampaignPerformance';
import { PolkSalesWorkflowSet } from '../types/Polk';
import { Dealer } from '../types/Dealer';
import { AttributionCriteriaState } from './Criteria/AttributionCriteria';
import { useAttributionCriteria } from './Criteria/AttributionCriteria';
import ApiService from '../ApiService';
import Utils from '../components/Utils';

import { Metric } from '../types/Analytics/Metric';

export const TABS = {
    POLK_REPORTED_SALES: 0,
    ADVERTISER_REPORTED_SALES: 1,
};

export class AttributionMetrics {
    totalSales: Metric | null | undefined = null;
    attributedSales: Metric | null | undefined = null;
    totalSpend: Metric | null | undefined = null;
    costPerSold: Metric | null | undefined = null;
    totalImpressions: Metric | null | undefined = null;
    totalClickthroughs: Metric | null | undefined = null;
    totalImpressionFrequency: Metric | null | undefined = null;
    totalDailyReach: Metric | null | undefined = null;

    totalMarketShares: Metric | null | undefined = null;
    totalAdShares: Metric | null | undefined = null;
}

type AttributionPageContextValue = AttributionCriteriaState & {
    dealer: Dealer | null;

    advertiser: Dealer | null;
    advertisers: Dealer[] | undefined;
    hasAdvertiser: boolean;
    hasOneAdvertiser: boolean;
    hasManyAdvertiser: boolean;

    tab: number;
    setTab: (tab: number) => void;

    attributionType: AttributionType;
    setAttributionType: (attributionType: AttributionType) => void;

    showPreviousAttributionDateMonth: boolean;
    attributionDate: Date;
    setAttributionDate: (attributionDate: Date) => void;

    attributionStatus: AttributionStatus;
    setAttributionStatus: (attributionStatus: AttributionStatus) => void;
    fetchAttributionStatus: () => void;
    isFetchingAttributionStatus: boolean;

    attributionStatusUpdateDate: Date | null;
    setAttributionStatusUpdateDate: (attributionStatusUpdateDate: Date | null) => void;
    attributionStatusUpdateDateFormatted?: string;

    advertiserSalesUpdateDate: Date | null;
    setAdvertiserSalesUpdateDate: (advertiserSalesUpdateDate: Date | null) => void;
    advertiserSalesUpdateDateFormatted?: string;

    polkDealerDealers: PolkDealerDealer[];
    setPolkDealerDealers: (polkDealerDealers: PolkDealerDealer[]) => void;
    fetchPolkDealerDealers: () => void;
    isAdvertiserPolkDealer: (polkDealer: PolkDealer) => boolean;
    isCompetitorPolkDealer: (polkDealer: PolkDealer) => boolean;
    getAdvertiserPolkDealerDealers: () => PolkDealerDealer[];
    getCompetitorPolkDealerDealers: () => PolkDealerDealer[];

    polkSalesAnalysis: PolkSalesAnalysis[];
    setPolkSalesAnalysis: (polkSalesAnalysis: PolkSalesAnalysis[]) => void;

    polkSalesAnalysisSets: PolkSalesAnalysisSet[];
    setPolkSalesAnalysisSets: (polkSalesAnalysisSets: PolkSalesAnalysisSet[]) => void;

    advertiserSalesWorkflowSets: PolkSalesWorkflowSet[] | undefined;
    polkSalesWorkflowSets: PolkSalesWorkflowSet[] | undefined;
    fetchPolkSalesWorkflowSets: () => void;
    isLoadingPolkSalesWorkflowSets: boolean;
    previousPolkSalesWorkflowSets: PolkSalesWorkflowSet[] | undefined;
    fetchPreviousPolkSalesWorkflowSets: () => void;
    isLoadingPreviousPolkSalesWorkflowSets: boolean;

    attributionPerformances: AttributionPerformance[] | undefined;
    fetchAttributionPerformances: () => void;
    isLoadingAttributionPerformances: boolean;
    previousAttributionPerformances: AttributionPerformance[] | undefined;
    fetchPreviousAttributionPerformances: () => void;
    isLoadingPreviousAttributionPerformances: boolean;

    performances: Performance[];
    setPerformances: (performances: Performance[]) => void;
    previousPerformances: Performance[];
    setPreviousPerformances: (previousPerformances: Performance[]) => void;

    isFetchingPerformances: boolean;
    setIsFetchingPerformances: (isFetchingPerformances: boolean) => void;
    isFetchingPreviousPerformances: boolean;
    setIsFetchingPreviousPerformances: (isFetchingPreviousPerformances: boolean) => void;

    getPerformances: (mediaType?: string) => Performance[];
    getPerformanceMediaTypes: () => string[];
    getPerformanceLabel: (performance: Performance) => string;

    attributionMetrics: AttributionMetrics;
    setAttributionMetrics: (attributionMetrics: AttributionMetrics) => void;
};

const AttributionPageContext = createContext<AttributionPageContextValue | undefined>(undefined);

const useAttributionPageContext = (): AttributionPageContextValue => {
    const context: AttributionPageContextValue | undefined = useContext(AttributionPageContext);
    if (!context) {
        throw new Error('useAttributionPageContext must be used within a AttributionPageProvider');
    }
    return context;
};

interface AttributionPageProviderProps {
    children: ReactNode;
    value: AttributionPageContextValue;
}

const AttributionPageProvider = ({ children, value }: AttributionPageProviderProps) => {
    return <AttributionPageContext.Provider value={value}>{children}</AttributionPageContext.Provider>;
};

type AttributionPageProps = {};

const useAttributionPage = (props?: AttributionPageProps): AttributionPageContextValue => {
    const [tab, setTab] = useState<number>(TABS.POLK_REPORTED_SALES);
    const [attributionType, setAttributionType] = useState<AttributionType>('Polk');

    const showPreviousAttributionDateMonth: boolean = useMemo(() => {
        const now = moment();

        return now.diff(moment().startOf('month'), 'days') >= 7;
    }, []);

    const $attributionCriteria: AttributionCriteriaState = useAttributionCriteria({
        attributionCriteria: {
            agencyIds: [],
            advertiserIds: [],
        },
    });
    const { attributionCriteria } = $attributionCriteria;

    const advertisers = useMemo((): Dealer[] | undefined => {
        if (attributionCriteria?.advertisers) {
            return attributionCriteria.advertisers;
        }

        return undefined;
    }, [attributionCriteria]);

    const advertiser = useMemo((): Dealer | null => {
        return advertisers?.length ? advertisers[0] : null;
    }, [advertisers]);

    const hasAdvertiser = useMemo((): boolean => {
        return advertisers ? advertisers.length > 0 : false;
    }, [advertisers]);

    const hasOneAdvertiser = useMemo((): boolean => {
        return advertisers ? advertisers.length === 1 : false;
    }, [advertisers]);

    const hasManyAdvertiser = useMemo((): boolean => {
        return advertisers ? advertisers.length > 1 : false;
    }, [advertisers]);

    const [attributionDate, setAttributionDate] = useState<Date>(() => {
        let _attributionDate = Utils.localStorage('attribution.attributionDate', null);

        if (_attributionDate) {
            _attributionDate = moment(_attributionDate).startOf('month').toDate();

            return _attributionDate;
        }

        return Utils.getMonthStart(showPreviousAttributionDateMonth ? -1 : -2);
    });

    const [attributionStatus, setAttributionStatus] = useState<AttributionStatus>({
        salesWorkflows: [],
        inProgress: true,
    });
    const [isFetchingAttributionStatus, setIsFetchingAttributionStatus] = useState<boolean>(false);

    const startDate: Date = moment(attributionDate).startOf('month').toDate();
    const endDate: Date = moment(attributionDate).endOf('month').toDate();

    const previousStartDate: Date = moment(attributionDate).subtract(1, 'month').startOf('month').toDate();
    const previousEndDate: Date = moment(attributionDate).subtract(1, 'month').endOf('month').toDate();

    const {
        data: polkSalesWorkflowSets,
        isLoading: isLoadingPolkSalesWorkflowSets,
        refetch: fetchPolkSalesWorkflowSets,
    } = useQuery({
        queryKey: [
            'attribution.polkSalesWorkflowSets',
            {
                advertiserIds: attributionCriteria.advertiserIds,
                startDate: startDate,
                endDate: endDate,
            },
        ],
        queryFn: async () => {
            const { data } = await ApiService.getPolkSalesWorkflowSets({
                advertiserIds: attributionCriteria.advertiserIds,
                startDate: startDate,
                endDate: endDate,
            });
            return data;
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const {
        data: previousPolkSalesWorkflowSets,
        isLoading: isLoadingPreviousPolkSalesWorkflowSets,
        refetch: fetchPreviousPolkSalesWorkflowSets,
    } = useQuery({
        queryKey: [
            'attribution.previousPolkSalesWorkflowSets',
            {
                advertiserIds: attributionCriteria.advertiserIds,
                startDate: previousStartDate,
                endDate: previousEndDate,
            },
        ],
        queryFn: async () => {
            const { data } = await ApiService.getPolkSalesWorkflowSets({
                advertiserIds: attributionCriteria.advertiserIds,
                startDate: previousStartDate,
                endDate: previousEndDate,
            });
            return data;
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const {
        data: attributionPerformances,
        isLoading: isLoadingAttributionPerformances,
        refetch: fetchAttributionPerformances,
    } = useQuery({
        queryKey: [
            'attribution.attributionPerformances',
            {
                dealerIds: attributionCriteria.advertiserIds,
                dataSet: 'Sales',
                startDate: startDate,
                endDate: endDate,
            },
        ],
        queryFn: async () => {
            const { data } = await ApiService.getAttributionPerformanceList({
                dealerIds: attributionCriteria.advertiserIds,
                dataSet: 'Sales',
                startDate: startDate,
                endDate: endDate,
            });
            return data;
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const {
        data: previousAttributionPerformances,
        isLoading: isLoadingPreviousAttributionPerformances,
        refetch: fetchPreviousAttributionPerformances,
    } = useQuery({
        queryKey: [
            'attribution.previousAttributionPerformances',
            {
                dealerIds: attributionCriteria.advertiserIds,
                dataSet: 'Sales',
                startDate: previousStartDate,
                endDate: previousEndDate,
            },
        ],
        queryFn: async () => {
            const { data } = await ApiService.getAttributionPerformanceList({
                dealerIds: attributionCriteria.advertiserIds,
                dataSet: 'Sales',
                startDate: previousStartDate,
                endDate: previousEndDate,
            });
            return data;
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const advertiserSalesWorkflowSets = useMemo(() => {
        return polkSalesWorkflowSets?.map((polkSalesWorkflowSet: PolkSalesWorkflowSet) => {
            if (attributionPerformances && polkSalesWorkflowSet.salesWorkflow) {
                const attributionPerformance = attributionPerformances.find(
                    (ap: AttributionPerformance) => ap.dealerId === polkSalesWorkflowSet.advertiserId
                );

                if (attributionPerformance) {
                    const updatedWorkflowResults = polkSalesWorkflowSet.salesWorkflow.workflowResults.map(
                        (result, index) =>
                            index === 0
                                ? {
                                      ...result,
                                      salesCount: attributionPerformance.totalSales,
                                      distinctUserCount: attributionPerformance.totalAdExposedSales,
                                  }
                                : result
                    );

                    return {
                        ...polkSalesWorkflowSet,
                        salesWorkflow: {
                            ...polkSalesWorkflowSet.salesWorkflow,
                            workflowResults: updatedWorkflowResults,
                        },
                    };
                }
            }

            return polkSalesWorkflowSet;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionPerformances, polkSalesWorkflowSets]);

    const [attributionStatusUpdateDate, setAttributionStatusUpdateDate] = useState<Date | null>(null);

    const attributionStatusUpdateDateFormatted: string | undefined = useMemo(() => {
        if (attributionStatusUpdateDate) {
            return moment(attributionStatusUpdateDate).format('MM/DD/YYYY [at] h:mm a');
        }
    }, [attributionStatusUpdateDate]);

    const [advertiserSalesUpdateDate, setAdvertiserSalesUpdateDate] = useState<Date | null>(null);
    const advertiserSalesUpdateDateFormatted = useMemo((): string | undefined => {
        if (advertiserSalesUpdateDate) {
            return moment(advertiserSalesUpdateDate).format('MM/DD/YYYY [at] h:mm a');
        }
    }, [advertiserSalesUpdateDate]);

    const [polkDealerDealers, setPolkDealerDealers] = useState<PolkDealerDealer[]>([]);
    const [polkSalesAnalysis, setPolkSalesAnalysis] = useState<PolkSalesAnalysis[]>([]);
    const [polkSalesAnalysisSets, setPolkSalesAnalysisSets] = useState<PolkSalesAnalysisSet[]>([]);

    const [performances, setPerformances] = useState<Performance[]>([]);
    const [previousPerformances, setPreviousPerformances] = useState<Performance[]>([]);

    const [isFetchingPerformances, setIsFetchingPerformances] = useState<boolean>(false);
    const [isFetchingPreviousPerformances, setIsFetchingPreviousPerformances] = useState<boolean>(false);

    const getPerformances = (mediaType?: string): Performance[] => {
        if (performances && performances.length) {
            return performances.filter((p: Performance) => p.mediaType === mediaType);
        }

        return [];
    };

    const getPerformanceMediaTypes = (): string[] => {
        if (performances && performances.length) {
            return performances
                .map((p: Performance) => p.mediaType)
                .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
        }

        return [];
    };

    const getPerformanceLabel = (p: Performance): string => {
        const labels = [];

        if (p?.mediaType) {
            labels.push(p.mediaType);
        }

        if (p?.campaignType) {
            labels.push(p.campaignType);
        }

        if (p?.strategyType) {
            labels.push(p.strategyType);
        }

        return labels.join(' ');
    };

    const [attributionMetrics, setAttributionMetrics] = useState<AttributionMetrics>(new AttributionMetrics());

    const fetchAttributionStatus = useCallback(() => {
        if (advertiser?.id) {
            setIsFetchingAttributionStatus(true);
            ApiService.getAttributionStatus(advertiser.id).then((response) => {
                setIsFetchingAttributionStatus(false);
                setAttributionStatus(response.data as AttributionStatus);
            });
        }
    }, [advertiser]);

    const fetchPolkDealerDealers = useCallback(() => {
        if (advertiser?.id) {
            ApiService.getPolkDealerDealers(advertiser.id).then((response) => {
                setPolkDealerDealers(response.data);
            });
        } else {
            setPolkDealerDealers([]);
        }
    }, [advertiser]);

    const isCompetitorPolkDealer = (polkDealer: PolkDealer): boolean => {
        let polkDealerDealer = null;

        if (polkDealerDealers && polkDealerDealers.length) {
            polkDealerDealer = polkDealerDealers.find((pdd: PolkDealerDealer) => pdd.polkDealer.id === polkDealer.id);
        }

        return polkDealerDealer ? polkDealerDealer.isCompetitor : false;
    };

    const isAdvertiserPolkDealer = (polkDealer: PolkDealer): boolean => {
        return isCompetitorPolkDealer(polkDealer) === false;
    };

    const getAdvertiserPolkDealerDealers = (): PolkDealerDealer[] => {
        return polkDealerDealers.filter((pdd: PolkDealerDealer) => pdd.isCompetitor === false);
    };

    const getCompetitorPolkDealerDealers = (): PolkDealerDealer[] => {
        return polkDealerDealers.filter((pdd: PolkDealerDealer) => pdd.isCompetitor === true);
    };

    useEffect(() => {
        if (attributionStatus?.salesWorkflows && attributionStatus.salesWorkflows.length) {
            setAttributionStatusUpdateDate(Utils.getDate(attributionStatus.salesWorkflows[0].updateDate));
        }
    }, [attributionStatus]);

    useEffect(() => {
        localStorage.setItem('attribution.attributionDate', moment(attributionDate).format('YYYY-MM-DD HH:mm:ss'));
    }, [attributionDate]);

    useEffect(() => {
        switch (tab) {
            case TABS.POLK_REPORTED_SALES:
                setAttributionType('Polk');
                break;

            case TABS.ADVERTISER_REPORTED_SALES:
                setAttributionType('Advertiser');
                break;
        }
    }, [tab, setAttributionType]);

    return {
        dealer: advertiser,

        advertiser,
        advertisers,
        hasAdvertiser,
        hasOneAdvertiser,
        hasManyAdvertiser,

        tab,
        setTab,

        ...$attributionCriteria,

        attributionType,
        setAttributionType,

        showPreviousAttributionDateMonth,
        attributionDate,
        setAttributionDate,

        attributionStatus,
        setAttributionStatus,
        fetchAttributionStatus,
        isFetchingAttributionStatus,

        attributionStatusUpdateDate,
        setAttributionStatusUpdateDate,
        attributionStatusUpdateDateFormatted,

        advertiserSalesUpdateDate,
        setAdvertiserSalesUpdateDate,
        advertiserSalesUpdateDateFormatted,

        polkDealerDealers,
        setPolkDealerDealers,
        fetchPolkDealerDealers,
        isAdvertiserPolkDealer,
        isCompetitorPolkDealer,
        getAdvertiserPolkDealerDealers,
        getCompetitorPolkDealerDealers,

        polkSalesAnalysis,
        setPolkSalesAnalysis,
        polkSalesAnalysisSets,
        setPolkSalesAnalysisSets,

        advertiserSalesWorkflowSets,
        polkSalesWorkflowSets,
        fetchPolkSalesWorkflowSets,
        isLoadingPolkSalesWorkflowSets,
        previousPolkSalesWorkflowSets,
        fetchPreviousPolkSalesWorkflowSets,
        isLoadingPreviousPolkSalesWorkflowSets,

        attributionPerformances,
        fetchAttributionPerformances,
        isLoadingAttributionPerformances,
        previousAttributionPerformances,
        fetchPreviousAttributionPerformances,
        isLoadingPreviousAttributionPerformances,

        performances,
        setPerformances,
        previousPerformances,
        setPreviousPerformances,

        isFetchingPerformances,
        setIsFetchingPerformances,
        isFetchingPreviousPerformances,
        setIsFetchingPreviousPerformances,

        getPerformances,
        getPerformanceMediaTypes,
        getPerformanceLabel,

        attributionMetrics,
        setAttributionMetrics,
    };
};

export type { AttributionPageContextValue };
export { AttributionPageContext };
export { useAttributionPageContext };

export type { AttributionPageProviderProps };
export { AttributionPageProvider };

export { useAttributionPage };
