import Heading from '../../components/Heading';
import { ChangeEvent, useEffect, useState } from 'react';
import { IconButton, InputAdornment, SelectChangeEvent, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import ApiService from '../../ApiService';
import { Agency } from '../../types/Agency';
import { LinkButton } from '../../components/LinkButton';
import ActiveStatus from '../../components/ActiveStatus';
import Block from '../../components/Block';
import CDGrid from '../../components/CDGrid';
import Column from '../../components/Column';
import ActiveStatusRadioSelect from '../../components/RadioSelect/ActiveStatusRadioSelect';
import Row from '../../components/Row';

export default function AgencyListPage() {
    const [agencies, setAgencies] = useState<Agency[]>([]);
    const [allAgencies, setAllAgencies] = useState<Agency[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const [activeStatusFilter, setActiveStatusFilter] = useState('all');
    const navigate = useNavigate();

    useEffect(() => {
        ApiService.getAgencies()
            .then(function (response) {
                setAllAgencies(response.data);
                setAgencies(response.data);
                filterRows(response.data, '', activeStatusFilter);
            })
            .catch(() => {
                setAgencies([]);
            });
    }, []);

    const handleStatusChange = (event: SelectChangeEvent<string>): void => {
        const status = event.target.value;
        setActiveStatusFilter(status);
        filterRows(allAgencies, searchText, status);
    };

    function clearSearch() {
        setSearchText('');
        filterRows(allAgencies, '', activeStatusFilter);
    }

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setSearchText(event.target.value);
        filterRows(allAgencies, event.target.value, activeStatusFilter);
    }

    function filterRows(agencies: Agency[], searchText: string, activeStatus: string) {
        const filteredRows = agencies.filter((row: Agency) => {
            const matchesSearch: boolean = row.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            const matchesActiveStatus =
                activeStatus === 'all'
                    ? true
                    : activeStatus === 'active'
                    ? row.activeStatus === true
                    : row.activeStatus === false;

            return matchesSearch && matchesActiveStatus;
        });
        setAgencies(filteredRows);
    }

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Heading>Agencies</Heading>

                        <Row justifyContent="flex-end">
                            <LinkButton href="new" variant="contained" color="primary">
                                Add Agency
                            </LinkButton>
                        </Row>
                    </Row>
                </Column>
            </Block>

            <Block flex={1}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <Row justifyContent="space-between">
                        <Row>
                            <ActiveStatusRadioSelect
                                aria-label="status"
                                name="status"
                                value={activeStatusFilter}
                                onChange={handleStatusChange}
                            />
                        </Row>

                        <TextField
                            inputRef={(input) => input && input.focus()}
                            id="searchText"
                            size="small"
                            value={searchText}
                            label="Search Agencies"
                            onChange={search}
                            InputProps={{
                                startAdornment: <SearchOutlined color="action" fontSize="small" />,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => clearSearch()} edge="end">
                                            <ClearOutlined color="action" fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Row>

                    <CDGrid
                        autoHeight={false}
                        autoPageSize={true}
                        columns={[
                            {
                                width: 95,
                                field: 'id',
                                type: 'number',
                                headerName: 'Id',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 250,
                                field: 'name',
                                headerName: 'Agency Name',
                                headerClassName: 'grid-header',
                            },
                            {
                                width: 200,
                                field: 'hostname',
                                headerName: 'Portal Hostname',
                                headerClassName: 'grid-header',
                                flex: 1,
                            },
                            {
                                width: 80,
                                field: 'activeStatus',
                                headerName: 'Status',
                                headerClassName: 'grid-header',
                                flex: 0.5,
                                renderCell: (params) => {
                                    let activeStatus = params.value as boolean;
                                    return <ActiveStatus activeStatus={activeStatus} />;
                                },
                            },
                        ]}
                        onRowClick={(params) => {
                            navigate(String(params.row.id));
                        }}
                        rows={agencies === null ? [] : agencies}
                    />
                </Column>
            </Block>
        </Column>
    );
}
