import AppBreadcrumbs from '../../components/AppBreadcrumbs';
import Block from '../../components/Block';
import Column from '../../components/Column';
import { useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import { AmcAttribution } from '../../types/AmcAttribution';
import { useParams } from 'react-router-dom';
import AmcAttributionForm from './AmcAttributionForm';

export default function AmcAttributionEdit() {
    const params = useParams();
    const id = params.id ? +params.id : null;
    const [amcAttribution, setAmcAttribution] = useState<AmcAttribution>(new AmcAttribution());

    useEffect(() => {
        if (id) {
            ApiService.getAmcAttribution(id).then((response) => {
                setAmcAttribution(response.data);
            });
        }
    }, [id]);

    return (
        <>
            <Block>
                <Column gap={1}>
                    <AppBreadcrumbs
                        items={[
                            { label: 'AMC Offline Attributions', href: '/amcAttributionList' },
                            { label: 'AMC Offline Attribution' },
                        ]}
                    />
                </Column>
            </Block>

            <Block>
                <Column gap={2}>
                    <AmcAttributionForm
                        size="md"
                        amcAttribution={amcAttribution}
                        onSave={(_amcAttribution: AmcAttribution) => {}}
                    />
                </Column>
            </Block>
        </>
    );
}
