interface UseState {
    appSidebarWidth: number;
    appSidebarOffset: number;
}

const useAppSidebar = (): UseState => {
    const appSidebarWidth: number = 256 + 24;
    const appSidebarOffset: number = appSidebarWidth;

    return {
        appSidebarWidth,
        appSidebarOffset,
    };
};

export type { UseState as UseAppSidebarState };
export { useAppSidebar };
