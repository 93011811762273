import { useCallback, useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';
import { Agency } from '../../../../types/Agency';
import { Dealer as Advertiser } from '../../../../types/Dealer';
import { ReportingCriteria } from '../../../../types/Criteria/ReportingCriteria';
import { useAdvertiserContext } from '../../../../hooks/Contexts/AdvertiserContext';
import { useAgencyContext } from '../../../../hooks/Contexts/AgencyContext';
import ApiService from '../../../../ApiService';
import Row from '../../../../components/Row';

interface Image {
    url: string;
    name: string;
    caption: string;
}

interface ReportingPerformanceExportLogoProps {
    reportingCriteria: ReportingCriteria;
}

const ReportingPerformanceExportLogo = ({ reportingCriteria }: ReportingPerformanceExportLogoProps) => {
    const defaultAgency: Agency | null = useAgencyContext();
    const { advertiserContext } = useAdvertiserContext();
    const { dealer: defaultAdvertiser } = advertiserContext;

    const [agency, setAgency] = useState<Agency | null>(defaultAgency || null);
    const [advertiser, setAdvertiser] = useState<Advertiser | null>(defaultAdvertiser || null);

    const fetchAdvertiser = useCallback(async (advertiserId: number) => {
        try {
            const response = await ApiService.getAdvertiser(advertiserId);
            setAdvertiser((prev) => (prev?.id !== response.data.id ? response.data : prev));
        } catch (error) {
            console.error('Failed to fetch advertiser', error);
        }
    }, []);

    const fetchAgency = useCallback(async (agencyId: number) => {
        try {
            const response = await ApiService.getAgency(agencyId);
            setAgency((prev) => (prev?.id !== response.data.id ? response.data : prev));
        } catch (error) {
            console.error('Failed to fetch agency', error);
        }
    }, []);

    useEffect(() => {
        if (reportingCriteria.dealerIds.length === 1) {
            fetchAdvertiser(reportingCriteria.dealerIds[0]);
        }
        if (reportingCriteria.agencyIds.length === 1) {
            fetchAgency(reportingCriteria.agencyIds[0]);
        }
    }, [reportingCriteria.dealerIds, reportingCriteria.agencyIds, fetchAdvertiser, fetchAgency]);

    const logo = useMemo((): Image | null => {
        if (advertiser) {
            return {
                url: advertiser.logoUrl,
                name: advertiser.dealerName,
                caption: `${advertiser.dealerName}'s Logo`,
            };
        }

        if (agency) {
            return {
                url: agency.logoUrl,
                name: agency.name,
                caption: `${agency.name}'s Logo`,
            };
        }

        return null;
    }, [agency, advertiser]);

    if (logo === null) {
        return null;
    }

    return (
        <Row justifyContent="center" alignItems="center">
            {logo.url && logo.url.length > 0 ? (
                <img src={logo.url} alt={logo.caption} style={{ maxHeight: 128 }} />
            ) : (
                <Typography variant="h4">{logo.name}</Typography>
            )}
        </Row>
    );
};

export type { ReportingPerformanceExportLogoProps };
export default ReportingPerformanceExportLogo;
