import { BaseCampaign, CampaignAdGroup } from './Campaign';
import { Dealer } from './Dealer';

export interface AdTagTemplate {
    id: number;
    name: string;
    template: string;
}

export enum AdTagType {
    VAST_VIDEO = 'VAST Video',
    HTML_IMAGE = 'HTML Image',
}

export enum DspPlatform {
    AMAZON = 'Amazon',
    DV360 = 'DV360',
    TTD = 'TTD',
}

export enum AdSize {
    '160x600' = '160x600',
    '300x600' = '300x600',
    '300x250' = '300x250',
    '728x90' = '728x90',
}

export enum AdTagPlacementStatus {
    READY = 'ready',
    WAITING = 'waiting',
    FINISHED_PARTIAL = 'finished-partial',
    FINISHED = 'finished',
    CANCELED = 'canceled',
    ARCHIVED = 'archived',
    ERROR = 'error',
}

export enum AdCreativeSource {
    FTP = 'FTP',
    CREATIVE_STUDIO = 'Creative Studio',
}

export interface AdTagPlacement {
    id?: number;
    dealerId: number;
    dealer: Dealer;
    campaignId?: number;
    campaign: BaseCampaign;
    campaignAdGroupId?: number;
    campaignAdGroup: CampaignAdGroup;
    dspPlatform: keyof typeof DspPlatform;
    projectId: string | null | undefined;
    adName: string | null;
    clickThroughUrl: string | null | undefined;
    impressionUrl: string | null | undefined;
    tagType: keyof typeof AdTagType;
    adTagTemplateId?: number;
    adTagTemplate?: AdTagTemplate;
    adSize?: keyof typeof AdSize;
    adUrl: string | null | undefined;
    adCode: string | null | undefined;
    adCreativeSource: keyof typeof AdCreativeSource;
    adTagImportSourceId: number | null;
    adTagImportSource: AdTagImportSource;
    filenameKey: string | null;
    filename: string | null;
    status: string | null | undefined;
    processMessage: string | null;
    createdDate: number; // Unix timestamp
    updatedDate: number; // Unix timestamp
}

export type AdTagPlacementData = Pick<
    AdTagPlacement,
    | 'id'
    | 'dealerId'
    | 'campaignId'
    | 'campaignAdGroupId'
    | 'adTagTemplateId'
    | 'dspPlatform'
    | 'projectId'
    | 'adUrl'
    | 'adName'
    | 'clickThroughUrl'
    | 'impressionUrl'
    | 'tagType'
    | 'adTagTemplateId'
    | 'adSize'
    | 'adCreativeSource'
    | 'adTagImportSourceId'
    | 'filenameKey'
    | 'filename'
    | 'status'
>;

export interface AdTagImportSource {
    id: number;
    s3Bucket: string;
    s3IncomingPath: string;
    s3DeliveredPath: string;
    s3Region: string;
}

// Creative Studio Interfaces
export interface Project {
    id: string;
    output: string;
    template: Template;
    'render-status': string;
    'encoding-profiles': string;
    'vast-tag': string;
    'html-tag': string;
    'template-id': number;
    'html-code': string;
    'data-source': string;
    'render-upload-s3': RenderUploadS3;
}

export interface Template {
    target: string;
    template: string;
}

export interface RenderUploadS3 {
    status: string;
    list: RenderUploadItem[];
}

export interface RenderUploadItem {
    name: string;
    status: string;
    url: string;
}
