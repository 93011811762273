import { Box, Button, Typography } from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Column from '../../components/Column';
import Row from '../../components/Row';

export default function CRMDataUploadSetupSuccessful(props: { resetSetup: Function }) {
    const { resetSetup } = props;

    const navigate = useNavigate();

    return (
        <Box sx={{ mx: 3, my: 9 }}>
            <Column gap={3} justifyContent="center" alignItems="center" sx={{ p: 5 }}>
                <CheckCircleIcon color="success" sx={{ fontSize: 72 }} />

                <Column gap={1} justifyContent="center" alignItems="center">
                    <Typography variant="h6" sx={{ fontSize: 20 }}>
                        Your data was uploaded successfully!
                    </Typography>

                    <Typography variant="body2">
                        You will receive an email notification once processing is complete.
                    </Typography>
                </Column>

                <Row gap={1} justifyContent="center" alignItems="center">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            resetSetup();
                        }}
                    >
                        Upload More Data
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => {
                            window.history.pushState(null, '', '');
                            navigate('/crmUploadResults');
                        }}
                    >
                        View Upload Results
                    </Button>
                </Row>
            </Column>
        </Box>
    );
}
