import UserImpersonationAlert from './User/UserImpersonationAlert';

const AppHeader = () => {
    return (
        <>
            <UserImpersonationAlert />
        </>
    );
};

export default AppHeader;
