import { Breadcrumbs, Link, Typography } from '@mui/material';
import { ChevronRight as ChevronRightIcon } from '@mui/icons-material';
import { Home as HomeIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';

interface AppBreadcrumbItem {
    href?: any;
    label: string;
}

interface AppBreadcrumbsProps {
    items?: AppBreadcrumbItem[];
    disableHome?: boolean;
}

export default function AppBreadcrumbs({ items = [], disableHome = true, ...props }: AppBreadcrumbsProps) {
    return (
        <div role="presentation">
            <Breadcrumbs
                maxItems={3}
                aria-label="breadcrumb"
                separator={<ChevronRightIcon color="action" fontSize="small" />}
            >
                {disableHome === false && (
                    <Link
                        component={RouterLink}
                        underline="hover"
                        color="inherit"
                        to="/"
                        sx={{ display: 'flex', alignItems: 'center' }}
                    >
                        <HomeIcon fontSize="small" />
                    </Link>
                )}

                {items.map((item: AppBreadcrumbItem, index: number) => {
                    if (item.href) {
                        return (
                            <Link
                                key={index}
                                component={RouterLink}
                                underline="hover"
                                color="text.secondary"
                                to={item.href}
                            >
                                {item.label}
                            </Link>
                        );
                    }

                    return (
                        <Typography key={index} variant="body1" color="text.primary">
                            {item.label}
                        </Typography>
                    );
                })}
            </Breadcrumbs>
        </div>
    );
}
