import { useContext } from 'react';
import { Box, Button, Card, CardContent, CardHeader, Divider, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

import { CreativePixel } from '../../../../types/HAT/CreativePixel';
import { InfoMessageContext } from '../../../../components/GlobalInfoMessage';
import Row from '../../../../components/Row';

interface CreativePixelCardProps {
    creativePixel: CreativePixel;
}

const CreativePixelCard = (props: CreativePixelCardProps) => {
    const { creativePixel } = props;
    const { showMessage } = useContext(InfoMessageContext);

    const handleCopy = (): void => {
        navigator.clipboard.writeText(creativePixel.code);
        showMessage('success', 'Copied to clipboard!');
    };

    return (
        <Card variant="outlined">
            <CardHeader
                title={
                    <Row alignItems="center">
                        <Typography variant="subtitle2" color="text.primary" fontWeight={500}>
                            {creativePixel.type}
                        </Typography>
                    </Row>
                }
                sx={{ backgroundColor: grey[50] }}
            />

            <Divider />

            <CardContent sx={{ backgroundColor: grey[50], p: 3 }}>
                <Row justifyContent="space-between">
                    <Box
                        sx={{
                            width: 0.75,
                            display: '-webkit-box',
                            WebkitBoxOrient: 'vertical',
                            wordBreak: 'break-word',
                            overflow: 'hidden',
                        }}
                    >
                        <Typography variant="body2" color="text.secondary">
                            {creativePixel.code}
                        </Typography>
                    </Box>

                    <Box flex={0}>
                        <Tooltip title="Copy to clipboard">
                            <Button variant="contained" size="medium" color="primary" onClick={handleCopy}>
                                Copy
                            </Button>
                        </Tooltip>
                    </Box>
                </Row>
            </CardContent>
        </Card>
    );
};

export type { CreativePixelCardProps };
export default CreativePixelCard;
