import {
    FormControl,
    FormControlProps,
    InputLabel,
    InputLabelProps,
    ListItemText,
    ListItemTextProps,
    MenuItem,
    MenuItemProps,
    OutlinedInput,
    Radio,
    RadioProps,
    Select,
    SelectProps,
} from '@mui/material';

interface RadioSelectOption {
    value: string;
    label: string;
}

type RadioSelectProps = Omit<SelectProps<string>, 'value'> & {
    defaultValue?: string;
    value: string;
    options: RadioSelectOption[];

    slotProps?: {
        formControl?: FormControlProps;
        inputLabel?: InputLabelProps;
        listItemText?: ListItemTextProps;
        radio?: RadioProps;
        menuItem?: MenuItemProps;
    };
};

const RadioSelect = (props: RadioSelectProps) => {
    const { options, slotProps = {}, ...selectProps } = props;

    return (
        <FormControl sx={{ width: 224 }} {...slotProps?.formControl}>
            <InputLabel shrink {...slotProps?.inputLabel}>
                Status
            </InputLabel>
            <Select
                displayEmpty={true}
                input={<OutlinedInput label="Status" />}
                renderValue={(value: string) => options.find((option) => option.value === value)?.label || 'Select'}
                size="small"
                {...selectProps}
                multiple={false}
            >
                {options.map((option: RadioSelectOption) => (
                    <MenuItem key={option.value} value={option.value}>
                        <Radio checked={props.value === option.value} value={option.value} {...slotProps?.radio} />

                        <ListItemText primary={option.label} {...slotProps?.listItemText} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export type { RadioSelectProps, RadioSelectOption };
export default RadioSelect;
