import { useEffect } from 'react';
import { ReportingCriteria } from '../../../../../types/Criteria/ReportingCriteria';
import { useConversionDataGrid } from '../DataGrids/ConversionDataGrid';
import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import ConversionDataGrid from '../DataGrids/ConversionDataGrid';

type ReportingPerformanceOnSiteInteractionTabProps = {
    reportingCriteria: ReportingCriteria;
};

const ReportingPerformanceOnSiteInteractionTab = (props: ReportingPerformanceOnSiteInteractionTabProps) => {
    const { reportingCriteria } = props;

    const { setOnSiteInteractions } = useReportingDashboardPageContext();

    const dataGrid = useConversionDataGrid({ reportingCriteria });
    const { rows = [] } = dataGrid;
    const { paginationModel, setPaginationModel } = dataGrid;
    const { sortModel, setSortModel } = dataGrid;
    const { query } = dataGrid;
    const { data, isLoading } = query;

    useEffect(() => {
        setOnSiteInteractions(rows);
    }, [rows, setOnSiteInteractions]);

    return (
        <>
            <ConversionDataGrid
                loading={isLoading}
                rows={rows}
                rowCount={data?.totalElements}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
            />
        </>
    );
};

export type { ReportingPerformanceOnSiteInteractionTabProps };
export default ReportingPerformanceOnSiteInteractionTab;
