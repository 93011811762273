import React, { useEffect, useState } from 'react';
import { DmaMapping } from '../../types/DspEntity';
import { ITab } from './DspAdGroupDictsTab';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ApiService from '../../ApiService';
import { AlertColor, Box, Button, Modal, TextField, Tooltip, Typography } from '@mui/material';
import theme from '../../theme';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AccessGuard from '../../components/AccessGuard';
import { USER_GROUP_ADMIN } from '../../types/User';
import TabPanel from '../../components/TabPanel';
import Column from '../../components/Column';
import Row from '../../components/Row';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import CDGrid, { CustomPagination } from '../../components/CDGrid';
import { GridColumns, GridFooterContainer, GridSortModel } from '@mui/x-data-grid';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import { AxiosPageableResponse } from '../../types/AxiosPageableResponse';

function DmaGridFooter(props: { totalElements: number; numberOfElements: number }) {
    return (
        <GridFooterContainer>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px' }}>
                <CustomPagination />
                <span>
                    Showing {props.numberOfElements} of {props.totalElements} records
                </span>
            </div>
        </GridFooterContainer>
    );
}

const DmaDataMappingTab = ({ tab, activeTab }: { tab: ITab; activeTab: number }) => {
    const defaultPageSize: number = 50;
    const pageValueArray = [10, 20, 50, 75, 100];
    const [list, setList] = useState<DmaMapping[] | null>(null);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [dma, setDma] = useState('');
    const [dmaDescription, setDmaDescription] = useState('');
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [numberOfElements, setNumberOfElements] = useState(0);
    const [country, setCountry] = useState('');
    const [open, setOpen] = useState(false);
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'id', sort: 'desc' }]);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = parseInt(event?.target?.value);
        if (pageValueArray.includes(value)) {
            setPageSize(value);
        } else {
            // Handle invalid value or reset to default
            setPageSize(defaultPageSize);
        }
    };

    function onPageChange(page: number) {
        setPage(page);
    }

    const resetForm = () => {
        setCity('');
        setState('');
        setZip('');
        setDma('');
        setCountry('');
        setDmaDescription('');
    };

    const handleClose = () => {
        resetForm();
        setOpen(false);
    };

    const handleOpen = () => {
        resetForm();
        setOpen(true);
    };

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    const editModalStyles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        borderRadius: '8px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const fetchData = async () => {
        const response: AxiosPageableResponse<DmaMapping> = await ApiService.getDmaMappings(page, pageSize, sortModel);
        if (response.status === 200) {
            setList(response.data.content);
            setTotalElements(response.data.totalElements);
            setNumberOfElements(response.data.numberOfElements);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, pageSize, sortModel]);

    //function to send Data for Dma Creation
    function dmaMappingSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        ApiService.addDmaMapping({
            city: city,
            state: state,
            zip: zip,
            dma: dma,
            dmaDescription: dmaDescription,
            country: country,
        }).then((newItem) => {
            if (!list) return;
            setList([...list, newItem.data]);
            showInfoMessage('success', 'DMA has been successfully created.');
        });
        handleClose();
    }

    const columns: GridColumns = [
        {
            flex: 1,
            minWidth: 100,
            field: 'id',
            headerName: 'ID',
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'dmaDescription',
            headerName: 'DMA Description',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        DMA Description
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            preProcessEditCellProps: (params) => {
                return {
                    ...params.props,
                    error: !params.props.value?.trim(),
                };
            },
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'dma',
            headerName: 'DMA Code',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        DMA Code
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            preProcessEditCellProps: (params) => {
                return {
                    ...params.props,
                    error: !params.props.value?.trim(),
                };
            },
        },
        {
            flex: 2,
            minWidth: 100,
            field: 'city',
            headerName: 'City',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        City
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            preProcessEditCellProps: (params) => {
                return {
                    ...params.props,
                    error: !params.props.value?.trim(),
                };
            },
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'state',
            headerName: 'State',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        State
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            preProcessEditCellProps: (params) => {
                return {
                    ...params.props,
                    error: !params.props.value?.trim(),
                };
            },
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'zip',
            headerName: 'Zip',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        Zip
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            preProcessEditCellProps: (params) => {
                return {
                    ...params.props,
                    error: !params.props.value?.trim(),
                };
            },
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'country',
            headerName: 'Country',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        Country
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            preProcessEditCellProps: (params) => {
                return {
                    ...params.props,
                    error: !params.props.value?.trim(),
                };
            },
        },
    ];

    return (
        <AccessGuard accessGroup={USER_GROUP_ADMIN}>
            <TabPanel index={tab.id} value={activeTab} sx={{ p: 0 }}>
                <InfoMessage {...infoMessage} />
                <Column gap={2}>
                    <Row justifyContent="space-between" alignItems="center">
                        <Row gap={0} sx={{ width: '100%' }}>
                            <Typography component="span" color="text.primary" variant="subtitle2" fontWeight={500}>
                                Instruction:{' '}
                                <Typography
                                    component="span"
                                    color="text.secondary"
                                    variant="subtitle2"
                                    fontWeight={500}
                                >
                                    {tab.description}
                                </Typography>
                            </Typography>
                        </Row>
                        <Row flex={0} justifyContent="flex-end">
                            <Button variant="text" onClick={handleOpen} sx={{ marginTop: '-1%' }}>
                                Add DMA
                            </Button>

                            <FormControl sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Rows Per Page</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={pageSize.toString()}
                                    label="Rows Per Page"
                                    onChange={handleChange}
                                >
                                    {pageValueArray?.map((value) => {
                                        return (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Row>
                    </Row>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={editModalStyles}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                                Add DMA
                            </Typography>
                            <form onSubmit={dmaMappingSubmit} style={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 2,
                                        mt: 2,
                                        width: '100%',
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        label="DMA Description"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e) => setDmaDescription(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="DMA Code"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e) => setDma(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="City"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e) => setCity(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="State"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e) => setState(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Zip"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e) => setZip(e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Country"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e) => setCountry(e.target.value)}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '120px' }}
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ width: '120px' }}
                                        onClick={handleClose}
                                        startIcon={<CloseIcon />}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Modal>
                    <CDGrid
                        id="dmaMapping"
                        variant="contained"
                        rowCount={totalElements}
                        pageSize={pageSize}
                        columns={columns}
                        page={page}
                        onPageChange={onPageChange}
                        onSortModelChange={setSortModel}
                        paginationMode={'server'}
                        sortingMode={'server'}
                        experimentalFeatures={{ newEditingApi: true }}
                        rows={list === null ? [] : list}
                        processRowUpdate={(newRow) => {
                            ApiService.updateDmaMapping(newRow);
                            setList((prevRows) =>
                                prevRows === null
                                    ? []
                                    : prevRows.map((row) => {
                                          if (row.id === newRow.id) {
                                              return newRow;
                                          } else {
                                              return row;
                                          }
                                      })
                            );
                            return newRow;
                        }}
                        onProcessRowUpdateError={() => {}}
                        components={{
                            Footer: DmaGridFooter,
                        }}
                        componentsProps={{
                            footer: {
                                pageSize,
                                totalElements,
                                numberOfElements,
                            },
                        }}
                    />
                </Column>
            </TabPanel>
        </AccessGuard>
    );
};

export default DmaDataMappingTab;
