import { useQuery } from 'react-query';
import ApiService from '../../../ApiService';

const useAuthToken = (advertiserId: number | undefined) => {
    return useQuery({
        queryKey: ['hat/authToken/advertiser', advertiserId],
        queryFn: async () => {
            if (advertiserId === undefined || advertiserId <= 0) {
                return undefined;
            }
            const { data } = await ApiService.getHatConfig(advertiserId);
            return data;
        },
        enabled: !!advertiserId && advertiserId > 0,
        refetchOnWindowFocus: false,
    });
};

export { useAuthToken };
