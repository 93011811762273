import Block from '../../components/Block';
import Column from '../../components/Column';
import Row from '../../components/Row';
import Heading from '../../components/Heading';
import DealerImportSites from '../dealers/DealerImportSites';

export default function ImportListPage() {
    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Heading>Import Manager</Heading>

                        <Row justifyContent="flex-end"></Row>
                    </Row>
                </Column>
            </Block>

            <Block>
                <Column gap={2}>
                    <DealerImportSites />
                </Column>
            </Block>
        </Column>
    );
}
