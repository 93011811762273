import { List, ListSubheader } from '@mui/material';

import { SidebarMenuItem } from './index';
import AccessGuard from '../AccessGuard';
import SidebarMenuListItem from './SidebarMenuListItem';

interface SidebarMenuListProps {
    items: SidebarMenuItem[];
    subheader?: React.ReactElement;
    onCollapse?: (item: SidebarMenuItem, index: number) => void;
}

const SidebarMenuList = (props: SidebarMenuListProps) => {
    const { items, subheader, onCollapse } = props;

    return (
        <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            component="nav"
            subheader={subheader ? <ListSubheader component="div">{subheader}</ListSubheader> : null}
        >
            {items.map((item: SidebarMenuItem, index: number) => {
                return (
                    <AccessGuard
                        key={index}
                        accessGroup={item?.accessGroup || ''}
                        accessAgency={item?.accessAgency || ''}
                    >
                        <SidebarMenuListItem
                            item={item}
                            handleCollapse={(newItem: SidebarMenuItem) => {
                                if (onCollapse) {
                                    onCollapse(newItem, index);
                                }
                            }}
                            slotProps={{
                                listItemButton: { sx: { py: 1.5 } },
                            }}
                        />
                    </AccessGuard>
                );
            })}
        </List>
    );
};

export type { SidebarMenuListProps };
export default SidebarMenuList;
