import { Agency } from '../../types/Agency';
import { Dealer } from '../../types/Dealer';
import { User } from '../../types/User';
import { useAdvertiserContext } from '../Contexts/AdvertiserContext';
import { useUserContext } from '../Contexts/UserContext';

interface UseState {
    isUserImpersonating: () => boolean;
    startUserImpersonation: (user: User) => void;
    stopUserImpersonation: () => void;
}

const useUserImpersonation = (): UseState => {
    const { setDealer: setAdvertiser } = useAdvertiserContext();
    const { userContext, setUserContext } = useUserContext();

    // Utilities

    const resetLocalStorage = (): void => {
        const localStorageKeys: string[] = ['attribution.criteria', 'reporting.criteria'];

        localStorageKeys.forEach((localStorageKey: string): void => {
            localStorage.removeItem(localStorageKey);
        });
    };

    const resetImpersonation = (): void => {
        resetLocalStorage();
        setAdvertiser(null);
    };

    //

    const isUserImpersonating = (): boolean => {
        return userContext.adminContext ? true : false;
    };

    const startUserImpersonation = (user: User): void => {
        let agency: Partial<Agency> | undefined | null = user.agency;

        if (!agency) {
            agency = user?.dealers.find((dealer: Dealer) => dealer.agency !== null)?.agency || null;
        }

        resetImpersonation();
        setUserContext(
            user.id,
            user.userName,
            user.name,
            user.group,
            user.agencyId,
            user.agency?.name,
            (agency as Agency) || undefined
        );
    };

    const stopUserImpersonation = (): void => {
        if (isUserImpersonating()) {
            setUserContext(null, null, undefined, undefined, null, undefined);
        }
        resetImpersonation();
    };

    return {
        isUserImpersonating,
        startUserImpersonation,
        stopUserImpersonation,
    };
};

export type { UseState as UseUserImpersonationState };
export { useUserImpersonation };
