import { FC } from 'react';
import { Box, BoxProps, Tab, Tabs, TabsProps } from '@mui/material';

import { SectionHeading } from '../../../../components/SectionHeading';
import Printable from '../../../../components/Printable';

const TABS = {
    CHANNEL_TRAFFIC: 0,
    CONVERSION_TRAFFIC: 1,
};

type HatTabsProps = {
    boxProps?: BoxProps;
} & TabsProps;

const HatTabs: FC<HatTabsProps> = (props: HatTabsProps): JSX.Element => {
    const { boxProps, ...tabsProps } = props;
    const tab: number = props.value;

    return (
        <Box
            sx={{
                // borderBottom: 1,
                // borderColor: 'divider',
                mt: 2,
            }}
            {...boxProps}
        >
            <Printable show={false}>
                <Tabs {...tabsProps}>
                    <Tab value={TABS.CHANNEL_TRAFFIC} label="Visits" />
                    <Tab value={TABS.CONVERSION_TRAFFIC} label="Conversions" />
                </Tabs>
            </Printable>
            <Printable show={true}>
                <Box sx={{ mb: 2 }}>
                    {tab === TABS.CHANNEL_TRAFFIC && <SectionHeading divider={false}>Channel Traffic</SectionHeading>}
                    {tab === TABS.CONVERSION_TRAFFIC && (
                        <SectionHeading divider={false}>Conversion Traffic</SectionHeading>
                    )}
                </Box>
            </Printable>
        </Box>
    );
};

export type { HatTabsProps };
export { TABS };
export default HatTabs;
