import { useRef } from 'react';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Article } from '@mui/icons-material';

const AppToolbarReleaseNoteArticlesButton = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();

    const handleOpenPopover = (): void => {
        navigate('/release-notes');
    };

    return (
        <>
            <Tooltip title="Release Notes">
                <IconButton ref={anchorRef} onClick={handleOpenPopover}>
                    <Badge color="error">
                        <Article color="action" fontSize="medium" />
                    </Badge>
                </IconButton>
            </Tooltip>
        </>
    );
};

export default AppToolbarReleaseNoteArticlesButton;
