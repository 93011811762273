import { styled } from '@mui/material';
import { Avatar, AvatarProps } from '@mui/material';

const SidebarMenuAvatar = styled((props: AvatarProps) => <Avatar variant="rounded" {...props} />)(({ theme }) => ({
    fontFamily: 'Inter',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '157%',
    letterSpacing: '0.1px',
    width: 34,
    height: 30,
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
}));

export default SidebarMenuAvatar;
