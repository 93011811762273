const PAGEABLE_SIZE = 20;

interface PageableRequest {
    page: number;
    size: number;
    sort?: 'asc' | 'desc';
    sortBy?: string;
}

interface Pageable<T> {
    content: T[];
    totalElements: number;
    totalPages: number;
    numberOfElements: number;
    size: number;
    number: number;
    first: boolean;
    last: boolean;
}

interface AxiosPageableResponse<T> {
    data: Pageable<T>;
    status: number;
    statusText: string;
    headers: any;
    config: any;
    request?: any;
}

interface AxiosInfinitePageableResponse<T> {
    items: T[];
    nextPage?: number | undefined;
}

export type {
    PageableRequest,
    Pageable as PageableResponse,
    Pageable,
    AxiosPageableResponse,
    AxiosInfinitePageableResponse,
};

export { PAGEABLE_SIZE };
