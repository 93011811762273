import { useRef, useEffect, useCallback } from 'react';
import { CancelTokenSource, AxiosRequestConfig } from 'axios';
import { withAxiosCancelSourceToken } from '../utils/AxiosCancelSourceToken';

interface UseState {
    withAxiosCancelTokenRequestConfig: <T extends AxiosRequestConfig>(
        defaultRequestConfig?: T | undefined,
        id?: string
    ) => AxiosRequestConfig;
    cancelTokenById: (id: string) => void;
    cancelAllTokens: () => void;
}

const useAxiosCancelTokens = (): UseState => {
    // Store cancel token sources keyed by an id.
    const cancelTokensRef = useRef<Record<string, CancelTokenSource>>({});

    /**
     * Generates a new cancelable Axios request config for the provided id.
     * If there's an existing token for that id, it cancels it before creating a new one.
     */
    const withAxiosCancelTokenRequestConfig = useCallback(
        <T extends AxiosRequestConfig>(defaultRequestConfig?: T | undefined, id: string = ''): AxiosRequestConfig => {
            // Cancel any existing token for this id.
            if (cancelTokensRef.current[id]) {
                cancelTokensRef.current[id].cancel(`Request for "${id}" canceled due to new action.`);
            }

            const { requestConfig, cancelTokenSource } = withAxiosCancelSourceToken(
                defaultRequestConfig || { headers: { XBusy: 'false' } }
            );

            // Store the new cancel token source under the id.
            cancelTokensRef.current[id] = cancelTokenSource;

            return requestConfig;
        },
        []
    );

    /**
     * Cancels the token associated with the provided id.
     */
    const cancelTokenById = useCallback((id: string) => {
        if (cancelTokensRef.current[id]) {
            cancelTokensRef.current[id].cancel(`Request for "${id}" canceled manually.`);
            delete cancelTokensRef.current[id];
        }
    }, []);

    /**
     * Cancels all active tokens.
     */
    const cancelAllTokens = useCallback(() => {
        Object.keys(cancelTokensRef.current).forEach((id) => {
            cancelTokensRef.current[id].cancel(`Request for "${id}" canceled by cancelAllTokens.`);
        });
        cancelTokensRef.current = {};
    }, []);

    // Cleanup: cancel any pending requests on unmount.
    useEffect(() => {
        return () => {
            Object.values(cancelTokensRef.current).forEach((tokenSource) => {
                tokenSource.cancel('Component unmounting.');
            });
            cancelTokensRef.current = {};
        };
    }, []);

    return { withAxiosCancelTokenRequestConfig, cancelTokenById, cancelAllTokens };
};

export type { UseState as UseAxiosCancelTokensState };
export { useAxiosCancelTokens };
