import { FC } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import Row from '../../components/Row';
import { PrintContext } from '../../hooks/usePrint';
import { HatDashboardPageProvider, useHatDashboardPage } from '../../hooks/Pages/HatDashboardPage';
import usePrint from '../../hooks/usePrint';
import HatCriteriaForm from './partials/HatCriteriaForm';
import HatTabPanels from './partials/Tabs/HatTabPanels';

// import ReportingPerformanceExportMenuButton from '../analytics/partials/Reporting/ReportingPerformanceExportMenuButton';
import HatMetrics from './partials/HatMetrics';
import { AttributionPageProvider, useAttributionPage } from '../../hooks/useAttributionPage';

const HatDashboardPage: FC = (): JSX.Element => {
    const theme = useTheme();
    const print = usePrint();
    const hatDashboardPage = useHatDashboardPage();
    const attributionPage = useAttributionPage();

    const { hatCriteria, setHatCriteria } = hatDashboardPage;

    return (
        <PrintContext.Provider value={print}>
            <HatDashboardPageProvider value={hatDashboardPage}>
                <Column gap={0} flex={1}>
                    <Block>
                        <Column gap={1}>
                            <Row spacing={2} justifyContent="space-between" alignItems="center">
                                <Heading>HAT Reporting</Heading>

                                <Row justifyContent="flex-end"></Row>
                            </Row>
                        </Column>
                    </Block>

                    <Block py={0}>
                        <Row spacing={2} justifyContent="space-between" alignItems="center">
                            <Row spacing={2} alignItems="center">
                                <Box
                                    sx={{
                                        '&': {
                                            width: '100%',

                                            [theme.breakpoints.up('xl')]: {
                                                width: 1200,
                                            },
                                        },
                                    }}
                                >
                                    <HatCriteriaForm criteria={hatCriteria} onChangeCriteria={setHatCriteria} />
                                </Box>
                            </Row>
                        </Row>
                    </Block>

                    <Block>
                        <Column>
                            <Row justifyContent="space-between">
                                <AttributionPageProvider value={attributionPage}>
                                    {/*<ReportingPerformanceExportMenuButton disabled={false} />*/}
                                </AttributionPageProvider>
                            </Row>
                        </Column>
                    </Block>

                    <Block>
                        <Column>
                            <Box>
                                <HatMetrics />
                            </Box>
                        </Column>

                        <Column>
                            <HatTabPanels />
                        </Column>
                    </Block>
                </Column>
            </HatDashboardPageProvider>
        </PrintContext.Provider>
    );
};

export default HatDashboardPage;
