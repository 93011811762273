import QuickSightDashboard from '../../components/QuickSightDashboard';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';

export default function OperationsDashboardPage() {
    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Heading>Operations Dashboard</Heading>
                </Column>
            </Block>

            <Block disableGutters>
                <Column gap={2} sx={{ height: '100%' }}>
                    <QuickSightDashboard dashboardId="2c09b926-aaad-4dd9-96a1-36f7e36b5a8f" />
                </Column>
            </Block>
        </Column>
    );
}
