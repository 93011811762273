import { useContext } from 'react';
import { AgencyContext } from '../../App';
import { Agency } from '../../types/Agency';

type AgencyContextState = Agency | null;

const useAgencyContext = (): AgencyContextState => {
    return useContext(AgencyContext);
};

export type { AgencyContext };
export type { AgencyContextState };
export { useAgencyContext };
