import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { RefreshOutlined as RefreshOutlinedIcon } from '@mui/icons-material';

import { LoadingButtonProps } from '../../../components/LoadingButton';
import ApiService from '../../../ApiService';
import LoadingButton from '../../../components/LoadingButton';

import { Campaign, CampaignChangeRequest } from '../../../types/Campaign';
import { useCampaignChange } from '../../../hooks/Models/Campaign/CampaignChange';
import { useCampaignChangeGracePeriod } from '../../../hooks/Models/Campaign/CampaignChangeGracePeriod';

interface CampaignRefreshButtonProps extends Omit<LoadingButtonProps, 'onChange'> {
    campaign: Campaign;
    campaignChangeRequest: CampaignChangeRequest | null;
    onChange?: (campaign: Campaign) => void;
}

const CampaignRefreshButton = ({
    campaign,
    campaignChangeRequest,
    onChange = undefined,
    onClick = undefined,
    ...props
}: CampaignRefreshButtonProps): JSX.Element => {
    const { isCampaignChangeable } = useCampaignChange({ campaign, campaignChangeRequest });
    const { hasCampaignChangeGracePeriodExpired } = useCampaignChangeGracePeriod({ campaign });

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isDisabled = useMemo((): boolean => {
        return isCampaignChangeable === false || isLoading;
    }, [isCampaignChangeable, isLoading]);

    const handleOnClick = useCallback(
        (event: MouseEvent<HTMLButtonElement>): void => {
            if (onClick) {
                onClick(event);
            }

            if (campaign && campaign.id) {
                setIsLoading(true);

                ApiService.refreshDspStatus(campaign.id)
                    .then((response): void => {
                        if (onChange) {
                            onChange(response.data);
                        }
                    })
                    .finally((): void => {
                        setIsLoading(false);
                    });
            }
        },
        [onChange, onClick, campaign]
    );

    const tooltip = useMemo((): string => {
        if (hasCampaignChangeGracePeriodExpired) {
            return 'You cannot Refresh a campaign 30 days after it has ended.';
        }

        return 'Get Latest status from DSP';
    }, [hasCampaignChangeGracePeriodExpired]);

    return (
        <LoadingButton
            tooltip={tooltip}
            startIcon={<RefreshOutlinedIcon />}
            {...props}
            loading={isLoading}
            loadingPosition="start"
            disabled={isDisabled}
            onClick={handleOnClick}
        >
            Refresh
        </LoadingButton>
    );
};

export default CampaignRefreshButton;
