import moment from 'moment-timezone';

import { Performance } from '../types/CampaignPerformance';
import { PolkSalesWorkflowResult, PolkSalesWorkflowSet } from '../types/Polk';
import Utils from '../components/Utils';

import { useAttributionPageContext } from './useAttributionPage';
import { calculatePerformanceValue } from './useCampaignPerformance';

export function usePolkAttributionCsvExporter() {
    const { attributionMetrics, polkSalesWorkflowSets } = useAttributionPageContext();

    const getCsvData = () => {
        let rows = [];

        rows.push([
            'Advertiser',
            'Total Sales',
            'Non-Attributed Sales',
            'Attributed Sales',
            'Market Share',
            'Ad Share',
            // attributionDate
            //     ? `${moment(attributionDate).format('MMMM')} Investment`
            //     : 'Total Investment',
            // 'Cost per Sold',
            'Total Exposures',
            'Total Clicks',
            'Household Reach',
            'Avg. Household Frequency',
        ]);

        if (polkSalesWorkflowSets) {
            rows.push(
                ...polkSalesWorkflowSets
                    .flatMap((workflowSet: PolkSalesWorkflowSet) => workflowSet.salesWorkflow?.workflowResults ?? [])
                    .map((workflowResult: PolkSalesWorkflowResult) => {
                        workflowResult.distinctUserCount = workflowResult.distinctUserCount || 0;
                        return [
                            workflowResult.polkDealer.dealerName,
                            workflowResult.salesCount.toString(),
                            (workflowResult.salesCount - workflowResult.distinctUserCount).toString(),
                            workflowResult.distinctUserCount.toString(),
                            workflowResult.salesCount.toString(),
                            workflowResult.distinctUserCount.toString(),
                            // ('$' + attributionMetrics?.totalSpend?.value?.toString()) as string,
                            // ('$' + attributionMetrics?.costPerSold?.value?.toString()) as string,
                            attributionMetrics?.totalImpressions?.value?.toString() as string,
                            attributionMetrics?.totalClickthroughs?.value?.toString() as string,
                            attributionMetrics?.totalDailyReach?.value?.toString() as string,
                            attributionMetrics?.totalImpressionFrequency?.value?.toString() as string,
                        ];
                    })
            );

            rows.push(
                ...polkSalesWorkflowSets
                    .flatMap(
                        (workflowSet: PolkSalesWorkflowSet) => workflowSet.opportunityWorkflow?.workflowResults ?? []
                    )
                    .map((workflowResult: PolkSalesWorkflowResult) => {
                        workflowResult.distinctUserCount = workflowResult.distinctUserCount || 0;
                        return [
                            workflowResult.polkDealer.dealerName,
                            workflowResult.salesCount.toString(),
                            (workflowResult.salesCount - workflowResult.distinctUserCount).toString(),
                            workflowResult.distinctUserCount.toString(),
                            workflowResult.salesCount.toString(),
                            workflowResult.distinctUserCount.toString(),
                        ];
                    })
            );
        }

        return rows;
    };

    return { getCsvData };
}

export function useAdvertiserAttributionCsvExporter() {
    const {
        attributionMetrics,
        polkSalesWorkflowSets,

        getPerformances,
        getPerformanceMediaTypes,
    } = useAttributionPageContext();

    const mediaTypes: string[] = getPerformanceMediaTypes();

    const getCsvData = () => {
        let rows = [
            ['Overview'],
            [
                'Advertiser',
                'Total Sales',
                'Non-Attributed Sales',
                'Attributed Sales',
                'Market Share',
                'Ad Share',
                'Total Exposures',
                'Total Clicks',
                'Household Reach',
                'Avg. Household Frequency',
                'Cost Per Sale',
                'Investment',
            ],
        ];

        const calculateMarketSharesPercentage = (workflowResult: PolkSalesWorkflowResult) => {
            let percentage: number = 0;

            if (attributionMetrics?.totalMarketShares?.value) {
                percentage = Math.round((workflowResult.salesCount / attributionMetrics.totalMarketShares.value) * 100);
            }

            return percentage;
        };

        const calculateAdSharesPercentage = (workflowResult: PolkSalesWorkflowResult) => {
            let percentage: number = 0;

            if (attributionMetrics?.totalAdShares?.value) {
                percentage = Math.round(
                    ((workflowResult.distinctUserCount || 0) / attributionMetrics.totalAdShares.value) * 100
                );
            }

            return percentage;
        };

        if (polkSalesWorkflowSets) {
            rows.push(
                ...polkSalesWorkflowSets
                    .flatMap((workflowSet: PolkSalesWorkflowSet) => workflowSet.salesWorkflow?.workflowResults ?? [])
                    .map((workflowResult: PolkSalesWorkflowResult) => {
                        workflowResult.distinctUserCount = workflowResult.distinctUserCount || 0;
                        return [
                            workflowResult.polkDealer.dealerName,
                            workflowResult.salesCount.toString(),
                            (workflowResult.salesCount - workflowResult.distinctUserCount).toString(),
                            workflowResult.distinctUserCount.toString(),
                            Utils.formatValue(calculateMarketSharesPercentage(workflowResult), 'decimal') + '%',
                            Utils.formatValue(calculateAdSharesPercentage(workflowResult), 'decimal') + '%',
                            attributionMetrics?.totalImpressions?.value?.toString() as string,
                            attributionMetrics?.totalClickthroughs?.value?.toString() as string,
                            attributionMetrics?.totalDailyReach?.value?.toString() as string,
                            attributionMetrics?.totalImpressionFrequency?.value?.toString() as string,
                            Utils.formatValue(attributionMetrics?.costPerSold?.value, 'dollar') as string,
                            Utils.formatValue(attributionMetrics?.totalSpend?.value, 'dollar') as string,
                        ];
                    })
            );
        }

        if (mediaTypes.length > 0) {
            rows.push(
                ...[
                    [
                        // An empty row.
                    ],
                    ['Campaign Performance'],
                    [
                        'Campaign',
                        'Media Type',
                        'Spend',
                        'Average CPM',
                        'Impressions',
                        'Conversions',
                        'Reach',
                        'Clicks',
                        'eCPCV',
                    ],
                ]
            );

            mediaTypes.forEach((mediaType: string) => {
                switch (mediaType) {
                    case 'Display':
                        rows.push(
                            ...getPerformances(mediaType).map((performance: Performance | any) => {
                                return [
                                    performance.campaignName,
                                    mediaType,
                                    Utils.formatValue(performance.totalCost, 'dollar'),
                                    Utils.formatValue(performance.CPM, 'dollar'),
                                    Utils.formatValue(performance.impressions),
                                    Utils.formatValue(performance.offAmazonConversions),
                                    Utils.formatValue(performance.dailyReach),
                                    Utils.formatValue(performance.clickthroughs),
                                    '-',
                                ];
                            })
                        );
                        break;

                    case 'Video':
                        rows.push(
                            ...getPerformances(mediaType).map((performance: Performance | any) => {
                                return [
                                    performance.campaignName,
                                    mediaType,
                                    Utils.formatValue(performance.totalCost, 'dollar'),
                                    Utils.formatValue(performance.CPM, 'dollar'),
                                    Utils.formatValue(performance.impressions),
                                    Utils.formatValue(performance.offAmazonConversions),
                                    '',
                                    '',
                                    Utils.formatValue(calculatePerformanceValue(performance, 'eCPCV'), 'dollar'),
                                ];
                            })
                        );
                        break;

                    case 'Audio':
                        rows.push(
                            ...getPerformances(mediaType).map((performance: Performance | any) => {
                                return [
                                    performance.campaignName,
                                    mediaType,
                                    Utils.formatValue(performance.totalCost, 'dollar'),
                                    Utils.formatValue(performance.CPM, 'dollar'),
                                    Utils.formatValue(performance.impressions),
                                    Utils.formatValue(performance.offAmazonConversions),
                                    Utils.formatValue(performance.dailyReach),
                                    Utils.formatValue(performance.clickthroughs),
                                    '-',
                                ];
                            })
                        );
                        break;
                }
            });
        }

        return rows;
    };

    return { getCsvData };
}

export default function useAttributionExport() {
    const { dealer, attributionDate } = useAttributionPageContext();

    const generateExportFilename = (format = '{id}'): string => {
        let keywords: string[] = [];

        if (dealer) {
            keywords.push(dealer.dealerName);
        }

        if (attributionDate) {
            keywords.push(moment(attributionDate).format('MMMM YYYY'));
        }

        return format.replace('{id}', keywords.map((_keyword) => _keyword.replaceAll(' ', '').toLowerCase()).join('-'));
    };

    return {
        generateExportFilename,
    };
}
