import { Box, BoxProps, Tab, Tabs, TabsProps } from '@mui/material';

import { SectionHeading } from '../../../../../components/SectionHeading';
import Printable from '../../../../../components/Printable';

export const TABS = {
    PERFORMANCE_OVERVIEW: 0,
    PERFORMANCE_MONITOR: 1,
    PROPERTY_SPEND: 2,
    ON_SITE_INTERACTION: 3,
};

type ReportingPerformanceTabsProps = {
    boxProps?: BoxProps;
} & TabsProps;

export default function ReportingPerformanceTabs(props: ReportingPerformanceTabsProps) {
    const { boxProps, ...tabsProps } = props;
    const tab: number = props.value;

    return (
        <Box
            sx={{
                // borderBottom: 1,
                // borderColor: 'divider',
                mt: 2,
            }}
            {...boxProps}
        >
            <Printable show={false}>
                <Tabs {...tabsProps}>
                    <Tab value={TABS.PERFORMANCE_OVERVIEW} label="Performance Overview" />
                    <Tab value={TABS.PERFORMANCE_MONITOR} label="Performance Monitor" />
                    <Tab value={TABS.PROPERTY_SPEND} label="Property Spend" />
                    <Tab value={TABS.ON_SITE_INTERACTION} label="On-Site Interactions" />
                </Tabs>
            </Printable>
            <Printable show={true}>
                <Box sx={{ mb: 2 }}>
                    {tab === TABS.PERFORMANCE_OVERVIEW && (
                        <SectionHeading divider={false}>Performance Overview</SectionHeading>
                    )}
                    {tab === TABS.PERFORMANCE_MONITOR && (
                        <SectionHeading divider={false}>Performance Monitor</SectionHeading>
                    )}
                    {tab === TABS.PROPERTY_SPEND && <SectionHeading divider={false}>Property Spend</SectionHeading>}
                    {tab === TABS.ON_SITE_INTERACTION && (
                        <SectionHeading divider={false}>On-Site Interactions</SectionHeading>
                    )}
                </Box>
            </Printable>
        </Box>
    );
}
