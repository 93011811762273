import Block from '../../../components/Block';
import Column from '../../../components/Column';
import Row from '../../../components/Row';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import ApiService from '../../../ApiService';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import { GridRowParams } from '@mui/x-data-grid';
import { AmcInstance } from '../../../types/AmcInstance';
import { useNavigate } from 'react-router-dom';
import { useAmcInstanceListFilter } from '../../../hooks/Filters/AmcInstanceListFilter';
import AmcInstanceCreateFormDialog from './AmcInstanceCreateFormDialog';
import AmcPageHeader from '../partials/AmcPageHeader';
import CDGrid from '../../../components/CDGrid';
import Utils from '../../../components/Utils';

export default function AmcInstanceListPage() {
    const navigate = useNavigate();

    const [amcInstances, setAmcInstances] = useState<AmcInstance[]>([]);
    const [allAmcInstances, setAllAmcInstances] = useState<AmcInstance[]>([]);
    const [amcInstanceFormDialogOpen, setAmcInstanceFormDialogOpen] = useState<boolean>(false);

    const { filteredAmcInstances, amcInstanceListCriteria, setAmcInstanceListCriteria } =
        useAmcInstanceListFilter(amcInstances);
    const [searchText, setSearchText] = useState<string>(amcInstanceListCriteria.searchText);

    const fetchAmcInstanceList = useCallback(() => {
        ApiService.getAmcInstanceList({
            agencyId: amcInstanceListCriteria.agencyId || null,
            advertiserId: amcInstanceListCriteria.advertiserId || null,
            searchText: amcInstanceListCriteria.searchText || '',
        })
            .then((response) => {
                setAmcInstances(response.data);
                setAllAmcInstances(response.data);
            })
            .catch(() => {});
    }, [amcInstanceListCriteria]);

    function refresh() {
        fetchAmcInstanceList();
    }

    useEffect(() => {
        fetchAmcInstanceList();
    }, [fetchAmcInstanceList]);

    function handleEdit(params: GridRowParams) {
        navigate(String(params.row.id), {
            state: { ...params.row, isNew: false },
        });
    }

    function clearSearch() {
        setAmcInstanceListCriteria({
            ...amcInstanceListCriteria,
            searchText: '',
        });
        setAmcInstances(allAmcInstances);
    }

    const debouncedSearch = useCallback(
        Utils.debounce((value: string) => {
            setAmcInstanceListCriteria({
                ...amcInstanceListCriteria,
                searchText: value,
            });
        }, 300),
        [amcInstanceListCriteria]
    );

    const handleSearch = (event: ChangeEvent<HTMLInputElement>): void => {
        event.preventDefault();
        setSearchText(event.target.value);
        debouncedSearch(event.target.value);
    };

    return (
        <Column gap={0} flex={1}>
            <AmcPageHeader criteria={amcInstanceListCriteria} setCriteria={setAmcInstanceListCriteria} />

            <Block flex={1}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <Row justifyContent="flex-end">
                        <TextField
                            inputRef={(input) => input && input.focus()}
                            id="searchText"
                            value={searchText}
                            label="Search Instance Name"
                            onChange={handleSearch}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchOutlined color="action" fontSize="small" />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchText && (
                                            <IconButton size="small" onClick={() => clearSearch()}>
                                                <ClearOutlined color="action" fontSize="small" />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => {
                                setAmcInstanceFormDialogOpen(true);
                            }}
                        >
                            Add Instance
                        </Button>
                    </Row>

                    <CDGrid
                        columns={[
                            {
                                width: 100,
                                field: 'id',
                                headerName: 'Instance ID',
                                renderCell: (params) => (
                                    <Button
                                        onClick={() => {
                                            handleEdit(params.value);
                                        }}
                                    >
                                        {params.value}
                                    </Button>
                                ),
                            },
                            {
                                width: 360,
                                field: 'name',
                                headerName: 'Name',
                            },
                            {
                                width: 400,
                                field: 'instanceName',
                                headerName: 'Instance Name',
                            },
                            {
                                width: 400,
                                field: 'status',
                                headerName: 'Status',
                                flex: 1,
                            },
                        ]}
                        autoHeight={false}
                        autoPageSize={true}
                        rows={filteredAmcInstances === null ? [] : filteredAmcInstances}
                        onRowClick={handleEdit}
                    />
                </Column>
            </Block>

            {amcInstanceFormDialogOpen && (
                <AmcInstanceCreateFormDialog
                    open={amcInstanceFormDialogOpen}
                    onClose={() => {
                        refresh();
                        setAmcInstanceFormDialogOpen(false);
                    }}
                />
            )}
        </Column>
    );
}
