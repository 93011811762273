import { useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useQuery } from 'react-query';

import { useAxiosCancelToken } from '../../../../hooks/AxiosCancelToken';
import { useReportingDashboardPageContext } from '../../../../hooks/useReportingDashboardPage';
import ApiService from '../../../../ApiService';
import MetricCard from '../../../../components/Metrics/MetricCard';

export default function ReportingPerformanceMetrics() {
    const { reportingCriteria, attributionMetrics, setAttributionMetrics } = useReportingDashboardPageContext();

    const withAxiosCancelTokenRequestConfig = useAxiosCancelToken();

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['reporting.metrics', reportingCriteria],
        queryFn: async () => {
            const { data } = await ApiService.getReportingMetrics(
                { ...reportingCriteria },
                withAxiosCancelTokenRequestConfig()
            );
            return data;
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        refetch();
    }, [reportingCriteria, refetch]);

    const totalSales = useMemo(() => {
        return data?.totalSales;
    }, [data]);

    const attributedSales = useMemo(() => {
        return data?.totalAdExposedSales;
    }, [data]);

    const totalSpend = useMemo(() => {
        return data?.totalCost;
    }, [data]);

    const averageCPM = useMemo(() => {
        return data?.averageCPM;
    }, [data]);

    const averageCTR = useMemo(() => {
        return data?.averageCTR;
    }, [data]);

    const averageViewabilityRate = useMemo(() => {
        return data?.averageViewabilityRate;
    }, [data]);

    const costPerSold = useMemo(() => {
        return data?.averageCostPerAdExposedSale;
    }, [data]);

    const totalImpressions = useMemo(() => {
        return data?.totalImpressions;
    }, [data]);

    const totalClickthroughs = useMemo(() => {
        return data?.totalClickThroughs;
    }, [data]);

    const totalDailyReach = useMemo(() => {
        return data?.totalReach;
    }, [data]);

    const videoCompleteRate = useMemo(() => {
        return data?.averageVideoCompleteRate;
    }, [data]);

    const costPerCompletedVideo = useMemo(() => {
        return data?.averageCostPerCompletedVideo;
    }, [data]);

    const timeSpent = useMemo(() => {
        return data?.averageTimeSpent;
    }, [data]);

    const upperMetricCardGridColumnSize = useMemo((): number => {
        if (reportingCriteria && ['Audio', 'Streaming TV'].includes(reportingCriteria.mediaType)) {
            return 4;
        }

        return 3;
    }, [reportingCriteria]);

    const metricCardGridColumnSize = useMemo((): number => {
        if (reportingCriteria && ['Streaming TV'].includes(reportingCriteria.mediaType)) {
            // return 3;
        }

        return 4;
    }, [reportingCriteria]);

    useEffect(() => {
        setAttributionMetrics({
            ...attributionMetrics,
            ...{
                averageCPM,
                averageCTR,
                averageViewabilityRate,
                totalSales,
                attributedSales,
                totalSpend,
                costPerSold,
                totalImpressions,
                totalClickthroughs,
                totalDailyReach,
                videoCompleteRate,
                costPerCompletedVideo,
                timeSpent,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        averageCPM,
        averageCTR,
        averageViewabilityRate,
        totalSales,
        attributedSales,
        totalSpend,
        costPerSold,
        totalImpressions,
        totalClickthroughs,
        totalDailyReach,
        videoCompleteRate,
        costPerCompletedVideo,
        timeSpent,
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={upperMetricCardGridColumnSize}>
                <MetricCard
                    loading={isLoading}
                    metric={totalSpend}
                    label="Total Investment"
                    tooltip="Total advertiser(s) ad spend for the selected time period."
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={upperMetricCardGridColumnSize}>
                <MetricCard
                    loading={isLoading}
                    metric={totalImpressions}
                    label="Total Exposures"
                    tooltip="Total number of ad exposures or impressions for the selected advertiser(s) for the selected time period."
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={upperMetricCardGridColumnSize}>
                <MetricCard
                    loading={isLoading}
                    metric={averageCPM}
                    label="Average CPM"
                    tooltip="Average CPM represents the average cost per thousand impressions across all media types."
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={upperMetricCardGridColumnSize}>
                <MetricCard
                    loading={isLoading}
                    metric={totalDailyReach}
                    label="Household Reach"
                    tooltip="Summary of total households exposed to an advertisement for the given month. Note: a full month of data is required before this value can be shown."
                    hideSublabel={true}
                />
            </Grid>

            {reportingCriteria && reportingCriteria.mediaType !== '' && (
                <>
                    {!['Audio', 'Streaming TV'].includes(reportingCriteria.mediaType) && (
                        <Grid item xs={metricCardGridColumnSize}>
                            <MetricCard
                                loading={isLoading}
                                metric={averageViewabilityRate}
                                label="Average Viewability"
                                tooltip="The percentage of viewable ad impressions relative to the total number of impressions served."
                                hideSublabel={true}
                            />
                        </Grid>
                    )}

                    {!['Streaming TV'].includes(reportingCriteria.mediaType) && (
                        <>
                            <Grid item xs={metricCardGridColumnSize}>
                                <MetricCard
                                    loading={isLoading}
                                    metric={totalClickthroughs}
                                    label={
                                        reportingCriteria.mediaType === 'Audio'
                                            ? 'Companion Banner Clicks'
                                            : 'Total Clicks'
                                    }
                                    tooltip={
                                        reportingCriteria.mediaType === 'Audio'
                                            ? 'The number of times users clicked on an audio ad companion banner.'
                                            : 'Total number of clicks on an advertisement for the selected time period.'
                                    }
                                    inverseColor={true}
                                    hideSublabel={true}
                                />
                            </Grid>

                            <Grid item xs={metricCardGridColumnSize}>
                                <MetricCard
                                    loading={isLoading}
                                    metric={averageCTR}
                                    label="Average Click-Through Rate (CTR)"
                                    tooltip="The percentage of users who clicked on an ad compared to the total number of times the ad was shown (impressions)."
                                    hideSublabel={true}
                                />
                            </Grid>
                        </>
                    )}

                    {['Video', 'Online Video', 'Streaming TV'].includes(reportingCriteria.mediaType) && (
                        <>
                            {!['Streaming TV'].includes(reportingCriteria.mediaType) && (
                                <Grid item xs={metricCardGridColumnSize}>
                                    <MetricCard
                                        loading={isLoading}
                                        metric={videoCompleteRate}
                                        label="Video Completion Rate (VCR)"
                                        tooltip="The percentage of users who watched a video ad in its entirety."
                                        hideSublabel={true}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={metricCardGridColumnSize}>
                                <MetricCard
                                    loading={isLoading}
                                    metric={costPerCompletedVideo}
                                    label="Cost Per Completed Video (CPCV)"
                                    tooltip="The average cost incurred each time a video ad is viewed in full."
                                    hideSublabel={true}
                                />
                            </Grid>

                            <Grid item xs={metricCardGridColumnSize}>
                                <MetricCard
                                    loading={isLoading}
                                    metric={timeSpent}
                                    label="Time Spent With Brand (Hours)"
                                    tooltip="The total amount of time users spend actively engaging with a brand's content, often measured across video views, ad interactions, and site visits."
                                    hideSublabel={true}
                                />
                            </Grid>
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
