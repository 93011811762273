import { SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';

interface UseState {
    activeTab: number;
    setActiveTab: (activeTab: number) => void;
    defaultActiveTab: number;
    handleChangeTab: (event: SyntheticEvent, newActiveTab: number) => void;
}

interface UseProps {
    defaultActiveTab?: number;
}

const useTabs = (props?: UseProps): UseState => {
    const { defaultActiveTab = 0 } = props || {};

    const [activeTab, setActiveTab] = useState<number>(defaultActiveTab);

    const handleChangeTab = useCallback((event: SyntheticEvent, newActiveTab: number) => {
        setActiveTab(newActiveTab);
    }, []);

    return {
        activeTab,
        setActiveTab,
        defaultActiveTab,
        handleChangeTab,
    };
};

type TabsProps = UseState;

export type { TabsProps, UseProps as UseTabsProps, UseState as UseTabsState };
export { useTabs };
