import { FC, useMemo } from 'react';

import { PieChartSlice } from '../../../../types/Chart';
import Utils from '../../../../components/Utils';

import { SimpleCountType } from '../../../../services/trialfire/types/SimpleCountType';
import { useHatDashboardPageContext } from '../../../../hooks/Pages/HatDashboardPage';
import PieChartCard from '../../../../components/Metrics/PieChartCard';

const HatConversionByConversionPieChartCard: FC = (): JSX.Element => {
    const { simpleCounts } = useHatDashboardPageContext();

    const channels = [
        { label: 'SRP Views', type: SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SRP_VIEWS },
        { label: 'VDP Views', type: SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_VDP_VIEWS },
        { label: 'Service Page Views', type: SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SERVICE_PAGE_VIEWS },
        { label: 'Specials Page Views', type: SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SPECIALS_PAGE_VISITS },
        { label: 'Identified Visitors', type: SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_IDENTIFIED_VISITORS },
        { label: 'Click to Calls', type: SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_CLICK_TO_CALLS },
        {
            label: 'Total Hours and Directions Views',
            type: SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS,
        },
    ];

    const queries = channels.map(({ type }) => simpleCounts[type]);

    const isLoading = useMemo(() => queries.some((q) => q?.isFetching || q?.isLoading), [queries]);

    const slices = useMemo((): PieChartSlice[] => {
        return channels.map(({ label, type }) => ({
            label,
            value: simpleCounts[type]?.data?.data ?? 0,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simpleCounts]);

    const slicesTotal = useMemo(() => {
        return slices.reduce((total, { value }) => total + value, 0);
    }, [slices]);

    return (
        <PieChartCard
            loading={isLoading}
            label="Conversion by Action"
            chartOptions={{
                dataLabels: { enabled: false },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                total: {
                                    label: 'Total Conversions',
                                    formatter: () => Utils.formatNumber(slicesTotal) as string,
                                },
                                value: {
                                    formatter: (value) =>
                                        value.startsWith('$')
                                            ? value
                                            : `${value ? `${Utils.formatNumber(parseFloat(value))}` : '0'}%`,
                                },
                            },
                        },
                    },
                },
                labels: slices.map(({ label }) => label),
                legend: {
                    horizontalAlign: 'center',
                    offsetX: -64,
                    offsetY: 64,
                },
            }}
            chartSeries={slices.map(({ value }) => (slicesTotal > 0 ? (value / slicesTotal) * 100 : 0))}
        />
    );
};

export default HatConversionByConversionPieChartCard;
