import { Autocomplete, TextField } from '@mui/material';

const SelectSize = ({ setSize, size }: { size: string; setSize: (size: string) => void }) => {
    const sizes = ['All', '300x250', '160x600', '300x600', '728x90'];

    return (
        <Autocomplete
            sx={{
                width: '224px',
            }}
            value={size}
            size="small"
            options={sizes}
            id="select-sizes"
            onChange={(e, value) => {
                setSize(value ? value : 'All');
            }}
            renderInput={(params) => <TextField {...params} label="Ad Size" />}
        />
    );
};

export default SelectSize;
