import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';

interface WithAxiosCancelSourceTokenState<T extends AxiosRequestConfig> {
    requestConfig: T;
    cancelTokenSource: CancelTokenSource;
    cancelToken: CancelToken;
}

/**
 * Generates a new Axios request config with a cancellation token.
 * This allows you to cancel previous requests when making a new one.
 */
const withAxiosCancelSourceToken = <T extends AxiosRequestConfig>(
    defaultRequestConfig: T
): WithAxiosCancelSourceTokenState<T> => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    const requestConfig: T = {
        ...defaultRequestConfig,
        cancelToken: cancelTokenSource.token,
    };

    return {
        requestConfig,
        cancelTokenSource,
        cancelToken: cancelTokenSource.token,
    };
};

export { withAxiosCancelSourceToken };
