import { useEffect, useState } from 'react';
import { ReportingCriteria } from '../../types/Criteria/ReportingCriteria';

interface ReportingCriteriaState {
    isReportingCriteriaSetup: boolean;
    setIsReportingCriteriaSetup: (isReportingCriteriaSetup: boolean) => void;
    defaultReportingCriteria: ReportingCriteria;
    setDefaultReportingCriteria: (reportingCriteria: ReportingCriteria) => void;
    reportingCriteria: ReportingCriteria;
    setReportingCriteria: (reportingCriteria: ReportingCriteria) => void;
    resetReportingCriteria: () => void;
}

interface ReportingCriteriaProps {
    isReportingCriteriaSetup?: boolean;
    reportingCriteria?: ReportingCriteria;
}

const useReportingCriteria = (props?: ReportingCriteriaProps): ReportingCriteriaState => {
    const [isReportingCriteriaSetup, setIsReportingCriteriaSetup] = useState<boolean>(
        props?.isReportingCriteriaSetup || false
    );
    const [defaultReportingCriteria, setDefaultReportingCriteria] = useState<ReportingCriteria>(() => {
        if (props?.reportingCriteria) {
            return props.reportingCriteria;
        }

        return {
            agencyIds: [],
            dealerIds: [],
            startDate: new Date(),
            endDate: new Date(),
            mediaType: '',
            strategyType: '',
        };
    });
    const [reportingCriteria, setReportingCriteria] = useState<ReportingCriteria>(() => {
        const rawReportingCriteria: string | null = localStorage.getItem('reporting.criteria');

        if (rawReportingCriteria) {
            return { ...JSON.parse(rawReportingCriteria), strategyType: '', mediaType: '' };
        }

        return defaultReportingCriteria;
    });

    const resetReportingCriteria = (): void => {
        setReportingCriteria(defaultReportingCriteria);
    };

    useEffect((): void => {
        localStorage.setItem('reporting.criteria', JSON.stringify(reportingCriteria));
    }, [reportingCriteria]);

    return {
        isReportingCriteriaSetup,
        setIsReportingCriteriaSetup,
        defaultReportingCriteria,
        setDefaultReportingCriteria,
        setReportingCriteria,
        reportingCriteria,
        resetReportingCriteria,
    };
};

export type { ReportingCriteriaProps, ReportingCriteriaState };
export { useReportingCriteria };
