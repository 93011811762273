import { FC } from 'react';
import { Grid } from '@mui/material';

import { useHatDashboardPageContext } from '../../../hooks/Pages/HatDashboardPage';
import MetricCard from '../../../components/Metrics/MetricCard';

const HatMetrics: FC = (): JSX.Element => {
    const { metrics } = useHatDashboardPageContext();

    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <MetricCard
                    loading={metrics?.TOTAL_UNIQUE_TRAFFIC?.isLoading ?? false}
                    metric={metrics?.TOTAL_UNIQUE_TRAFFIC}
                    label="Total Visits"
                    tooltip=""
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={metrics?.TOTAL_AD_INFLUENCED_TRAFFIC?.isLoading ?? false}
                    metric={metrics?.TOTAL_AD_INFLUENCED_TRAFFIC}
                    label="Influenced Visits"
                    tooltip=""
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={metrics?.TOTAL_CONVERSIONS?.isLoading ?? false}
                    metric={metrics?.TOTAL_CONVERSIONS}
                    label="Total Conversions"
                    tooltip=""
                    hideSublabel={true}
                />
            </Grid>

            <Grid item xs={3}>
                <MetricCard
                    loading={metrics?.TOTAL_AD_INFLUENCED_CONVERSIONS?.isLoading ?? false}
                    metric={metrics?.TOTAL_AD_INFLUENCED_CONVERSIONS}
                    label="Influenced Conversions"
                    tooltip=""
                    hideSublabel={true}
                />
            </Grid>
        </Grid>
    );
};

export default HatMetrics;
