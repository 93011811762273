import { AmcInstance as Resource } from '../../types/AmcInstance';
import { useResourceListFilter } from './ResourceListFilter';

interface ResourceListCriteria {
    searchText: string;
    agencyId: number | null;
    advertiserId: number | null;
}

interface UseResourceListFilterState {
    filteredAmcInstances: Resource[];
    amcInstanceListCriteria: ResourceListCriteria;
    setAmcInstanceListCriteria: (resourceListCriteria: ResourceListCriteria) => void;
    resetAmcInstanceListCriteria: () => void;
    amcInstances: Resource[];
    setAmcInstances: (resources: Resource[]) => void;
}

const initialResourceListCriteriaState: ResourceListCriteria = {
    searchText: '',
    agencyId: null,
    advertiserId: null,
};

const filterResources = (resources: Resource[], resourceListCriteria: ResourceListCriteria): Resource[] => {
    return resources.filter((resource: Resource) => {
        const matchesSearch =
            resource.name.toLowerCase().indexOf(resourceListCriteria.searchText.toLowerCase()) > -1 ||
            resource.instanceName.toLowerCase().indexOf(resourceListCriteria.searchText.toLowerCase()) > -1;

        return matchesSearch;
    });
};

const useAmcInstanceListFilter = (
    initialResources: Resource[] = [],
    initialResourceListCriteria: ResourceListCriteria = initialResourceListCriteriaState
): UseResourceListFilterState => {
    const { resourceListCriteria, setResourceListCriteria, filteredResources, resources, setResources } =
        useResourceListFilter<Resource>({
            initialResources,
            initialResourceListCriteria: initialResourceListCriteriaState,
            filterResources,
        });

    const resetResourceListCriteria = (): void => {
        setResourceListCriteria(initialResourceListCriteriaState);
    };

    return {
        filteredAmcInstances: filteredResources,
        amcInstanceListCriteria: resourceListCriteria,
        setAmcInstanceListCriteria: setResourceListCriteria,
        resetAmcInstanceListCriteria: resetResourceListCriteria,
        amcInstances: resources,
        setAmcInstances: setResources,
    };
};

export type {
    UseResourceListFilterState as UseAmcInstanceListFilterState,
    ResourceListCriteria as AmcInstanceListCriteria,
};
export { useAmcInstanceListFilter };
