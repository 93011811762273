import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';
import theme from '../theme';

interface HeadingProps extends BoxProps {
    variant?: TypographyProps['variant'];
}

const Heading = ({ variant = 'h6', ...props }: HeadingProps) => {
    return (
        <Box {...props} width="100%">
            <Typography variant={variant ? variant : 'h6'} color="text.primary">
                {props.children}
            </Typography>
        </Box>
    );
};

interface SubHeadingProps extends BoxProps {}

const SubHeading = (props: SubHeadingProps) => {
    return (
        <Box
            {...props}
            style={{
                fontSize: '14px',
                fontWeight: theme.typography.fontWeightMedium,
                color: theme.palette.text.secondary,
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
            }}
        >
            {props.children}
        </Box>
    );
};

export type { HeadingProps, SubHeadingProps };
export { SubHeading };
export default Heading;
