import { useEffect } from 'react';
import { Box } from '@mui/material';
import { useQuery } from 'react-query';

import { ReportingCriteria } from '../../../../../types/Criteria/ReportingCriteria';
import { useAxiosCancelToken } from '../../../../../hooks/AxiosCancelToken';
import ApiService from '../../../../../ApiService';
import PerformanceEntityDataGrid from '../DataGrids/PerformanceEntityDataGrid';

type ReportingPerformanceMonitorTabProps = {
    reportingCriteria: ReportingCriteria;
};

const ReportingPerformanceMonitorTab = (props: ReportingPerformanceMonitorTabProps): JSX.Element => {
    const { reportingCriteria } = props;
    const withAxiosCancelTokenRequestConfig = useAxiosCancelToken();

    const {
        data: rows,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['reporting.performanceEntities', reportingCriteria],
        queryFn: async () => {
            const { data } = await ApiService.getReportingPerformanceEntities(
                { ...reportingCriteria },
                withAxiosCancelTokenRequestConfig()
            );
            return data;
        },
        enabled: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        refetch();
    }, [reportingCriteria, refetch]);

    return (
        <Box
            sx={{
                width: '100%',
                maxWidth: '100vw',
                overflowX: 'auto',

                '& > .MuiPaper-root': {
                    boxShadow: 'none',
                },
            }}
        >
            <PerformanceEntityDataGrid reportingCriteria={reportingCriteria} rows={rows || []} loading={isLoading} />
        </Box>
    );
};

export type { ReportingPerformanceMonitorTabProps };
export default ReportingPerformanceMonitorTab;
