import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment-timezone';

import { AgencyContext } from '../../App';
import { HatCriteriaState } from '../Criteria/HatCriteria';
import { Metric } from '../../types/Analytics/Metric';
import { useHatCriteria } from '../Criteria/HatCriteria';
import { UseTabsState, useTabs } from '../Tabs';
import ApiService from '../../ApiService';

import { SimpleCountType } from '../../services/trialfire/types/SimpleCountType';
import { SimpleCountQueryMap } from '../../services/trialfire/types/SimpleCountQueryMap';
import { SimpleCountCriteria, useSimpleCount } from '../../services/trialfire/hooks/SimpleCount';
import { UseSimpleCountsState, useSimpleCounts } from '../../services/trialfire/hooks/SimpleCounts';

/**
 * Hat Metrics
 *
 */

interface HatMetrics {
    TOTAL_UNIQUE_TRAFFIC?: Metric;
    TOTAL_AD_INFLUENCED_TRAFFIC?: Metric;
    TOTAL_CONVERSIONS?: Metric;
    TOTAL_AD_INFLUENCED_CONVERSIONS?: Metric;
}

interface UseHatMetricsProps {
    simpleCounts: Partial<SimpleCountQueryMap>;
}

const useHatMetrics = ({ simpleCounts }: UseHatMetricsProps) => {
    const [metrics, setMetrics] = useState<HatMetrics>({});

    const totalUniqueTrafficSources = [
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_TOTAL_VISITS,
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_DIRECT,
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_ORGANIC_SEARCH,
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_PAID_SEARCH,
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_REFERRAL,
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_SOCIAL,
    ];

    const totalUniqueTrafficSum = totalUniqueTrafficSources.reduce(
        (sum, type) => sum + (simpleCounts[type]?.data?.data ?? 0),
        0
    );
    const totalUniqueTrafficIsLoading = totalUniqueTrafficSources.some(
        (type) => simpleCounts[type]?.isLoading || simpleCounts[type]?.isFetching
    );

    useEffect(() => {
        setMetrics((prevMetrics) => ({
            ...prevMetrics,
            TOTAL_UNIQUE_TRAFFIC: {
                format: 'number',
                value: totalUniqueTrafficSum,
                isLoading: totalUniqueTrafficIsLoading,
            },
        }));
    }, [totalUniqueTrafficSum, totalUniqueTrafficIsLoading]);

    const totalAdInfluencedTrafficSources = [
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_TOTAL_VISITS,
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_DIRECT,
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_ORGANIC_SEARCH,
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_PAID_SEARCH,
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_REFERRAL,
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_SOCIAL,
    ];

    const totalAdInfluencedTrafficSum = totalAdInfluencedTrafficSources.reduce(
        (sum, type) => sum + (simpleCounts[type]?.data?.data ?? 0),
        0
    );
    const totalAdInfluencedTrafficIsLoading = totalAdInfluencedTrafficSources.some(
        (type) => simpleCounts[type]?.isLoading || simpleCounts[type]?.isFetching
    );

    useEffect(() => {
        setMetrics((prevMetrics) => ({
            ...prevMetrics,
            TOTAL_AD_INFLUENCED_TRAFFIC: {
                format: 'number',
                value: totalAdInfluencedTrafficSum,
                isLoading: totalAdInfluencedTrafficIsLoading,
            },
        }));
    }, [totalAdInfluencedTrafficSum, totalAdInfluencedTrafficIsLoading]);

    const totalConversionsSources = [
        SimpleCountType.TOTAL_CONVERSIONS_CLICK_TO_CALLS,
        SimpleCountType.TOTAL_CONVERSIONS_IDENTIFIED_VISITORS,
        SimpleCountType.TOTAL_CONVERSIONS_SERVICE_PAGE_VIEWS,
        SimpleCountType.TOTAL_CONVERSIONS_SPECIALS_PAGE_VISITS,
        SimpleCountType.TOTAL_CONVERSIONS_SRP_VIEWS,
        SimpleCountType.TOTAL_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS,
        SimpleCountType.TOTAL_CONVERSIONS_VDP_VIEWS,
    ];

    const totalConversionsSum = totalConversionsSources.reduce(
        (sum, type) => sum + (simpleCounts[type]?.data?.data ?? 0),
        0
    );
    const totalConversionsIsLoading = totalConversionsSources.some(
        (type) => simpleCounts[type]?.isLoading || simpleCounts[type]?.isFetching
    );

    useEffect(() => {
        setMetrics((prevMetrics) => ({
            ...prevMetrics,
            TOTAL_CONVERSIONS: {
                format: 'number',
                value: totalConversionsSum,
                isLoading: totalConversionsIsLoading,
            },
        }));
    }, [totalConversionsSum, totalConversionsIsLoading]);

    const totalAdInfluencedConversionsSources = [
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_CLICK_TO_CALLS,
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_IDENTIFIED_VISITORS,
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SERVICE_PAGE_VIEWS,
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SPECIALS_PAGE_VISITS,
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SRP_VIEWS,
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS,
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_VDP_VIEWS,
    ];

    const totalAdInfluencedConversionsSum = totalAdInfluencedConversionsSources.reduce(
        (sum, type) => sum + (simpleCounts[type]?.data?.data ?? 0),
        0
    );
    const totalAdInfluencedConversionsIsLoading = totalAdInfluencedConversionsSources.some(
        (type) => simpleCounts[type]?.isLoading || simpleCounts[type]?.isFetching
    );

    useEffect(() => {
        setMetrics((prevMetrics) => ({
            ...prevMetrics,
            TOTAL_AD_INFLUENCED_CONVERSIONS: {
                format: 'number',
                value: totalAdInfluencedConversionsSum,
                isLoading: totalAdInfluencedConversionsIsLoading,
            },
        }));
    }, [totalAdInfluencedConversionsSum, totalAdInfluencedConversionsIsLoading]);

    return {
        metrics,
        setMetrics,
    };
};

/**
 * Hat Dashboard Page Context
 *
 */

type HatDashboardPageContextValue = HatCriteriaState &
    UseSimpleCountsState & {
        tabs: UseTabsState;

        defaultMinDate: Date;
        defaultMaxDate: Date;
        defaultStartDate: Date;
        defaultEndDate: Date;

        metrics: HatMetrics;
        setMetrics: Dispatch<SetStateAction<HatMetrics>>;
    };

const HatDashboardPageContext = createContext<HatDashboardPageContextValue | undefined>(undefined);

const useHatDashboardPageContext = (): HatDashboardPageContextValue => {
    const context: HatDashboardPageContextValue | undefined = useContext(HatDashboardPageContext);
    if (!context) {
        throw new Error('useHatDashboardPageContext must be used within a HatDashboardPageProvider');
    }
    return context;
};

/**
 * Hat Dashboard Page Provider
 *
 */

interface HatDashboardPageProviderProps {
    children: ReactNode;
    value: HatDashboardPageContextValue;
}

const HatDashboardPageProvider = ({ children, value }: HatDashboardPageProviderProps) => {
    return <HatDashboardPageContext.Provider value={value}>{children}</HatDashboardPageContext.Provider>;
};

/**
 * Hat Dashboard Page Hook
 *
 */

const useHatDashboardPage = (): HatDashboardPageContextValue => {
    // const { withAxiosCancelTokenRequestConfig } = useAxiosCancelTokens();

    const agency = useContext(AgencyContext);
    const agencyId: number = agency?.id as number;

    const tabs: UseTabsState = useTabs();

    let defaultMinDate = new Date(2020, 0, 1);
    let defaultMaxDate: Date = moment().subtract(1, 'days').startOf('day').toDate();
    let defaultStartDate: Date = moment().subtract(30, 'days').startOf('day').toDate();
    let defaultEndDate: Date = defaultMaxDate;

    const $hatCriteria: HatCriteriaState = useHatCriteria({
        hatCriteria: {
            agencyId: agencyId > 0 ? agencyId : null,
            advertiserId: null,
            startDate: defaultStartDate,
            endDate: defaultEndDate,
        },
    });
    const { hatCriteria } = $hatCriteria;

    const [simpleCountCriteria, setSimpleCountCriteria] = useState<SimpleCountCriteria>({
        apiToken: '',
        range: {
            startDate: moment(defaultStartDate).unix(),
            endDate: moment(defaultStartDate).unix(),
            period: 'weekly',
        },
    });

    const { simpleCounts, setSimpleCounts } = useSimpleCounts();

    useEffect(() => {
        if (hatCriteria.advertiserId) {
            ApiService.getDealer(hatCriteria.advertiserId).then((response) => {
                const { data } = response;

                setSimpleCountCriteria((prev) => ({
                    ...prev,
                    apiToken: data?.tfApiToken || '',
                }));
            });
        } else {
            setSimpleCountCriteria((prev) => ({
                ...prev,
                apiToken: '',
            }));
        }
    }, [hatCriteria.advertiserId]);

    useEffect(() => {
        setSimpleCountCriteria((prev) => ({
            ...prev,
            range: {
                ...prev.range,
                startDate: moment(hatCriteria.startDate).valueOf(),
                endDate: moment(hatCriteria.endDate).valueOf(),
            },
            advertiserId: hatCriteria.advertiserId || undefined,
        }));
    }, [hatCriteria]);

    const totalAdInfluencedConversionsClickToCallsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_CLICK_TO_CALLS,
        simpleCountCriteria
    );
    const totalAdInfluencedConversionsIdentifiedVisitorsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_IDENTIFIED_VISITORS,
        simpleCountCriteria
    );
    const totalAdInfluencedConversionsServicePageViewsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SERVICE_PAGE_VIEWS,
        simpleCountCriteria
    );
    const totalAdInfluencedConversionsSpecialsPageVisitsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SPECIALS_PAGE_VISITS,
        simpleCountCriteria
    );
    const totalAdInfluencedConversionsSrpViewsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SRP_VIEWS,
        simpleCountCriteria
    );
    const totalAdInfluencedConversionsTotalHoursAndDirectionsViewsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS,
        simpleCountCriteria
    );
    const totalAdInfluencedConversionsVdpViewsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_VDP_VIEWS,
        simpleCountCriteria
    );
    const totalAdInfluencedConversionsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS,
        simpleCountCriteria
    );
    const totalAdInfluencedTrafficDirectSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_DIRECT,
        simpleCountCriteria
    );
    const totalAdInfluencedTrafficOrganicSearchSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_ORGANIC_SEARCH,
        simpleCountCriteria
    );
    const totalAdInfluencedTrafficPaidSearchSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_PAID_SEARCH,
        simpleCountCriteria
    );
    const totalAdInfluencedTrafficReferralSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_REFERRAL,
        simpleCountCriteria
    );
    const totalAdInfluencedTrafficSocialSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_SOCIAL,
        simpleCountCriteria
    );
    const totalAdInfluencedTrafficTotalVisitsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_TOTAL_VISITS,
        simpleCountCriteria
    );
    const totalAdInfluencedTrafficSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC,
        simpleCountCriteria
    );
    const totalConversionsTotalVisitsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_AMAZON_DIRECT,
        simpleCountCriteria
    );
    const totalConversionsClickToCallsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_CLICK_TO_CALLS,
        simpleCountCriteria
    );
    const totalConversionsDirectSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_DIRECT,
        simpleCountCriteria
    );
    const totalConversionsIdentifiedVisitorsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_IDENTIFIED_VISITORS,
        simpleCountCriteria
    );
    const totalConversionsOrganicSearchSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_ORGANIC,
        simpleCountCriteria
    );
    const totalConversionsPaidSearchSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_PAID_SEARCH,
        simpleCountCriteria
    );
    const totalConversionsReferralSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_REFERRAL,
        simpleCountCriteria
    );
    const totalConversionsServicePageViewsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_SERVICE_PAGE_VIEWS,
        simpleCountCriteria
    );
    const totalConversionsSocialSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_SOCIAL,
        simpleCountCriteria
    );
    const totalConversionsSpecialsPageVisitsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_SPECIALS_PAGE_VISITS,
        simpleCountCriteria
    );
    const totalConversionsSrpViewsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_SRP_VIEWS,
        simpleCountCriteria
    );
    const totalConversionsTotalHoursAndDirectionsViewsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS,
        simpleCountCriteria
    );
    const totalConversionsVdpViewsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_CONVERSIONS_VDP_VIEWS,
        simpleCountCriteria
    );
    const totalConversionsSimpleCountQuery = useSimpleCount(SimpleCountType.TOTAL_CONVERSIONS, simpleCountCriteria);
    const totalUniqueTrafficDirectSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_DIRECT,
        simpleCountCriteria
    );
    const totalUniqueTrafficOrganicSearchSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_ORGANIC_SEARCH,
        simpleCountCriteria
    );
    const totalUniqueTrafficPaidSearchSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_PAID_SEARCH,
        simpleCountCriteria
    );
    const totalUniqueTrafficReferralSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_REFERRAL,
        simpleCountCriteria
    );
    const totalUniqueTrafficSocialSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_SOCIAL,
        simpleCountCriteria
    );
    const totalUniqueTrafficTotalVisitsSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC_TOTAL_VISITS,
        simpleCountCriteria
    );
    const totalUniqueTrafficSimpleCountQuery = useSimpleCount(
        SimpleCountType.TOTAL_UNIQUE_TRAFFIC,
        simpleCountCriteria
    );

    const { metrics, setMetrics } = useHatMetrics({ simpleCounts });

    useEffect(() => {
        setSimpleCounts((prev) => ({
            ...prev,
            [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_CLICK_TO_CALLS]:
                totalAdInfluencedConversionsClickToCallsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_IDENTIFIED_VISITORS]:
                totalAdInfluencedConversionsIdentifiedVisitorsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SERVICE_PAGE_VIEWS]:
                totalAdInfluencedConversionsServicePageViewsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SPECIALS_PAGE_VISITS]:
                totalAdInfluencedConversionsSpecialsPageVisitsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SRP_VIEWS]:
                totalAdInfluencedConversionsSrpViewsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS]:
                totalAdInfluencedConversionsTotalHoursAndDirectionsViewsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_VDP_VIEWS]:
                totalAdInfluencedConversionsVdpViewsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS]: totalAdInfluencedConversionsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_DIRECT]: totalAdInfluencedTrafficDirectSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_ORGANIC_SEARCH]:
                totalAdInfluencedTrafficOrganicSearchSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_PAID_SEARCH]:
                totalAdInfluencedTrafficPaidSearchSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_REFERRAL]: totalAdInfluencedTrafficReferralSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_SOCIAL]: totalAdInfluencedTrafficSocialSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_TOTAL_VISITS]:
                totalAdInfluencedTrafficTotalVisitsSimpleCountQuery,
            [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC]: totalAdInfluencedTrafficSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_AMAZON_DIRECT]: totalConversionsTotalVisitsSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_CLICK_TO_CALLS]: totalConversionsClickToCallsSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_DIRECT]: totalConversionsDirectSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_IDENTIFIED_VISITORS]: totalConversionsIdentifiedVisitorsSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_ORGANIC]: totalConversionsOrganicSearchSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_PAID_SEARCH]: totalConversionsPaidSearchSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_REFERRAL]: totalConversionsReferralSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_SERVICE_PAGE_VIEWS]: totalConversionsServicePageViewsSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_SOCIAL]: totalConversionsSocialSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_SPECIALS_PAGE_VISITS]:
                totalConversionsSpecialsPageVisitsSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_SRP_VIEWS]: totalConversionsSrpViewsSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS]:
                totalConversionsTotalHoursAndDirectionsViewsSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS_VDP_VIEWS]: totalConversionsVdpViewsSimpleCountQuery,
            [SimpleCountType.TOTAL_CONVERSIONS]: totalConversionsSimpleCountQuery,
            [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_DIRECT]: totalUniqueTrafficDirectSimpleCountQuery,
            [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_ORGANIC_SEARCH]: totalUniqueTrafficOrganicSearchSimpleCountQuery,
            [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_PAID_SEARCH]: totalUniqueTrafficPaidSearchSimpleCountQuery,
            [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_REFERRAL]: totalUniqueTrafficReferralSimpleCountQuery,
            [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_SOCIAL]: totalUniqueTrafficSocialSimpleCountQuery,
            [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_TOTAL_VISITS]: totalUniqueTrafficTotalVisitsSimpleCountQuery,
            [SimpleCountType.TOTAL_UNIQUE_TRAFFIC]: totalUniqueTrafficSimpleCountQuery,
        }));
    }, [
        setSimpleCounts,
        totalAdInfluencedConversionsClickToCallsSimpleCountQuery,
        totalAdInfluencedConversionsIdentifiedVisitorsSimpleCountQuery,
        totalAdInfluencedConversionsServicePageViewsSimpleCountQuery,
        totalAdInfluencedConversionsSpecialsPageVisitsSimpleCountQuery,
        totalAdInfluencedConversionsSrpViewsSimpleCountQuery,
        totalAdInfluencedConversionsTotalHoursAndDirectionsViewsSimpleCountQuery,
        totalAdInfluencedConversionsVdpViewsSimpleCountQuery,
        totalAdInfluencedConversionsSimpleCountQuery,
        totalAdInfluencedTrafficDirectSimpleCountQuery,
        totalAdInfluencedTrafficOrganicSearchSimpleCountQuery,
        totalAdInfluencedTrafficPaidSearchSimpleCountQuery,
        totalAdInfluencedTrafficReferralSimpleCountQuery,
        totalAdInfluencedTrafficSocialSimpleCountQuery,
        totalAdInfluencedTrafficTotalVisitsSimpleCountQuery,
        totalAdInfluencedTrafficSimpleCountQuery,
        totalConversionsTotalVisitsSimpleCountQuery,
        totalConversionsClickToCallsSimpleCountQuery,
        totalConversionsDirectSimpleCountQuery,
        totalConversionsIdentifiedVisitorsSimpleCountQuery,
        totalConversionsOrganicSearchSimpleCountQuery,
        totalConversionsPaidSearchSimpleCountQuery,
        totalConversionsReferralSimpleCountQuery,
        totalConversionsServicePageViewsSimpleCountQuery,
        totalConversionsSocialSimpleCountQuery,
        totalConversionsSpecialsPageVisitsSimpleCountQuery,
        totalConversionsSrpViewsSimpleCountQuery,
        totalConversionsTotalHoursAndDirectionsViewsSimpleCountQuery,
        totalConversionsVdpViewsSimpleCountQuery,
        totalConversionsSimpleCountQuery,
        totalUniqueTrafficDirectSimpleCountQuery,
        totalUniqueTrafficOrganicSearchSimpleCountQuery,
        totalUniqueTrafficPaidSearchSimpleCountQuery,
        totalUniqueTrafficReferralSimpleCountQuery,
        totalUniqueTrafficSocialSimpleCountQuery,
        totalUniqueTrafficTotalVisitsSimpleCountQuery,
        totalUniqueTrafficSimpleCountQuery,
    ]);

    return {
        defaultMinDate,
        defaultMaxDate,
        defaultStartDate,
        defaultEndDate,

        ...$hatCriteria,

        tabs,

        metrics,
        setMetrics,

        simpleCounts,
        setSimpleCounts,
    };
};

export type { HatDashboardPageContextValue };
export { HatDashboardPageContext };
export { useHatDashboardPageContext };

export type { HatDashboardPageProviderProps };
export { HatDashboardPageProvider };

export { useHatDashboardPage };
