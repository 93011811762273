import { useContext, useEffect } from 'react';
import { Box, Button, Divider, Stack, Tooltip, Typography } from '@mui/material';
import {
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    UpdateOutlined as UpdateOutlinedIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { SectionHeading } from '../../components/SectionHeading';
import { UserContext } from '../../App';
import Column from '../../components/Column';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import useAdvertiserAttribution from '../../hooks/useAdvertiserAttribution';
import AttributionExportMenuButton from './AttributionExportMenuButton';
import PolkMatchbackMetrics from './PolkMatchbackMetrics';
import PolkMatchbackDataTabs from './PolkMatchbackDataTabs';

interface PolkMatchbackTabProps {
    showExportButton?: boolean;
}

export default function PolkMatchbackTab({ showExportButton = true }: PolkMatchbackTabProps) {
    const { userContext } = useContext(UserContext);
    const {
        advertiser,
        hasOneAdvertiser,
        attributionDate,
        attributionStatusUpdateDateFormatted,
        fetchAttributionStatus,
        fetchPolkSalesWorkflowSets,
        fetchPreviousPolkSalesWorkflowSets,
    } = useAttributionPageContext();

    const { fetchPerformances, fetchPreviousPerformances } = useAdvertiserAttribution();

    useEffect(() => {
        fetchAttributionStatus();
    }, [fetchAttributionStatus]);

    useEffect(() => {
        fetchPolkSalesWorkflowSets();
    }, [fetchPolkSalesWorkflowSets]);

    useEffect(() => {
        fetchPreviousPolkSalesWorkflowSets();
    }, [fetchPreviousPolkSalesWorkflowSets]);

    useEffect(() => {
        fetchPerformances();
    }, [fetchPerformances]);

    useEffect(() => {
        fetchPreviousPerformances();
    }, [fetchPreviousPerformances]);

    return (
        <Column gap={3} sx={{ mt: (theme) => theme.spacing(1) }}>
            <Row>
                <Row gap={0}>
                    {hasOneAdvertiser && attributionStatusUpdateDateFormatted && (
                        <Row gap={1} alignItems="center" justifyContent="flex-start">
                            <Row gap={0.5} sx={{ width: 'auto' }}>
                                <UpdateOutlinedIcon color="success" fontSize="small" />
                                <Typography variant="body2" color="text.secondary">
                                    Last updated{' '}
                                    <Typography component="span" variant="body2" color="text.primary" fontWeight={500}>
                                        {attributionStatusUpdateDateFormatted}
                                    </Typography>
                                </Typography>
                            </Row>

                            <Divider orientation="vertical" sx={{ height: 14 }} />

                            <Row gap={0.5} alignItems="center" sx={{ width: 'auto' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Data Source
                                </Typography>

                                <Tooltip title="Sales data provided by Reported Sales.">
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            </Row>
                        </Row>
                    )}
                </Row>

                <Row alignItems="center" justifyContent="flex-end">
                    {hasOneAdvertiser && (
                        <Printable show={false}>
                            <>
                                {userContext.isAdvertiser() ? (
                                    <>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="medium"
                                            component={Link}
                                            to={`mailto:support@cognitionads.com?subject=Request to Map Reported Sales Data for Advertiser`}
                                        >
                                            Update Advertisers & Competitors
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        {advertiser && (
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                size="medium"
                                                component={Link}
                                                to={`/advertisers/${advertiser.id}/#polkSales`}
                                            >
                                                Update Advertisers & Competitors
                                            </Button>
                                        )}
                                    </>
                                )}
                            </>
                        </Printable>
                    )}

                    {showExportButton && <AttributionExportMenuButton />}
                </Row>
            </Row>

            <Printable show={true}>
                <>
                    {hasOneAdvertiser && advertiser && (
                        <Row justifyContent="center" alignItems="center">
                            {advertiser.logoUrl && advertiser.logoUrl.length > 0 ? (
                                <img
                                    src={advertiser.logoUrl}
                                    alt={`${advertiser.dealerName}'s Logo`}
                                    style={{ maxHeight: 128 }}
                                />
                            ) : (
                                <Typography variant="h4">{advertiser.dealerName}</Typography>
                            )}
                        </Row>
                    )}
                    <Row justifyContent="center" alignItems="center">
                        <Typography variant="h5">
                            {moment(Utils.getDate(attributionDate)).format('MMMM YYYY')}
                        </Typography>
                    </Row>
                </>
            </Printable>

            <div>
                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Column gap={0.5}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <SectionHeading divider={false}>Overview</SectionHeading>
                        </Stack>
                    </Column>
                </Stack>
            </div>

            <div>
                <PolkMatchbackMetrics />
            </div>

            <Box>
                <PolkMatchbackDataTabs />
            </Box>

            <Box
                sx={{
                    borderTop: 1,
                    borderColor: 'divider',
                    py: 2,
                }}
            >
                <Stack direction="row" spacing={1.5} justifyContent="center" alignItems="center">
                    <Typography variant="body2" color="text.primary">
                        Powered By
                    </Typography>

                    <img
                        src="/Logo_Black_iCAN.svg"
                        alt="Powered By Cognition iCAN"
                        style={{
                            height: 30,
                        }}
                    />
                </Stack>
            </Box>
        </Column>
    );
}
