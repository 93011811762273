import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import React, { useEffect, useState } from 'react';
import { GridColumns, GridFooterContainer, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import ApiService from '../../ApiService';
import CDGrid, { CustomPagination } from '../../components/CDGrid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Row from '../../components/Row';
import { AxiosPageableResponse } from '../../types/AxiosPageableResponse';
import { ReleaseNote } from '../../types/ReleaseNote';
import CloseIcon from '@mui/icons-material/Close';

function ReleaseNoteArticlesFooter(props: { totalElements: number; numberOfElements: number }) {
    return (
        <GridFooterContainer>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px' }}>
                <CustomPagination />
                <span>
                    Showing {props.numberOfElements} of {props.totalElements} records
                </span>
            </div>
        </GridFooterContainer>
    );
}

export default function ReleaseNoteArticlesPage() {
    const defaultPageSize: number = 10;
    const pageValueArray = [10, 20, 50, 75, 100];
    const [list, setList] = useState<ReleaseNote[] | null>(null);
    const [page, setPage] = useState(0);
    const [selectedRow, setSelectedRow] = useState<GridRenderCellParams<ReleaseNote> | null>(null);
    const [totalElements, setTotalElements] = useState(0);
    const [numberOfElements, setNumberOfElements] = useState(0);
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'releaseDate', sort: 'desc' }]);
    const [open, setOpen] = useState(false);

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = parseInt(event?.target?.value);
        if (pageValueArray.includes(value)) {
            setPageSize(value);
        } else {
            // Handle invalid value or reset to default
            setPageSize(defaultPageSize);
        }
    };

    function onPageChange(page: number) {
        setPage(page);
    }

    const fetchData = async () => {
        const response: AxiosPageableResponse<ReleaseNote> = await ApiService.getPublishedReleaseNotes(
            page,
            pageSize,
            sortModel
        );
        if (response.status === 200) {
            setList(response.data.content);
            setTotalElements(response.data.totalElements);
            setNumberOfElements(response.data.numberOfElements);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, pageSize, sortModel]);

    const columns: GridColumns = [
        {
            flex: 3,
            minWidth: 100,
            field: 'description',
            headerName: 'Release Note',

            renderCell: (params: GridRenderCellParams<ReleaseNote>) => (
                <Typography
                    variant="body2"
                    sx={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                        setSelectedRow(params);
                        setOpen(true);
                    }}
                >
                    {params.value}
                </Typography>
            ),
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'releaseDate',
            type: 'date',
            valueFormatter: (params) => {
                if (!params.value) return '';
                return new Date(params.value).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                });
            },
            headerName: 'Release Date',
        },
        {
            flex: 2,
            minWidth: 100,
            field: 'appVersion',
            headerName: 'Version',
        },
    ];

    return (
        <Box
            sx={{
                '& .status-Failed': {
                    color: 'rgb(211, 47, 47)',
                },
                '& .status-Success': {
                    color: 'green',
                },
            }}
        >
            <Block>
                <Column gap={2}>
                    <Row flex={0} justifyContent="flex-end">
                        <Heading>Release Notes</Heading>

                        <FormControl sx={{ minWidth: 120 }} size="small">
                            <InputLabel id="demo-select-small-label">Rows Per Page</InputLabel>
                            <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={pageSize.toString()}
                                label="Rows Per Page"
                                onChange={handleChange}
                            >
                                {pageValueArray?.map((value) => {
                                    return (
                                        <MenuItem key={value} value={value}>
                                            {value}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Row>
                    <Modal open={open} onClose={() => setOpen(false)}>
                        <Box>
                            {selectedRow && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        height: '80%',
                                        transform: 'translate(-50%, -50%)',
                                        width: '80%',
                                        bgcolor: 'background.paper',
                                        boxShadow: 24,
                                        p: 3,
                                        borderRadius: 2,
                                    }}
                                >
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Typography>
                                            <strong>{selectedRow.row.description}</strong>
                                        </Typography>
                                        <IconButton onClick={() => setOpen(false)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Box>
                                    <Box mt={2} sx={{ maxHeight: '90%', overflowY: 'auto' }}>
                                        <div dangerouslySetInnerHTML={{ __html: selectedRow.row.content }} />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Modal>
                    <CDGrid
                        rowCount={totalElements}
                        pageSize={pageSize}
                        rows={list === null ? [] : list}
                        columns={columns}
                        page={page}
                        onPageChange={onPageChange}
                        onSortModelChange={setSortModel}
                        autoHeight
                        disableSelectionOnClick={true}
                        components={{
                            Footer: ReleaseNoteArticlesFooter,
                        }}
                        componentsProps={{
                            footer: {
                                pageSize,
                                totalElements,
                                numberOfElements,
                            },
                        }}
                        rowsPerPageOptions={[20]}
                        sortingMode={'server'}
                        paginationMode={'server'}
                    />
                </Column>
            </Block>
        </Box>
    );
}
