import { useQuery } from 'react-query';
import ApiService from '../../ApiService';

const fetchData = async (advertiserId: number) => {
    const { data } = await ApiService.getHatCreativePixels(advertiserId);
    return data;
};

const useCreativePixelListQuery = (advertiserId: number, apiToken: string) => {
    return useQuery(['creativePixelList', advertiserId, apiToken], () => fetchData(advertiserId), {
        staleTime: Infinity,
        enabled: !!advertiserId,
    });
};

export { useCreativePixelListQuery };
