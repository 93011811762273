import { SyntheticEvent, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TabsContainer } from '../../../components/Tabs';
import ApiService from '../../../ApiService';
import AppBreadcrumbs from '../../../components/AppBreadcrumbs';
import Block from '../../../components/Block';
import Column from '../../../components/Column';
import Heading from '../../../components/Heading';
import Row from '../../../components/Row';
import TabPanel from '../../../components/TabPanel';

import { Campaign, CampaignFee } from '../../../types/Campaign';
import { CampaignDetailsPageContext } from '../../../hooks/useCampaignDetailsPage';
import { useUserContext } from '../../../hooks/Contexts/UserContext';
import useCampaignDetailsPage from '../../../hooks/useCampaignDetailsPage';
import AdServerPlacementsTab from './AdServerPlacementsTab';
import CampaignArchiveButton from './CampaignArchiveButton';
import CampaignDeleteButton from './CampaignDeleteButton';
import CampaignDetailsHeader from './CampaignDetailsHeader';
import CampaignFeesComponent from './CampaignFeesComponent';
import CampaignModifyButton from './CampaignModifyButton';
import CampaignRefreshButton from './CampaignRefreshButton';
import ChangeRequestTab from './ChangeRequestTab';
import CreativeTab from './CreativeTab';
import FlightsTab from './FlightsTab';
import PerformanceTab from './PerformanceTab';
import SummaryTab from './SummaryTab';
import TargetingTab from './TargetingTab';

export const TABS = {
    PERFORMANCE: 0,
    SUMMARY: 1,
    TARGETING: 2,
    CREATIVE: 3,
    FLIGHTS: 4,
    AD_SERVER_PLACEMENTS: 5,
    FEES: 6,
    CHANGE_REQUESTS: 7,
};

const FT_SAS_ACCOUNT_ID = 2;
const VIDEO_MEDIA_TYPE = 'Video';

const CampaignDetailsPage = (): JSX.Element | null => {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const id = params.id ? +params.id : null;

    const campaignDetailsPage = useCampaignDetailsPage();
    const { campaignChangeRequest } = campaignDetailsPage;

    const [selectedTab, setSelectedTab] = useState(location?.state?.tab ?? TABS.PERFORMANCE);
    const [campaign, setCampaign] = useState<Campaign | null>(null);
    const { userContext } = useUserContext();

    useEffect(() => {
        getCampaign();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    function getCampaign() {
        if (id) {
            ApiService.getCampaign(id)
                .then((response) => {
                    let campaign = response.data;
                    if (campaign.deliveryStatusId === 'PENDING_COGNITION' || campaign.hasUpdates) {
                        setSelectedTab(TABS.SUMMARY);
                    }
                    setCampaign(campaign);
                })
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        navigate('/notFound');
                    }
                });
        }
    }

    const showAdServerPlacements = () =>
        campaign?.dealer?.sasAccountId === FT_SAS_ACCOUNT_ID &&
        campaign?.dealer?.ftAdvertiserId &&
        userContext.isAdmin() &&
        campaign?.mediaType === VIDEO_MEDIA_TYPE;

    function selectTab(_event: SyntheticEvent, value: any) {
        setSelectedTab(value);
    }

    if (!campaign) {
        return null;
    }

    return (
        <CampaignDetailsPageContext.Provider value={campaignDetailsPage}>
            <Column gap={0} flex={1}>
                <Block>
                    <Column gap={1}>
                        <AppBreadcrumbs items={[{ label: 'Campaigns', href: '/campaignManager' }]} />

                        <Row gap={1} sx={{ alignItems: 'center' }}>
                            <span>
                                <Heading>Campaign Details</Heading>
                            </span>
                            <span style={{ flex: 1 }} />
                            {userContext.isAdmin() && (
                                <>
                                    <CampaignRefreshButton
                                        campaign={campaign}
                                        campaignChangeRequest={campaignChangeRequest}
                                        onChange={(campaign: Campaign): void => {
                                            setCampaign(campaign);
                                        }}
                                    />
                                </>
                            )}
                            <CampaignModifyButton campaign={campaign} campaignChangeRequest={campaignChangeRequest} />
                            {(userContext.isAdmin() || userContext.isAgency()) && (
                                <CampaignArchiveButton
                                    campaign={campaign}
                                    onCampaignArchive={() => {
                                        setTimeout(() => {
                                            navigate('/campaignManager');
                                        }, 2000);
                                    }}
                                />
                            )}
                            {userContext.isAdmin() && (
                                <CampaignDeleteButton
                                    campaign={campaign}
                                    onCampaignDelete={() => {
                                        setTimeout(() => {
                                            navigate('/campaignManager');
                                        }, 2000);
                                    }}
                                />
                            )}
                        </Row>
                    </Column>
                </Block>

                <Column gap={2} sx={{ height: '100%' }}>
                    <CampaignDetailsHeader campaign={campaign} />

                    <TabsContainer>
                        <Tabs value={selectedTab} onChange={selectTab}>
                            <Tab value={TABS.PERFORMANCE} label="Performance" />
                            <Tab value={TABS.SUMMARY} label="Summary" />
                            <Tab value={TABS.TARGETING} label="Targeting" />
                            {userContext.isAdmin() && <Tab value={TABS.CREATIVE} label="Creative (BETA)" />}
                            {userContext.isAdmin() && <Tab value={TABS.FLIGHTS} label="Flights" />}
                            {showAdServerPlacements() && (
                                <Tab value={TABS.AD_SERVER_PLACEMENTS} label="Ad Server Placements" />
                            )}
                            {userContext.isAdmin() && <Tab value={TABS.FEES} label="Fees" />}
                            <Tab value={TABS.CHANGE_REQUESTS} label={'Change Requests'} />
                        </Tabs>
                    </TabsContainer>

                    <Block>
                        <TabPanel index={TABS.PERFORMANCE} value={selectedTab}>
                            <PerformanceTab campaign={campaign} />
                        </TabPanel>

                        <TabPanel index={TABS.SUMMARY} value={selectedTab}>
                            <SummaryTab campaign={campaign} reloadCampaign={getCampaign} />
                        </TabPanel>

                        <TabPanel index={TABS.TARGETING} value={selectedTab}>
                            <TargetingTab campaign={campaign} onChange={() => {}} />
                        </TabPanel>

                        {userContext.isAdmin() && (
                            <TabPanel index={TABS.CREATIVE} value={selectedTab}>
                                <CreativeTab campaign={campaign} />
                            </TabPanel>
                        )}

                        {userContext.isAdmin() && (
                            <TabPanel index={TABS.FLIGHTS} value={selectedTab}>
                                <FlightsTab campaign={campaign} reloadCampaign={getCampaign} />
                            </TabPanel>
                        )}

                        <TabPanel index={TABS.AD_SERVER_PLACEMENTS} value={selectedTab}>
                            <AdServerPlacementsTab campaign={campaign} reloadCampaign={getCampaign} />
                        </TabPanel>

                        {userContext.isAdmin() && (
                            <TabPanel index={TABS.FEES} value={selectedTab}>
                                <CampaignFeesComponent
                                    campaignFee={campaign.campaignFee}
                                    allowEditToggle={userContext.isAdmin() && campaign.deliveryStatusId !== 'ENDED'}
                                    editMode={false}
                                    onChange={(campaignFee: CampaignFee) => {
                                        const updatedCampaign = { ...campaign, campaignFee: campaignFee };
                                        setCampaign(updatedCampaign);
                                        ApiService.updateCampaign(updatedCampaign).then((response) => {
                                            setCampaign(response.data);
                                        });
                                    }}
                                />
                            </TabPanel>
                        )}

                        <TabPanel index={TABS.CHANGE_REQUESTS} value={selectedTab}>
                            <ChangeRequestTab campaign={campaign} />
                        </TabPanel>
                    </Block>
                </Column>
            </Column>
        </CampaignDetailsPageContext.Provider>
    );
};

export default CampaignDetailsPage;
