import { useContext } from 'react';
import { UserContext, UserContextValue } from '../../App';
import { Agency } from '../../types/Agency';

interface UserContextState {
    userContext: UserContextValue;
    setUserContext: (
        _userId: number | null | undefined,
        _userName: string | null,
        _name: string | undefined,
        _group: string | undefined,
        _agencyId: number | null,
        _organization: string | undefined,
        _agency?: Agency
    ) => void;
    refreshUserContext: () => void;
}

const useUserContext = (): UserContextState => {
    return useContext(UserContext);
};

export type { UserContext, UserContextValue };
export type { UserContextState };
export { useUserContext };
