import { TradeInMarket } from './TradeInMarket';

export class Dealer {
    id?: number;
    dealerName: string = '';
    agencyId: number | undefined | null;

    agency?: {
        id: any;
        name: string;
        tfCustomerId: string;
        tfUserIsActive: boolean;
        creativeStudioIsActive: boolean;
        creativeStudioUserId: string | null;
    };
    verticalId: number | undefined;
    sasAccountId: number | undefined;
    dspEntityId: number | undefined;
    publicId: string = '';
    address: string = '';
    phone: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';
    countryCode: string = 'US';
    url: string = '';
    logoUrl: string = '';
    defaultBadge: string = '';
    fixedOpsBadge: string = '';
    preferredPhotoIndex: number = 1;
    minPhotoExportCount: number = 1;
    partsId: string = '';
    tradeInEnrolled = false;
    tradeInMarket?: TradeInMarket;
    amcInstanceId?: number;
    dspAdvertiser = '';
    sizmekAdvertiserId = '';
    modified?: boolean;
    ageExportFilterOperator: string = '';
    ageExportFilterValue: number = 0;
    newUsedFilter: string = '';
    certifiedFilter: string = '';
    customLabelFilter: string = '';
    legalDisclaimer: string = '';
    dspReportImpressions: boolean = true;
    dspReportSummaryGeo: boolean = true;
    dspReportSummaryAppBySpend: boolean = true;
    dspAdTagId: string = '';
    dspAdTagProvider: string = '';
    dspAdTagCode: string = '';
    ftAdvertiserId: string = '';
    activeStatus: boolean = true;
    ctaText: string = '';
    ctaButtonColor: string = '';
    ctaTextSecondary: string = '';
    ctaButtonColorSecondary: string = '';
    defaultAdImageUrl: string = '';
    defaultAdImageUrl300x600: string = '';
    defaultAdImageUrl160x600: string = '';
    defaultAdImageUrl728x90: string = '';
    showTrim: boolean = false;
    gatewayAmazonDSPIsActive: number = 0;
    imageFilter: string = '';
    tfTokenIsActive: boolean = false;
    tfApiToken: string = '';
    tfSnippet: string = '';
    creativeStudioIsActive: boolean = false;
    creativeStudioUserId: string | null = null;
}
