import { useMemo } from 'react';
import { Campaign } from '../../../types/Campaign';

interface UseProps {
    campaign: Campaign;
}

interface UseState {
    hasCampaignChangeGracePeriod: boolean;
    hasCampaignChangeGracePeriodExpired: boolean;
}

const CAMPAIGN_CHANGE_GRACE_PERIOD_DAYS = 180;

const useCampaignChangeGracePeriod = ({ campaign }: UseProps): UseState => {
    const hasCampaignChangeGracePeriod = useMemo((): boolean => {
        const today: Date = new Date();

        const campaignEndDate: Date = new Date(campaign.endDate as Date);
        const gracePeriodEndDate: Date = new Date(campaignEndDate);

        gracePeriodEndDate.setDate(campaignEndDate.getDate() + CAMPAIGN_CHANGE_GRACE_PERIOD_DAYS);

        return today < gracePeriodEndDate;
    }, [campaign.endDate]);

    const hasCampaignChangeGracePeriodExpired = useMemo((): boolean => {
        return hasCampaignChangeGracePeriod === false;
    }, [hasCampaignChangeGracePeriod]);

    return {
        hasCampaignChangeGracePeriod,
        hasCampaignChangeGracePeriodExpired,
    };
};

export type { UseProps as UseCampaignChangeGracePeriodProps };
export type { UseState as UseCampaignChangeGracePeriodState };
export { useCampaignChangeGracePeriod };
