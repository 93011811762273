import { useEffect, useMemo, useState } from 'react';
import { Typography } from '@mui/material';

import { Performance } from '../../../../../types/CampaignPerformance';
import Printable from '../../../../../components/Printable';
import Row from '../../../../../components/Row';
import Utils from '../../../../../components/Utils';

import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import BasicBarChartCard from '../../../../../components/Metrics/BasicBarChartCard';

type PerformanceAverageCPMBasicBarChartCardProps = {
    loading?: boolean;
};

export default function PerformanceAverageCPMBasicBarChartCard(props: PerformanceAverageCPMBasicBarChartCardProps) {
    const { loading = false } = props;
    const { performances } = useReportingDashboardPageContext();

    const [mediaType, setMediaType] = useState<string>(() => {
        return Utils.localStorage('analytics.reporting.performanceAverageCPMBasicBarChart', 'Display');
    });

    const mediaTypes = useMemo((): string[] => {
        return [
            'Display',
            'Streaming TV',
            'Online Video',
            'Audio',
            // Note: Legacy media type
            'Video',
        ];
    }, []);

    const seriesData = useMemo(() => {
        if (mediaTypes && mediaTypes.length && performances && performances.length) {
            return mediaTypes
                .map((_mediaType: string) => {
                    const serieDatum = {
                        x: _mediaType,
                        y: 0,
                    };

                    const mediaTypePerformances: Performance[] = performances.filter(
                        (p: Performance) => p.mediaType === _mediaType
                    );

                    if (mediaTypePerformances.length) {
                        serieDatum.y = mediaTypePerformances.reduce(
                            (total: number, p: Performance) => total + p.CPM,
                            0
                        );
                        serieDatum.y /= mediaTypePerformances.length;
                    }

                    return serieDatum;
                })
                .filter((serieDatum) => {
                    return serieDatum.y > 0;
                });
        }

        return [];
    }, [mediaTypes, performances]);

    const chartSeries = useMemo(() => {
        if (performances && performances.length) {
            return [
                {
                    name: 'Impressions',
                    data: seriesData.filter((d) => d.y > 0).map((d) => d.y),
                },
            ];
        }

        return [];
    }, [seriesData, performances]);

    useEffect(() => {
        localStorage.setItem('analytics.reporting.performanceAverageCPMBasicBarChart', mediaType);
    }, [mediaType]);

    useEffect(() => {
        if (mediaTypes && mediaTypes.length) {
            if (!mediaTypes.includes(mediaType)) {
                setMediaType(mediaTypes[0]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaTypes]);

    // useEffect(() => {
    //     if (mediaType) {
    //         setPerformances(getPerformances(mediaType));
    //     } else {
    //         setPerformances([]);
    //     }
    // }, [getPerformances, mediaType]);

    return (
        <BasicBarChartCard
            loading={loading}
            label={
                <Row gap={1} alignItems="center" sx={{ mr: 0.5 }} width="auto">
                    <Printable show={true}>
                        <Typography variant="body1" color="text.primary">
                            Average CPM by Media Type
                        </Typography>
                    </Printable>
                    <Printable show={false}>
                        <>
                            <Typography variant="body1" color="text.primary">
                                Average CPM by Media Type
                            </Typography>
                        </>
                    </Printable>
                </Row>
            }
            tooltip="Cost per thousand impressions/exposures per ad type"
            chartOptions={{
                dataLabels: {
                    offsetX: 65,
                    formatter: (value: number, opts) => {
                        if (typeof value === 'number') {
                            return Utils.formatCurrency(value, 2) as string;
                        }

                        return value;
                    },
                },
                xaxis: {
                    categories: seriesData.map((d) => d.x),
                },
            }}
            chartSeries={chartSeries}
        />
    );
}
