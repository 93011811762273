import { useRef, useEffect, useCallback } from 'react';
import { CancelTokenSource, AxiosRequestConfig } from 'axios';
import { withAxiosCancelSourceToken } from '../utils/AxiosCancelSourceToken';

type UseState = <T extends AxiosRequestConfig>(defaultRequestConfig?: T | undefined) => AxiosRequestConfig;

const useAxiosCancelToken = (): UseState => {
    const cancelTokenRef = useRef<CancelTokenSource | null>(null);

    /**
     * Generates a new cancelable Axios request config.
     * Cancels the previous request before generating a new one.
     */
    const withAxiosCancelTokenRequestConfig = useCallback(
        <T extends AxiosRequestConfig>(defaultRequestConfig?: T | undefined): AxiosRequestConfig => {
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Request canceled due to new action.');
            }

            const { requestConfig, cancelTokenSource } = withAxiosCancelSourceToken(
                defaultRequestConfig || {
                    headers: { XBusy: 'false' },
                }
            );

            cancelTokenRef.current = cancelTokenSource;

            return requestConfig;
        },
        []
    );

    // Cleanup on unmount
    useEffect(() => {
        return () => {
            if (cancelTokenRef.current) {
                cancelTokenRef.current.cancel('Component unmounting.');
            }
        };
    }, []);

    return withAxiosCancelTokenRequestConfig;
};

export type { UseState as UseAxiosCancelTokenState };
export { useAxiosCancelToken };
