import { useState, useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';

import ApiService from '../../ApiService';
import { Dealer } from '../../types/Dealer';
import { CampaignAdGroup } from '../../types/Campaign';
import { Agency } from '../../types/Agency';

const SelectAdvertiser = ({
    setAdvertiser,
    setYearMonth,
    setAdGroup,
    setSize,
    agency,
    advertiser,
}: {
    setAdvertiser: (advertiser: Dealer | null) => void;
    setYearMonth: (yearMonth: string) => void;
    setAdGroup: (adGroup: CampaignAdGroup | null) => void;
    setSize: (size: string) => void;
    agency: Agency | null;
    advertiser: Dealer | null;
}) => {
    const [advertisers, setAdvertisers] = useState<Dealer[]>([]);
    const [filteredAdvertisers, setFilteredAdvertisers] = useState<Dealer[]>([]);

    useEffect(() => {
        ApiService.getDealersFtAdvertiserIdIsNotNull()
            .then((response) => {
                setAdvertisers(response?.data);
                setFilteredAdvertisers(response?.data);
            })
            .catch(() => {
                setAdvertisers([]);
                setFilteredAdvertisers([]);
            });
    }, []);

    useEffect(() => {
        // filter & reset
        setAdvertiser(null);
        if (agency?.id) {
            setFilteredAdvertisers(advertisers?.filter((advertiser) => advertiser.agencyId === agency.id));
            setAdGroup(null);
            setYearMonth('');
            setSize('All');
        } else {
            setFilteredAdvertisers(advertisers);
        }
    }, [agency]);

    return (
        <Autocomplete
            sx={{
                width: '224px',
            }}
            size="small"
            value={advertiser}
            options={filteredAdvertisers}
            id="select-advertiser"
            getOptionLabel={(option) => option.dealerName}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.dealerName}
                </li>
            )}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            onChange={(e, value) => {
                setAdGroup(null);
                setYearMonth('');
                setSize('All');
                setAdvertiser(value);
            }}
            renderInput={(params) => <TextField {...params} label="Advertiser" />}
        />
    );
};

export default SelectAdvertiser;
