import { FC } from 'react';
import { useHatChannelTrafficTable } from '../Tables/HatChannelTrafficTable';
import Column from '../../../../components/Column';
import HatChannelTrafficTable from '../Tables/HatChannelTrafficTable';

const HatChannelTrafficTableView: FC = (): JSX.Element => {
    const { rows } = useHatChannelTrafficTable();

    return (
        <Column gap={3}>
            <HatChannelTrafficTable rows={rows} />
        </Column>
    );
};

export default HatChannelTrafficTableView;
