import { FC } from 'react';
import { Grid } from '@mui/material';
import Column from '../../../../components/Column';
import HatChannelByChannelPieChartCard from '../Charts/HatChannelByChannelPieChartCard';
import HatChannelByTypePieChartCard from '../Charts/HatChannelByTypePieChartCard';

const HatChannelTrafficChartView: FC = (): JSX.Element => {
    return (
        <Column gap={3}>
            <Grid container spacing={2}>
                <Grid item xl={6} xs={12}>
                    <HatChannelByTypePieChartCard />
                </Grid>

                <Grid item xl={6} xs={12}>
                    <HatChannelByChannelPieChartCard />
                </Grid>
            </Grid>
        </Column>
    );
};

export default HatChannelTrafficChartView;
