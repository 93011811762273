import { ChangeEvent, useEffect, useMemo } from 'react';
import { FormGroup, FormControlLabel, Switch, Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import { InventorySource, InventorySourceCriteria } from '../../types/Inventory';
import { Card, CardContent } from '../../pages/campaign/partials/CampaignWizard/CampaignWizardStyles';
import { CDTextField } from '../CDTextField';
import { CDGridProProps } from '../CDGridPro';
import { SelectionDataGridCard } from '../DataGrid/SelectionDataGrid';
import { getGridAllOperators } from '../../hooks/useDataGridFilter';
import { useInventorySourceTypes } from '../../hooks/useInventorySourceTypes';
import Column from '../Column';
import DataGridNoRowsOverlay from '../DataGrid/DataGridNoRowsOverlay';
import Row from '../Row';
import Utils from '../Utils';

const InventorySourceDataGridNoRowsOverlay = () => {
    return (
        <DataGridNoRowsOverlay>
            <Typography variant="subtitle2">Select Inventory Source(s) to see inventory exchange results.</Typography>
        </DataGridNoRowsOverlay>
    );
};

type InventorySourceDataGridCardProps = {
    rows: InventorySource[];
    selections?: InventorySource[];
    onChange?: (items: InventorySource[]) => void;
    searchCriteria: InventorySourceCriteria;
    changeSearchCriteria: (searchCriteria: InventorySourceCriteria) => void;
} & Omit<CDGridProProps<InventorySource>, 'columns'>;

const InventorySourceDataGridCard = (props: InventorySourceDataGridCardProps) => {
    const { changeSearchCriteria, onChange, rows, searchCriteria, selections } = props;

    const { setInventorySourceTypes, identifyInventorySourceTypes } = useInventorySourceTypes();

    const columns = useMemo(() => {
        let _columns: GridColDef[] = [
            {
                headerName: 'Name',
                field: 'name',
                flex: 1,
                width: 360,
                filterOperators: getGridAllOperators(),
            },
            {
                headerName: 'Inventory Type',
                field: 'inventoryType',
                flex: 1,
                width: 120,
            },
            {
                headerName: 'Device',
                field: 'deviceType',
                // flex: 1,
                width: 100,
            },
        ];

        if (searchCriteria.inventorySourceTypes.includes('DEAL')) {
            _columns.push(
                ...[
                    {
                        headerName: 'Price Type',
                        field: 'deal.dealPrice.priceType',
                        // flex: 1,
                        width: 100,
                        valueGetter: (value: string, row: InventorySource) => {
                            return row?.deal?.dealPrice?.priceType;
                        },
                    },
                    {
                        headerName: 'Amount',
                        field: 'deal.dealPrice.amount',
                        // flex: 1,
                        width: 100,
                        valueGetter: (value: string, row: InventorySource) => {
                            return Utils.formatCurrency(row?.deal?.dealPrice?.amount);
                        },
                    },
                    {
                        headerName: 'Deal Type',
                        field: 'deal.dealType',
                        // flex: 1,
                        width: 100,
                        valueGetter: (value: string, row: InventorySource) => {
                            return row?.deal?.dealType;
                        },
                    },
                ]
            );
        }

        return _columns;
    }, [searchCriteria]);

    const filteredRows = useMemo((): InventorySource[] => {
        return rows.filter(
            (row: InventorySource) => !selections?.some((selectedRow: InventorySource) => selectedRow.id === row.id)
        );
    }, [rows, selections]);

    useEffect(() => {
        setInventorySourceTypes(selections ? identifyInventorySourceTypes(selections) : []);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selections]);

    return (
        <>
            <Card variant="outlined">
                <CardContent>
                    <Row spacing={0.5} alignItems="center">
                        <CDTextField
                            sx={{ width: '300px' }}
                            id="searchText"
                            value={searchCriteria.searchText}
                            autoComplete="off"
                            size="small"
                            label="Search Inventory Supplies"
                            onChange={(event) => {
                                changeSearchCriteria({
                                    ...searchCriteria,
                                    searchText: event.target.value,
                                });
                            }}
                        />

                        {searchCriteria.mediaType === 'Video' && (
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            defaultChecked={searchCriteria?.runPrimeVideoOnly ?? false}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                                changeSearchCriteria({
                                                    ...searchCriteria,
                                                    runPrimeVideoOnly: event.target.checked,
                                                });
                                            }}
                                        />
                                    }
                                    label="Run Prime Video only"
                                />
                            </FormGroup>
                        )}
                    </Row>
                </CardContent>
                <Column>
                    <SelectionDataGridCard
                        variant="elevation"
                        command="attach"
                        title="Select Advertising Inventory Supply"
                        rows={filteredRows}
                        columns={columns}
                        onChange={(newRows: InventorySource[]) => {
                            if (onChange) {
                                onChange([...(selections || []), ...newRows]);
                            }
                        }}
                        slots={{
                            noRowsOverlay: InventorySourceDataGridNoRowsOverlay,
                            toolbar: undefined,
                        }}
                    />
                </Column>
            </Card>

            <SelectionDataGridCard
                command="detach"
                title="Selected Advertising Inventory Supply"
                rows={selections}
                columns={columns}
                onChange={(rowsToRemove: InventorySource[]) => {
                    if (onChange) {
                        onChange([
                            ...(selections || []).filter((row: InventorySource) => {
                                return !rowsToRemove.some((removeRow: InventorySource) => removeRow.id === row.id);
                            }),
                        ]);
                    }
                }}
            />
        </>
    );
};

export type { InventorySourceDataGridCardProps };
export { InventorySourceDataGridNoRowsOverlay };
export default InventorySourceDataGridCard;
