import { SimpleCountType } from '../types/SimpleCountType';
import * as PAYLOAD from '../payloads/simplecount';

const SIMPLECOUNT_PAYLOAD_MAPPING: Record<SimpleCountType, any> = {
    [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_CLICK_TO_CALLS]:
        PAYLOAD.TOTAL_AD_INFLUENCED_CONVERSIONS_CLICK_TO_CALLS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_IDENTIFIED_VISITORS]:
        PAYLOAD.TOTAL_AD_INFLUENCED_CONVERSIONS_IDENTIFIED_VISITORS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SERVICE_PAGE_VIEWS]:
        PAYLOAD.TOTAL_AD_INFLUENCED_CONVERSIONS_SERVICE_PAGE_VIEWS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SPECIALS_PAGE_VISITS]:
        PAYLOAD.TOTAL_AD_INFLUENCED_CONVERSIONS_SPECIALS_PAGE_VISITS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SRP_VIEWS]: PAYLOAD.TOTAL_AD_INFLUENCED_CONVERSIONS_SRP_VIEWS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS]:
        PAYLOAD.TOTAL_AD_INFLUENCED_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_VDP_VIEWS]: PAYLOAD.TOTAL_AD_INFLUENCED_CONVERSIONS_VDP_VIEWS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS]: PAYLOAD.TOTAL_AD_INFLUENCED_CONVERSIONS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_DIRECT]: PAYLOAD.TOTAL_AD_INFLUENCED_TRAFFIC_DIRECT,
    [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_ORGANIC_SEARCH]: PAYLOAD.TOTAL_AD_INFLUENCED_TRAFFIC_ORGANIC_SEARCH,
    [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_PAID_SEARCH]: PAYLOAD.TOTAL_AD_INFLUENCED_TRAFFIC_PAID_SEARCH,
    [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_REFERRAL]: PAYLOAD.TOTAL_AD_INFLUENCED_TRAFFIC_REFERRAL,
    [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_SOCIAL]: PAYLOAD.TOTAL_AD_INFLUENCED_TRAFFIC_SOCIAL,
    [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_TOTAL_VISITS]: PAYLOAD.TOTAL_AD_INFLUENCED_TRAFFIC_TOTAL_VISITS,
    [SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC]: PAYLOAD.TOTAL_AD_INFLUENCED_TRAFFIC,
    [SimpleCountType.TOTAL_CONVERSIONS_AMAZON_DIRECT]: PAYLOAD.TOTAL_CONVERSIONS_AMAZON_DIRECT,
    [SimpleCountType.TOTAL_CONVERSIONS_CLICK_TO_CALLS]: PAYLOAD.TOTAL_CONVERSIONS_CLICK_TO_CALLS,
    [SimpleCountType.TOTAL_CONVERSIONS_DIRECT]: PAYLOAD.TOTAL_CONVERSIONS_DIRECT,
    [SimpleCountType.TOTAL_CONVERSIONS_IDENTIFIED_VISITORS]: PAYLOAD.TOTAL_CONVERSIONS_IDENTIFIED_VISITORS,
    [SimpleCountType.TOTAL_CONVERSIONS_ORGANIC]: PAYLOAD.TOTAL_CONVERSIONS_ORGANIC,
    [SimpleCountType.TOTAL_CONVERSIONS_PAID_SEARCH]: PAYLOAD.TOTAL_CONVERSIONS_PAID_SEARCH,
    [SimpleCountType.TOTAL_CONVERSIONS_REFERRAL]: PAYLOAD.TOTAL_CONVERSIONS_REFERRAL,
    [SimpleCountType.TOTAL_CONVERSIONS_SERVICE_PAGE_VIEWS]: PAYLOAD.TOTAL_CONVERSIONS_SERVICE_PAGE_VIEWS,
    [SimpleCountType.TOTAL_CONVERSIONS_SOCIAL]: PAYLOAD.TOTAL_CONVERSIONS_SOCIAL,
    [SimpleCountType.TOTAL_CONVERSIONS_SPECIALS_PAGE_VISITS]: PAYLOAD.TOTAL_CONVERSIONS_SPECIALS_PAGE_VISITS,
    [SimpleCountType.TOTAL_CONVERSIONS_SRP_VIEWS]: PAYLOAD.TOTAL_CONVERSIONS_SRP_VIEWS,
    [SimpleCountType.TOTAL_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS]:
        PAYLOAD.TOTAL_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS,
    [SimpleCountType.TOTAL_CONVERSIONS_VDP_VIEWS]: PAYLOAD.TOTAL_CONVERSIONS_VDP_VIEWS,
    [SimpleCountType.TOTAL_CONVERSIONS]: PAYLOAD.TOTAL_CONVERSIONS,
    [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_DIRECT]: PAYLOAD.TOTAL_UNIQUE_TRAFFIC_DIRECT,
    [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_ORGANIC_SEARCH]: PAYLOAD.TOTAL_UNIQUE_TRAFFIC_ORGANIC_SEARCH,
    [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_PAID_SEARCH]: PAYLOAD.TOTAL_UNIQUE_TRAFFIC_PAID_SEARCH,
    [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_REFERRAL]: PAYLOAD.TOTAL_UNIQUE_TRAFFIC_REFERRAL,
    [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_SOCIAL]: PAYLOAD.TOTAL_UNIQUE_TRAFFIC_SOCIAL,
    [SimpleCountType.TOTAL_UNIQUE_TRAFFIC_TOTAL_VISITS]: PAYLOAD.TOTAL_UNIQUE_TRAFFIC_TOTAL_VISITS,
    [SimpleCountType.TOTAL_UNIQUE_TRAFFIC]: PAYLOAD.TOTAL_UNIQUE_TRAFFIC,
};

const getSimpleCountPayload = (type: SimpleCountType) => {
    return SIMPLECOUNT_PAYLOAD_MAPPING[type] ?? null;
};

export { getSimpleCountPayload };
