enum MediaType {
    Display = 'Display',
    OLV = 'OLV',
    STV = 'STV',
    Audio = 'Audio',
}

enum MediaTypeName {
    Video = 'Video',
    Display = 'Display',
    Audio = 'Audio',
    StreamingTV = 'Streaming TV',
    OnlineVideo = 'Online Video',
    STV = 'Streaming TV',
    OLV = 'Online Video',
}

export { MediaType, MediaTypeName };
