import { useMemo } from 'react';

import { PieChartSlice } from '../../../../../types/Chart';
import { Performance } from '../../../../../types/CampaignPerformance';
import Utils from '../../../../../components/Utils';

import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import PieChartCard from '../../../../../components/Metrics/PieChartCard';

type PerformanceInvestmentByStrategyTypePieChartCardProps = {
    loading?: boolean;
};

export default function PerformanceInvestmentByStrategyTypePieChartCard(
    props: PerformanceInvestmentByStrategyTypePieChartCardProps
) {
    const { loading = false } = props;
    const { performances } = useReportingDashboardPageContext();

    const slices = useMemo((): PieChartSlice[] => {
        let slices: PieChartSlice[] = [];

        const _calculate = (strategyType: string): number => {
            if (performances && performances.length) {
                return performances
                    .filter((p: Performance) => p.strategyType === strategyType)
                    .map((p: Performance) => p.totalCost)
                    .reduce((a, b) => a + b, 0);
            }

            return 0;
        };

        slices.push({
            label: 'Acquisition',
            value: _calculate('Acquisition'),
        });

        slices.push({
            label: 'Awareness',
            value: _calculate('Awareness'),
        });

        slices.push({
            label: 'Inventory',
            value: _calculate('Inventory'),
        });

        slices.push({
            label: 'FixedOps',
            value: _calculate('FixedOps'),
        });

        slices.push({
            label: 'Parts',
            value: _calculate('Parts'),
        });

        slices = slices.filter((s: PieChartSlice) => s.value > 0);

        return slices;
    }, [performances]);

    const slicesTotal = useMemo((): number => {
        if (slices) {
            return slices.map((s) => s.value).reduce((a, b) => a + b, 0);
        }

        return 0;
    }, [slices]);

    const totalSpendMetricLabel: string = useMemo(() => {
        return 'Total Investment';
    }, []);

    return (
        <PieChartCard
            loading={loading}
            label="Investment by Campaign Type"
            tooltip="Total ad spend by campaign type"
            chartOptions={{
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                total: {
                                    label: totalSpendMetricLabel,
                                    formatter: (w) => {
                                        return Utils.formatCurrency(slicesTotal, 2) ?? '';
                                    },
                                },
                                value: {
                                    formatter: function (value) {
                                        if (value.substring(0, 1) !== '$') {
                                            // value = Math.round((parseFloat(value) / slicesTotal) * 100) + '';
                                            return `${value}%`;
                                        }
                                        return value;
                                        // return Utils.formatCurrency(value, 2) ?? '';;
                                    },
                                },
                            },
                        },
                    },
                },
                labels: slices.map((_share) => _share.label),
                legend: {
                    horizontalAlign: 'center',
                    offsetX: -64,
                    offsetY: 64,
                },
            }}
            chartSeries={slices.map((_share) => {
                let value: number = Math.round((_share.value / slicesTotal) * 100);
                return value;
            })}
            // chartSeries={slices.map((s: PieChartSlice) => s.value)}
        />
    );
}
