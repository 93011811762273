import { useEffect } from 'react';
import { ReportingCriteria } from '../../../../../types/Criteria/ReportingCriteria';
import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import { useSpendByApplicationDataGrid } from '../DataGrids/SpendByApplicationDataGrid';
import SpendByApplicationDataGrid from '../DataGrids/SpendByApplicationDataGrid';

type ReportingPerformancePropertySpendTabProps = {
    reportingCriteria: ReportingCriteria;
};

const ReportingPerformancePropertySpendTab = (props: ReportingPerformancePropertySpendTabProps) => {
    const { reportingCriteria } = props;

    const { setPropertySpends } = useReportingDashboardPageContext();

    const dataGrid = useSpendByApplicationDataGrid({ reportingCriteria });
    const { rows = [] } = dataGrid;
    const { paginationModel, setPaginationModel } = dataGrid;
    const { sortModel, setSortModel } = dataGrid;
    const { query } = dataGrid;
    const { data, isLoading } = query;

    useEffect(() => {
        setPropertySpends(rows);
    }, [rows, setPropertySpends]);

    return (
        <>
            <SpendByApplicationDataGrid
                loading={isLoading}
                rows={rows}
                rowCount={data?.totalElements}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
            />
        </>
    );
};

export type { ReportingPerformancePropertySpendTabProps };
export default ReportingPerformancePropertySpendTab;
