import { useEffect, useMemo } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

import Column from '../../components/Column';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import usePolkAttribution from '../../hooks/usePolkAttribution';
import PolkMatchbackTable from './PolkMatchbackTable';

export default function PolkMatchbackTableView() {
    const { polkSalesAnalysisSets } = useAttributionPageContext();
    const { fetchPolkSalesAnalysisSets } = usePolkAttribution();

    const hasData = useMemo((): boolean => {
        return polkSalesAnalysisSets && polkSalesAnalysisSets.length > 0 ? true : false;
    }, [polkSalesAnalysisSets]);

    const boxHeight: number = 270;

    useEffect(() => {
        fetchPolkSalesAnalysisSets();
    }, [fetchPolkSalesAnalysisSets]);

    return (
        <Column gap={3}>
            <Box>
                {hasData === true && <PolkMatchbackTable />}
                {hasData === false && (
                    <Card variant="outlined">
                        <CardContent>
                            <Column
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: boxHeight + 2 }}
                            >
                                <Typography variant="body2">No data available</Typography>
                            </Column>
                        </CardContent>
                    </Card>
                )}
            </Box>
        </Column>
    );
}
