import { useEffect, useState } from 'react';
import { Alert, Button } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import { AmcGlobalWorkflowCriteria } from '../../../types/Criteria/AmcGlobalWorkflowCriteria';
import { useAxiosCancelToken } from '../../../hooks/AxiosCancelToken';
import { GridToolbarContainer } from '../../../components/DataGrid';
import ApiService from '../../../ApiService';
import Block from '../../../components/Block';
import CDGrid from '../../../components/CDGrid';
import Column from '../../../components/Column';
import Row from '../../../components/Row';

import AmcPageHeader from '../partials/AmcPageHeader';
import WorkflowCreateFormDialog from './WorkflowCreateFormDialog';

export default function WorkflowsPage() {
    const navigate = useNavigate();
    const withAxiosCancelTokenRequestConfig = useAxiosCancelToken();

    const [workflowFormDialogOpen, setWorkflowFormDialogOpen] = useState<boolean>(false);

    const [criteria, setCriteria] = useState<AmcGlobalWorkflowCriteria>({
        searchText: '',
        agencyId: null,
        advertiserId: null,
    });

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['amcGlobalWorkflows'],
        queryFn: async () => {
            const { data } = await ApiService.getWorkflows(withAxiosCancelTokenRequestConfig());
            return data;
        },
        enabled: false,
    });

    useEffect(() => {
        refetch();
    }, [criteria, refetch]);

    function handleEditWorkflow(params: GridRowParams) {
        if (params && params.row) {
            navigate('' + params.row.id, {
                state: { ...params.row, isNew: false },
            });
        }
    }

    function CustomToolbar() {
        return <GridToolbarContainer></GridToolbarContainer>;
    }

    return (
        <Column gap={0} flex={1}>
            <AmcPageHeader criteria={criteria} setCriteria={setCriteria} />

            <Block>
                <Column gap={2}>
                    <Row justifyContent="flex-end">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => {
                                setWorkflowFormDialogOpen(true);
                            }}
                        >
                            Add Global Workflow
                        </Button>
                    </Row>

                    <Alert severity="info">Global Workflows applied to all Instances before Workflow Execution.</Alert>

                    <CDGrid
                        loading={isLoading}
                        components={{ Toolbar: CustomToolbar }}
                        getRowId={(row) => row.workflowId}
                        columns={[
                            {
                                width: 400,
                                field: 'workflowId',
                                headerName: 'Workflow ID',
                                headerClassName: 'grid-header',
                                renderCell: (params) => (
                                    <Button
                                        onClick={() => {
                                            handleEditWorkflow(params.value);
                                        }}
                                    >
                                        {params.value}
                                    </Button>
                                ),
                            },
                            {
                                width: 200,
                                field: 'sqlQuery',
                                headerName: 'SQL',
                                headerClassName: 'grid-header',
                                flex: 1,
                            },
                        ]}
                        rows={data || []}
                        onRowClick={handleEditWorkflow}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Column>
            </Block>

            {workflowFormDialogOpen && (
                <WorkflowCreateFormDialog
                    open={workflowFormDialogOpen}
                    onClose={() => {
                        refetch();
                        setWorkflowFormDialogOpen(false);
                    }}
                />
            )}
        </Column>
    );
}
