import { useMemo } from 'react';
import { DateRange } from '@mui/x-date-pickers-pro/models';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment-timezone';

import { getStaticRange } from '../../../components/DateRangePicker';
import { StaticRange } from '../../../components/DateRangePicker';
import DateRangePicker from '../../../components/DateRangePicker';

const getDefaultDateRanges = (): StaticRange[] => {
    const dateRanges: StaticRange[] = [];
    const yesterday = moment().subtract(1, 'days').startOf('day').toDate();

    dateRanges.push(
        getStaticRange('This Month', moment().startOf('month').toDate(), yesterday),
        getStaticRange('This Quarter', moment().startOf('quarter').toDate(), yesterday),
        getStaticRange(
            'Last Month',
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate()
        ),
        getStaticRange(
            'Last Quarter',
            moment().subtract(1, 'quarter').startOf('quarter').toDate(),
            moment().subtract(1, 'quarter').endOf('quarter').toDate()
        ),
        getStaticRange('Last 30 Days', moment().subtract(30, 'days').toDate(), yesterday),
        getStaticRange('Last 60 Days', moment().subtract(60, 'days').toDate(), yesterday),
        getStaticRange('Last 90 Days', moment().subtract(90, 'days').toDate(), yesterday),
        getStaticRange('Year to Date', moment().startOf('year').toDate(), yesterday)
    );

    return dateRanges;
};

const dateRanges: StaticRange[] = getDefaultDateRanges();

export type HatCriteriaDataRangePickerProps = {
    onChange(startDate?: Date, endDate?: Date): void;
    startDate: Date;
    endDate: Date;
    minDate?: Date;
    maxDate?: Date;
};

export default function HatCriteriaDataRangePicker(props: HatCriteriaDataRangePickerProps) {
    const { onChange, startDate, endDate, minDate = new Date(), maxDate = new Date() } = props;

    const handleDateRangeChange = (newDateRange: DateRange<Dayjs>): void => {
        if (newDateRange[0] && newDateRange[1]) {
            onChange(newDateRange[0].toDate(), newDateRange[1].toDate());
        }
    };

    const memoedValue = useMemo(() => {
        return [dayjs(startDate), dayjs(endDate)] as DateRange<Dayjs>;
    }, [startDate, endDate]);

    return (
        <DateRangePicker
            label="Date Range"
            ranges={dateRanges}
            minDate={dayjs(minDate)}
            maxDate={dayjs(maxDate)}
            disableFuture={true}
            value={memoedValue}
            onChange={handleDateRangeChange}
        />
    );
}
