import { Auth } from 'aws-amplify';

interface UseState {
    userSignOut: () => Promise<any>;
}

const useUserAuth = (): UseState => {
    const userSignOut = async (): Promise<any> => {
        return await Auth.signOut();
    };

    return {
        userSignOut,
    };
};

export type { UseState as UseUserAuthState };
export { useUserAuth };
