import { useEffect, useRef, useState } from 'react';
import {
    DataGridProProps,
    GridColDef,
    GridPaginationModel,
    GridRenderCellParams,
    GridSortModel,
} from '@mui/x-data-grid-pro';
import { UseQueryResult, useQuery } from 'react-query';

import { Pageable } from '../../../../../types/AxiosPageableResponse';
import { ConversionSummaryListCriteria as Criteria } from '../../../../../types/Analytics/ConversionSummaryListCriteria';
import { ConversionByConversionNameSummary as Conversion } from '../../../../../types/Analytics/ConversionSummary';
import { ReportingCriteria } from '../../../../../types/Criteria/ReportingCriteria';
import ApiService from '../../../../../ApiService';
import DataGridPro from '../../../../../components/CDGridPro';
import Utils from '../../../../../components/Utils';

/**
 * Conversion Data Grid
 */

type ConversionDataGridProps = Omit<DataGridProProps, 'columns' | 'rows'> & {
    rows?: Conversion[];
};

const ConversionDataGrid = (props: ConversionDataGridProps) => {
    const columns: GridColDef[] = [
        {
            filterable: false,
            field: 'conversionName',
            headerName: 'Interaction',
            flex: 1,
            width: 200,
        },
        {
            filterable: false,
            field: 'conversions',
            headerName: 'Attributed Traffic',
            headerAlign: 'right',
            align: 'right',
            flex: 1,
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                const { row } = params;
                return <>{Utils.formatValue(row.conversions)}</>;
            },
        },
    ];

    const getRowId = (row: Conversion): string => {
        return row.conversionName;
    };

    return (
        <DataGridPro
            // Overridable props
            paginationMode="server"
            sortingMode="server"
            {...props}
            disableColumnPinning
            getRowId={getRowId}
            columns={columns}
            slots={{
                toolbar: null,
            }}
        />
    );
};

/**
 * Conversion Data Query Hook
 */

const fetch = async (criteria: Criteria): Promise<Pageable<Conversion>> => {
    const { data } = await ApiService.getConversionByConversionNameSummaryList(criteria, {
        headers: { XBusy: 'false' },
    });
    return data;
};

const useConversionByConversionNameSummaryListQuery = (
    criteria: Criteria
): UseQueryResult<Pageable<Conversion>, unknown> => {
    return useQuery(['conversionByConversionNameSummaryList', criteria], () => fetch(criteria), {
        enabled: true,
    });
};

/**
 * Conversion Data Grid Hook
 */

interface UseConversionDataGridState {
    criteria: Criteria;
    setCriteria: (criteria: Criteria) => void;

    rows: Conversion[];
    setRows: (rows: Conversion[]) => void;
    paginationModel: GridPaginationModel;
    setPaginationModel: (paginationModel: GridPaginationModel) => void;
    sortModel: GridSortModel;
    setSortModel: (sortModel: GridSortModel) => void;

    query: UseQueryResult<Pageable<Conversion>, unknown>;
}

interface UseConversionDataGridProps {
    reportingCriteria: ReportingCriteria;
}

const getDefaultCriteria = (): Criteria => {
    return {
        agencyIds: [],
        dealerIds: [],
        startDate: new Date(),
        endDate: new Date(),
        mediaType: '',
        strategyType: '',

        page: 0,
        pageSize: 20,
        sortBy: 'conversions',
        sort: 'desc',
    };
};

const useConversionDataGrid = (props: UseConversionDataGridProps): UseConversionDataGridState => {
    const { reportingCriteria } = props;

    const suppressPagination = useRef<boolean>(false);

    const defaultCriteria: Criteria = getDefaultCriteria();
    const [criteria, setCriteria] = useState<Criteria>({
        ...defaultCriteria,
        ...reportingCriteria,
    });

    const [rows, setRows] = useState<Conversion[]>([]);
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: criteria.page,
        pageSize: criteria.pageSize,
    });
    const [sortModel, setSortModel] = useState<GridSortModel>((): GridSortModel => {
        return [
            {
                field: criteria.sortBy || (defaultCriteria.sortBy as string),
                sort: criteria.sort === 'desc' ? 'desc' : 'asc',
            },
        ];
    });

    const query = useConversionByConversionNameSummaryListQuery(criteria);
    const { data } = query;

    useEffect(() => {
        setCriteria({ ...criteria, ...reportingCriteria, page: 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingCriteria]);

    useEffect(() => {
        setRows(data?.content || []);
    }, [data]);

    useEffect(() => {
        if (suppressPagination.current === false) {
            setCriteria((prevCriteria: Criteria) => ({
                ...prevCriteria,
                page: paginationModel.page,
                pageSize: paginationModel.pageSize,
            }));
        }

        suppressPagination.current = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginationModel]);

    useEffect(() => {
        suppressPagination.current = true;
        setPaginationModel({ ...paginationModel, page: 0 });

        if (sortModel.length > 0) {
            setCriteria((prevCriteria: Criteria) => ({
                ...prevCriteria,
                sortBy: sortModel[0].field,
                sort: sortModel[0].sort === 'desc' ? 'desc' : 'asc',
                page: 0,
            }));
        } else {
            setCriteria((prevCriteria: Criteria) => ({
                ...prevCriteria,
                sortBy: defaultCriteria.sortBy,
                sort: defaultCriteria.sort === 'desc' ? 'desc' : 'asc',
                page: 0,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortModel]);

    return {
        criteria,
        setCriteria,

        rows,
        setRows,
        paginationModel,
        setPaginationModel,
        sortModel,
        setSortModel,

        query,
    };
};

export type { ConversionDataGridProps };
export default ConversionDataGrid;

export { useConversionByConversionNameSummaryListQuery };

export type { UseConversionDataGridState, UseConversionDataGridProps };
export { getDefaultCriteria };
export { useConversionDataGrid };
