import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import {
    // Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { Table, TablePrimaryCell } from '../../../../components/Metrics/MetricTable';
import Utils from '../../../../components/Utils';

import { SimpleCountType } from '../../../../services/trialfire/types/SimpleCountType';
import { useHatDashboardPageContext } from '../../../../hooks/Pages/HatDashboardPage';

interface HatConversionTrafficTableRow {
    conversionName: string;
    totalConversions: number;
    totalAdInfluencedConversion: number;
    percentAdInfluenced: number;
}

/**
 * Hat Conversion Traffic Table Hook
 *
 */

interface UseHatConversionTrafficTableState {
    rows: HatConversionTrafficTableRow[];
    setRows: Dispatch<SetStateAction<HatConversionTrafficTableRow[]>>;
}

const useHatConversionTrafficTable = (): UseHatConversionTrafficTableState => {
    const { simpleCounts } = useHatDashboardPageContext();

    const totalConversionsClickToCallsSimpleCountQuery = simpleCounts[SimpleCountType.TOTAL_CONVERSIONS_CLICK_TO_CALLS];
    const totalConversionsIdentifiedVisitorsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_CONVERSIONS_IDENTIFIED_VISITORS];
    const totalConversionsServicePageViewsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_CONVERSIONS_SERVICE_PAGE_VIEWS];
    const totalConversionsSpecialsPageVisitsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_CONVERSIONS_SPECIALS_PAGE_VISITS];
    const totalConversionsSrpViewsSimpleCountQuery = simpleCounts[SimpleCountType.TOTAL_CONVERSIONS_SRP_VIEWS];
    const totalConversionsTotalHoursAndDirectionsViewsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS];
    const totalConversionsVdpViewsSimpleCountQuery = simpleCounts[SimpleCountType.TOTAL_CONVERSIONS_VDP_VIEWS];

    const totalAdInfluencedConversionsClickToCallsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_CLICK_TO_CALLS];
    const totalAdInfluencedConversionsIdentifiedVisitorsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_IDENTIFIED_VISITORS];
    const totalAdInfluencedConversionsServicePageViewsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SERVICE_PAGE_VIEWS];
    const totalAdInfluencedConversionsSpecialsPageVisitsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SPECIALS_PAGE_VISITS];
    const totalAdInfluencedConversionsSrpViewsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_SRP_VIEWS];
    const totalAdInfluencedConversionsTotalHoursAndDirectionsViewsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS];
    const totalAdInfluencedConversionsVdpViewsSimpleCountQuery =
        simpleCounts[SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS_VDP_VIEWS];

    const [rows, setRows] = useState<HatConversionTrafficTableRow[]>([]);

    useEffect(() => {
        let _rows: HatConversionTrafficTableRow[] = [];

        _rows.push({
            conversionName: 'SRP Views',
            totalConversions: totalConversionsSrpViewsSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedConversion: totalAdInfluencedConversionsSrpViewsSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            conversionName: 'VDP Views',
            totalConversions: totalConversionsVdpViewsSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedConversion: totalAdInfluencedConversionsVdpViewsSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            conversionName: 'Service Page Views',
            totalConversions: totalConversionsServicePageViewsSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedConversion: totalAdInfluencedConversionsServicePageViewsSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            conversionName: 'Specials Page Views',
            totalConversions: totalConversionsSpecialsPageVisitsSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedConversion:
                totalAdInfluencedConversionsSpecialsPageVisitsSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            conversionName: 'Identified Visitors',
            totalConversions: totalConversionsIdentifiedVisitorsSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedConversion:
                totalAdInfluencedConversionsIdentifiedVisitorsSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            conversionName: 'Click to Calls',
            totalConversions: totalConversionsClickToCallsSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedConversion: totalAdInfluencedConversionsClickToCallsSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.push({
            conversionName: 'Total Hours and Directions Views',
            totalConversions: totalConversionsTotalHoursAndDirectionsViewsSimpleCountQuery?.data?.data ?? 0,
            totalAdInfluencedConversion:
                totalAdInfluencedConversionsTotalHoursAndDirectionsViewsSimpleCountQuery?.data?.data ?? 0,
            percentAdInfluenced: 0,
        });

        _rows.forEach((_row: HatConversionTrafficTableRow, _index: number) => {
            _rows[_index].percentAdInfluenced = Math.round(
                _row.totalConversions > 0
                    ? (_row.totalAdInfluencedConversion / (_row.totalAdInfluencedConversion + _row.totalConversions)) *
                          100
                    : 0
            );
        });

        setRows(_rows);
    }, [
        totalConversionsClickToCallsSimpleCountQuery?.data,
        totalConversionsIdentifiedVisitorsSimpleCountQuery?.data,
        totalConversionsSpecialsPageVisitsSimpleCountQuery?.data,
        totalConversionsSrpViewsSimpleCountQuery?.data,
        totalConversionsServicePageViewsSimpleCountQuery?.data,
        totalConversionsTotalHoursAndDirectionsViewsSimpleCountQuery?.data,
        totalConversionsVdpViewsSimpleCountQuery?.data,

        totalAdInfluencedConversionsClickToCallsSimpleCountQuery?.data,
        totalAdInfluencedConversionsIdentifiedVisitorsSimpleCountQuery?.data,
        totalAdInfluencedConversionsServicePageViewsSimpleCountQuery?.data,
        totalAdInfluencedConversionsSpecialsPageVisitsSimpleCountQuery?.data,
        totalAdInfluencedConversionsSrpViewsSimpleCountQuery?.data,
        totalAdInfluencedConversionsTotalHoursAndDirectionsViewsSimpleCountQuery?.data,
        totalAdInfluencedConversionsVdpViewsSimpleCountQuery?.data,
    ]);

    return { rows, setRows };
};

/**
 * Hat Conversion Traffic Table
 */

interface HatConversionTrafficTableProps {
    rows: HatConversionTrafficTableRow[];
}

const HatConversionTrafficTable: FC<HatConversionTrafficTableProps> = ({
    rows,
}: HatConversionTrafficTableProps): JSX.Element => {
    return (
        <TableContainer
            sx={{
                borderCollapse: 'inherit',
                borderColor: (theme) => theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Conversion</TableCell>
                        <TableCell>Total Conversions</TableCell>
                        <TableCell>Influenced Conversions</TableCell>
                        <TableCell>% Influenced</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((item: HatConversionTrafficTableRow, index: number) => (
                        <TableRow key={index}>
                            <TablePrimaryCell>{item.conversionName}</TablePrimaryCell>
                            <TableCell>{Utils.formatValue(item.totalConversions)}</TableCell>
                            <TableCell>{Utils.formatValue(item.totalAdInfluencedConversion)}</TableCell>
                            <TableCell>{Utils.formatValue(item.percentAdInfluenced, 'percent')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export type { UseHatConversionTrafficTableState };
export { useHatConversionTrafficTable };

export type { HatConversionTrafficTableRow };
export type { HatConversionTrafficTableProps };
export default HatConversionTrafficTable;
