import { useEffect } from 'react';
import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import {
    HelpOutlineOutlined as HelpOutlineOutlinedIcon,
    UpdateOutlined as UpdateOutlinedIcon,
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import { SectionHeading } from '../../components/SectionHeading';
import Column from '../../components/Column';
import Printable from '../../components/Printable';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import useAdvertiserAttribution from '../../hooks/useAdvertiserAttribution';
import AdvertiserMatchbackMetrics from './AdvertiserMatchbackMetrics';
import AdvertiserMatchbackMoreMenu from './partials/AdvertiserMatchbackMoreMenu';
import AttributionExportMenuButton from './AttributionExportMenuButton';
import PolkMatchbackDataTabs from './PolkMatchbackDataTabs';

// type VIEW = 'chart' | 'table';

interface AdvertiserMatchbackTabProps {
    showExportButton?: boolean;
}

export default function AdvertiserMatchbackTab({ showExportButton = true }: AdvertiserMatchbackTabProps) {
    const {
        advertiser,
        hasOneAdvertiser,
        attributionDate,
        attributionStatus,
        fetchAttributionStatus,
        advertiserSalesUpdateDateFormatted,
        fetchPolkSalesWorkflowSets,
        fetchPreviousPolkSalesWorkflowSets,
    } = useAttributionPageContext();

    const { fetchPerformances, fetchPreviousPerformances } = useAdvertiserAttribution();

    useEffect(() => {
        fetchAttributionStatus();
    }, [fetchAttributionStatus]);

    useEffect(() => {
        fetchPolkSalesWorkflowSets();
    }, [fetchPolkSalesWorkflowSets]);

    useEffect(() => {
        fetchPreviousPolkSalesWorkflowSets();
    }, [fetchPreviousPolkSalesWorkflowSets]);

    useEffect(() => {
        fetchPerformances();
    }, [fetchPerformances]);

    useEffect(() => {
        fetchPreviousPerformances();
    }, [fetchPreviousPerformances]);

    return (
        <Column gap={3} sx={{ mt: (theme) => theme.spacing(1) }}>
            <Row>
                <Row gap={0}>
                    {hasOneAdvertiser && advertiserSalesUpdateDateFormatted && (
                        <Row gap={1} alignItems="center" justifyContent="flex-start">
                            <Row gap={0.5} sx={{ width: 'auto' }}>
                                <UpdateOutlinedIcon color="success" fontSize="small" />
                                <Typography variant="body2" color="text.secondary">
                                    Last updated{' '}
                                    <Typography component="span" variant="body2" color="text.primary" fontWeight={500}>
                                        {advertiserSalesUpdateDateFormatted}
                                    </Typography>
                                </Typography>
                            </Row>

                            <Divider orientation="vertical" sx={{ height: 14 }} />

                            <Row gap={0.5} alignItems="center" sx={{ width: 'auto' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Data Source
                                </Typography>

                                <Tooltip title="Sales data provided by Advertiser">
                                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                                </Tooltip>
                            </Row>
                        </Row>
                    )}
                </Row>

                {advertiser && (
                    <Row sx={{ pr: 2 }} justifyContent="flex-end">
                        <Printable show={false}>
                            <>
                                {hasOneAdvertiser && (
                                    <>
                                        <Button
                                            component={Link}
                                            variant="outlined"
                                            size="medium"
                                            color="primary"
                                            to={`/crmUpload/${advertiser.id}`}
                                            target="_blank"
                                        >
                                            Upload CRM Data
                                        </Button>
                                    </>
                                )}
                            </>
                        </Printable>

                        {showExportButton && <AttributionExportMenuButton />}

                        {hasOneAdvertiser && (
                            <Printable show={false}>
                                <AdvertiserMatchbackMoreMenu />
                            </Printable>
                        )}
                    </Row>
                )}
            </Row>

            <Printable show={true}>
                <>
                    {hasOneAdvertiser && advertiser && (
                        <Row justifyContent="center" alignItems="center">
                            {advertiser.logoUrl && advertiser.logoUrl.length > 0 ? (
                                <img
                                    src={advertiser.logoUrl}
                                    alt={`${advertiser.dealerName}'s Logo`}
                                    style={{ maxHeight: 128 }}
                                />
                            ) : (
                                <Typography variant="h4">{advertiser.dealerName}</Typography>
                            )}
                        </Row>
                    )}
                    <Row justifyContent="center" alignItems="center">
                        <Typography variant="h5">
                            {moment(Utils.getDate(attributionDate)).format('MMMM YYYY')}
                        </Typography>
                    </Row>
                </>
            </Printable>

            <Box>
                <Row justifyContent="space-between" alignItems="center" spacing={2}>
                    <Column gap={0.5}>
                        <Row gap={0.5} alignItems="center">
                            <SectionHeading divider={false}>Overview</SectionHeading>
                        </Row>
                    </Column>
                </Row>
            </Box>

            {attributionStatus && attributionDate && (
                <Box>
                    <AdvertiserMatchbackMetrics />
                </Box>
            )}

            <Box>
                <PolkMatchbackDataTabs />
            </Box>
        </Column>
    );
}
