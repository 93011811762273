export class AmcInstance {
    id: number = 0;
    name = '';
    instanceName = '';
    instanceId = '';
    awsAccountId: string = '';
    owningEntityId: string = '';
    isNew?: boolean;
    status: string = '';
    updatedAdvertiser: string = '';
}

export class ProvisionDetails {
    advertiserName = '';
    instanceId = '';
    instanceName = '';
    owningEntityId = '';
    dealerCountryCode? = '';
}

export interface AwsAmcInstance {
    instanceId: string;
    instanceName: string;
    instanceType: string;
    customerCanonicalName: string;
    creationStatus: string;
    creationDateTime: Date;
    apiEndpoint: string;
}
