import { AlertColor, Button, Grid, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import WebsiteProviders from '../../data/WebsiteProviders.js';
import { ChangeEvent, useEffect, useState } from 'react';
import { CopyAllRounded } from '@mui/icons-material';
import { Dealer } from '../../types/Dealer.js';

interface AdTagBuilderProps {
    dealer: Dealer;
    showInfoMessage: (alertColor: AlertColor, message: string) => void;
    handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function AdTagBuilder({ dealer, showInfoMessage, handleInputChange }: AdTagBuilderProps) {
    const [provider, setProvider] = useState(dealer.dspAdTagProvider || '-1');

    useEffect(() => {
        makeCode();
    }, [dealer.dspAdTagProvider, dealer.dspAdTagId]);

    const makeCode = () => {
        let code = '';
        if (dealer.dspAdTagId && dealer.dspAdTagProvider) {
            const template = WebsiteProviders.find((p) => p.value === dealer.dspAdTagProvider)?.codeTemplate ?? '';
            console.log('WebsiteProviders', WebsiteProviders);
            code = template.replace(/<ADTAGID>/gi, dealer.dspAdTagId);
        }
        if (code !== (dealer.dspAdTagCode ?? ''))
            handleInputChange({ target: { name: 'dspAdTagCode', value: code } } as ChangeEvent<HTMLInputElement>);
    };

    const handleProviderChange = (event: ChangeEvent<HTMLInputElement>) => {
        setProvider(event.target.value);
        const value = event.target.value === '-1' ? null : event.target.value;
        if (value !== dealer.dspAdTagProvider)
            handleInputChange({ target: { name: 'dspAdTagProvider', value } } as ChangeEvent<HTMLInputElement>);
    };

    const handleCopyCodeClick = () => {
        navigator.clipboard.writeText(dealer.dspAdTagCode);
        showInfoMessage('success', 'Ad Tag Code copied!');
    };

    return (
        <Grid container spacing={2} direction="column">
            <Grid item>
                <Typography variant="h6">DSP Ad Tag Builder</Typography>
            </Grid>
            <Grid item>
                <TextField
                    name="dspAdTagId"
                    label="Ad Tag ID"
                    size="small"
                    fullWidth={true}
                    variant="outlined"
                    value={dealer.dspAdTagId}
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item>
                <TextField
                    variant="outlined"
                    fullWidth
                    size="small"
                    label="Website Provider"
                    name="dspAdTagProvider"
                    select
                    value={provider}
                    onChange={handleProviderChange}
                    SelectProps={{ native: false }}
                >
                    <MenuItem value="-1" disabled>
                        - Select website provider -
                    </MenuItem>
                    {WebsiteProviders.map((provider) => {
                        return (
                            <MenuItem key={provider.value} value={provider.value}>
                                {provider.name}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>
            <Grid item>
                <TextField
                    name="adTagCode"
                    label="Ad Tag Code"
                    value={dealer.dspAdTagCode}
                    multiline
                    rows={7}
                    fullWidth={true}
                    disabled={true}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                />
            </Grid>
            <Grid item style={{ textAlign: 'right' }}>
                <Tooltip title="Copy generated code to the clipboard">
                    <Button
                        variant="contained"
                        disabled={!dealer.dspAdTagCode}
                        onClick={handleCopyCodeClick}
                        color="secondary"
                        startIcon={<CopyAllRounded />}
                    >
                        Copy Code
                    </Button>
                </Tooltip>
            </Grid>
        </Grid>
    );
}
