import { useCallback, useEffect } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { Dayjs } from 'dayjs';
import moment from 'moment-timezone';

import { TABS as DATA_TABS } from './PolkMatchbackDataTabs';
import { AttributionCriteria } from '../../types/Criteria/AttributionCriteria';
import { Agency } from '../../types/Agency';
import { Dealer as Advertiser } from '../../types/Dealer';
import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import { USER_GROUP_ADMIN } from '../../types/User';
import AccessGuard from '../../components/AccessGuard';
import AdvertiserAutocomplete from '../../components/AdvertiserAutocomplete';
import AgencyAutocomplete from '../../components/AgencyAutocomplete';
import CDMonthDatePicker from '../../components/CDMonthDatePicker';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

interface AttributionCriteriaFormProps {
    criteria: AttributionCriteria;
    onCriteriaChange: (criteria: AttributionCriteria) => void;
}

const AttributionCriteriaForm = ({ criteria, onCriteriaChange }: AttributionCriteriaFormProps) => {
    const { setIsAttributionCriteriaSetup, isAttributionCriteriaSetup, attributionDate, setAttributionDate } =
        useAttributionPageContext();

    const generateDateRange = () => {
        const dateRange: { startDate: Date; endDate: Date } = {
            startDate: moment('2024-01-01').startOf('day').toDate(),
            endDate: moment().startOf('day').toDate(),
        };

        if (!Utils.isAfterFirstSundayOfCurrentMonth()) {
            dateRange.endDate = moment(dateRange.endDate).subtract(1, 'months').toDate();
        }

        return dateRange;
    };

    const { startDate: attributionMinDate, endDate: attributionMaxDate } = generateDateRange();

    useEffect(() => {
        if (!Utils.isAfterFirstSundayOfCurrentMonth()) {
            setAttributionDate(attributionMaxDate);
        }

        localStorage.setItem('attribution.polkDataTab', DATA_TABS.SALES_ANALYSIS as any as string);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnAgencyChange = useCallback(
        (agencies: Agency[]): void => {
            onCriteriaChange({
                ...criteria,
                agencyIds: agencies.map((agency: Agency) => agency.id as number),
                agencies: agencies,
            });
        },
        [criteria, onCriteriaChange]
    );

    const handleOnAdvertiserChange = useCallback(
        (advertisers: Advertiser[]): void => {
            if (isAttributionCriteriaSetup === false) {
                setIsAttributionCriteriaSetup(true);
            }

            onCriteriaChange({
                ...criteria,
                advertiserIds: advertisers.map((advertiser: Advertiser) => advertiser.id as number),
                advertisers: advertisers,
            });
        },
        [criteria, onCriteriaChange, setIsAttributionCriteriaSetup, isAttributionCriteriaSetup]
    );

    const handleOnAttributionDateChange = (newDate: Dayjs | null): void => {
        if (newDate) {
            console.log('handleOnAttributionDateChange', newDate.toDate());
            setAttributionDate(newDate.toDate());
        }
    };

    return (
        <Row spacing={2} justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={1}>
                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                    <div
                        style={{
                            minWidth: '280px',
                        }}
                    >
                        <AgencyAutocomplete
                            multiple
                            mass
                            size="small"
                            value={criteria.agencyIds}
                            onChange={handleOnAgencyChange}
                        />
                    </div>
                </AccessGuard>

                <div
                    style={{
                        minWidth: '280px',
                    }}
                >
                    <AdvertiserAutocomplete
                        multiple
                        mass
                        size="small"
                        value={criteria.advertiserIds}
                        criteria={{ agencyIds: criteria.agencyIds }}
                        onChange={handleOnAdvertiserChange}
                    />
                </div>

                <div
                    style={{
                        minWidth: '280px',
                    }}
                >
                    <Tooltip
                        placement="top"
                        title="Current Month data will be available within 7 days of the start of the current month."
                    >
                        <Box>
                            <CDMonthDatePicker
                                label="Month"
                                value={attributionDate}
                                minDate={attributionMinDate}
                                maxDate={attributionMaxDate}
                                onChange={handleOnAttributionDateChange}
                            />
                        </Box>
                    </Tooltip>
                </div>
            </Stack>
        </Row>
    );
};

export type { AttributionCriteriaFormProps };
export default AttributionCriteriaForm;
