import { useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { DataGridProProps, GridColDef, GridColumnHeaderParams } from '@mui/x-data-grid-pro';

import { MediaTypeName } from '../../../../../types/MediaType';
import { PerformanceEntity } from '../../../../../types/Analytics/PerformanceEntity';
import { ReportingCriteria } from '../../../../../types/Criteria/ReportingCriteria';
import DataGridPro from '../../../../../components/CDGridPro';
import Utils from '../../../../../components/Utils';

type PerformanceEntityDataGridProps = Omit<DataGridProProps, 'columns' | 'rows'> & {
    reportingCriteria: ReportingCriteria;
    rows?: PerformanceEntity[];
};

const PerformanceEntityDataGrid = ({
    reportingCriteria,
    rows = [],
    ...props
}: PerformanceEntityDataGridProps): JSX.Element => {
    const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row: PerformanceEntity) => row.id.split('/');

    const renderHeader = (delimiter: string = '/', tooltip: string = '') => {
        return (params: GridColumnHeaderParams) => {
            const headerName: string = params?.colDef?.headerName as string;
            const names: string[] = headerName ? headerName.split(delimiter) : [];

            return (
                <Tooltip title={tooltip || headerName} arrow>
                    <strong style={{ textAlign: 'right', display: 'inline-block' }}>
                        {delimiter && names.length > 1 ? (
                            <>
                                {names[0]}
                                {delimiter === '' || delimiter === ' ' ? <></> : <span>/</span>}
                                <br />
                                {names[1]}
                            </>
                        ) : (
                            headerName
                        )}
                    </strong>
                </Tooltip>
            );
        };
    };

    const groupingColDef: DataGridProProps['groupingColDef'] = {
        headerName: 'Entity',
        hideDescendantCount: false,
        sortable: true,
        // valueFormatter: () => '',
        flex: 3,
        minWidth: 360,
        renderHeader: renderHeader(' '),
    };

    const columns: GridColDef[] = useMemo(() => {
        const _columns: GridColDef[] = [
            {
                field: 'type',
                headerName: 'Type',
                flex: 1,
                minWidth: 184,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader(''),
            },
            {
                field: 'totalCost',
                headerName: 'Investment',
                flex: 1,
                minWidth: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader(' ', 'Total Investment'),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value, 'dollar') : params.value;
                },
            },
            {
                field: 'totalImpressions',
                headerName: 'Exposures',
                flex: 1,
                minWidth: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader(' ', 'Total Exposures'),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value) : params.value;
                },
            },
            {
                field: 'averageCPM',
                headerName: 'CPM',
                flex: 1,
                minWidth: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader('/', 'Average CPM'),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value, 'dollar') : params.value;
                },
            },
            {
                field: 'totalReach',
                headerName: 'Household Reach',
                flex: 1,
                minWidth: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader(' '),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value) : params.value;
                },
            },
            {
                field: 'averageViewabilityRate',
                headerName: 'Viewability',
                flex: 1,
                minWidth: 144,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader('/', 'Average Viewability'),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value, 'percent') : params.value;
                },
            },
            {
                field: 'totalClickThroughs',
                headerName: 'Clicks',
                flex: 1,
                minWidth: 116,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader(' ', 'Total Clicks'),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value) : params.value;
                },
            },
            {
                field: 'averageCTR',
                headerName: reportingCriteria.mediaType === MediaTypeName.Audio ? 'Companion Banner Clicks' : 'CTR',
                flex: 1,
                minWidth: reportingCriteria.mediaType === MediaTypeName.Audio ? 244 : 132,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader(
                    '/',
                    reportingCriteria.mediaType === MediaTypeName.Audio
                        ? 'Average Companion Banner Clicks'
                        : 'Average Click-Through Rate'
                ),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value, 'percent') : params.value;
                },
            },
            {
                field: 'totalConversions',
                headerName: 'Conversions',
                flex: 1,
                minWidth: 148,
                headerAlign: 'right',
                align: 'right',
                renderHeader: renderHeader(''),
                renderCell: (params) => {
                    return params.value ? Utils.formatValue(params.value) : params.value;
                },
            },
        ];

        return _columns.filter((_column: GridColDef) => {
            if (_column.field === 'averageViewabilityRate') {
                return reportingCriteria.mediaType !== MediaTypeName.StreamingTV;
            }
            return true;
        });
    }, [reportingCriteria]);

    return (
        <DataGridPro
            disableAutosize={true}
            disableColumnResize={true}
            density="standard"
            slots={{
                toolbar: null,
            }}
            pageSize={10}
            {...props}
            treeData
            columns={columns}
            rows={rows}
            getTreeDataPath={getTreeDataPath}
            groupingColDef={groupingColDef}
            sx={{
                display: 'grid',
                gridTemplateRows: 'auto 1f auto',

                '& .MuiDataGrid-columnHeaderTitleContainerContent': {
                    overflow: 'visible',
                },
            }}
        />
    );
};

export type { PerformanceEntityDataGridProps };
export default PerformanceEntityDataGrid;
