import { Alert, Box, Paper, styled } from '@mui/material';
import { AdCreative, AssetFileMetadata } from '../../types/AmazonAds';

export interface CreativePreviewProps {
    adCreatives?: AdCreative[];
    parseXML: (url: string) => Promise<string | undefined>;
}

export default function CreativePreview(props: CreativePreviewProps) {
    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const renderHTMLPreview = (html: string) => {
        return <div dangerouslySetInnerHTML={{ __html: html }} />;
    };

    const renderVideoPreview = (url: string, contentType: string) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'hidden',
                }}
            >
                <video
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }}
                    controls
                >
                    <source src={url} type={contentType} />
                    Your browser does not support the video tag.
                </video>
            </Box>
        );
    };

    const renderImagePreview = (url: string, width: number, height: number) => {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: width,
                    height: height,
                    overflow: 'hidden',
                }}
            >
                <img
                    src={url}
                    alt="Creative asset"
                    style={{
                        width: width,
                        height: height,
                    }}
                />
            </Box>
        );
    };

    const renderItem = (adCreative: AdCreative, index: number) => {
        return (
            <Item key={index} sx={{ display: 'block' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
                    <div style={{ fontWeight: 'bold' }}>{adCreative.name}</div>|
                    <div style={{ fontWeight: 'bold' }}>
                        {adCreative.assets[0].fileMetadataObject.contentType === 'video/mp4' &&
                            `${adCreative.assets[0].fileMetadataObject.resolutionWidth}x${adCreative.assets[0].fileMetadataObject.resolutionHeight}`}
                        {adCreative.assets[0].fileMetadataObject.contentType.startsWith('image') &&
                            `${adCreative.assets[0].fileMetadataObject.width}x${adCreative.assets[0].fileMetadataObject.height}`}
                    </div>
                </Box>
                {adCreative.assets[0].fileMetadataObject.contentType === 'video/mp4'
                    ? renderVideoPreview(adCreative.assets[0].url, adCreative.assets[0].fileMetadataObject.contentType)
                    : renderImagePreview(
                          adCreative.assets[0].url,
                          adCreative.assets[0].fileMetadataObject.width,
                          adCreative.assets[0].fileMetadataObject.height
                      )}
                {adCreative.assets[0].fileMetadataObject.contentType === 'HTML' &&
                    renderHTMLPreview(adCreative.assets[0].fileMetadata)}
            </Item>
        );
    };

    const renderCreatives = () => {
        const creatives: JSX.Element[] = [];
        props.adCreatives?.forEach(async (adCreative, index) => {
            if (adCreative.assets[0].fileMetadataObject && adCreative.assets[0].fileMetadataObject.contentType) {
                console.log('no need to parse xml');
                creatives.push(renderItem(adCreative, index));
            } else {
                if (adCreative.assets[0].assetSubTypes === 'THIRD_PARTY_VAST_VPAID') {
                    const metadata: AssetFileMetadata = {
                        contentType: 'video/mp4',
                        durationInSeconds: 0,
                        extension: '',
                        height: 0,
                        width: 0,
                        resolutionHeight: 0,
                        resolutionWidth: 0,
                        sizeInBytes: 0,
                        audioSampleRateInHz: 0,
                        aspectRatio: '',
                    };
                    console.log('parsing xml in createive preview component');
                    const file = await props.parseXML(adCreative.assets[0].fileMetadata);
                    adCreative.assets[0].fileMetadataObject = metadata;
                    if (file) {
                        adCreative.assets[0].url = file;
                    }
                    creatives.push(renderItem(adCreative, index));
                }
            }
        });
        return creatives;
    };
    return (
        <>
            {!props.adCreatives?.length && (
                <Alert severity="info">No creatives found for the Campaign Month or Ad Group chosen</Alert>
            )}
            {renderCreatives()}
        </>
    );
}
