import { MouseEvent, useContext, useState } from 'react';
import { Box, IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import {
    AccountCircleOutlined as AccountCircleOutlinedIcon,
    ArrowDropDown as ArrowDropDownIcon,
    KeyboardReturn,
    LogoutOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../App';
import { useUserAuth } from '../../hooks/Users/UserAuth';
import { useUserImpersonation } from '../../hooks/Users/UserImpersonation';
import theme from '../../theme';

const AppToolbarMenu = () => {
    const { userSignOut } = useUserAuth();
    const { isUserImpersonating, stopUserImpersonation } = useUserImpersonation();
    const { userContext } = useContext(UserContext);
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleLogout = async (event: MouseEvent<HTMLElement>): Promise<void> => {
        if (!isUserImpersonating()) {
            await userSignOut();
            navigate('/');
        } else {
            stopUserImpersonation();
            navigate('/users');
        }
    };

    return (
        <Box>
            <IconButton
                aria-label="more"
                id="user-menu-toggle-button"
                aria-controls={open ? 'user-menu-toggle-button' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                sx={{
                    borderRadius: theme.shape.borderRadius,
                    px: 2,
                    py: 1,
                }}
                onClick={handleClick}
            >
                <Stack direction="row" spacing={1} color="inherit">
                    <AccountCircleOutlinedIcon color="action" />

                    <Typography color="text.primary" fontWeight={500}>
                        {userContext ? userContext.name : ''}
                    </Typography>

                    <ArrowDropDownIcon color="action" />
                </Stack>
            </IconButton>
            <Menu
                id="user-menu"
                MenuListProps={{
                    'aria-labelledby': 'user-menu-toggle-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        minWidth: '220px',
                    },
                }}
            >
                <MenuItem
                    key="logout"
                    onClick={handleLogout}
                    sx={{
                        '&:hover': {
                            backgroundColor: theme.palette.grey.A100,
                        },
                    }}
                >
                    <Stack direction="row" spacing={2}>
                        {userContext.adminContext ? (
                            <>
                                <KeyboardReturn />
                                <Typography>Return to Admin View</Typography>
                            </>
                        ) : (
                            <>
                                <LogoutOutlined />
                                <Typography>Logout</Typography>
                            </>
                        )}
                    </Stack>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default AppToolbarMenu;
