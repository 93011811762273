import { FC, useMemo } from 'react';

import { PieChartSlice } from '../../../../types/Chart';
import Utils from '../../../../components/Utils';

import { SimpleCountType } from '../../../../services/trialfire/types/SimpleCountType';
import { useHatDashboardPageContext } from '../../../../hooks/Pages/HatDashboardPage';
import PieChartCard from '../../../../components/Metrics/PieChartCard';

const HatConversionByTypePieChartCard: FC = (): JSX.Element => {
    const { simpleCounts, metrics } = useHatDashboardPageContext();

    const trafficTypes = [
        { label: 'Total Visits', type: SimpleCountType.TOTAL_UNIQUE_TRAFFIC },
        { label: 'Influenced', type: SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC },
    ];

    const queries = trafficTypes.map(({ type }) => simpleCounts[type]);

    const isLoading = useMemo(() => queries.some((q) => q?.isFetching || q?.isLoading), [queries]);

    const slices = useMemo((): PieChartSlice[] => {
        return [
            {
                label: 'Total Visits',
                value: (metrics?.TOTAL_UNIQUE_TRAFFIC?.value || 0) - (metrics?.TOTAL_AD_INFLUENCED_TRAFFIC?.value || 0),
            },
            { label: 'Influenced Visits', value: metrics?.TOTAL_AD_INFLUENCED_TRAFFIC?.value || 0 },
        ];
    }, [metrics?.TOTAL_UNIQUE_TRAFFIC, metrics?.TOTAL_AD_INFLUENCED_TRAFFIC]);

    const slicesTotal = useMemo(() => {
        return slices.reduce((total, { value }) => total + value, 0);
    }, [slices]);

    return (
        <PieChartCard
            loading={isLoading}
            label="Visit by Type"
            chartOptions={{
                dataLabels: { enabled: false },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                total: {
                                    label: 'Total Visits',
                                    formatter: () => Utils.formatNumber(slicesTotal) as string,
                                },
                                value: {
                                    formatter: (value) =>
                                        value.startsWith('$') ? value : `${value ? `${value}` : '0'}%`,
                                },
                            },
                        },
                    },
                },
                labels: slices.map(({ label }) => label),
                legend: {
                    horizontalAlign: 'center',
                    offsetX: -64,
                    offsetY: 64,
                },
            }}
            chartSeries={slices.map(({ value }) => (slicesTotal > 0 ? Math.round((value / slicesTotal) * 100) : 0))}
        />
    );
};

export default HatConversionByTypePieChartCard;
