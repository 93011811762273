import { useMemo } from 'react';
import {
    // Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { isPerformanceMediaType } from '../../../../../hooks/useCampaignPerformance';
import { MediaPerformance, MediaCampaignPerformance } from '../../../../../types/MediaPerformance';
import { Performance } from '../../../../../types/CampaignPerformance';
import { ReportingCriteria } from '../../../../../types/Criteria/ReportingCriteria';
import { Table, TableCellText, TablePrimaryCell } from '../../../../../components/Metrics/MetricTable';
import { useReportingMediaPerformances } from '../../../../../hooks/ReportingMediaPerformances';
import Utils from '../../../../../components/Utils';

export type MediaPerformanceListTableProps = {
    reportingCriteria: ReportingCriteria;
    performances: Performance[];
};

export function MediaPerformanceListTable({ reportingCriteria, performances }: MediaPerformanceListTableProps) {
    const { mediaPerformances } = useReportingMediaPerformances({ reportingCriteria, performances });

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <TableCellText tooltip="Media Type" justifyContent="flex-start">
                            Media Type
                        </TableCellText>
                    </TableCell>
                    <TableCell>
                        <TableCellText tooltip="Total Investment">Investment</TableCellText>
                    </TableCell>
                    <TableCell>
                        <TableCellText tooltip="Total Exposures">Exposures</TableCellText>
                    </TableCell>
                    <TableCell>
                        <TableCellText tooltip="Average CPM">CPM</TableCellText>
                    </TableCell>
                    <TableCell>Household Reach</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {mediaPerformances.map((mediaPerformance: MediaPerformance, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{mediaPerformance.mediaType}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(mediaPerformance.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(mediaPerformance.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(mediaPerformance.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(mediaPerformance.dailyReach)}</TableCell>
                        <TableCell>{Utils.formatValue(mediaPerformance.clickthroughs)}</TableCell>
                        <TableCell>{Utils.formatValue(mediaPerformance.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export type MediaCampaignPerformanceListTableProps = {
    mediaType: string;
    performances: Performance[];
};

export function MediaCampaignPerformanceListTable(props: MediaCampaignPerformanceListTableProps) {
    const { mediaType, performances } = props;

    const items = useMemo((): MediaCampaignPerformance[] => {
        const list: Record<string, MediaCampaignPerformance> = {};

        performances
            .filter((perfomance: Performance): boolean => {
                return isPerformanceMediaType(perfomance, mediaType);
            })
            .forEach((perfomance: Performance): void => {
                if (perfomance.campaignName in list) {
                    const item: MediaCampaignPerformance = list[perfomance.campaignName];

                    item.totalCost += perfomance.totalCost;
                    item.impressions += perfomance.impressions;
                    item.CPM += perfomance.CPM;
                    item.dailyReach += perfomance.dailyReach;
                    item.clickthroughs += perfomance.clickthroughs;
                    item.offAmazonConversions += perfomance.offAmazonConversions;

                    list[perfomance.campaignName] = item;
                } else {
                    list[perfomance.campaignName] = perfomance;
                }
            });

        return Object.values(list);
    }, [mediaType, performances]);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Campaign</TableCell>
                    <TableCell>Total Investment</TableCell>
                    <TableCell>Total Exposures</TableCell>
                    <TableCell>Average CPM</TableCell>
                    <TableCell>Household Reach</TableCell>
                    <TableCell>Clicks</TableCell>
                    <TableCell>
                        <TableCellText tooltip="Conversions as reported by the DSP">Conversions</TableCellText>
                    </TableCell>
                </TableRow>
            </TableHead>

            <TableBody>
                {items.map((item: MediaCampaignPerformance, index: number) => (
                    <TableRow key={index}>
                        <TablePrimaryCell>{item.campaignName}</TablePrimaryCell>
                        <TableCell>{Utils.formatValue(item.totalCost, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(item.impressions)}</TableCell>
                        <TableCell>{Utils.formatValue(item.CPM, 'dollar')}</TableCell>
                        <TableCell>{Utils.formatValue(item.dailyReach)}</TableCell>
                        <TableCell>{Utils.formatValue(item.clickthroughs)}</TableCell>
                        <TableCell>{Utils.formatValue(item.offAmazonConversions)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export type ReportingPerformanceTableProps = {
    reportingCriteria: ReportingCriteria;
    performances: Performance[];
};

export default function ReportingPerformanceTable(props: ReportingPerformanceTableProps) {
    const { performances, reportingCriteria } = props;

    return (
        <TableContainer
            sx={{
                borderCollapse: 'inherit',
                borderColor: (theme) => theme.palette.grey[300],
                borderStyle: 'solid',
                borderWidth: 1,
                borderRadius: '8px',
                overflow: 'hidden',
            }}
        >
            <MediaPerformanceListTable reportingCriteria={reportingCriteria} performances={performances} />
        </TableContainer>
    );
}
