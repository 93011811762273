import Heading from '../../../components/Heading';
import { ChangeEvent, FormEvent, useCallback, useContext, useEffect, useRef, useMemo, useState } from 'react';
import {
    Alert,
    AlertColor,
    Box,
    Button,
    CardContent,
    CardHeader,
    Paper,
    Skeleton,
    Step,
    Stepper,
    Tooltip,
} from '@mui/material';
import {
    AddOutlined,
    CloseOutlined,
    NavigateBeforeOutlined,
    NavigateNextOutlined,
    SaveOutlined,
    SendOutlined,
} from '@mui/icons-material';
import { Campaign, CampaignChangeRequest, ForecastingParameters } from '../../../types/Campaign';
import ApiService from '../../../ApiService';
import { InfoMessage, InfoMessageProps } from '../../../components/InfoMessage';
import { UserContext } from '../../../App';
import DealerSelector from '../../../components/DealerSelector';
import { Dealer } from '../../../types/Dealer';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CodeLookupComponent from '../../../components/CodeLookupComponent';
import { SectionHeading } from '../../../components/SectionHeading';
import CampaignSummary from './CampaignSummary';
import { CDTextField } from '../../../components/CDTextField';
import Block from '../../../components/Block';
import Column from '../../../components/Column';
import Row from '../../../components/Row';
import NewDealerModal from '../../dealers/NewDealerModal';
import CDDatePicker from '../../../components/CDDatePicker';
import StepGeoTargeting from './StepGeoTargeting';
import StepAudienceTargeting from './StepAudienceTargeting';
import StepCreativeAssets from './StepCreativeAssets';
import { CampaignCard } from './CampaignCard';
import CDPageToolbar from '../../../components/CDPageToolbar';
import StepHeader from './StepHeader';
import { AdvertiserContext } from '../../../AdvertiserContext';
import CDButton from '../../../components/CDButton';
import Utils from '../../../components/Utils';
import DuplicateCampaignsComponent from './DuplicateCampaignsComponent';

export default function CampaignEditPage() {
    const STEP_CAMPAIGN_INFORMATION = 0;
    const STEP_GEO_TARGETING = 1;
    const STEP_AUDIENCE_TARGETING = 2;
    const STEP_CREATIVE_ASSETS = 3;
    const STEP_SUMMARY = 4;

    const MAX_FILE_SIZE = 314572800;
    const { advertiserContext, setDealer } = useContext(AdvertiserContext);

    const location = useLocation();

    const isChangeRequest = location.state && location.state.isChangeRequest;
    const campaignId = isChangeRequest ? location.state && location.state.campaignId : null;
    const changeRequestId = location.state && location.state.changeRequestId ? +location.state.changeRequestId : null;

    const params = useParams();
    const navigate = useNavigate();
    const isNewCampaign = !params.id;

    const [loaded, setLoaded] = useState(false);

    const [campaign, setCampaign] = useState<CampaignChangeRequest>(
        new CampaignChangeRequest(advertiserContext?.dealer?.id)
    );
    const dealer = campaign.dealer ? campaign.dealer : advertiserContext.dealer;
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [files, setFiles] = useState<File[]>([]);
    const { userContext } = useContext(UserContext);
    const isAdmin = userContext.isAdmin();
    const [filterValues, setFilterValues] = useState(false);
    const [forecastingParameters, setForecastingParameters] = useState<ForecastingParameters>(
        new ForecastingParameters()
    );
    const [showError, setShowError] = useState(false);
    const [activeStep, setActiveStep] = useState(STEP_CAMPAIGN_INFORMATION);
    const [stepError, setStepError] = useState<number>(-1);
    const [duplicateCampaigns, setDuplicateCampaigns] = useState<Campaign[]>([]);
    const [ignoreDuplicates, setIgnoreDuplicates] = useState(false);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const [showNewAdvertiserDialog, setShowNewAdvertiserDialog] = useState(false);
    const [complete, setComplete] = useState(false);

    function goBack() {
        if (location.state && location.state.from === 'overview') {
            navigate(-1);
        } else {
            navigate('/');
        }
    }

    function validate(): boolean {
        setShowError(false);
        if (
            !campaign.dealerId ||
            campaign.mediaType === '' ||
            campaign.campaignType === '' ||
            campaign.strategyType === '' ||
            campaign.startDate === undefined ||
            campaign.endDate === undefined
        ) {
            setShowError(true);
            return false;
        }
        return true;
    }

    function onDateChange(dateField: string, newValue: Date | null) {
        if (newValue !== null) {
            setCampaign((prev) => {
                return {
                    ...prev,
                    [dateField]: newValue,
                };
            });
        }
    }

    const populateImmediateDates = (days: number = 1) => {
        let endDate = Utils.getMonthEnd(0);
        if (campaign.endDate && campaign.endDate > endDate) {
            endDate = campaign.endDate;
        }
        let startDate = new Date();
        startDate.setDate(startDate.getDate() + days);
        setCampaign((prev) => ({
            ...prev,
            startDate: startDate,
            endDate: endDate,
        }));
    };

    const campaignRef = useRef(campaign);
    campaignRef.current = campaign;

    const startDateMinDate = useMemo(() => {
        if (campaign.startDate) {
            return campaignRef.current.startDate;
        }

        return undefined;
    }, [campaign.startDate]);

    const forecastingParametersRef = useRef(forecastingParameters);
    forecastingParametersRef.current = forecastingParameters;

    const calculateAvailableImpressions = useCallback(() => {
        const campaign = campaignRef.current;
        const fcParams = forecastingParametersRef.current;

        if (campaign.population) {
            let availableReach = (campaign.population * fcParams.primeMembers) / fcParams.usPopulation;
            availableReach = Math.round(availableReach / 1000) * 1000;

            let availableImpressions = availableReach * fcParams.frequencyValue * fcParams.frequencyInterval;
            availableImpressions = Math.round(availableImpressions / 1000) * 1000;
            if (isNaN(availableImpressions)) {
                availableImpressions = 0;
            }
            let impressionGoal = Number(campaign.impressionGoal);
            if (impressionGoal > availableImpressions) {
                impressionGoal = availableImpressions;
            }
            setCampaign((prev) => ({
                ...prev,
                impressionGoal: impressionGoal,
                availableReach: Number(availableReach.toFixed(0)),
                availableImpressions: Number(availableImpressions.toFixed(0)),
            }));
        }
    }, []);

    useEffect(() => {
        setCampaign((prev) => ({ ...prev, dealerId: advertiserContext?.dealer?.id }));
    }, [advertiserContext?.dealer]);

    useEffect(() => {
        if (isChangeRequest) {
            setDuplicateCampaigns([]);
            ApiService.getCampaignChangeRequest(campaignId, changeRequestId)
                .then((response) => {
                    let campaign = response.data;
                    campaign.changeRequestId = campaign.id;
                    setCampaign(campaign);

                    if (campaign.status === 'Pending') {
                        populateImmediateDates(0);
                    }

                    const isComplete = campaign.status === 'Complete';
                    setComplete(isComplete);
                    setActiveStep(isComplete ? STEP_SUMMARY : STEP_CAMPAIGN_INFORMATION);
                    calculateAvailableImpressions();
                    setLoaded(true);
                })
                .catch(() => {});
        } else {
            setLoaded(true);
        }
        ApiService.getForecastingParameters()
            .then((result) => {
                setForecastingParameters(result.data);
            })
            .catch(() => {});
    }, [calculateAvailableImpressions, campaignId, changeRequestId, isChangeRequest]);

    useEffect(() => {
        // Only check for duplicates when creating a new Campaign
        if (!campaign.id && !campaign.campaignId) {
            if (campaign.dealerId && campaign.mediaType && campaign.campaignType && campaign.strategyType) {
                ApiService.findDuplicateCampaigns(
                    null,
                    campaign.dealerId,
                    campaign.mediaType,
                    campaign.campaignType,
                    campaign.strategyType
                ).then((response) => {
                    setIgnoreDuplicates(false);
                    setDuplicateCampaigns(response.data);
                    setShowError(false);
                });
            }
        }
    }, [
        campaign.id,
        campaign.campaignId,
        campaign.dealerId,
        campaign.mediaType,
        campaign.campaignType,
        campaign.strategyType,
    ]);

    // Call calculateAvailableImpressions when the request population changes
    useEffect(() => {
        calculateAvailableImpressions();
    }, [campaign.population, calculateAvailableImpressions]);

    function updatePartial(value: Partial<Campaign>): void {
        setCampaign((prev) => {
            return { ...prev, ...value };
        });
    }

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        const field = event.target.name;
        let data: CampaignChangeRequest;
        let value: any = event.target.value;
        if (value === 'true') {
            value = true;
        } else if (value === 'false') {
            value = false;
        }
        if (field.startsWith('campaignType')) {
            data = { ...campaign, [field]: event.target.checked };
        } else if (field === 'deliveryStatus') {
            data = {
                ...campaign,
                deliveryStatusId: event.target.value,
            };
        } else {
            data = { ...campaign, [field]: value };
        }
        setCampaign(data);
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!validate()) {
            showInfoMessage('error', 'Please fill out all required fields.');
            return;
        }

        updateCampaign();
    }

    const selectDealer = useCallback(
        (dealerId: number | null, dealer: Dealer) => {
            if (dealerId) {
                setCampaign((value) => {
                    return { ...value, dealerId: dealerId };
                });
            }
            setDealer(dealer);
        },
        [setDealer]
    );

    function updateCampaign() {
        const cmpgn = {
            ...campaign,
            startDate: Utils.getESTStartDate(campaign.startDate as Date),
            endDate: Utils.getESTEndDate(campaign.endDate as Date),
        };
        if (userContext.isAgency()) {
            cmpgn.hasUpdates = true;
        }
        if (!cmpgn.impressionBased) {
            cmpgn.impressionGoal = null;
        }
        if (cmpgn.campaignId) {
            setSaveInProgress(true);
            ApiService.updateCampaignChangeRequest(cmpgn, files).then(() => {
                showInfoMessage('success', 'Campaign change request has been submitted.');
                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            });
        } else {
            ApiService.updateCampaign(cmpgn, files)
                .then(() => {
                    if (!cmpgn.id) {
                        showInfoMessage('success', 'Campaign has been created.');
                        setTimeout(() => {
                            navigate('/');
                        }, 3000);
                    } else {
                        showInfoMessage('success', 'Campaign has been updated.');
                        setFiles([]);
                    }
                })
                .catch(() => {});
        }
    }

    function handleNext() {
        if (duplicateCampaigns.length > 0 && !ignoreDuplicates) {
            setStepError(activeStep);
            setShowError(true);
            return;
        }

        if (activeStep === STEP_AUDIENCE_TARGETING && !campaign.budget) {
            setStepError(activeStep);
            setShowError(true);
            return;
        }
        if (
            isNewCampaign &&
            (!campaign.dealerId ||
                !campaign.startDate ||
                !campaign.endDate ||
                !campaign.mediaType ||
                !campaign.campaignType ||
                !campaign.strategyType)
        ) {
            setShowError(true);
            setStepError(activeStep);
            return;
        }
        setShowError(false);
        setStepError(-1);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    function handleBack() {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }

    if (!loaded) {
        return (
            <>
                <Heading>New Campaign</Heading>
                <Skeleton animation="wave" variant="rectangular" height="60vh" />
            </>
        );
    }

    const centerContent = {
        width: '100%',
        maxWidth: '900px',
        minWidth: '620px',
        marginLeft: 'auto',
        marginRight: 'auto',
    };

    const handleNewDealerSave = (dealer: Dealer) => {
        ApiService.updateDealer(dealer)
            .then((response) => {
                setShowNewAdvertiserDialog(false);
                showInfoMessage('success', 'New advertiser has been created.');
                selectDealer(response.data.id ? response.data.id : null, dealer);
            })
            .catch(() => {
                showInfoMessage('error', 'Advertiser creation failed.');
            });
    };

    const handleNewDealerCancel = () => setShowNewAdvertiserDialog(false);

    const handleNewDealerMessages = (alertLevel: AlertColor, message: string) => showInfoMessage(alertLevel, message);

    // @ts-ignore
    return (
        <>
            <InfoMessage {...infoMessage} />
            <NewDealerModal
                open={showNewAdvertiserDialog}
                onSave={handleNewDealerSave}
                onCancel={handleNewDealerCancel}
                onMessage={handleNewDealerMessages}
            />
            <Block>
                <Heading>
                    {isNewCampaign
                        ? 'New Campaign'
                        : 'Campaign Change Request: ' +
                          campaign.mediaType +
                          ' ' +
                          campaign.campaignType +
                          ' ' +
                          campaign.strategyType}
                </Heading>
                <form onSubmit={handleSubmit}>
                    <Column sx={{ marginBottom: '10px', height: 'auto' }}>
                        <Box sx={centerContent}>
                            <Stepper activeStep={activeStep} nonLinear={!isNewCampaign}>
                                <Step key="step1">
                                    <StepHeader
                                        id={campaign.id}
                                        label="Details"
                                        stepNumber={STEP_CAMPAIGN_INFORMATION}
                                        activeStep={activeStep}
                                        stepError={stepError}
                                        stepWarning={false}
                                        setActiveStep={setActiveStep}
                                    />
                                </Step>
                                <Step key="step3">
                                    <StepHeader
                                        id={campaign.id}
                                        label="Geography Targeting"
                                        stepNumber={STEP_GEO_TARGETING}
                                        activeStep={activeStep}
                                        stepError={stepError}
                                        stepWarning={false}
                                        setActiveStep={setActiveStep}
                                    />
                                </Step>

                                <Step key="step4">
                                    <StepHeader
                                        id={campaign.id}
                                        label="Audience Targeting"
                                        stepNumber={STEP_AUDIENCE_TARGETING}
                                        activeStep={activeStep}
                                        stepError={stepError}
                                        stepWarning={false}
                                        setActiveStep={setActiveStep}
                                    />
                                </Step>
                                <Step key="step2">
                                    <StepHeader
                                        id={campaign.id}
                                        label="Creative Assets"
                                        stepNumber={STEP_CREATIVE_ASSETS}
                                        activeStep={activeStep}
                                        stepError={stepError}
                                        stepWarning={
                                            activeStep > STEP_CREATIVE_ASSETS &&
                                            files.length === 0 &&
                                            campaign.creativeAssetLink === null
                                        }
                                        setActiveStep={setActiveStep}
                                    />
                                </Step>

                                <Step key="step5">
                                    <StepHeader
                                        id={campaign.id}
                                        label="Summary"
                                        stepNumber={STEP_SUMMARY}
                                        activeStep={activeStep}
                                        stepError={stepError}
                                        stepWarning={false}
                                        setActiveStep={setActiveStep}
                                    />
                                </Step>
                            </Stepper>
                        </Box>

                        {activeStep === STEP_CAMPAIGN_INFORMATION && (
                            <CampaignCard sx={centerContent}>
                                <CardHeader title="Advertiser" />
                                <CardContent>
                                    <Column sx={centerContent}>
                                        <Row>
                                            {campaign.id && <Column gap={0}>{campaign.dealer?.dealerName}</Column>}
                                            {!campaign.id && (
                                                <div style={{}}>
                                                    <DealerSelector
                                                        error={!campaign.dealerId && showError}
                                                        disabled={!isNewCampaign}
                                                        agencyId={advertiserContext.agencyId}
                                                        dealerId={campaign.dealerId}
                                                        dealerSelected={selectDealer}
                                                        variant="outlined"
                                                    />
                                                </div>
                                            )}

                                            {dealer && dealer.logoUrl && (
                                                <Tooltip title={dealer.url}>
                                                    <Paper elevation={4}>
                                                        <a
                                                            href={dealer.url}
                                                            style={{
                                                                cursor: 'pointer',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                height: '100%',
                                                            }}
                                                            target="new"
                                                        >
                                                            <img alt="" height="40px" src={dealer.logoUrl} />
                                                        </a>
                                                    </Paper>
                                                </Tooltip>
                                            )}

                                            {!campaign.id && userContext.isAgency() && (
                                                <CDButton
                                                    variant="outlined"
                                                    size="medium"
                                                    color="primary"
                                                    disableFocusRipple
                                                    startIcon={<AddOutlined />}
                                                    onClick={() => setShowNewAdvertiserDialog(true)}
                                                >
                                                    New Advertiser
                                                </CDButton>
                                            )}
                                        </Row>

                                        <SectionHeading>Campaign Information</SectionHeading>

                                        {isAdmin && dealer && dealer.id && !dealer.sizmekAdvertiserId && (
                                            <Tooltip title="Create an Amazon Ad Suite Advertiser on the Advertiser page">
                                                <Link to={'/advertisers/' + dealer.id}>
                                                    <Alert severity="warning">
                                                        An Amazon Ad Suite Advertiser has not been created for{' '}
                                                        {dealer.dealerName}
                                                    </Alert>
                                                </Link>
                                            </Tooltip>
                                        )}

                                        <Row>
                                            <CDDatePicker
                                                value={campaign.startDate}
                                                onChange={(dateValue) => onDateChange('startDate', dateValue)}
                                                showError={showError}
                                                label={'Start Date'}
                                                disabled={complete}
                                                minDate={startDateMinDate}
                                            />
                                            <CDDatePicker
                                                value={campaign.endDate}
                                                onChange={(dateValue) => onDateChange('endDate', dateValue)}
                                                showError={showError}
                                                label={'End Date'}
                                                disabled={complete}
                                                minDate={campaign.startDate}
                                            />

                                            {!complete && (
                                                <>
                                                    <CDButton variant="text" onClick={() => populateImmediateDates()}>
                                                        Immediate
                                                    </CDButton>
                                                    <CDButton
                                                        variant="text"
                                                        onClick={() => {
                                                            let endDate = Utils.getMonthEnd(1);
                                                            if (campaign.endDate && campaign.endDate > endDate) {
                                                                endDate = campaign.endDate;
                                                            }
                                                            setCampaign((prev) => ({
                                                                ...prev,
                                                                startDate: Utils.getMonthStart(1),
                                                                endDate: endDate,
                                                            }));
                                                        }}
                                                    >
                                                        Next Month
                                                    </CDButton>
                                                </>
                                            )}
                                        </Row>

                                        {!campaign.id && (
                                            <Row>
                                                <CodeLookupComponent
                                                    label="Media Type"
                                                    codeGroup="MediaType"
                                                    includeNone={true}
                                                    variant="outlined"
                                                    required={true}
                                                    error={showError && campaign.mediaType === ''}
                                                    value={campaign.mediaType}
                                                    disabled={!isNewCampaign}
                                                    onChange={(mediaType) =>
                                                        setCampaign((prev) => ({
                                                            ...prev,
                                                            mediaType: mediaType,
                                                        }))
                                                    }
                                                />
                                                <CodeLookupComponent
                                                    label="Campaign Type"
                                                    codeGroup="CampaignType"
                                                    includeNone={true}
                                                    variant="outlined"
                                                    required={true}
                                                    error={showError && campaign.campaignType === ''}
                                                    value={campaign.campaignType}
                                                    disabled={!isNewCampaign}
                                                    onChange={(campaignType) => {
                                                        setCampaign((prev) => ({
                                                            ...prev,
                                                            campaignType: campaignType,
                                                        }));
                                                        setFilterValues(!filterValues);
                                                    }}
                                                    applyFilter={campaign.mediaType}
                                                    filter={(codeList) => {
                                                        if (campaign.mediaType === 'Audio') {
                                                            codeList = codeList.filter(
                                                                (code) => code.code === 'Static'
                                                            );
                                                        }
                                                        return codeList;
                                                    }}
                                                    help={
                                                        <ul>
                                                            <li>
                                                                Dynamic (DCO) ads use product feeds to optimize ads with
                                                                specific product details aligned to consumer signals.
                                                            </li>
                                                            <li>
                                                                Static ad creative is custom designed once and deployed
                                                                globally to all consumers.
                                                            </li>
                                                        </ul>
                                                    }
                                                />
                                                <CodeLookupComponent
                                                    label="Strategy"
                                                    codeGroup="StrategyType"
                                                    includeNone={true}
                                                    variant="outlined"
                                                    required={true}
                                                    error={showError && campaign.strategyType === ''}
                                                    value={campaign.strategyType}
                                                    disabled={!isNewCampaign}
                                                    onChange={(strategyType) =>
                                                        setCampaign((prev) => ({
                                                            ...prev,
                                                            strategyType: strategyType,
                                                        }))
                                                    }
                                                    applyFilter={filterValues}
                                                    filter={(codeList) => {
                                                        if (
                                                            campaign.campaignType === 'Static' ||
                                                            (dealer?.verticalId && dealer.verticalId !== 2)
                                                        ) {
                                                            codeList = codeList.filter(
                                                                (code) => code.code === 'Awareness'
                                                            );
                                                        }
                                                        return codeList;
                                                    }}
                                                />
                                            </Row>
                                        )}

                                        <DuplicateCampaignsComponent
                                            duplicateCampaigns={duplicateCampaigns}
                                            clearDuplicates={() => {
                                                setDuplicateCampaigns([]);
                                                setIgnoreDuplicates(true);
                                            }}
                                            showError={showError}
                                            ignoreDuplicates={ignoreDuplicates}
                                            allowModify={true}
                                        />

                                        {duplicateCampaigns.length === 0 && (
                                            <>
                                                <Row>
                                                    <CDTextField
                                                        sx={{ width: '400px' }}
                                                        name="emailForApproval"
                                                        label="Email for Approval"
                                                        variant="outlined"
                                                        id="input-emailForApproval"
                                                        fullWidth={true}
                                                        onChange={handleChange}
                                                        disabled={complete}
                                                        value={campaign.emailForApproval ?? ''}
                                                    />
                                                </Row>

                                                <Row sx={{ height: '50px' }}>
                                                    {campaign.strategyType === 'Inventory' && (
                                                        <CDTextField
                                                            name="inventoryProvider"
                                                            label="Inventory Provider"
                                                            value={campaign.inventoryProvider}
                                                            required={false}
                                                            variant="outlined"
                                                            fullWidth={true}
                                                            disabled={complete}
                                                            helperText="For Inventory DCO Campaigns"
                                                            onChange={handleChange}
                                                        />
                                                    )}
                                                </Row>
                                            </>
                                        )}
                                    </Column>
                                </CardContent>
                            </CampaignCard>
                        )}

                        {activeStep === STEP_CREATIVE_ASSETS && (
                            <StepCreativeAssets
                                campaign={campaign}
                                onChange={updatePartial}
                                setFiles={setFiles}
                                disabled={complete}
                                MAX_FILE_SIZE={MAX_FILE_SIZE}
                            />
                        )}

                        {activeStep === STEP_GEO_TARGETING && (
                            <StepGeoTargeting
                                campaign={campaign}
                                dealer={dealer}
                                forecastingParameters={forecastingParameters}
                                onChange={(value: Partial<Campaign>) => {
                                    setCampaign((prev) => {
                                        return { ...prev, ...value };
                                    });
                                }}
                            />
                        )}

                        {activeStep === STEP_AUDIENCE_TARGETING && (
                            <StepAudienceTargeting
                                campaign={campaign}
                                forecastingParameters={forecastingParameters}
                                showError={showError}
                                disabled={complete}
                                onChange={(value: Partial<Campaign>) => {
                                    setCampaign((prev) => {
                                        return { ...prev, ...value };
                                    });
                                }}
                            />
                        )}

                        {activeStep === STEP_SUMMARY && (
                            <Box sx={centerContent}>
                                <CampaignSummary
                                    allowEdit={!complete}
                                    reloadCampaign={() => {}}
                                    campaign={campaign}
                                    dealer={dealer}
                                    files={files}
                                    statusChange={handleChange}
                                    onChange={updatePartial}
                                />
                            </Box>
                        )}
                    </Column>
                    <CDPageToolbar>
                        <CDButton
                            tabIndex={-1}
                            size="medium"
                            color="error"
                            startIcon={<CloseOutlined />}
                            onClick={goBack}
                        >
                            Cancel
                        </CDButton>

                        <Box flex="1" />

                        {activeStep !== STEP_CAMPAIGN_INFORMATION && (
                            <CDButton
                                startIcon={<NavigateBeforeOutlined />}
                                disabled={activeStep === 0 || saveInProgress}
                                onClick={handleBack}
                                sx={{ mr: 0 }}
                            >
                                Back
                            </CDButton>
                        )}

                        {activeStep !== STEP_SUMMARY && (
                            <Button
                                variant="contained"
                                endIcon={<NavigateNextOutlined />}
                                disabled={activeStep === STEP_SUMMARY}
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        )}

                        {activeStep === STEP_SUMMARY && (
                            <Button
                                color="primary"
                                variant="contained"
                                disabled={complete || saveInProgress}
                                name="save"
                                type="submit"
                                startIcon={campaign.id ? <SaveOutlined /> : <SendOutlined />}
                            >
                                {isNewCampaign ? 'Submit' : 'Save'}
                            </Button>
                        )}
                    </CDPageToolbar>
                </form>
            </Block>
        </>
    );
}
