import Heading from '../../components/Heading';
import CDGrid from '../../components/CDGrid';
import { useEffect, useState } from 'react';
import { ReleaseNote } from '../../types/ReleaseNote';
import ApiService from '../../ApiService';
import { CheckCircleOutlined } from '@mui/icons-material';
import Utils from '../../components/Utils';
import { useNavigate } from 'react-router-dom';
import { LinkButton } from '../../components/LinkButton';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Row from '../../components/Row';

export function ReleaseNoteListPage() {
    const navigate = useNavigate();
    const [releaseNotes, setReleaseNotes] = useState<ReleaseNote[] | null>(null);

    useEffect(() => {
        ApiService.getReleaseNotes()
            .then((response) => {
                setReleaseNotes(response.data);
            })
            .catch(() => {});
    }, []);

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Heading width="100%">Release Notes</Heading>

                        <Row justifyContent="flex-end">
                            <LinkButton href="new" variant="contained" color="primary">
                                Add Release Note
                            </LinkButton>
                        </Row>
                    </Row>
                </Column>
            </Block>

            <Block flex={1}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <CDGrid
                        columns={[
                            {
                                width: 120,
                                field: 'published',
                                headerName: 'Released',
                                headerAlign: 'center',
                                align: 'center',
                                renderCell: (params) => {
                                    return params.value ? <CheckCircleOutlined color="success" /> : <></>;
                                },
                            },
                            {
                                width: 200,
                                field: 'description',
                                headerName: 'Release Title',
                                flex: 1,
                            },
                            {
                                width: 150,
                                field: 'releaseDate',
                                headerName: 'Release Date',
                                valueGetter: (params) => {
                                    return Utils.formatDate(params.value as Date);
                                },
                            },
                            {
                                width: 150,
                                field: 'appVersion',
                                headerName: 'App Version',
                            },
                        ]}
                        autoHeight={false}
                        autoPageSize={true}
                        rows={releaseNotes ? releaseNotes : []}
                        onRowClick={(params) => {
                            navigate('' + params.row.id);
                        }}
                    />
                </Column>
            </Block>
        </Column>
    );
}
