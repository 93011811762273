import { ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';

import { AgencyContext } from '../App';
import { AppBySpendBySiteNameSummary as PropertySpend } from '../types/Analytics/AppBySpendSummary';
import { AttributionStatus, PolkDealer, PolkDealerDealer, PolkSalesAnalysis, PolkSalesWorkflow } from '../types/Polk';
import { Performance } from '../types/CampaignPerformance';
import { ConversionByConversionNameSummary as OnSiteInteraction } from '../types/Analytics/ConversionSummary';
import { getUniquePerformanceStrategyTypes } from './useCampaignPerformance';
import { ReportingCriteriaState } from './Criteria/ReportingCriteria';
import { useAxiosCancelTokens } from './AxiosCancelTokens';
import { useReportingCriteria } from './Criteria/ReportingCriteria';
import { useTabs } from './Tabs';
import { toCampaignPerformance } from '../utils/CampaignPerformance';
import ApiService from '../ApiService';
import Utils from '../components/Utils';

import { Metric } from '../types/Analytics/Metric';

export class AttributionMetrics {
    averageCPM: Metric | null | undefined = null;
    averageCTR: Metric | null | undefined = null;
    averageViewabilityRate: Metric | null | undefined = null;

    totalSales: Metric | null | undefined = null;
    attributedSales: Metric | null | undefined = null;
    totalSpend: Metric | null | undefined = null;
    costPerSold: Metric | null | undefined = null;
    totalImpressions: Metric | null | undefined = null;
    totalClickthroughs: Metric | null | undefined = null;
    totalDailyReach: Metric | null | undefined = null;

    totalMarketShares: Metric | null | undefined = null;
    totalAdShares: Metric | null | undefined = null;

    videoCompleteRate: Metric | null | undefined = null;
    costPerCompletedVideo: Metric | null | undefined = null;
    timeSpent: Metric | null | undefined = null;
}

interface ReportingPropertySpendState {
    propertySpends: PropertySpend[] | undefined;
    setPropertySpends: (propertySpends: PropertySpend[] | undefined) => void;
}

interface ReportingOnSiteInteraction {
    onSiteInteractions: OnSiteInteraction[] | undefined;
    setOnSiteInteractions: (onSiteInteractions: OnSiteInteraction[] | undefined) => void;
}

type ReportingDashboardPageContextValue = ReportingCriteriaState &
    ReportingPropertySpendState &
    ReportingOnSiteInteraction & {
        mediaTabs: any;
        performanceTabs: any;

        defaultMinDate: Date;
        defaultMaxDate: Date;
        defaultStartDate: Date;
        defaultEndDate: Date;

        attributionStatus: AttributionStatus;
        setAttributionStatus: (attributionStatus: AttributionStatus) => void;
        isFetchingAttributionStatus: boolean;

        attributionStatusUpdateDate: Date | null;
        setAttributionStatusUpdateDate: (attributionStatusUpdateDate: Date | null) => void;
        attributionStatusUpdateDateFormatted?: string;

        advertiserSalesUpdateDate: Date | null;
        setAdvertiserSalesUpdateDate: (advertiserSalesUpdateDate: Date | null) => void;
        advertiserSalesUpdateDateFormatted?: string;

        polkDealerDealers: PolkDealerDealer[];
        setPolkDealerDealers: (polkDealerDealers: PolkDealerDealer[]) => void;
        isAdvertiserPolkDealer: (polkDealer: PolkDealer) => boolean;
        isCompetitorPolkDealer: (polkDealer: PolkDealer) => boolean;
        getAdvertiserPolkDealerDealers: () => PolkDealerDealer[];
        getCompetitorPolkDealerDealers: () => PolkDealerDealer[];

        polkSalesAnalysis: PolkSalesAnalysis[];
        setPolkSalesAnalysis: (polkSalesAnalysis: PolkSalesAnalysis[]) => void;

        polkSalesWorkflow: PolkSalesWorkflow | null;
        setPolkSalesWorkflow: (polkSalesWorkflow: PolkSalesWorkflow | null) => void;
        polkOpportunitiesWorkflow: PolkSalesWorkflow | null;
        setPolkOpportunitiesWorkflow: (polkOpportunitiesWorkflow: PolkSalesWorkflow | null) => void;

        performances: Performance[];
        fetchPerformances: () => void;
        isFetchingPerformances: boolean;

        activeStrategyTypes: Record<string, string>;
        setActiveStrategyTypes: (activeStrategyTypes: Record<string, string>) => void;
        isActiveStrategyType: (strategyType: string) => boolean;

        activeMediaTypes: Record<string, string>;
        isActiveMediaType: (mediaType: string) => boolean;
        fetchActiveMediaTypes: () => void;
        isFetchingActiveMediaTypes: boolean;

        getPerformances: (mediaType?: string) => void;
        getPerformanceMediaTypes: () => void;
        getPerformanceLabel: (performance: Performance) => void;

        attributionMetrics: AttributionMetrics;
        setAttributionMetrics: (attributionMetrics: AttributionMetrics) => void;
    };

const ReportingDashboardPageContext = createContext<ReportingDashboardPageContextValue | undefined>(undefined);

const useReportingDashboardPageContext = (): ReportingDashboardPageContextValue => {
    const context: ReportingDashboardPageContextValue | undefined = useContext(ReportingDashboardPageContext);
    if (!context) {
        throw new Error('useReportingDashboardPageContext must be used within a ReportingDashboardPageProvider');
    }
    return context;
};

interface ReportingDashboardPageProviderProps {
    children: ReactNode;
    value: ReportingDashboardPageContextValue;
}

const ReportingDashboardPageProvider = ({ children, value }: ReportingDashboardPageProviderProps) => {
    return <ReportingDashboardPageContext.Provider value={value}>{children}</ReportingDashboardPageContext.Provider>;
};

const useReportingDashboardPage = (): ReportingDashboardPageContextValue => {
    const { withAxiosCancelTokenRequestConfig } = useAxiosCancelTokens();

    const agency = useContext(AgencyContext);
    const agencyId: number = agency?.id as number;

    let defaultMinDate = new Date(2023, 0, 1);
    let defaultMaxDate: Date = moment().subtract(1, 'days').startOf('day').toDate();
    let defaultStartDate: Date = moment().subtract(30, 'days').startOf('day').toDate();
    let defaultEndDate: Date = defaultMaxDate;

    const $reportingCriteria: ReportingCriteriaState = useReportingCriteria({
        reportingCriteria: {
            agencyIds: agencyId > 0 ? [agencyId] : [],
            dealerIds: [],
            startDate: defaultStartDate,
            endDate: defaultEndDate,
            mediaType: '',
            strategyType: '',
        },
    });
    const { reportingCriteria } = $reportingCriteria;

    const mediaTabs = useTabs();
    const performanceTabs = useTabs();

    const [attributionStatus, setAttributionStatus] = useState<AttributionStatus>({
        salesWorkflows: [],
        inProgress: true,
    });
    const [isFetchingAttributionStatus, setIsFetchingAttributionStatus] = useState<boolean>(false);

    const [attributionStatusUpdateDate, setAttributionStatusUpdateDate] = useState<Date | null>(null);

    const attributionStatusUpdateDateFormatted: string | undefined = useMemo(() => {
        if (attributionStatusUpdateDate) {
            return moment(attributionStatusUpdateDate).format('M/D/YYYY [at] h:mm a');
        }
    }, [attributionStatusUpdateDate]);

    const [advertiserSalesUpdateDate, setAdvertiserSalesUpdateDate] = useState<Date | null>(null);
    const advertiserSalesUpdateDateFormatted = useMemo((): string | undefined => {
        if (advertiserSalesUpdateDate) {
            return moment(advertiserSalesUpdateDate).format('M/D/YYYY [at] h:mm a');
        }
    }, [advertiserSalesUpdateDate]);

    const [polkDealerDealers, setPolkDealerDealers] = useState<PolkDealerDealer[]>([]);
    const [polkSalesAnalysis, setPolkSalesAnalysis] = useState<PolkSalesAnalysis[]>([]);

    const [polkSalesWorkflow, setPolkSalesWorkflow] = useState<PolkSalesWorkflow | null>(null);
    const [polkOpportunitiesWorkflow, setPolkOpportunitiesWorkflow] = useState<PolkSalesWorkflow | null>(null);

    const [activeStrategyTypes, setActiveStrategyTypes] = useState<Record<string, string>>({});
    const isActiveStrategyType = (strategyType: string): boolean => {
        return strategyType in activeStrategyTypes;
    };

    const isActiveMediaType = (mediaType: string): boolean => {
        return activeMediaTypes ? mediaType in activeMediaTypes : false;
    };

    const [propertySpends, setPropertySpends] = useState<PropertySpend[] | undefined>([]);
    const [onSiteInteractions, setOnSiteInteractions] = useState<OnSiteInteraction[] | undefined>([]);

    const {
        data: performances,
        isFetching: isFetchingPerformances,
        refetch: fetchPerformances,
    } = useQuery({
        queryKey: ['reporting.performances', reportingCriteria],
        queryFn: async () => {
            const { data } = await ApiService.getCampaignPerformanceList(
                { ...reportingCriteria },
                withAxiosCancelTokenRequestConfig({}, 'reporting.performances')
            );
            return data.map((x: any) => toCampaignPerformance(x));
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    const {
        data: activeMediaTypes,
        isFetching: isFetchingActiveMediaTypes,
        refetch: fetchActiveMediaTypes,
    } = useQuery({
        queryKey: ['reporting.activeMediaTypes', reportingCriteria],
        queryFn: async () => {
            const { data } = await ApiService.getCampaignPerformanceActiveMediaTypes(
                { ...reportingCriteria },
                withAxiosCancelTokenRequestConfig({}, 'reporting.activeStrategyTypes')
            );
            let newActiveMediaTypes: Record<string, string> = {};

            data.forEach((mediaType: string) => {
                newActiveMediaTypes[mediaType] = mediaType;
            });
            return newActiveMediaTypes;
        },
        enabled: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (reportingCriteria.strategyType === '') {
            let newActiveStrategyTypes: Record<string, string> = {};

            getUniquePerformanceStrategyTypes(performances || []).forEach((strategyType: string) => {
                newActiveStrategyTypes[strategyType] = strategyType.replace(/([a-z])([A-Z])/g, '$1 $2');
            });

            setActiveStrategyTypes(newActiveStrategyTypes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [performances, reportingCriteria.strategyType]);

    const getPerformances = (mediaType?: string): Performance[] => {
        if (performances && performances.length) {
            return performances.filter((p: Performance) => p.mediaType === mediaType);
        }

        return [];
    };

    const getPerformanceMediaTypes = (): string[] => {
        if (performances && performances.length) {
            return performances
                .map((p: Performance) => p.mediaType)
                .filter((value: string, index: number, array: string[]) => array.indexOf(value) === index);
        }

        return [];
    };

    const getPerformanceLabel = (p: Performance): string => {
        const labels = [];

        if (p?.mediaType) {
            labels.push(p.mediaType);
        }

        if (p?.campaignType) {
            labels.push(p.campaignType);
        }

        if (p?.strategyType) {
            labels.push(p.strategyType);
        }

        return labels.join(' ');
    };

    const [attributionMetrics, setAttributionMetrics] = useState<AttributionMetrics>(new AttributionMetrics());

    useEffect(() => {
        setIsFetchingAttributionStatus(false);
    }, []);

    const isCompetitorPolkDealer = (polkDealer: PolkDealer): boolean => {
        let polkDealerDealer = null;

        if (polkDealerDealers && polkDealerDealers.length) {
            polkDealerDealer = polkDealerDealers.find((pdd: PolkDealerDealer) => pdd.polkDealer.id === polkDealer.id);
        }

        return polkDealerDealer ? polkDealerDealer.isCompetitor : false;
    };

    const isAdvertiserPolkDealer = (polkDealer: PolkDealer): boolean => {
        return isCompetitorPolkDealer(polkDealer) === false;
    };

    const getAdvertiserPolkDealerDealers = (): PolkDealerDealer[] => {
        return polkDealerDealers.filter((pdd: PolkDealerDealer) => pdd.isCompetitor === false);
    };

    const getCompetitorPolkDealerDealers = (): PolkDealerDealer[] => {
        return polkDealerDealers.filter((pdd: PolkDealerDealer) => pdd.isCompetitor === true);
    };

    useEffect(() => {
        if (attributionStatus?.salesWorkflows && attributionStatus.salesWorkflows.length) {
            setAttributionStatusUpdateDate(Utils.getDate(attributionStatus.salesWorkflows[0].updateDate));
        }
    }, [attributionStatus]);

    return {
        defaultMinDate,
        defaultMaxDate,
        defaultStartDate,
        defaultEndDate,

        ...$reportingCriteria,

        propertySpends,
        setPropertySpends,

        onSiteInteractions,
        setOnSiteInteractions,

        mediaTabs,
        performanceTabs,

        attributionStatus,
        setAttributionStatus,
        isFetchingAttributionStatus,

        attributionStatusUpdateDate,
        setAttributionStatusUpdateDate,
        attributionStatusUpdateDateFormatted,

        advertiserSalesUpdateDate,
        setAdvertiserSalesUpdateDate,
        advertiserSalesUpdateDateFormatted,

        polkDealerDealers,
        setPolkDealerDealers,
        isAdvertiserPolkDealer,
        isCompetitorPolkDealer,
        getAdvertiserPolkDealerDealers,
        getCompetitorPolkDealerDealers,

        polkSalesAnalysis,
        setPolkSalesAnalysis,

        polkSalesWorkflow,
        setPolkSalesWorkflow,
        polkOpportunitiesWorkflow,
        setPolkOpportunitiesWorkflow,

        performances: performances || [],
        fetchPerformances,
        isFetchingPerformances,

        activeStrategyTypes,
        setActiveStrategyTypes,
        isActiveStrategyType,

        activeMediaTypes: activeMediaTypes || {},
        isActiveMediaType,
        fetchActiveMediaTypes,
        isFetchingActiveMediaTypes,

        getPerformances,
        getPerformanceMediaTypes,
        getPerformanceLabel,

        attributionMetrics,
        setAttributionMetrics,
    };
};

export type { ReportingDashboardPageContextValue };
export { ReportingDashboardPageContext };
export { useReportingDashboardPageContext };

export type { ReportingDashboardPageProviderProps };
export { ReportingDashboardPageProvider };

export { useReportingDashboardPage };
