import React from 'react';
import { Box, BoxProps } from '@mui/material';

interface TabPanelProps extends BoxProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...boxProps } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}>
            {value === index && (
                <Box sx={{ paddingTop: 3 }} {...boxProps}>
                    {children}
                </Box>
            )}
        </div>
    );
};

export type { TabPanelProps };
export default TabPanel;
