import { SyntheticEvent, useContext, useState } from 'react';
import { Box, Tab, Tabs } from '@mui/material';

import { hasAccess } from '../../components/AccessGuard';
import { UserContext } from '../../App';
import { USER_GROUP_ADMIN } from '../../types/User';
import { TabsContainer } from '../../components/Tabs';
import DspAdGroupDictsTab from './DspAdGroupDictsTab';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import Row from '../../components/Row';
import DspMacrosTab from './DspMacrosTab';
import DmaDataMappingTab from './DmaDataMappingTab';
import AudienceMappingTab from './AudienceMappingTab';

const DataMappingPage = () => {
    const [value, setValue] = useState(0);
    const { userContext } = useContext(UserContext);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const TABS = [
        {
            id: 0,
            label: 'Ad Group Mapping',
            component: (
                <DspAdGroupDictsTab
                    activeTab={value}
                    tab={{
                        id: 0,
                        description:
                            'Map ad group name values to inventory name values based on the type of targeting strategy.',
                    }}
                />
            ),
        },
        {
            id: 1,
            label: 'DMA Mapping',
            component: (
                <DmaDataMappingTab
                    activeTab={value}
                    tab={{
                        id: 1,
                        description:
                            'Visualize locations for targeting setup on the campaign creation page, and for creative decisioning for retargeting purposes.',
                    }}
                />
            ),
        },
        {
            id: 2,
            label: 'DSP Macros',
            component: (
                <DspMacrosTab
                    activeTab={value}
                    tab={{
                        id: 2,
                        description:
                            'DSP Macros are placeholder variables within URLs that are dynamically replaced with values from the DSP when an ad is served.',
                    }}
                />
            ),
        },
        {
            id: 3,
            label: 'Audience Mapping',
            component: (
                <AudienceMappingTab
                    activeTab={value}
                    tab={{
                        id: 3,
                        description:
                            'Used as a lookup table for our campaign creation process so that when a user selects a Targeting Strategy it can automatically select the audiences that are mapped to the corresponding Key Value(s) of that Strategy Type.',
                    }}
                />
            ),
        },
    ];

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Heading>Data Mapping</Heading>

                        <Row justifyContent="flex-end"></Row>
                    </Row>
                </Column>
            </Block>

            <Column gap={0} sx={{ height: '100%' }}>
                <TabsContainer>
                    <Tabs value={value} onChange={handleChange}>
                        {TABS?.map((tab, index) => {
                            return (
                                hasAccess(userContext, USER_GROUP_ADMIN) && (
                                    <Tab
                                        key={index}
                                        value={tab.id}
                                        label={tab.label}
                                        style={{ textTransform: 'none' }}
                                    />
                                )
                            );
                        })}
                    </Tabs>
                </TabsContainer>

                <Block>
                    {TABS?.map((tab, index) => {
                        return <Box key={index}>{tab.component} </Box>;
                    })}
                </Block>
            </Column>
        </Column>
    );
};

export default DataMappingPage;
