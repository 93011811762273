import { useMemo, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
// import { CDTextField } from './CDTextField';
import { TextField, TextFieldProps } from '@mui/material';
import {
    ArrowDropDownOutlined as ArrowDropDownOutlinedIcon,
    ArrowDropUpOutlined as ArrowDropUpOutlinedIcon,
} from '@mui/icons-material';

export type CDMonthDatePickerProps = {
    value: Dayjs | Date | string;
    minDate?: Dayjs | Date | string | undefined;
    maxDate?: Dayjs | Date | string | undefined;

    required?: TextFieldProps['required'];
    variant?: TextFieldProps['variant'];
    size?: TextFieldProps['size'];
    sx?: TextFieldProps['sx'];
} & Omit<DatePickerProps<Dayjs, Dayjs>, 'renderInput' | 'value' | 'minDate' | 'maxDate'>;

const CDMonthDatePicker = (props: CDMonthDatePickerProps) => {
    const {
        value: defaultValue,
        minDate: defaultMinDate,
        maxDate: defaultMaxDate,

        required: textFieldRequired = false,
        variant: textFieldVariant = 'outlined',
        size: textFieldSize = 'small',
        sx: textFieldSx = {},
        ...datePickerProps
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const value = useMemo((): Dayjs => {
        return dayjs(defaultValue);
    }, [defaultValue]);

    const minDate = useMemo((): Dayjs | undefined => {
        if (defaultMinDate) {
            return dayjs(defaultMinDate);
        }

        return undefined;
    }, [defaultMinDate]);

    const maxDate = useMemo((): Dayjs | undefined => {
        if (defaultMaxDate) {
            return dayjs(defaultMaxDate);
        }

        return undefined;
    }, [defaultMaxDate]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                label="Month"
                {...datePickerProps}
                value={value}
                minDate={minDate}
                maxDate={maxDate}
                views={['year', 'month']}
                onChange={(v: Dayjs | null): void => props.onChange?.(v?.set('date', 1) ?? null)}
                openTo="month"
                renderInput={(params) => (
                    <TextField
                        {...params}
                        required={textFieldRequired}
                        variant={textFieldVariant}
                        size={textFieldSize}
                        inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                        }}
                        sx={{
                            '& .MuiIconButton-root .MuiSvgIcon-root': {
                                color: (theme) => theme.palette.action.active,
                            },
                            ...textFieldSx,
                        }}
                    />
                )}
                components={{
                    OpenPickerIcon: isOpen ? ArrowDropUpOutlinedIcon : ArrowDropDownOutlinedIcon,
                }}
                PopperProps={{
                    sx: {
                        '& .MuiMonthPicker-root': {
                            margin: (theme) => theme.spacing(2),
                            width: 'auto',
                        },
                    },
                }}
                onOpen={(): void => setIsOpen(true)}
                onClose={(): void => setIsOpen(false)}
            />
        </LocalizationProvider>
    );
};

export default CDMonthDatePicker;
