interface UseState {
    appContentWidth: number;
}

const useAppContent = (): UseState => {
    const appContentWidth: number = 1024;

    return {
        appContentWidth,
    };
};

export type { UseState as UseAppContentState };
export { useAppContent };
