import { styled } from '@mui/material';
import { ListItemText as BaseListItemText } from '@mui/material';

import { SidebarMenuListItemProps } from './SidebarMenuListItem';
import { useSidebarMenuItem } from '../../hooks/SidebarMenuItem';
import SidebarMenuListItem from './SidebarMenuListItem';

export const SlotListItemText = styled(BaseListItemText)(({ theme }) => ({
    '&': {
        color: theme.palette.text.secondary,
    },
}));

interface SidebarMenuSubListItemProps extends SidebarMenuListItemProps {}

const SidebarMenuSubListItem = (props: SidebarMenuSubListItemProps) => {
    const { slots: defaultSlots = {} } = props;

    const { isSidebarMenuItemActive } = useSidebarMenuItem(props.item);

    return (
        <SidebarMenuListItem
            {...props}
            slots={{
                ListItemText: isSidebarMenuItemActive() ? BaseListItemText : SlotListItemText,
                ...defaultSlots,
            }}
        />
    );
};

export type { SidebarMenuSubListItemProps };
export default SidebarMenuSubListItem;
