import { CampaignStatus } from '../../../types/Campaign';
import CampaignStatusComponent from '../../../pages/campaignManager/CampaignStatusComponent';

type CampaignStatusChipProps = {
    deliveryStatus: CampaignStatus;
};

export default function CampaignStatusChip(props: CampaignStatusChipProps) {
    return <CampaignStatusComponent deliveryStatus={props.deliveryStatus} />;
}
