import { useContext, useEffect, useRef, useState } from 'react';
import { Badge, IconButton, Tooltip } from '@mui/material';
import { NotificationsOutlined as NotificationsOutlinedIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../App';
import ApiService from '../../ApiService';

const AppToolbarNotificationsButton = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const navigate = useNavigate();
    const [unread, setUnread] = useState<number>(0);
    const { userContext } = useContext(UserContext);

    const handleOpenPopover = (): void => {
        navigate('/jobStatus');
    };

    useEffect((): void => {
        if (userContext.isAdmin()) {
            getFailedJobCount();
        }
    }, [userContext]);

    const getFailedJobCount = (): void => {
        ApiService.getFailedJobCount()
            .then((response) => {
                setUnread(response.data);
            })
            .catch(() => {});
    };

    return (
        <>
            <Tooltip title="Job Status">
                <IconButton ref={anchorRef} onClick={handleOpenPopover}>
                    <Badge color="error" badgeContent={unread}>
                        <NotificationsOutlinedIcon color="action" fontSize="medium" />
                    </Badge>
                </IconButton>
            </Tooltip>
        </>
    );
};

export default AppToolbarNotificationsButton;
