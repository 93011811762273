import { useCallback, useContext, useMemo } from 'react';
import { FormControl } from '@mui/material';

import { Agency } from '../../../types/Agency';
import { Dealer as Advertiser } from '../../../types/Dealer';
import { UserContext } from '../../../App';
import { HatCriteria } from '../../../types/Criteria/HatCriteria';
import { useHatDashboardPageContext } from '../../../hooks/Pages/HatDashboardPage';
import AdvertiserAutocomplete from '../../../components/AdvertiserAutocomplete';
import AgencyAutocomplete from '../../../components/AgencyAutocomplete';
import Row from '../../../components/Row';
import HatCriteriaDataRangePicker from './HatCriteriaDataRangePicker';

type HatCriteriaFormProps = {
    criteria: HatCriteria;
    onChangeCriteria: (criteria: HatCriteria) => void;
};

export default function HatCriteriaForm(props: HatCriteriaFormProps) {
    const { criteria, onChangeCriteria } = props;

    const { userContext } = useContext(UserContext);
    const { defaultMinDate, defaultMaxDate, isHatCriteriaSetup, setIsHatCriteriaSetup } = useHatDashboardPageContext();

    const handleAgencyOnChange = useCallback(
        (agency: Agency | null): void => {
            onChangeCriteria({
                ...criteria,
                agencyId: agency?.id || null,
                agency: agency || null,
            });
        },
        [criteria, onChangeCriteria]
    );

    const handleAdvertiserOnChange = useCallback(
        (advertiser: Advertiser | null) => {
            if (isHatCriteriaSetup === false) {
                setIsHatCriteriaSetup(true);
            }

            onChangeCriteria({
                ...criteria,
                advertiserId: advertiser?.id || null,
                advertiser: advertiser || null,
            });
        },
        [criteria, onChangeCriteria, setIsHatCriteriaSetup, isHatCriteriaSetup]
    );

    const advertiserCritieria = useMemo(() => {
        return { agencyIds: criteria?.agencyId ? [criteria.agencyId] : [] };
    }, [criteria.agencyId]);

    return (
        <Row>
            {userContext.isAdmin() && (
                <FormControl
                    fullWidth
                    sx={{
                        '& .MuiAutocomplete-root': {
                            width: '100%',
                        },
                    }}
                >
                    <AgencyAutocomplete size="small" value={criteria.agencyId} onChange={handleAgencyOnChange} />
                </FormControl>
            )}

            <FormControl
                fullWidth
                sx={{
                    '& .MuiAutocomplete-root': {
                        width: '100%',
                    },
                }}
            >
                <AdvertiserAutocomplete
                    size="small"
                    value={criteria.advertiserId}
                    criteria={advertiserCritieria}
                    onChange={handleAdvertiserOnChange}
                />
            </FormControl>

            <FormControl fullWidth>
                <HatCriteriaDataRangePicker
                    minDate={defaultMinDate}
                    maxDate={defaultMaxDate}
                    startDate={criteria.startDate}
                    endDate={criteria.endDate}
                    onChange={(startDate?: Date, endDate?: Date): void => {
                        const isFilled: boolean = startDate !== undefined && endDate !== undefined;
                        if (isFilled) {
                            onChangeCriteria({
                                ...criteria,
                                startDate: startDate ? startDate : criteria.startDate,
                                endDate: endDate ? endDate : criteria.endDate,
                            });
                        }
                    }}
                />
            </FormControl>
        </Row>
    );
}
