import { Box, Chip, Toolbar, Tooltip, Typography } from '@mui/material';
import {
    // GridActionsCellItem,
    gridClasses,
    GridColumns,
    // GridColumnHeaderTitle,
    GridToolbarExport,
    GridValueGetterParams,
    GridValueFormatterParams,
} from '@mui/x-data-grid';
import { GridToolbarContainer } from '../../components/DataGrid';
import { useCallback, useContext, useEffect, useState } from 'react';

import {
    Autorenew as AutorenewIcon,
    CancelOutlined as CancelOutlinedIcon,
    CheckCircleOutlined as CheckCircleOutlinedIcon,
    RefreshOutlined,
    SendOutlined as SendOutlinedIcon,
} from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Dealer } from '../../types/Dealer';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import { AdvertiserContext } from '../../AdvertiserContext';
import { USER_GROUP_ADMIN } from '../../types/User';
import { UserContext } from '../../App';
import CDGrid from '../../components/CDGrid';
import CDButton from '../../components/CDButton';
import Column from '../../components/Column';
import Row from '../../components/Row';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';

import AccessGuard from '../../components/AccessGuard';
import AgencySelector from '../../components/AgencySelector';
import DealerSelector from '../../components/DealerSelector';

import CRMDataUploadResultItemActions from './CRMDataUploadResultItemActions';
import CRMDataUploadSendToAmcDialog from './CRMDataUploadSendToAmcDialog';
import CRMDataUploadViewDialog from './CRMDataUploadViewDialog';

export default function CRMDataUploadResult(props: { agencyId?: number | null; dealer?: Dealer | null | undefined }) {
    const { agencyId, dealer } = props;
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [salesDataUploadList, setSalesDataUploadList] = useState<SalesDataUpload[] | null>(null);
    const { userContext } = useContext(UserContext);
    const isAdmin = userContext.isAdmin();
    const { advertiserContext, setAdvertiserContext, setDealer } = useContext(AdvertiserContext);
    const dealerSelected = useCallback(
        (dealerId, dealer: Dealer | null) => {
            setDealer(dealer === null ? new Dealer() : dealer);
        },
        [setDealer]
    );
    const [sendToAmcDialogOpen, setSendToAmcDialogOpen] = useState<boolean>(false);
    const [salesDataUpload, setSalesDataUpload] = useState<SalesDataUpload | null>(null);
    const [viewDialogOpen, setViewDialogOpen] = useState<boolean>(false);

    const refresh = useCallback(() => {
        setSalesDataUploadList(null);
        ApiService.getSalesDataUploadsByDealer(agencyId ?? null, dealer ? dealer.id : null)
            .then(function (response) {
                setSalesDataUploadList(response.data);
            })
            .catch(() => {
                setSalesDataUploadList([]);
            });
    }, [agencyId, dealer]);

    useEffect(() => {
        refresh();
    }, [agencyId, dealer, refresh]);

    useEffect(() => {
        if (params?.salesDataUploadId) {
            ApiService.getSalesDataUpload(parseInt(params.salesDataUploadId)).then((response) => {
                setSalesDataUpload(response.data);
                setViewDialogOpen(true);
            });
        }
    }, []);

    function handleSendToAMC(workflowId: number | null, campaignIds: number[]) {
        setSendToAmcDialogOpen(false);

        if (salesDataUpload) {
            salesDataUpload.workflowId = workflowId;
            salesDataUpload.campaignIds = campaignIds;

            ApiService.queueForAMC(salesDataUpload)
                .then(() => {
                    refresh();
                })
                .catch(() => {});
        }
    }

    const handleOpenSendToAmcDialog = () => {
        setSendToAmcDialogOpen(true);
    };

    const columns: GridColumns = [];

    if (location.pathname === '/crmUpload') {
        columns.push({
            flex: 0.75,
            minWidth: 150,
            field: 'dealerName',
            headerName: 'Advertiser',
            headerClassName: 'grid-header',
        });
    }

    columns.push(
        ...[
            {
                flex: 1,
                minWidth: 120,
                field: 'crmDataType',
                headerName: 'Data Type',
            },
            {
                flex: 1,
                minWidth: 200,
                field: 'dateRange',
                headerName: 'Date Range',
                valueGetter: (params: GridValueGetterParams) => {
                    const dateRanges: string[] = [];

                    if (params.row?.beginDate) {
                        dateRanges.push(Utils.formatDate(params.row.beginDate));
                    }

                    if (params.row?.endDate) {
                        dateRanges.push(Utils.formatDate(params.row.endDate));
                    }

                    return dateRanges.join(' - ');
                },
                valueFormatter: (params: GridValueFormatterParams) => {
                    return params.value;
                },
            },
            {
                flex: 1,
                minWidth: 270,
                field: 'originalFileName',
                headerName: 'File Name',
                headerClassName: 'grid-header',
            },
            {
                flex: 0.75,
                width: 120,
                field: 'dateUploaded',
                headerName: 'Uploaded On',
                valueFormatter: (params: GridValueFormatterParams) => {
                    return new Date(params.value as string).toLocaleDateString();
                },
            },
        ]
    );

    if (isAdmin) {
        columns.push({
            flex: 0.75,
            minWidth: 150,
            field: 'uploadedByName',
            headerName: 'Uploaded By',
            headerClassName: 'grid-header',
        });
    }

    columns.push(
        {
            flex: 0.5,
            minWidth: 150,
            field: 'status',
            headerName: 'Upload Status',
            valueGetter: (params) => {
                return params.row?.status;
            },
            renderCell: (params) => {
                let color: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
                let value = Utils.convertCase(params.value);

                switch (value) {
                    case 'Processing':
                        color = 'warning';
                        break;

                    case 'Pending':
                        color = 'warning';
                        break;

                    case 'Completed':
                    case 'Succeeded':
                    case 'Success':
                        color = 'success';
                        value = 'Completed';
                        break;

                    case 'Upload Failed':
                    case 'Failed':
                        color = 'error';
                        break;

                    case 'Rejected':
                        color = 'error';
                        break;

                    case 'Uploaded':
                        color = 'info';
                        break;

                    default:
                        color = 'info';
                        break;
                }

                if (['Failed', 'Upload Failed'].includes(value as string)) {
                    return (
                        <Tooltip title={params.row.errorMessage}>
                            <Chip label={value} size="small" variant="filled" color={color} />
                        </Tooltip>
                    );
                }

                return <Chip label={value} size="small" variant="filled" color={color} />;
            },
        },
        {
            flex: 0.75,
            width: 50,
            field: 'sendToAmc',
            headerName: 'AMC Status',
            type: 'actions',
            sortable: true,
            valueGetter: (params) => {
                switch (params.row?.status) {
                    case 'Failed':
                    case 'In progress':
                    case 'Pending':
                    case 'Running':
                    case 'Succeeded':
                    case 'Queued':
                    case 'Uploaded':
                        return 0;
                }

                return 1;
            },
            getActions: (params) => {
                let actions = [];
                if (isAdmin) {
                    let workflowExecutionStatus: string = Utils.convertCase(
                        params.row.workflowExecutionStatus
                    ) as string;

                    switch (workflowExecutionStatus) {
                        case 'Succeeded':
                            actions.push(
                                <Tooltip title="Data has been sent">
                                    <Chip
                                        label="Completed"
                                        icon={<CheckCircleOutlinedIcon fontSize="small" />}
                                        size="small"
                                        variant="filled"
                                        color="success"
                                    />
                                </Tooltip>
                            );
                            break;

                        case 'Failed':
                            actions.push(
                                <Tooltip title={`Send to AMC failed: ${params.row.errorMessage}`}>
                                    <Chip
                                        label={workflowExecutionStatus}
                                        icon={<CancelOutlinedIcon fontSize="small" />}
                                        size="small"
                                        variant="filled"
                                        color="error"
                                    />
                                </Tooltip>
                            );
                            break;

                        case 'Queued':
                        case 'Pending':
                        case 'In progress':
                        case 'Running':
                            actions.push(
                                <Chip
                                    label={workflowExecutionStatus}
                                    icon={<AutorenewIcon fontSize="small" />}
                                    size="small"
                                    variant="filled"
                                    color="warning"
                                />
                            );
                            break;

                        default:
                            if (params.row.amcInstanceId) {
                                if (!['Failed', 'Upload Failed'].includes(params.row.status as string)) {
                                    actions.push(
                                        <Tooltip title="Ready to send to AMC">
                                            <Chip
                                                label="Ready"
                                                icon={<SendOutlinedIcon fontSize="small" />}
                                                size="small"
                                                variant="filled"
                                                color="success"
                                                onClick={() => {
                                                    setSendToAmcDialogOpen(true);
                                                    setSalesDataUpload(params.row);
                                                }}
                                            />
                                        </Tooltip>
                                    );
                                }
                            } else {
                                actions.push(
                                    <Tooltip
                                        title={
                                            <>
                                                AMC Instance not setup for this Advertiser so you cannot run an
                                                Attribution report. Please contact{' '}
                                                <a href="mailto:support@cognitionads.com">support@cognitionads.com</a>{' '}
                                                for assistance.
                                            </>
                                        }
                                    >
                                        <Chip label="No AMC Instance" size="small" variant="filled" color="warning" />
                                    </Tooltip>
                                );
                            }
                            break;
                    }
                }
                return actions;
            },
        },
        {
            flex: 0.5,
            width: 50,
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            renderCell: (params) => {
                return (
                    <CRMDataUploadResultItemActions
                        refresh={refresh}
                        salesDataUpload={params.row}
                        handleOpenSendToAmcDialog={handleOpenSendToAmcDialog}
                    />
                );
            },
        }
    );

    if (isAdmin) {
        columns.push();
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <Row justifyContent="space-between" alignItems="center">
                    <Row gap={0} sx={{ width: '100%' }}>
                        <Typography component="span" color="text.primary" variant="subtitle2" fontWeight={500}>
                            Instruction:{' '}
                            <Typography component="span" color="text.secondary" variant="subtitle2" fontWeight={500}>
                                Send your first-party data to AMC to match with Amazon users.
                            </Typography>
                        </Typography>
                    </Row>

                    <Toolbar disableGutters>
                        <Row>
                            <CDButton variant="outlined" startIcon={<RefreshOutlined />} onClick={refresh}>
                                Refresh
                            </CDButton>
                            <GridToolbarExport
                                variant="outlined"
                                color="primary"
                                csvOptions={{
                                    fileName: 'PartitionUploadResults',
                                }}
                            />
                        </Row>
                    </Toolbar>
                </Row>
            </GridToolbarContainer>
        );
    }

    return (
        <Column>
            <Row>
                <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                    <Box>
                        <AgencySelector
                            variant="outlined"
                            label="Agency"
                            agencySelected={(agencyId) => {
                                setAdvertiserContext(null, agencyId);
                            }}
                            agencyId={advertiserContext.agencyId}
                        />
                    </Box>
                </AccessGuard>

                <Box>
                    <DealerSelector
                        variant="outlined"
                        label="Advertiser"
                        agencyId={advertiserContext.agencyId}
                        required={false}
                        dealerId={advertiserContext.dealer ? advertiserContext.dealer.id : null}
                        dealerSelected={dealerSelected}
                    />
                </Box>
            </Row>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                }}
            >
                <CDGrid
                    variant="contained"
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'status', sort: 'desc' }],
                        },
                    }}
                    autoHeight={true}
                    autoPageSize={true}
                    pageSize={10}
                    headerHeight={75}
                    rowHeight={75}
                    components={{ Toolbar: CustomToolbar }}
                    columns={columns}
                    rows={salesDataUploadList === null ? [] : salesDataUploadList}
                    onRowClick={(params) => {
                        if (params.row) {
                            setSalesDataUpload(params.row);
                            setViewDialogOpen(true);
                        }
                    }}
                    sx={{
                        [`& .${gridClasses.columnHeaderTitleContainer}`]: {
                            overflow: 'visible',
                        },
                    }}
                />
            </Box>

            {sendToAmcDialogOpen && salesDataUpload && (
                <CRMDataUploadSendToAmcDialog
                    dealerId={salesDataUpload.dealerId}
                    open={sendToAmcDialogOpen}
                    onCancel={() => setSendToAmcDialogOpen(false)}
                    onClose={handleSendToAMC}
                />
            )}

            {viewDialogOpen && salesDataUpload && (
                <CRMDataUploadViewDialog
                    salesDataUpload={salesDataUpload}
                    open={viewDialogOpen}
                    onClose={() => {
                        if (params?.salesDataUploadId) {
                            navigate('/crmUploadResults');
                        }
                        setViewDialogOpen(false);
                    }}
                    handleOpenSendToAmcDialog={handleOpenSendToAmcDialog}
                />
            )}
        </Column>
    );
}
