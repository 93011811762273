import { Dealer } from '../../../types/Dealer';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Alert,
    AlertProps,
    Box,
    CardContent,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    Tooltip,
    tooltipClasses,
    Typography,
} from '@mui/material';
import Utils from '../../../components/Utils';
import CreativeAssetsComponent from './CreativeAssetsComponent';
import { ContentCopyOutlined, EditOutlined, MailOutlined } from '@mui/icons-material';
import { CampaignCard } from './CampaignCard';
import { Campaign } from '../../../types/Campaign';
import AccessGuard from '../../../components/AccessGuard';
import { USER_GROUP_ADMIN } from '../../../types/User';
import AssignDspCampaignDialog from '../campaignDetailView/AssignDspCampaignDialog';
import ApiService from '../../../ApiService';
import theme from '../../../theme';
import { styled } from '@mui/styles';
import { TooltipProps } from '@mui/material/Tooltip';
import CDLink from '../../../components/CDLink';
import CDButton from '../../../components/CDButton';
import DuplicateCampaignsComponent from './DuplicateCampaignsComponent';
import Column from '../../../components/Column';
import { UserContext } from '../../../App';
import EditImpressionGoalDialog from '../campaignDetailView/EditImpressionGoalDialog';
import Row from '../../../components/Row';

import { useCampaignDetailsPageContext } from '../../../hooks/useCampaignDetailsPage';
import { useCampaignGateway } from '../../../hooks/useCampaignGateway';
import CampaignAudienceTypeSummary from '../../../components/Campaign/CampaignAudienceTypeSummary';
import CampaignBudgetCapSummary from '../../../components/Campaign/CampaignBudgetCapSummary';
import CampaignCreativeTypeSummary from '../../../components/Campaign/CampaignCreativeTypeSummary';
import CampaignFrequencySummary from '../../../components/Campaign/CampaignFrequencySummary';
import CampaignProductCategorySummary from '../../../components/Campaign/CampaignProductCategorySummary';

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 700,
    },
});

function TruncateText(props: { value: string; maxWidth: number; modified: boolean }) {
    const { value, maxWidth, modified } = props;
    if (value && value.startsWith('http')) {
        return <CDLink linkText={value} modified={modified} />;
    }
    if (value && value.length > maxWidth) {
        return (
            <>
                <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {value.substring(0, maxWidth)}
                </Box>
                <CustomWidthTooltip title={value}>
                    <span
                        style={{
                            cursor: 'pointer',
                            color: theme.palette.primary.main,
                        }}
                    >
                        {' '}
                        more...
                    </span>
                </CustomWidthTooltip>
            </>
        );
    } else {
        return <span>{value}</span>;
    }
}

function CopyToClipboard(props: { value: any }) {
    return (
        <Tooltip title="Copy to Clipboard">
            <IconButton
                onClick={() => {
                    navigator.clipboard.writeText(props.value);
                }}
            >
                <ContentCopyOutlined fontSize="small" />
            </IconButton>
        </Tooltip>
    );
}

function HighlightValueChange(props: { currentValue: any; newValue: any; modified?: boolean; allowCopy?: boolean }) {
    let { currentValue, newValue, modified, allowCopy } = props;

    if (modified === undefined) {
        modified = currentValue !== newValue;
    }
    if (!newValue) {
        return (
            <>
                <Grid item xs={9}>
                    <TruncateText value={currentValue} maxWidth={100} modified={false} />
                    {allowCopy && currentValue && <CopyToClipboard value={currentValue} />}
                </Grid>
            </>
        );
    }
    return (
        <>
            <Grid item xs={4} sx={{ textDecoration: 'none' }}>
                <TruncateText value={currentValue} maxWidth={50} modified={false} />
            </Grid>
            <Grid item xs={5} style={{ fontWeight: modified ? 600 : 500 }}>
                <TruncateText value={newValue} maxWidth={50} modified={modified} />
                {allowCopy && (
                    <Tooltip title="Copy to Clipboard">
                        <IconButton
                            onClick={() => {
                                navigator.clipboard.writeText(newValue);
                            }}
                        >
                            <ContentCopyOutlined fontSize="small" />
                        </IconButton>
                    </Tooltip>
                )}
            </Grid>
        </>
    );
}

function ZipCodeDisplay(props: { zipcodes?: string; modified: boolean }) {
    const { zipcodes, modified } = props;
    if (!zipcodes) {
        return <span></span>;
    }
    return (
        <>
            <Typography sx={{ fontWeight: modified ? 600 : 500 }}>
                {zipcodes.split(',').length}
                <Tooltip title="Copy to Clipboard">
                    <IconButton
                        onClick={() => {
                            navigator.clipboard.writeText(zipcodes);
                        }}
                    >
                        <ContentCopyOutlined fontSize="small" />
                    </IconButton>
                </Tooltip>
            </Typography>
        </>
    );
}

function CDLabel(props: { children: React.ReactElement | string }) {
    return (
        <Box
            sx={{
                color: theme.palette.text.secondary,
                fontWeight: 500,
            }}
        >
            {props.children}
        </Box>
    );
}

interface AlertContainerProps {
    severity: AlertProps['severity'];
    children: AlertProps['children'];
}

const AlertContainer = ({ severity, children }: AlertContainerProps) => {
    return (
        <Column>
            <Box sx={{ paddingBottom: '16px' }}>
                <Alert severity={severity}>
                    <p style={{ margin: 0 }}>{children}</p>
                </Alert>
            </Box>
        </Column>
    );
};

export default function CampaignSummaryV2(props: {
    campaign: Campaign;
    reloadCampaign(): void;
    dealer: Dealer | null;
    allowEdit: boolean;
    showDuplicates: boolean;
}) {
    const { campaign, reloadCampaign, showDuplicates } = props;

    const location = useLocation();
    const { campaignChangeRequest, setCampaignChangeRequest } = useCampaignDetailsPageContext();
    const { isCampaignGatewayUsed } = useCampaignGateway(campaign);

    const [assignDspCampaign, setAssignDspCampaign] = useState(false);
    const [editImpressionGoal, setEditImpressionGoal] = useState(false);
    const { userContext } = useContext(UserContext);
    const [duplicateCampaigns, setDuplicateCampaigns] = useState<Campaign[]>([]);
    const [wasRecentlyCreated, setWasRecentlyCreated] = useState<boolean>(location?.state?.wasRecentlyCreated ?? false);
    const [wasRecentlyUpdated, setWasRecentlyUpdated] = useState<boolean>(location?.state?.wasRecentlyUpdated ?? false);
    const [wasRecentlyCompleted, setWasRecentlyCompleted] = useState<boolean>(false);
    const [wasUpdateRecentlyCompleted, setWasUpdateRecentlyCompleted] = useState<boolean>(false);

    const resetWasRecentlyActions = (): void => {
        setWasRecentlyCreated(false);
        setWasRecentlyUpdated(false);

        setWasRecentlyCompleted(false);
        setWasUpdateRecentlyCompleted(false);
    };

    useEffect(() => {
        if (campaign.id) {
            ApiService.getCampaignChangeRequestPending(campaign.id).then((response) => {
                setCampaignChangeRequest(response.data);
            });
            if (showDuplicates) {
                ApiService.findDuplicateCampaigns(
                    campaign.id,
                    campaign.dealerId,
                    campaign.mediaType,
                    campaign.campaignType,
                    campaign.strategyType
                ).then((response) => {
                    setDuplicateCampaigns(response.data);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign]);

    let dspLink;
    if (campaign.deliveryStatusId.startsWith('PENDING')) {
        dspLink = (
            <Tooltip title={'Assign DSP Campaign ID'}>
                <IconButton
                    sx={{ padding: '4px', marginLeft: 1, marginBottom: '1px' }}
                    onClick={() => setAssignDspCampaign(true)}
                >
                    <EditOutlined fontSize="small" />
                </IconButton>
            </Tooltip>
        );
    }
    if (campaign.dspCampaignId) {
        dspLink = (
            <Tooltip title={'Go to DSP Campaign ' + campaign.dspCampaignId}>
                <Link
                    onClick={(event) => event.stopPropagation()}
                    href={
                        'https://advertising.amazon.com/dsp/ENTITY' +
                        campaign?.dspEntity?.entityId.substring(1) +
                        '/orders/' +
                        campaign.dspCampaignId +
                        '/edit'
                    }
                    target="_blank"
                    rel="noreferrer"
                >
                    {campaign.dspCampaignId}
                </Link>
            </Tooltip>
        );
    }

    const renderWasRecentlyCompletedAlert = () => {
        if (isCampaignGatewayUsed()) {
            return <AlertContainer severity="warning">Please manually create the campaign in the DSP.</AlertContainer>;
        }

        if (campaign.dspCampaignId || !campaign.error) {
            if (campaign.mediaType == 'Audio') {
                return (
                    <AlertContainer severity="warning">
                        Audio campaigns cannot be automatically created. Please manually create the campaign in the DSP.
                    </AlertContainer>
                );
            }

            if (campaign.gatewayAmazonDSPIsActive === 2) {
                <AlertContainer severity="success">
                    The campaign has been automatically updated in the DSP.
                </AlertContainer>;
            }

            return (
                <AlertContainer severity="warning">
                    You chose not to automatically create the campaign in the DSP. Please manually complete the campaign
                    setup.
                </AlertContainer>
            );
        }

        return (
            <AlertContainer severity="error">
                Campaign could not be automatically created. Please manually create the campaign in the DSP.
            </AlertContainer>
        );
    };

    const renderWasUpdateRecentlyCompleted = () => {
        if (isCampaignGatewayUsed()) {
            return (
                <AlertContainer severity="success">The campaign has been manually updated in the DSP.</AlertContainer>
            );
        }

        if (campaign.dspCampaignId || !campaign.error) {
            if (campaign.mediaType == 'Audio' || !campaign?.gatewayAmazonDSPPostEnabled) {
                return (
                    <AlertContainer severity="success">
                        The campaign has been manually updated in the DSP.
                    </AlertContainer>
                );

                return (
                    <AlertContainer severity="success">
                        The campaign has been automatically updated in the DSP.
                    </AlertContainer>
                );
            }
        }

        return (
            <AlertContainer severity="error">
                The campaign update failed. Please manually update the campaign in the DSP.
            </AlertContainer>
        );
    };

    const renderCampaignProcessingAlert = () => {
        if (!userContext.isAdmin()) {
            return null;
        }

        if (!campaign.gatewayAmazonDSPPostEnabled) {
            return null;
        }

        if (campaign.dspCampaignId && campaign.deliveryStatusId !== 'PENDING_COGNITION') {
            return null;
        }

        return (
            <AlertContainer severity="info">
                Campaign is still being processed in the Amazon DSP. We will update the DSP Campaign ID once this
                process is complete.
            </AlertContainer>
        );
    };

    const renderEmptyDspCampaignIdAlert = () => {
        if (campaign.dspCampaignId) {
            return null;
        }

        if (isCampaignGatewayUsed()) {
            return null;
        }

        if (campaign.gatewayAmazonDSPPostEnabled && campaign.deliveryStatusId !== 'PENDING_COGNITION') {
            return null;
        }

        return (
            <AlertContainer severity="warning">
                <strong>Note:</strong> DSP Campaign ID is not assigned. Automatic updates will not occur.
            </AlertContainer>
        );
    };

    return (
        <>
            {editImpressionGoal && (
                <EditImpressionGoalDialog
                    campaign={campaign}
                    onClose={(impressionGoal: number | null) => {
                        setEditImpressionGoal(false);
                        if (impressionGoal !== null) {
                            const updateCampaign = { ...campaign, impressionGoal: impressionGoal };
                            ApiService.updateCampaign(updateCampaign).then(() => {
                                reloadCampaign();
                            });
                        }
                    }}
                />
            )}

            {assignDspCampaign && (
                <AssignDspCampaignDialog
                    campaign={campaign}
                    onClose={(dspCampaignId: string | null) => {
                        setAssignDspCampaign(false);
                        if (dspCampaignId !== null) {
                            const updateCampaign = { ...campaign, dspCampaignId: dspCampaignId };
                            ApiService.updateCampaign(updateCampaign).then(() => {
                                reloadCampaign();
                            });
                        }
                    }}
                />
            )}

            <CampaignCard variant="outlined">
                <CardContent>
                    {wasRecentlyCreated && (
                        <AlertContainer severity="success">
                            Your campaign submission is pending for approval.
                        </AlertContainer>
                    )}
                    {wasRecentlyUpdated && (
                        <AlertContainer severity="success">
                            Your campaign change request is under review.
                        </AlertContainer>
                    )}
                    {wasRecentlyCompleted && renderWasRecentlyCompletedAlert()}
                    {wasUpdateRecentlyCompleted && renderWasUpdateRecentlyCompleted()}
                    {renderCampaignProcessingAlert()}
                    {renderEmptyDspCampaignIdAlert()}

                    {showDuplicates && campaign.deliveryStatusId === 'PENDING_COGNITION' && (
                        <Column>
                            <Box
                                sx={{
                                    paddingBottom: '16px',
                                }}
                            >
                                <DuplicateCampaignsComponent
                                    duplicateCampaigns={duplicateCampaigns}
                                    clearDuplicates={() => {}}
                                    showError={false}
                                    ignoreDuplicates={false}
                                    allowModify={false}
                                />
                            </Box>
                        </Column>
                    )}

                    <Grid container spacing={0.3}>
                        <Grid item xs={3}>
                            Campaign Info
                        </Grid>

                        {!campaign.hasUpdates && (
                            <>
                                <Grid item xs={9} />
                            </>
                        )}

                        {campaign.hasUpdates ? (
                            <>
                                <Grid item xs={4}>
                                    <CDLabel>Current</CDLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    <CDLabel>Pending</CDLabel>
                                </Grid>
                                <Grid item xs={1}>
                                    {userContext.isAdmin() && (
                                        <CDButton
                                            onClick={() => {
                                                resetWasRecentlyActions();
                                                if (campaign.id) {
                                                    ApiService.updateCampaignChangeRequestComplete(campaign.id).then(
                                                        () => {
                                                            reloadCampaign();
                                                            setWasUpdateRecentlyCompleted(true);
                                                        }
                                                    );
                                                }
                                            }}
                                        >
                                            Complete
                                        </CDButton>
                                    )}
                                </Grid>
                            </>
                        ) : (
                            <>
                                <Grid item xs={11} />
                                <Grid item xs={1}>
                                    {userContext.isAdmin() && campaign.deliveryStatusId === 'PENDING_COGNITION' && (
                                        <CDButton
                                            onClick={() => {
                                                resetWasRecentlyActions();
                                                if (campaign.id) {
                                                    ApiService.updateCampaignComplete(campaign.id).then(() => {
                                                        reloadCampaign();
                                                        setWasRecentlyCompleted(true);
                                                    });
                                                }
                                            }}
                                        >
                                            Complete
                                        </CDButton>
                                    )}
                                </Grid>
                            </>
                        )}

                        {campaign.id && (
                            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                <>
                                    <Grid item xs={3}>
                                        <CDLabel>DSP Campaign ID</CDLabel>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <span>{dspLink}</span>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CDLabel>PO Number</CDLabel>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <span>{campaign.poNumber}</span>
                                    </Grid>
                                    <Grid item xs={12}>
                                        &nbsp;
                                    </Grid>
                                </>
                            </AccessGuard>
                        )}

                        <Grid item xs={3}>
                            <CDLabel>Agency</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            {campaign.dealer?.agency?.name}
                        </Grid>
                        <Grid item xs={5}></Grid>
                        <Grid item xs={3}>
                            <CDLabel>Created By</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <Row>
                                {campaign.createdByUser?.name}{' '}
                                <Tooltip title={'Email ' + campaign.createdByUser?.emailAddress}>
                                    <a href={'mailto:' + campaign.createdByUser?.emailAddress}>
                                        <MailOutlined color="primary" fontSize="small" />{' '}
                                    </a>
                                </Tooltip>
                            </Row>
                        </Grid>

                        <Grid item xs={5}>
                            {campaignChangeRequest && (
                                <Row>
                                    {campaignChangeRequest?.createdByUser?.name}{' '}
                                    <Tooltip title={'Email ' + campaignChangeRequest?.createdByUser?.emailAddress}>
                                        <a href={'mailto:' + campaignChangeRequest?.createdByUser?.emailAddress}>
                                            <MailOutlined color="primary" fontSize="small" />{' '}
                                        </a>
                                    </Tooltip>
                                </Row>
                            )}
                        </Grid>
                        <Grid item xs={3}>
                            <CDLabel>Created On</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            {Utils.formatESTDateLong(campaign.createdDate)}
                        </Grid>
                        <Grid item xs={5}>
                            {Utils.formatESTDateLong(campaignChangeRequest?.createdDate)}
                        </Grid>

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Product Category</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CampaignProductCategorySummary campaign={campaign} />
                        </Grid>
                        <Grid item xs={5} />

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Start / End Date</CDLabel>
                        </Grid>
                        <HighlightValueChange
                            currentValue={
                                Utils.formatESTDateLong(campaign.startDate) +
                                ' - ' +
                                Utils.formatESTDateLong(campaign.endDate)
                            }
                            newValue={
                                campaignChangeRequest
                                    ? Utils.formatESTDateLong(campaignChangeRequest?.startDate) +
                                      ' - ' +
                                      Utils.formatESTDateLong(campaignChangeRequest?.endDate)
                                    : undefined
                            }
                            modified={
                                campaign.startDate !== campaignChangeRequest?.startDate ||
                                campaign.endDate !== campaignChangeRequest?.endDate
                            }
                        />

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Budget cap</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CampaignBudgetCapSummary campaign={campaign} />
                        </Grid>
                        <Grid item xs={5}>
                            {campaignChangeRequest && (
                                <CampaignBudgetCapSummary
                                    campaign={{
                                        ...campaign,
                                        budgetCapType: campaignChangeRequest?.budgetCapType,
                                        budgetCapAmount: campaignChangeRequest?.budgetCapAmount,
                                        budgetCapRecurrenceTimePeriod:
                                            campaignChangeRequest?.budgetCapRecurrenceTimePeriod,
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Frequency cap</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CampaignFrequencySummary campaign={campaign} />
                        </Grid>
                        <Grid item xs={5}>
                            {campaignChangeRequest && (
                                <CampaignFrequencySummary
                                    campaign={{
                                        ...campaign,
                                        frequencyMaxImpressions: campaignChangeRequest?.frequencyMaxImpressions,
                                        frequencyType: campaignChangeRequest?.frequencyType,
                                        frequencyTimeUnitCount: campaignChangeRequest?.frequencyTimeUnitCount,
                                        frequencyTimeUnit: campaignChangeRequest?.frequencyTimeUnit,
                                    }}
                                />
                            )}
                        </Grid>

                        {userContext.isAdmin() && (
                            <>
                                <Grid item xs={3}>
                                    <CDLabel>Fixed CPM</CDLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    {Utils.formatCurrency(campaign.campaignFee.agencyFixedCPM)}
                                </Grid>
                                <Grid item xs={5} />
                                <Grid item xs={3}>
                                    <CDLabel>Agency Incremental CPM</CDLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    {Utils.formatCurrency(campaign.campaignFee.agencyIncrementalCPM)}
                                </Grid>
                                <Grid item xs={5} />
                            </>
                        )}

                        <Grid item xs={3}>
                            <CDLabel>Budget</CDLabel>
                        </Grid>
                        <HighlightValueChange
                            currentValue={Utils.formatCurrency(campaign.budget)}
                            newValue={Utils.formatCurrency(campaignChangeRequest?.budget)}
                        />

                        <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                            <>
                                <Grid item xs={3}>
                                    <CDLabel>DSP Budget</CDLabel>
                                </Grid>
                                <HighlightValueChange
                                    currentValue={Utils.formatCurrency(campaign.dspBudget)}
                                    newValue={Utils.formatCurrency(campaignChangeRequest?.dspBudget)}
                                />
                            </>
                        </AccessGuard>

                        <Grid item xs={3}>
                            <CDLabel>Impression Goal</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            {Utils.formatNumber(campaign.impressionGoal)}
                            {!campaignChangeRequest && (
                                <Tooltip title={'Set Impression Goal'}>
                                    <IconButton
                                        sx={{ padding: '4px', marginLeft: 1, marginBottom: '1px' }}
                                        onClick={() => setEditImpressionGoal(true)}
                                    >
                                        <EditOutlined fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid
                            item
                            xs={5}
                            sx={{
                                fontWeight:
                                    campaign.impressionGoal !== campaignChangeRequest?.impressionGoal ? 600 : 500,
                            }}
                        >
                            {Utils.formatNumber(campaignChangeRequest?.impressionGoal)}
                        </Grid>

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={12}>
                            Geography Targeting
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Radius</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            {campaign.zipcodeRadius} Miles
                        </Grid>
                        <Grid item xs={5}>
                            {campaignChangeRequest && <>{campaignChangeRequest?.zipcodeRadius} Miles</>}
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Zip Codes</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <ZipCodeDisplay modified={false} zipcodes={campaign.zipcodes} />
                        </Grid>
                        <Grid item xs={5}>
                            <ZipCodeDisplay
                                modified={campaign?.zipcodes !== campaignChangeRequest?.zipcodes}
                                zipcodes={campaignChangeRequest?.zipcodes}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Additional Targeting</CDLabel>
                        </Grid>
                        <HighlightValueChange
                            currentValue={campaign.geographyTargeting}
                            newValue={campaignChangeRequest?.geographyTargeting}
                            allowCopy={true}
                        />

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>

                        <Grid item xs={12}>
                            Audience Targeting
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Audience Type</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CampaignAudienceTypeSummary campaign={campaign} />
                        </Grid>
                        <Grid item xs={5}>
                            {campaignChangeRequest && (
                                <CampaignAudienceTypeSummary
                                    campaign={{
                                        ...campaign,
                                        audienceType: campaignChangeRequest?.audienceType,
                                    }}
                                />
                            )}
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Audiences</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <List sx={{ pt: 0, pb: 0 }} dense>
                                <ListItem sx={{ p: 0 }}>In-Market Consumer</ListItem>
                                {campaign.audienceDemographic && <ListItem sx={{ p: 0 }}>Demographic</ListItem>}
                                {campaign.audienceLifeStageEvents && <ListItem sx={{ p: 0 }}>Life Stage</ListItem>}
                                {campaign.dataSets &&
                                    campaign.dataSets
                                        .filter((ds) => ds.audienceTargeting !== 'X')
                                        .map((dataSet) => (
                                            <ListItem sx={{ p: 0 }}>
                                                Custom Audience:{' '}
                                                {dataSet.audienceTargeting === 'I' ? 'Include ' : 'Exclude '}{' '}
                                                {dataSet.dataSetName}
                                            </ListItem>
                                        ))}
                            </List>
                        </Grid>
                        <Grid item xs={5}>
                            {campaignChangeRequest && (
                                <List sx={{ pt: 0, pb: 0 }} dense>
                                    <ListItem sx={{ p: 0 }}>In-Market Consumer</ListItem>
                                    {campaignChangeRequest.audienceDemographic && (
                                        <ListItem sx={{ p: 0 }}>Demographic</ListItem>
                                    )}
                                    {campaignChangeRequest.audienceLifeStageEvents && (
                                        <ListItem sx={{ p: 0 }}>Life Stage</ListItem>
                                    )}
                                    {campaignChangeRequest.dataSets &&
                                        campaignChangeRequest.dataSets
                                            .filter((ds) => ds.audienceTargeting !== 'X')
                                            .map((dataSet) => (
                                                <ListItem sx={{ p: 0 }}>
                                                    Custom Audience:{' '}
                                                    {dataSet.audienceTargeting === 'I' ? 'Include ' : 'Exclude '}{' '}
                                                    {dataSet.dataSetName}
                                                </ListItem>
                                            ))}
                                </List>
                            )}
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Keywords</CDLabel>
                        </Grid>
                        <HighlightValueChange
                            currentValue={campaign.audienceTargeting}
                            newValue={campaignChangeRequest?.audienceTargeting}
                        />

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={12}>
                            Creative
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Creative Type</CDLabel>
                        </Grid>
                        <Grid item xs={4}>
                            <CampaignCreativeTypeSummary campaign={campaign} />
                        </Grid>
                        <Grid item xs={5} />

                        {campaign.campaignType === 'Static' && (
                            <>
                                <Grid item xs={3}>
                                    <CDLabel>Asset Link</CDLabel>
                                </Grid>
                                <HighlightValueChange
                                    currentValue={campaign.creativeAssetLink}
                                    newValue={campaignChangeRequest?.creativeAssetLink}
                                />

                                <Grid item xs={3}>
                                    <CDLabel>Assets</CDLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    <CreativeAssetsComponent
                                        viewOnly={true}
                                        campaign={campaign}
                                        onFilesChanged={(files) => {}}
                                        maxFileSize={0}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    {campaignChangeRequest && (
                                        <CreativeAssetsComponent
                                            viewOnly={true}
                                            campaign={campaignChangeRequest}
                                            onFilesChanged={(files) => {}}
                                            maxFileSize={0}
                                        />
                                    )}
                                </Grid>
                            </>
                        )}

                        <Grid item xs={12}>
                            &nbsp;
                        </Grid>
                        <Grid item xs={12}>
                            Additional Information
                        </Grid>

                        <Grid item xs={3}>
                            <CDLabel>Email for Approval</CDLabel>
                        </Grid>
                        <HighlightValueChange
                            currentValue={campaign.emailForApproval}
                            newValue={campaignChangeRequest?.emailForApproval}
                        />

                        <Grid item xs={3}>
                            <CDLabel>Call to Action</CDLabel>
                        </Grid>
                        <HighlightValueChange
                            currentValue={campaign.callToActions}
                            newValue={campaignChangeRequest?.callToActions}
                        />

                        <Grid item xs={3}>
                            <CDLabel>Landing Page</CDLabel>
                        </Grid>
                        <HighlightValueChange
                            currentValue={campaign.landingPage}
                            newValue={campaignChangeRequest?.landingPage}
                        />

                        <Grid item xs={3}>
                            <CDLabel>Inventory Provider</CDLabel>
                        </Grid>

                        <HighlightValueChange
                            currentValue={campaign.inventoryProvider}
                            newValue={campaignChangeRequest?.inventoryProvider}
                        />

                        <Grid item xs={3}>
                            <CDLabel>Additional Comments</CDLabel>
                        </Grid>
                        <HighlightValueChange
                            currentValue={campaign.additionalComments}
                            newValue={campaignChangeRequest?.additionalComments}
                        />
                    </Grid>
                </CardContent>
            </CampaignCard>
        </>
    );
}
