import { useContext } from 'react';
import { AdvertiserContext, AdvertiserContextValue } from '../../AdvertiserContext';
import { Dealer as Advertiser } from '../../types/Dealer';

interface AdvertiserContextState {
    advertiserContext: AdvertiserContextValue;
    setDealer: (dealer: Advertiser | null) => void;
    setAdvertiserContext: (dealer: Advertiser | null, agencyId: number | null) => void;
}

const useAdvertiserContext = (): AdvertiserContextState => {
    return useContext(AdvertiserContext);
};

export type { AdvertiserContext, AdvertiserContextValue };
export type { AdvertiserContextState };
export { useAdvertiserContext };
