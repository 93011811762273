import { Alert, Chip, Stack, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';

import { Agency } from '../../types/Agency';
import { SalesDataUpload } from '../../types/SalesDataUpload';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import Row from '../../components/Row';
import { TabsContainer } from '../../components/Tabs';
import TabPanel from '../../components/TabPanel';
import { PrintContext } from '../../hooks/usePrint';
import usePrint from '../../hooks/usePrint';
import ApiService from '../../ApiService';
import Utils from '../../components/Utils';

import { TABS as DATA_TABS } from './PolkMatchbackDataTabs';
import { AttributionPageProvider, TABS } from '../../hooks/useAttributionPage';
import { useAttributionPage } from '../../hooks/useAttributionPage';
import AdvertiserMatchbackSetup from './AdvertiserMatchbackSetup';
import AdvertiserMatchbackTab from './AdvertiserMatchbackTab';
import AttributionCriteriaForm from './AttributionCriteriaForm';
import PolkMatchbackSetup from './PolkMatchbackSetup';
import PolkMatchbackTab from './PolkMatchbackTab';

export default function AttributionPage() {
    const [agency, setAgency] = useState<Agency | null>(null);

    const print = usePrint();

    const attributionPage = useAttributionPage();
    const {
        tab,
        setTab,
        advertiser,
        advertisers,
        hasAdvertiser,
        setAdvertiserSalesUpdateDate,
        polkDealerDealers,
        fetchPolkDealerDealers,

        attributionCriteria,
        setAttributionCriteria,
    } = attributionPage;

    const [salesDataUploads, setSalesDataUploads] = useState<SalesDataUpload[]>([]);

    const isPolkMatchbackSetup = useMemo(() => {
        if (advertisers) {
            if (advertisers.length <= 1) {
                if (polkDealerDealers.length > 0) {
                    return true;
                }
                return agency && agency?.polkSalesIsActive;
            }
            return true;
        }
        return false;
    }, [advertisers, agency, polkDealerDealers]);

    const isAdvertiserMatchbackSetup = useMemo(() => {
        if (advertisers) {
            if (advertisers.length <= 1) {
                return salesDataUploads.length > 0;
            }
            return true;
        }
        return false;
    }, [advertisers, salesDataUploads]);

    function selectTab(_event: SyntheticEvent, value: any) {
        switch (value) {
            case TABS.ADVERTISER_REPORTED_SALES:
                localStorage.setItem('attribution.polkDataTab', DATA_TABS.CAMPAIGN_PERFORMANCE as any as string);
                localStorage.setItem('attribution.advertiserView', 'chart');
                break;

            case TABS.POLK_REPORTED_SALES:
                localStorage.setItem('attribution.polkDataTab', DATA_TABS.SALES_ANALYSIS as any as string);
                localStorage.setItem('attribution.polkView', 'chart');
                break;
        }

        setTab(value);
    }

    useEffect(() => {
        fetchPolkDealerDealers();
    }, [fetchPolkDealerDealers]);

    useEffect(() => {
        if (advertiser?.id) {
            ApiService.getSalesDataUploadsByDealer(null, advertiser.id).then((response) => {
                setSalesDataUploads(response.data);
            });
        } else {
            setSalesDataUploads([]);
        }
    }, [advertiser]);

    useEffect(() => {
        if (advertiser !== null && (agency === null || advertiser.agencyId !== agency.id)) {
            if (advertiser?.agencyId) {
                ApiService.getAgency(advertiser.agencyId).then((response) => {
                    setAgency(response.data);
                });
            }
        }
    }, [agency, advertiser]);

    useEffect(() => {
        if (salesDataUploads && salesDataUploads.length) {
            setAdvertiserSalesUpdateDate(Utils.getDate(salesDataUploads[0].dateUploaded));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [salesDataUploads]);

    return (
        <PrintContext.Provider value={print}>
            <AttributionPageProvider value={attributionPage}>
                <Column gap={0} flex={1}>
                    <Block>
                        <Column gap={1}>
                            <Row spacing={2} justifyContent="space-between" alignItems="center">
                                <Heading>Attribution</Heading>

                                <Row justifyContent="flex-end"></Row>
                            </Row>
                        </Column>
                    </Block>

                    <Block py={0}>
                        <AttributionCriteriaForm
                            criteria={attributionCriteria}
                            onCriteriaChange={setAttributionCriteria}
                        />

                        {!hasAdvertiser && (
                            <>
                                <Alert severity="info" sx={{ my: 2 }}>
                                    Select an Advertiser to view the available Attribution data.
                                </Alert>
                            </>
                        )}
                    </Block>

                    {hasAdvertiser && (
                        <Block disableGutters>
                            <TabsContainer sx={{ mt: 2 }}>
                                <Tabs value={tab} onChange={selectTab}>
                                    <Tab
                                        value={TABS.POLK_REPORTED_SALES}
                                        label={
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <span>Reported Sales</span>
                                                <Chip
                                                    variant="outlined"
                                                    label="New"
                                                    size="small"
                                                    color="success"
                                                    sx={{ backgroundColor: '#EDF7ED' }}
                                                />
                                            </Stack>
                                        }
                                    />
                                    <Tab
                                        value={TABS.ADVERTISER_REPORTED_SALES}
                                        label={
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <span>Advertiser Reported Sales</span>
                                                <Chip
                                                    variant="outlined"
                                                    label="New"
                                                    size="small"
                                                    color="success"
                                                    sx={{ backgroundColor: '#EDF7ED' }}
                                                />
                                            </Stack>
                                        }
                                    />
                                </Tabs>
                            </TabsContainer>
                        </Block>
                    )}

                    {hasAdvertiser && (
                        <Block>
                            <TabPanel index={TABS.POLK_REPORTED_SALES} value={tab} sx={{ p: 0 }}>
                                {isPolkMatchbackSetup === true && <PolkMatchbackTab />}
                                {isPolkMatchbackSetup === false && advertiser !== null && (
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{ mt: 9 }}
                                    >
                                        <PolkMatchbackSetup dealer={advertiser} />
                                    </Stack>
                                )}
                            </TabPanel>

                            <TabPanel index={TABS.ADVERTISER_REPORTED_SALES} value={tab} sx={{ p: 0 }}>
                                {isAdvertiserMatchbackSetup === true && <AdvertiserMatchbackTab />}
                                {isAdvertiserMatchbackSetup === false && advertiser !== null && (
                                    <Stack
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{ mt: 9 }}
                                    >
                                        <AdvertiserMatchbackSetup dealer={advertiser} />
                                    </Stack>
                                )}
                            </TabPanel>
                        </Block>
                    )}
                </Column>
            </AttributionPageProvider>
        </PrintContext.Provider>
    );
}
