import { Box, BoxProps } from '@mui/material';

interface TabsContainerProps extends BoxProps {
    disableBorders?: boolean;
    disableGutters?: boolean;
}

const TabsContainer = ({ disableBorders = false, disableGutters = false, sx = {}, ...props }: TabsContainerProps) => {
    return (
        <Box
            {...props}
            sx={{
                borderBottom: disableBorders ? 0 : 1,
                borderColor: 'divider',
                px: disableGutters ? 0 : 3,
                ...sx,
            }}
        />
    );
};

export type { TabsContainerProps };
export { TabsContainer };
