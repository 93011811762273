import { useEffect, useMemo, useState } from 'react';

interface FilterResources<T> {
    (resources: T[], resourceListCriteria: ResourceListCriteria): T[];
}

type ResourceListCriteria<T = {}> = {
    searchText: string;
    agencyId: number | null;
    advertiserId: number | null;
} & T;

interface UseResourceListFilterState<T> {
    filteredResources: T[];
    resourceListCriteria: ResourceListCriteria;
    setResourceListCriteria: (resourceListCriteria: ResourceListCriteria) => void;
    resetResourceListCriteria: () => void;
    resources: T[];
    setResources: (resources: T[]) => void;
    filterResources: FilterResources<T> | null;
}

const initialResourceListCriteriaState: ResourceListCriteria = {
    searchText: '',
    agencyId: null,
    advertiserId: null,
};

interface UseResourceListFilterProps<T> {
    initialResources: T[];
    initialResourceListCriteria: ResourceListCriteria;
    filterResources: FilterResources<T> | null;
}

const useResourceListFilter = <T,>(props: UseResourceListFilterProps<T>): UseResourceListFilterState<T> => {
    const {
        initialResources = [],
        initialResourceListCriteria = initialResourceListCriteriaState,
        filterResources = null,
    } = props;

    const [resources, setResources] = useState<T[]>(initialResources);
    const [resourceListCriteria, setResourceListCriteria] = useState<ResourceListCriteria>(initialResourceListCriteria);

    const filteredResources = useMemo((): T[] => {
        if (filterResources) {
            return filterResources(resources, resourceListCriteria);
        }

        return resources;
    }, [filterResources, resources, resourceListCriteria]);

    const resetResourceListCriteria = (): void => {
        setResourceListCriteria(initialResourceListCriteriaState);
    };

    useEffect(() => {
        setResources(initialResources);
    }, [initialResources]);

    return {
        filteredResources,
        resourceListCriteria,
        setResourceListCriteria,
        resetResourceListCriteria,
        resources,
        setResources,
        filterResources,
    };
};

export type {
    initialResourceListCriteriaState,
    UseResourceListFilterState,
    UseResourceListFilterProps,
    ResourceListCriteria,
};
export { useResourceListFilter };
