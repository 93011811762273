import { RadioSelectProps, RadioSelectOption } from '../RadioSelect';
import RadioSelect from '../RadioSelect';

type ActiveStatusRadioSelectProps = Omit<RadioSelectProps, 'options'> & {
    options?: RadioSelectOption[];
};

const ActiveStatusRadioSelect = (props: ActiveStatusRadioSelectProps) => {
    const {
        options = [
            { value: 'all', label: 'All' },
            { value: 'active', label: 'Active' },
            { value: 'inactive', label: 'Inactive' },
        ],
        ...radioSelectProps
    } = props;

    return (
        <RadioSelect
            aria-label="status"
            name="status"
            options={options}
            slotProps={{
                formControl: { sx: { width: 120 } },
            }}
            {...radioSelectProps}
        />
    );
};

export type { ActiveStatusRadioSelectProps };
export default ActiveStatusRadioSelect;
