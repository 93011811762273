import { Box, Tooltip } from '@mui/material';
import { LoadingButton as BaseLoadingButton, LoadingButtonProps as BaseLoadingButtonProps } from '@mui/lab';

interface LoadingButtonProps extends BaseLoadingButtonProps {
    tooltip?: string;
}

const LoadingButton = ({ tooltip = '', ...props }: LoadingButtonProps): JSX.Element => {
    return (
        <Tooltip title={tooltip ? tooltip : ''}>
            <Box component="span">
                <BaseLoadingButton variant="outlined" color="primary" size="medium" disableFocusRipple {...props} />
            </Box>
        </Tooltip>
    );
};

export type { LoadingButtonProps };
export default LoadingButton;
