import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../App';
import ApiService from '../../ApiService';
import { Campaign, CampaignCriteria, CampaignStatusCount } from '../../types/Campaign';
import Utils from '../../components/Utils';
import { CircularProgress, Grid, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import CampaignStatusFilter from './CampaignStatusFilter';
import { ClearOutlined, SearchOutlined } from '@mui/icons-material';
import CampaignManagerGrid from './CampaignManagerGrid';
import CampaignManagerDataGridExportCsvButton from './CampaignManagerDataGridExportCsvButton';
import CampaignMonthSelect from './CampaignMonthSelect';
import CampaignIncludePendingSwitch from './CampaignIncludePendingSwitch';
import { AdvertiserContext } from '../../AdvertiserContext';
import { useNavigate } from 'react-router-dom';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import Row from '../../components/Row';
import theme from '../../theme';
import { CampaignManagerPageContext } from '../../hooks/useCampaignManagerPage';
import useCampaignManagerPage from '../../hooks/useCampaignManagerPage';
import AccessGuard from '../../components/AccessGuard';
import { Dealer } from '../../types/Dealer';
import { USER_GROUP_ADMIN, USER_GROUP_AGENCY } from '../../types/User';
import AgencySelector from '../../components/AgencySelector';
import DealerSelector from '../../components/DealerSelector';

const PAGE = 'campaignManager.';
const PAGE_SIZE = 10;

export default function CampaignManagerPage() {
    const navigate = useNavigate();

    const campaignManagerPage = useCampaignManagerPage();
    const { setCampaignGridColumnVisibility } = campaignManagerPage;

    const [campaigns, setCampaigns] = useState<Campaign[]>([]);
    const [campaignStatusCounts, setCampaignStatusCounts] = useState<CampaignStatusCount[]>([]);
    const { userContext } = useContext(UserContext);
    const { advertiserContext, setAdvertiserContext, setDealer } = useContext(AdvertiserContext);

    const [campaignCriteria, setCampaignCriteria] = useState<CampaignCriteria>(() => {
        let criteria = Utils.localStorage(PAGE + 'campaignCriteria', null);
        if (criteria) {
            let value = JSON.parse(criteria);
            value.startDate = new Date(value.startDate);
            value.endDate = new Date(value.endDate);
            return value;
        }
        return {
            startDate: Utils.getMonthStart(0),
            endDate: Utils.getMonthEnd(0),
            deliveryStatusId: '',
            includePendingCampaigns: false,
        };
    });

    const dealerSelected = useCallback(
        (dealerId, dealer: Dealer | null) => {
            setDealer(dealer === null ? new Dealer() : dealer);
        },
        [setDealer]
    );

    useEffect(() => {
        const criteria = {
            ...campaignCriteria,
            startDate: campaignCriteria.startDate.getTime(),
            endDate: campaignCriteria.endDate.getTime(),
        };
        const value = JSON.stringify(criteria);
        localStorage.setItem(PAGE + 'campaignCriteria', value);
    }, [campaignCriteria]);

    const prevCampaignCriteria = useRef(campaignCriteria);
    useEffect(() => {
        campaignCriteria.dealerIds =
            advertiserContext?.dealer && advertiserContext.dealer.id ? [advertiserContext.dealer.id] : null;
        campaignCriteria.agencyId = advertiserContext?.agencyId ? advertiserContext.agencyId : undefined;
        campaignCriteria.pageSize = PAGE_SIZE;

        ApiService.getCampaigns(campaignCriteria).then((response) => {
            setCampaigns(response.data);
        });

        const ignoredKeys = ['page', 'deliveryStatusId', 'sort'];
        const ignoredChangesOnly =
            ignoredKeys.some((key) => prevCampaignCriteria.current[key] !== campaignCriteria[key]) &&
            Object.keys(prevCampaignCriteria.current).every(
                (key) => ignoredKeys.includes(key) || prevCampaignCriteria.current[key] === campaignCriteria[key]
            );

        if (!ignoredChangesOnly) {
            ApiService.getCampaignStatusCounts(campaignCriteria).then((response) => {
                const data = response.data;

                const roleStatusCounts = userContext.isAdmin()
                    ? data
                    : data.filter((item) => item.statusId !== 'PENDING_COGNITION');

                setCampaignStatusCounts(roleStatusCounts);
            });
        }
        prevCampaignCriteria.current = campaignCriteria;
    }, [advertiserContext.dealer, advertiserContext.agencyId, campaignCriteria]);

    const [searchText, setSearchText] = useState(campaignCriteria.searchText);
    const [searchTextLoading, setSearchTextLoading] = useState(false);

    const debounce = (func: (...args: any[]) => any, delay: number) => {
        let timeoutId: any;
        return (...args: any[]) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedChangeSearchCriteria = useRef(
        debounce((campaignCriteria, value) => {
            setSearchTextLoading(false);
            changeSearchCriteria({ ...campaignCriteria, searchText: value, deliveryStatusId: '' });
        }, 1000)
    ).current;

    useEffect(() => {
        if (searchText === campaignCriteria.searchText) return;
        setSearchTextLoading(true);
        debouncedChangeSearchCriteria(campaignCriteria, searchText);
    }, [searchText]);

    function changeSearchCriteria(campaignCriteria: CampaignCriteria) {
        setCampaignCriteria({ ...campaignCriteria });
    }

    function clearSearch() {
        setSearchText('');
        localStorage.setItem(PAGE + 'campaignSearch', '');
    }

    function onPageChange(gridPage: number) {
        if (gridPage === campaignCriteria.page) return;
        setCampaignCriteria({ ...campaignCriteria, page: gridPage });
    }

    function onSortModelChange([order]: any) {
        if (!order) order = { field: 'dealer', sort: 'asc' };
        let { field, sort } = order;
        if (field === 'dealer') field = 'dealerName';
        if (field === 'deliveryStatus') field = 'deliveryStatusId';
        if (field === 'agency') field = 'agencyName';
        if (field === 'createdByUser') field = 'createdByUserName';
        setCampaignCriteria({ ...campaignCriteria, sort: `${field} ${sort}` });
    }

    const getSearchCampaignTooltip = (): React.ReactNode => {
        let tooltip: string = 'Search by Campaign Name.';

        if (userContext.isAdmin()) {
            tooltip = 'Search by DSP, Campaign ID, Advertiser, or Campaign Name.';
        }

        if (userContext.isAgency()) {
            tooltip = 'Search by Advertiser or Campaign Name.';
        }

        return <>{tooltip}</>;
    };

    return (
        <CampaignManagerPageContext.Provider value={campaignManagerPage}>
            <Column gap={0} flex={1}>
                <Block>
                    <Column gap={1}>
                        <Row spacing={2} justifyContent="space-between" alignItems="center">
                            <Heading>Campaigns</Heading>

                            <Row justifyContent="flex-end">
                                <CampaignManagerDataGridExportCsvButton campaignCriteria={campaignCriteria} />
                            </Row>
                        </Row>
                    </Column>
                </Block>

                <Block py={0}>
                    <Row gap={2} justifyContent="space-between" alignItems="flex-start">
                        <Grid container spacing={2}>
                            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                <Grid item xl={3} md={6} xs={12}>
                                    <AgencySelector
                                        variant="outlined"
                                        label="Agency"
                                        agencySelected={(agencyId) => {
                                            setAdvertiserContext(null, agencyId);
                                        }}
                                        agencyId={advertiserContext.agencyId}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            </AccessGuard>

                            <AccessGuard accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}>
                                <Grid item xl={3} md={6} xs={12}>
                                    <DealerSelector
                                        variant="outlined"
                                        label="Advertiser"
                                        agencyId={advertiserContext.agencyId}
                                        required={false}
                                        dealerId={advertiserContext.dealer ? advertiserContext.dealer.id : null}
                                        dealerSelected={dealerSelected}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            </AccessGuard>

                            <Grid item xl={3} md={6} xs={12}>
                                <CampaignMonthSelect
                                    campaignCriteria={campaignCriteria}
                                    changeSearchCriteria={(criteria: any) => changeSearchCriteria({ ...criteria })}
                                />
                            </Grid>

                            <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                                <Grid item xl={3} md={6} xs={12}>
                                    <CampaignIncludePendingSwitch
                                        campaignCriteria={campaignCriteria}
                                        changeSearchCriteria={(criteria: any) => changeSearchCriteria({ ...criteria })}
                                    />
                                </Grid>
                            </AccessGuard>
                        </Grid>

                        <TextField
                            sx={{
                                height: 40,
                                width: 320,
                                '& .MuiInputBase-input': {
                                    backgroundColor: campaignCriteria.searchText ? '#F2F5FF' : '',
                                    color: theme.palette.text.secondary,
                                    fontSize: theme.typography.fontSize,
                                    height: '1.64375em',

                                    '&::placeholder': {
                                        color: theme.palette.text.primary,
                                    },
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: campaignCriteria.searchText
                                        ? `${theme.palette.primary.main} !important`
                                        : '',
                                },
                            }}
                            id="searchText"
                            value={searchText}
                            autoComplete="off"
                            size="small"
                            label="Search Campaigns"
                            onChange={(event) => {
                                setSearchText(event.target.value);
                            }}
                            InputProps={{
                                startAdornment:
                                    campaignCriteria.searchText?.length > 0 ? (
                                        ''
                                    ) : (
                                        <InputAdornment position="start">
                                            <Tooltip title={getSearchCampaignTooltip()}>
                                                <SearchOutlined fontSize="small" color="action" />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                endAdornment: searchTextLoading ? (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => clearSearch()} edge="end">
                                            <CircularProgress color="inherit" size={20} />
                                        </IconButton>
                                    </InputAdornment>
                                ) : campaignCriteria.searchText?.length > 0 ? (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => clearSearch()} edge="end">
                                            <ClearOutlined fontSize="small" color="action" />
                                        </IconButton>
                                    </InputAdornment>
                                ) : (
                                    ''
                                ),
                            }}
                        />
                    </Row>
                </Block>

                <Block flex={1}>
                    <Column sx={{ height: '100%' }}>
                        <CampaignStatusFilter
                            campaignStatusCounts={campaignStatusCounts}
                            selectedStatus={campaignCriteria.deliveryStatusId}
                            onClick={(value: string) => {
                                changeSearchCriteria({
                                    ...campaignCriteria,
                                    deliveryStatusId: value,
                                });
                            }}
                        />

                        <CampaignManagerGrid
                            campaigns={campaigns}
                            totalCampaigns={
                                campaignStatusCounts.find((c) => c.statusId === campaignCriteria.deliveryStatusId)
                                    ?.count || 0
                            }
                            currentPage={campaignCriteria.page}
                            campaignCriteria={campaignCriteria}
                            deliveryStatusId={campaignCriteria.deliveryStatusId}
                            onPageChange={onPageChange}
                            onSortModelChange={onSortModelChange}
                            onShowDetails={(campaign: Campaign) => {
                                navigate('' + campaign.id);
                            }}
                            onGridColumnVisibilityChange={(gridColumnVisibility) => {
                                setCampaignGridColumnVisibility(gridColumnVisibility);
                            }}
                        />
                    </Column>
                </Block>
            </Column>
        </CampaignManagerPageContext.Provider>
    );
}
