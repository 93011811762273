import { styled } from '@mui/material';
import { ButtonGroup as BaseButtonGroup } from '@mui/material';

const ButtonGroup = styled(BaseButtonGroup)(({ theme }) => ({
    '& .MuiButton-root': {
        borderColor: theme.palette.divider,
        height: 40,
        width: 40,
    },
    '& .MuiButton-root:hover': {
        borderColor: theme.palette.action.selected,
    },
    '& .MuiButton-root .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
    '& .MuiButton-selected': {
        backgroundColor: theme.palette.action.selected,
    },
    '& .MuiButton-selected .MuiSvgIcon-root': {
        color: theme.palette.text.primary,
    },
    '& .MuiButtonGroup-grouped:not(:last-of-type):hover': {
        borderRightColor: theme.palette.divider,
    },
}));

export default ButtonGroup;
