import { Box, Divider } from '@mui/material';

import Column from './Column';
import Row from './Row';
// import SidebarFooter from './Sidebar/SidebarFooter';
import SidebarHeader from './Sidebar/SidebarHeader';
import SidebarNavigation from './Sidebar/SidebarNavigation';

const AppSidebar = () => {
    return (
        <Row
            gap={0}
            sx={{
                height: '100%',
            }}
        >
            <Column gap={0} justifyContent="space-between">
                <SidebarHeader />

                <Divider />

                <Box
                    sx={{
                        height: '100%',
                        overflowY: 'auto',
                    }}
                >
                    <SidebarNavigation />
                </Box>

                {/*<SidebarFooter />*/}
            </Column>

            <Divider orientation="vertical" />
        </Row>
    );
};

export default AppSidebar;
