import { useContext, useEffect } from 'react';
import { AdvertiserContext } from '../../../AdvertiserContext';
import { Dealer as Advertiser } from '../../../types/Dealer';
import { USER_GROUP_ADMIN, USER_GROUP_AGENCY } from '../../../types/User';

import AccessGuard from '../../../components/AccessGuard';
import AgencySelector from '../../../components/AgencySelector';
import DealerSelector from '../../../components/DealerSelector';
import Row from '../../../components/Row';

interface AmcPageFilterProps<T> {
    criteria: T;
    setCriteria: (criteria: T) => void;
}

const AmcPageFilter = <T extends { agencyId: number | null; advertiserId: number | null }>(
    props: AmcPageFilterProps<T>
) => {
    const { criteria, setCriteria } = props;
    const { advertiserContext, setAdvertiserContext } = useContext(AdvertiserContext);

    useEffect(() => {
        if (advertiserContext) {
            setCriteria({
                ...criteria,
                agencyId: advertiserContext.agencyId || null,
                advertiserId: advertiserContext.dealer?.id || null,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertiserContext]);

    return (
        <Row justifyContent="space-between">
            <Row>
                <AccessGuard accessGroup={`${USER_GROUP_ADMIN}`}>
                    <AgencySelector
                        required={false}
                        variant="outlined"
                        label="Agency"
                        agencySelected={(agencyId: number | null) => {
                            // setCriteria({ ...criteria, agencyId: agencyId, advertiserId: null });
                            setAdvertiserContext(null, agencyId);
                        }}
                        agencyId={criteria.agencyId}
                        sx={{ width: 224 }}
                    />
                </AccessGuard>

                <AccessGuard accessGroup={`${USER_GROUP_ADMIN},${USER_GROUP_AGENCY}`}>
                    <DealerSelector
                        required={false}
                        variant="outlined"
                        label="Advertiser"
                        agencyId={criteria?.agencyId || null}
                        dealerId={criteria?.advertiserId || null}
                        dealerSelected={(advertiserId: number | null, advertiser: Advertiser | null) => {
                            // setCriteria({ ...criteria, advertiserId: advertiserId });
                            setAdvertiserContext(advertiser, criteria.agencyId);
                        }}
                        sx={{ width: 224 }}
                        showLabel={true}
                    />
                </AccessGuard>
            </Row>
        </Row>
    );
};

export type { AmcPageFilterProps };
export default AmcPageFilter;
