import { useCallback, useContext, useMemo } from 'react';
import { FormControl } from '@mui/material';

import { Agency } from '../../../../types/Agency';
import { Dealer as Advertiser } from '../../../../types/Dealer';
import { UserContext } from '../../../../App';
import { ReportingCriteria } from '../../../../types/Criteria/ReportingCriteria';
import { useReportingDashboardPageContext } from '../../../../hooks/useReportingDashboardPage';
import AdvertiserAutocomplete from '../../../../components/AdvertiserAutocomplete';
import AgencyAutocomplete from '../../../../components/AgencyAutocomplete';
import Row from '../../../../components/Row';
import ReportingCampaignStrategyTypeSelect from './ReportingCampaignStrategyTypeSelect';
import ReportingCriteriaDataRangePicker from './ReportingCriteriaDataRangePicker';

type ReportingCriteriaFormProps = {
    reportingCriteria: ReportingCriteria;
    onChangeReportingCriteria: (reportingCriteria: ReportingCriteria) => void;
    onFetchAdvertisers?: (advertisers: Advertiser[]) => void;
    onFetchingAdvertisers?: () => void;
};

export default function ReportingCriteriaForm(props: ReportingCriteriaFormProps) {
    const { reportingCriteria, onChangeReportingCriteria, onFetchAdvertisers, onFetchingAdvertisers } = props;

    const { userContext } = useContext(UserContext);
    const {
        activeStrategyTypes,
        defaultMinDate,
        defaultMaxDate,
        isReportingCriteriaSetup,
        setIsReportingCriteriaSetup,
    } = useReportingDashboardPageContext();

    const handleAgencyOnChange = useCallback(
        (agencies: Agency[]): void => {
            onChangeReportingCriteria({
                ...reportingCriteria,
                agencyIds: agencies.map((agency: Agency) => agency.id as number),
            });
        },
        [reportingCriteria, onChangeReportingCriteria]
    );

    const handleAdvertiserOnChange = useCallback(
        (advertisers: Advertiser[]) => {
            if (onFetchAdvertisers) {
                onFetchAdvertisers(advertisers);
            }

            if (isReportingCriteriaSetup === false) {
                setIsReportingCriteriaSetup(true);
            }

            onChangeReportingCriteria({
                ...reportingCriteria,
                dealerIds: advertisers.map((advertiser: Advertiser) => advertiser.id as number),
            });
        },
        [
            reportingCriteria,
            onChangeReportingCriteria,
            onFetchAdvertisers,
            setIsReportingCriteriaSetup,
            isReportingCriteriaSetup,
        ]
    );

    const advertiserCritieria = useMemo(() => {
        return { agencyIds: reportingCriteria.agencyIds };
    }, [reportingCriteria.agencyIds]);

    return (
        <Row>
            {userContext.isAdmin() && (
                <FormControl
                    fullWidth
                    sx={{
                        '& .MuiAutocomplete-root': {
                            width: '100%',
                        },
                    }}
                >
                    <AgencyAutocomplete
                        multiple
                        mass
                        size="small"
                        value={reportingCriteria.agencyIds}
                        onChange={handleAgencyOnChange}
                    />
                </FormControl>
            )}

            <FormControl
                fullWidth
                sx={{
                    '& .MuiAutocomplete-root': {
                        width: '100%',
                    },
                }}
            >
                <AdvertiserAutocomplete
                    multiple
                    mass
                    size="small"
                    value={reportingCriteria.dealerIds}
                    criteria={advertiserCritieria}
                    onChange={handleAdvertiserOnChange}
                    onLoading={onFetchingAdvertisers}
                />
            </FormControl>

            <FormControl fullWidth>
                <ReportingCampaignStrategyTypeSelect
                    strategyTypes={activeStrategyTypes}
                    reportingCriteria={reportingCriteria}
                    onChangeReportingCriteria={onChangeReportingCriteria}
                />
            </FormControl>

            <FormControl fullWidth>
                <ReportingCriteriaDataRangePicker
                    minDate={defaultMinDate}
                    maxDate={defaultMaxDate}
                    startDate={reportingCriteria.startDate}
                    endDate={reportingCriteria.endDate}
                    onChange={(startDate?: Date, endDate?: Date): void => {
                        const isFilled: boolean = startDate !== undefined && endDate !== undefined;
                        if (isFilled) {
                            onChangeReportingCriteria({
                                ...reportingCriteria,
                                startDate: startDate ? startDate : reportingCriteria.startDate,
                                endDate: endDate ? endDate : reportingCriteria.endDate,
                            });
                        }
                    }}
                />
            </FormControl>
        </Row>
    );
}
