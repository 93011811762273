import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../App';
import { useAttributionPageContext } from '../../../hooks/useAttributionPage';

const AdvertiserMatchbackMoreMenu = () => {
    const { userContext } = useContext(UserContext);
    const { dealer } = useAttributionPageContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                size="medium"
                aria-label="more"
                id="advertiser-matchback-more-menu-button"
                aria-controls={open ? 'advertiser-matchback-more-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id="advertiser-matchback-more-menu"
                MenuListProps={{
                    'aria-labelledby': 'advertiser-matchback-more-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {userContext.isAdvertiser() ? (
                    <MenuItem
                        component={Link}
                        to={`mailto:support@cognitionads.com?subject=Request to Map Polk Data for Advertiser`}
                    >
                        Assign Competitors
                    </MenuItem>
                ) : (
                    dealer && (
                        <MenuItem component={Link} to={`/advertisers/${dealer.id}/#polkSales`}>
                            Assign Competitors
                        </MenuItem>
                    )
                )}
            </Menu>
        </div>
    );
};

export default AdvertiserMatchbackMoreMenu;
