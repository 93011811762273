import { Skeleton } from '@mui/material';

import Column from '../../components/Column';
import Row from '../../components/Row';

export default function SkeletonGroupedBarChartCard() {
    return (
        <Row>
            <Column sx={{ flexGrow: 1, px: 3, pt: 6, pb: 6 }}>
                <Column gap={1}>
                    <Row
                        gap={4}
                        alignItems="flex-start"
                        justifyContent="center"
                        sx={{
                            borderRightWidth: 0,
                            borderTopWidth: 0,
                            borderLeftWidth: 0,
                            borderBottomWidth: 1,
                            borderStyle: 'solid',
                            borderColor: 'divider',

                            pt: 3,
                            pl: 3,
                        }}
                    >
                        {Array.from({ length: 8 }).map((_, index) => (
                            <Row key={index} gap={1} alignItems="flex-end">
                                <Skeleton variant="rectangular" width={60} height={200} />
                            </Row>
                        ))}
                    </Row>
                </Column>

                <Row gap={3} alignItems="center" justifyContent="center">
                    {Array.from({ length: 2 }).map((_, index) => (
                        <Row key={index} gap={1} sx={{ width: 'auto' }}>
                            <Skeleton variant="rectangular" width={15} height={15} />

                            <Skeleton width={120} height={15} />
                        </Row>
                    ))}
                </Row>
            </Column>
        </Row>
    );
}
