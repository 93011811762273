import React, { useContext } from 'react';
import { UserContext, UserContextValue } from '../App';
import { USER_GROUP_AGENCY } from '../types/User';
import AccessDenied from './AccessDenied';

export interface AccessGuardProps {
    accessGroup?: string;
    accessAgency?: string;
    children: React.ReactElement;
    condition?(): boolean;
}

/**
 * Wrapper component to restrict component view to specific User Types
 */
export default function AccessGuard(props: AccessGuardProps): JSX.Element {
    const { userContext } = useContext(UserContext);
    const { accessGroup, accessAgency, condition, children } = props;

    if (hasAccess(userContext, accessGroup, accessAgency) && (condition ? condition() : true)) {
        return children;
    } else {
        return <></>;
    }
}

export function AccessRoute(props: AccessGuardProps): JSX.Element {
    const { userContext } = useContext(UserContext);
    const { accessGroup, accessAgency, condition, children } = props;

    if (hasAccess(userContext, accessGroup, accessAgency) && (condition ? condition() : true)) {
        return children;
    } else {
        return <AccessDenied />;
    }
}

export function hasAccess(userContext: UserContextValue, accessGroup?: string, accessAgency?: string) {
    const agency: any = userContext.agency;
    const groups = userContext.groups;
    // Grant the advertiser access to their assigned agency.
    if (agency && accessAgency && !groups.includes(USER_GROUP_AGENCY)) {
        groups.push(USER_GROUP_AGENCY);
    }
    const hasGroupAccess = !accessGroup || accessGroup.split(',').find((group) => groups.indexOf(group) > -1);
    const hasAgencyAccess = !agency || !accessAgency || accessAgency.split(',').find((aa) => agency[aa]);
    // if (accessAgency) debugger;
    return !!hasGroupAccess && !!hasAgencyAccess;
}
