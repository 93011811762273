import { Dispatch, SetStateAction, useState } from 'react';

import { SimpleCountQueryMap } from '../types/SimpleCountQueryMap';

interface UseState {
    simpleCounts: Partial<SimpleCountQueryMap>;
    setSimpleCounts: Dispatch<SetStateAction<Partial<SimpleCountQueryMap>>>;
}

const useSimpleCounts = (): UseState => {
    const [simpleCounts, setSimpleCounts] = useState<Partial<SimpleCountQueryMap>>({});

    return {
        simpleCounts,
        setSimpleCounts,
    };
};

export type { SimpleCountQueryMap };
export type { UseState as UseSimpleCountsState };
export { useSimpleCounts };
