import { useMemo } from 'react';
import { EditOutlined as EditOutlinedIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import CDButton from '../../../components/CDButton';

import { Campaign, CampaignChangeRequest } from '../../../types/Campaign';
import { useCampaignChange } from '../../../hooks/Models/Campaign/CampaignChange';
import { useCampaignChangeGracePeriod } from '../../../hooks/Models/Campaign/CampaignChangeGracePeriod';
import { useUserContext } from '../../../hooks/Contexts/UserContext';

interface CampaignModifyButtonProps {
    campaign: Campaign;
    campaignChangeRequest: CampaignChangeRequest | null;
}

const CampaignModifyButton = ({ campaign, campaignChangeRequest }: CampaignModifyButtonProps): JSX.Element | null => {
    const { userContext } = useUserContext();
    const navigate = useNavigate();

    const { isCampaignChangeable } = useCampaignChange({ campaign, campaignChangeRequest });
    const { hasCampaignChangeGracePeriodExpired } = useCampaignChangeGracePeriod({ campaign });

    const isDisabled = useMemo((): boolean => {
        return isCampaignChangeable === false;
    }, [isCampaignChangeable]);

    const tooltip = useMemo((): string => {
        if (hasCampaignChangeGracePeriodExpired) {
            return 'You cannot Modify a campaign 30 days after it has ended. If you intend to restart this campaign please create a new one.';
        }

        if (campaignChangeRequest !== null && campaignChangeRequest.status === 'Pending') {
            return 'You can not modify a campaign that already has a pending change request.';
        }

        return 'Request Campaign Changes';
    }, [campaignChangeRequest, hasCampaignChangeGracePeriodExpired]);

    return (
        <>
            <CDButton
                tooltip={tooltip}
                disabled={isDisabled}
                startIcon={<EditOutlinedIcon />}
                onClick={() => {
                    navigate('/campaign/' + campaign.id, {
                        state: {
                            isChangeRequest: true,
                            campaignId: campaign.id,
                            from: 'overview',
                        },
                    });
                }}
            >
                Modify
            </CDButton>
            {userContext.isAdmin() && (
                <CDButton
                    tooltip={tooltip}
                    disabled={isDisabled}
                    startIcon={<EditOutlinedIcon />}
                    onClick={() => {
                        navigate('/campaign/v2/' + campaign.id, {
                            state: {
                                // isChangeRequest: true,
                                // campaignId: campaign.id,
                                // from: 'overview',
                            },
                        });
                    }}
                >
                    Modify 2.0
                </CDButton>
            )}
        </>
    );
};

export type { CampaignModifyButtonProps };
export default CampaignModifyButton;
