import { useEffect, useState } from 'react';
import { MenuItem, TextField } from '@mui/material';
import moment from 'moment';

import ApiService from '../../ApiService';
import { Dealer } from '../../types/Dealer';
import theme from '../../theme';
import { CampaignAdGroup } from '../../types/Campaign';

const SelectMonth = ({
    advertiser,
    setYearMonth,
    yearMonth,
    setAdGroup,
    setSize,
}: {
    advertiser: Dealer | null;
    yearMonth: string;
    setYearMonth: (yearMonth: string) => void;
    setAdGroup: (adGroup: CampaignAdGroup | null) => void;
    setSize: (size: string) => void;
}) => {
    const [months, setMonths] = useState<{ id: string; label: string }[]>([]);

    useEffect(() => {
        function getCampaignAdGroups() {
            if (!advertiser || !advertiser?.id) {
                setMonths([]);
                return;
            }
            ApiService.getOldestStartDateOfAdGroup(advertiser.id)
                .then((response) => {
                    const year_month: string[] = response?.data?.split('-') || [];
                    if (year_month && year_month?.length) {
                        setMonths(getMonthList(+year_month[0], +year_month[1]));
                        // default current month
                        setYearMonth(moment().format('YYYY-MM'));
                    }
                })
                .catch(() => {
                    setMonths([]);
                });
        }
        getCampaignAdGroups();
    }, [advertiser]);

    const getMonthList = (startYear: number, startMonth: number) => {
        const date = new Date(startYear, startMonth - 1); // Months are zero-indexed
        const endDate = new Date();
        const monthList = [];

        while (date <= endDate) {
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // Adjust for zero-indexed months
            const formattedMonth = `${year}-${month.toString().padStart(2, '0')}`;
            monthList.push({
                id: formattedMonth,
                label: moment({ year: year, month: month - 1 }).format('MMMM YYYY'),
            });

            date.setMonth(date.getMonth() + 1); // Move to the next month
        }

        return monthList;
    };

    return (
        <TextField
            select
            value={yearMonth ?? ''}
            sx={{
                width: '224px',
                fontSize: theme.typography.fontSize,
                '& .MuiInputBase-input': {
                    fontSize: theme.typography.fontSize,
                },
            }}
            label="Month"
            placeholder="Select Month"
            disabled={!advertiser || !advertiser?.ftAdvertiserId}
            size="small"
            onChange={(e) => {
                setAdGroup(null);
                setSize('All');
                setYearMonth(e?.target?.value ? e.target.value : '');
            }}
        >
            {months
                ?.sort((a, b) => {
                    if (a.id < b.id) {
                        return 1;
                    }
                    if (a.id > b.id) {
                        return -1;
                    }

                    return 0;
                })
                ?.map((month) => {
                    return (
                        <MenuItem key={month.id} value={month.id}>
                            {month.label}
                        </MenuItem>
                    );
                })}
        </TextField>
    );
};

export default SelectMonth;
