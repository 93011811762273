import { useCallback, useState } from 'react';
import { Box, Button, ButtonProps, Card, IconButton, IconButtonProps, Stack, Typography } from '@mui/material';
import { AddCircleOutline as AddCircleOutlineIcon, DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { GridColDef, GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid-pro';

import DataGrid, { CDGridProProps as DataGridProps } from '../CDGridPro';
import Column from '../Column';
import DataGridNoRowsOverlay from './DataGridNoRowsOverlay';

type SelectionDataGridCommand = 'attach' | 'detach';

interface SelectionDataGridCommandButtonProps<T extends GridValidRowModel> extends IconButtonProps {
    command: SelectionDataGridCommand;
}

const SelectionDataGridCommandButton = <T extends GridValidRowModel>({
    command,
    ...props
}: SelectionDataGridCommandButtonProps<T>) => {
    return (
        <IconButton {...props}>
            {command === 'attach' && <AddCircleOutlineIcon fontSize="small" color="action" />}
            {command === 'detach' && <DeleteOutlineIcon fontSize="small" color="error" />}
        </IconButton>
    );
};

interface SelectionDataGridBulkCommandButtonProps<T extends GridValidRowModel> extends ButtonProps {
    command: SelectionDataGridCommand;
    visible?: boolean;
}

const SelectionDataGridBulkCommandButton = <T extends GridValidRowModel>({
    command,
    visible = false,
    ...props
}: SelectionDataGridBulkCommandButtonProps<T>) => {
    return (
        <Button
            variant="contained"
            color={command === 'attach' ? 'primary' : 'error'}
            size="small"
            startIcon={command === 'attach' ? <AddCircleOutlineIcon /> : <DeleteOutlineIcon />}
            {...props}
            sx={{ visibility: visible ? 'visible' : 'hidden' }}
        >
            {command === 'attach' ? 'Bulk Add' : 'Bulk Remove'}
        </Button>
    );
};

interface SelectionDataGridProps<T extends GridValidRowModel> extends DataGridProps<T> {
    command: SelectionDataGridCommand;
    onChange: (rows: T[]) => void;
}

const SelectionDataGrid = <T extends GridValidRowModel>({ command, onChange, ...props }: SelectionDataGridProps<T>) => {
    const columns: GridColDef[] = [
        {
            headerName: '',
            field: 'Actions',
            width: 60,
            hideSortIcons: true,
            disableColumnMenu: true,
            filterable: false,
            sortable: false,
            renderCell: (params) => (
                <SelectionDataGridCommandButton
                    command={command}
                    onClick={() => {
                        onChange([params.row]);
                    }}
                />
            ),
        },
        ...props.columns,
    ];

    return (
        <DataGrid
            variant="outlined"
            slots={{
                noRowsOverlay: DataGridNoRowsOverlay,
                toolbar: undefined,
            }}
            PaperProps={{
                elevation: 0,
            }}
            {...props}
            checkboxSelection
            columns={columns}
            sx={{
                '& .MuiDataGrid-main': {
                    mt: -0.5,
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                    color: 'text.primary',
                },
            }}
        />
    );
};

interface SelectionDataGridCardProps<T extends GridValidRowModel> extends SelectionDataGridProps<T> {
    title?: React.ReactNode;
}

const SelectionDataGridCard = <T extends GridValidRowModel>({
    title,
    command,
    onChange,
    rows = [],
    variant = 'outlined',
    ...props
}: SelectionDataGridCardProps<T>) => {
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

    const handleOnChange = useCallback((): void => {
        onChange(rows.filter((row: T) => rowSelectionModel.includes(row.id)));
    }, [onChange, rows, rowSelectionModel]);

    const handleOnRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
        setRowSelectionModel(newRowSelectionModel);
    };

    return (
        <Card variant={variant === 'outlined' ? 'outlined' : 'elevation'} elevation={0}>
            <Column gap={0}>
                <Stack>
                    <Box sx={{ px: 2, py: 2 }}>
                        <Typography variant="subtitle1">
                            {title || (command === 'attach' ? 'Selection Items' : 'Selected Items')}
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            sx={{
                                visibility: rows.length > 0 ? 'visible' : 'hidden',
                            }}
                        >
                            <Typography component="span" color="text.primary" variant="subtitle2">
                                {rowSelectionModel.length}
                            </Typography>
                            <Typography component="span" color="text.secondary" variant="subtitle2">
                                /{rows.length} selected
                            </Typography>
                        </Typography>
                    </Box>
                    <Box sx={{ px: 2, pb: 1 }}>
                        <SelectionDataGridBulkCommandButton
                            command={command}
                            onClick={handleOnChange}
                            visible={rowSelectionModel.length > 0}
                        />
                    </Box>
                </Stack>
                <Box
                    sx={{
                        height: rows.length > 0 ? 'auto' : '150px',
                        width: '100%',
                        flexGrow: 1,
                    }}
                >
                    <SelectionDataGrid
                        variant="elevation"
                        pageSize={10}
                        {...props}
                        command={command}
                        rows={rows}
                        onChange={onChange}
                        rowSelectionModel={rowSelectionModel}
                        onRowSelectionModelChange={handleOnRowSelectionModelChange}
                    />
                </Box>
            </Column>
        </Card>
    );
};

export type { SelectionDataGridCommand, SelectionDataGridCommandButtonProps, SelectionDataGridBulkCommandButtonProps };
export { SelectionDataGridCommandButton, SelectionDataGridBulkCommandButton };

export type { SelectionDataGridCardProps };
export { SelectionDataGridCard };

export type { SelectionDataGridProps };
export default SelectionDataGrid;
