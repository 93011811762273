import { FC, useMemo } from 'react';

import { PieChartSlice } from '../../../../types/Chart';
import Utils from '../../../../components/Utils';

import { SimpleCountType } from '../../../../services/trialfire/types/SimpleCountType';
import { useHatDashboardPageContext } from '../../../../hooks/Pages/HatDashboardPage';
import PieChartCard from '../../../../components/Metrics/PieChartCard';

const HatChannelByChannelPieChartCard: FC = (): JSX.Element => {
    const { simpleCounts } = useHatDashboardPageContext();

    const channels = [
        { label: 'STV/OLV/Display', type: SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_TOTAL_VISITS },
        { label: 'Direct', type: SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_DIRECT },
        { label: 'Organic', type: SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_ORGANIC_SEARCH },
        { label: 'Social', type: SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_SOCIAL },
        { label: 'Paid Search', type: SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_PAID_SEARCH },
        { label: 'Referral', type: SimpleCountType.TOTAL_AD_INFLUENCED_TRAFFIC_REFERRAL },
    ];

    const queries = channels.map(({ type }) => simpleCounts[type]);

    const isLoading = useMemo(() => queries.some((q) => q?.isFetching || q?.isLoading), [queries]);

    const slices = useMemo((): PieChartSlice[] => {
        return channels.map(({ label, type }) => ({
            label,
            value: simpleCounts[type]?.data?.data ?? 0,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simpleCounts]);

    const slicesTotal = useMemo(() => {
        return slices.reduce((total, { value }) => total + value, 0);
    }, [slices]);

    return (
        <PieChartCard
            loading={isLoading}
            label="Influenced Visits by Channel"
            chartOptions={{
                dataLabels: { enabled: false },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                total: {
                                    label: 'Influenced Visits',
                                    formatter: () => Utils.formatNumber(slicesTotal) as string,
                                },
                                value: {
                                    formatter: (value) =>
                                        value.startsWith('$') ? value : `${value ? `${value}` : '0'}%`,
                                },
                            },
                        },
                    },
                },
                labels: slices.map(({ label }) => label),
                legend: {
                    horizontalAlign: 'center',
                    offsetX: -64,
                    offsetY: 64,
                },
            }}
            chartSeries={slices.map(({ value }) => (slicesTotal > 0 ? Math.round((value / slicesTotal) * 100) : 0))}
        />
    );
};

export default HatChannelByChannelPieChartCard;
