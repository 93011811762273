import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

const DataGridNoRowsOverlay = ({ children }: { children?: ReactNode }) => {
    return (
        <Stack spacing={1} justifyContent="center" alignItems="center" sx={{ height: '70px' }}>
            {children ? children : <Typography variant="subtitle2">No Data Available</Typography>}
        </Stack>
    );
};

export default DataGridNoRowsOverlay;
