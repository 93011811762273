import { deepmerge } from '@mui/utils';
import { useQuery } from 'react-query';
import moment from 'moment-timezone';

import { SimpleCount } from '../types/SimpleCount';
import { SimpleCountType } from '../types/SimpleCountType';
import { getSimpleCountPayload } from '../utils/SimpleCountPayload';
import { useAuthToken } from './AuthToken';

interface SimpleCountCriteria {
    advertiserId?: number;
    apiToken: string;
    range: {
        startDate: number;
        endDate: number;
        period: string;
    };
}

const generateSimpleCountPayload = (type: SimpleCountType, criteria: SimpleCountCriteria) => {
    let payload = getSimpleCountPayload(type);

    if (payload === null || Object.entries(payload).length === 0) {
        return {};
    }

    return deepmerge(payload, {
        apiToken: criteria.apiToken,
        namedEvent: { apiToken: criteria.apiToken },
        range: criteria.range,
        browserTimezone: moment.tz.guess(),
    });
};

const useSimpleCount = (type: SimpleCountType, criteria: SimpleCountCriteria) => {
    const { data } = useAuthToken(criteria.advertiserId);
    const { clickstreamXAuthToken, clickstreamUrl } = data || {};

    return useQuery<SimpleCount>({
        queryKey: ['services/trialfire/analytics/simplecount', type, criteria],
        queryFn: async () => {
            const payload = generateSimpleCountPayload(type, criteria);

            if (Object.entries(payload).length === 0) {
                return null;
            }

            const response = await fetch(`${clickstreamUrl}/analytics/simplecount`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': clickstreamXAuthToken ?? '',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            return response.json();
        },
        enabled: !!clickstreamXAuthToken && !!criteria.apiToken,
        refetchOnWindowFocus: false,
    });
};

export type { SimpleCountCriteria };
export { SimpleCountType };
export { useSimpleCount };
