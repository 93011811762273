import Stack, { StackProps } from '@mui/material/Stack';

interface RowProps extends StackProps {}

const Row = (props: RowProps) => {
    return <Stack direction="row" gap={2} width="100%" {...props} />;
};

export type { RowProps };
export default Row;
