import { List } from '@mui/material';

import { SidebarMenuItem } from './index';
import AccessGuard from '../AccessGuard';
import SidebarMenuSubListItem from './SidebarMenuSubListItem';

interface SidebarMenuSubListProps {
    items: SidebarMenuItem[];
    onCollapse?: (item: SidebarMenuItem, index: number) => void;
}

const SidebarMenuSubList = (props: SidebarMenuSubListProps) => {
    const { items, onCollapse } = props;

    return (
        <List disablePadding>
            {items.map((item: SidebarMenuItem, index: number) => {
                return (
                    <AccessGuard
                        key={index}
                        accessGroup={item?.accessGroup || ''}
                        accessAgency={item?.accessAgency || ''}
                    >
                        <SidebarMenuSubListItem
                            item={item}
                            handleCollapse={(newItem: SidebarMenuItem) => {
                                if (onCollapse) {
                                    onCollapse(newItem, index);
                                }
                            }}
                        />
                    </AccessGuard>
                );
            })}
        </List>
    );
};

export default SidebarMenuSubList;
