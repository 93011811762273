import { useCallback, useMemo } from 'react';

import {
    PolkDealer,
    PolkSalesAnalysis,
    PolkSalesAnalysisSet,
    PolkSalesWorkflowResult,
    PolkSalesWorkflowSet,
} from '../types/Polk';

import { TABS, useAttributionPageContext } from './useAttributionPage';

type MarketShare = {
    polkDealer: PolkDealer;
    dealerName: string;
    value: number;
};

type AdShare = {
    polkDealer: PolkDealer;
    dealerName: string;
    value: number;
};

export function calculateExposedSales(workflowResult: PolkSalesWorkflowResult): number {
    return workflowResult.distinctUserCount;
}

export function calculateNonExposedSales(workflowResult: PolkSalesWorkflowResult): number {
    return workflowResult.salesCount - workflowResult.distinctUserCount;
}

export const createPolkSalesAnalysisSet = (polkSalesWorkflowSet: PolkSalesWorkflowSet): PolkSalesAnalysisSet => {
    let polkSalesAnalysisSet: PolkSalesAnalysisSet = {
        polkSalesAnalysis: [],
    };
    let polkSalesAnalysis: PolkSalesAnalysis[] = [];

    if (polkSalesWorkflowSet.salesWorkflow) {
        polkSalesAnalysis.unshift(
            ...polkSalesWorkflowSet.salesWorkflow.workflowResults.map((workflowResult: PolkSalesWorkflowResult) => {
                return {
                    polkDealer: workflowResult.polkDealer,
                    dealerName: workflowResult.polkDealer.dealerName,
                    marketShare: workflowResult.salesCount,
                    marketSharePercentage: 0,
                    adShare: workflowResult.distinctUserCount,
                    adSharePercentage: 0,
                    totalExposedSales: calculateExposedSales(workflowResult),
                    totalNonExposedSales: calculateNonExposedSales(workflowResult),
                    totalSales: workflowResult.salesCount,
                };
            })
        );
    }

    if (polkSalesWorkflowSet.opportunityWorkflow) {
        polkSalesAnalysis.push(
            ...polkSalesWorkflowSet.opportunityWorkflow.workflowResults.map(
                (workflowResult: PolkSalesWorkflowResult) => {
                    return {
                        polkDealer: workflowResult.polkDealer,
                        dealerName: workflowResult.polkDealer.dealerName,
                        marketShare: workflowResult.salesCount,
                        marketSharePercentage: 0,
                        adShare: workflowResult.distinctUserCount,
                        adSharePercentage: 0,
                        totalExposedSales: calculateExposedSales(workflowResult),
                        totalNonExposedSales: calculateNonExposedSales(workflowResult),
                        totalSales: workflowResult.salesCount,
                    };
                }
            )
        );
    }

    if (polkSalesAnalysis.length > 0) {
        let totalShare = 0;

        // Calculate market share
        totalShare = polkSalesAnalysis.map((psa: PolkSalesAnalysis) => psa.marketShare).reduce((a, b) => a + b, 0);

        polkSalesAnalysis = polkSalesAnalysis.map((psa: PolkSalesAnalysis) => {
            psa.marketSharePercentage = totalShare ? Math.round((psa.marketShare / totalShare) * 100) : 0;
            return psa;
        });

        // Calculate ad share
        totalShare = polkSalesAnalysis.map((psa: PolkSalesAnalysis) => psa.adShare).reduce((a, b) => a + b, 0);

        polkSalesAnalysis = polkSalesAnalysis.map((psa: PolkSalesAnalysis) => {
            psa.adSharePercentage = totalShare ? Math.round((psa.adShare / totalShare) * 100) : 0;
            return psa;
        });
    }

    polkSalesAnalysisSet.polkSalesAnalysis = polkSalesAnalysis;

    return polkSalesAnalysisSet;
};

export default function usePolkAttribution() {
    const {
        tab,
        advertiserSalesWorkflowSets,
        polkSalesWorkflowSets,
        polkSalesAnalysis,
        setPolkSalesAnalysis,
        polkSalesAnalysisSets,
        setPolkSalesAnalysisSets,
    } = useAttributionPageContext();

    const fetchPolkSalesAnalysisSets = useCallback(() => {
        if (tab === TABS.ADVERTISER_REPORTED_SALES) {
            setPolkSalesAnalysisSets(
                advertiserSalesWorkflowSets?.map((advertiserSalesWorkflowSet: PolkSalesWorkflowSet) => {
                    return createPolkSalesAnalysisSet(advertiserSalesWorkflowSet);
                }) || []
            );
        } else {
            setPolkSalesAnalysisSets(
                polkSalesWorkflowSets?.map((polkSalesWorkflowSet: PolkSalesWorkflowSet) => {
                    return createPolkSalesAnalysisSet(polkSalesWorkflowSet);
                }) || []
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advertiserSalesWorkflowSets, polkSalesWorkflowSets, tab]);

    const marketShares = useMemo(() => {
        let _marketShares: MarketShare[] = [];
        let workflowSets: PolkSalesWorkflowSet[] | undefined = polkSalesWorkflowSets;

        if (tab === TABS.ADVERTISER_REPORTED_SALES) {
            workflowSets = advertiserSalesWorkflowSets;
        }

        if (workflowSets) {
            _marketShares.push(
                ...workflowSets
                    .flatMap(
                        (workflowSet: PolkSalesWorkflowSet) => workflowSet.opportunityWorkflow?.workflowResults ?? []
                    )
                    .map((workflowResult: PolkSalesWorkflowResult) => {
                        return {
                            polkDealer: workflowResult.polkDealer,
                            dealerName: workflowResult.polkDealer.dealerName,
                            value: workflowResult.salesCount,
                        };
                    })
            );

            _marketShares.sort((msA: MarketShare, msB: MarketShare) => msB.value - msA.value);

            _marketShares.unshift(
                ...workflowSets
                    .flatMap((workflowSet: PolkSalesWorkflowSet) => workflowSet.salesWorkflow?.workflowResults ?? [])
                    .map((workflowResult: PolkSalesWorkflowResult) => {
                        return {
                            polkDealer: workflowResult.polkDealer,
                            dealerName: workflowResult.polkDealer.dealerName,
                            value: workflowResult.salesCount,
                        };
                    })
            );
        }

        return _marketShares;
    }, [advertiserSalesWorkflowSets, polkSalesWorkflowSets, tab]);

    const marketSharesTotal: number = useMemo(() => {
        return marketShares.map((ms: MarketShare) => ms.value).reduce((a: number, b: number) => a + b, 0);
    }, [marketShares]);

    const adShares = useMemo(() => {
        let _adShares: AdShare[] = [];
        let workflowSets: PolkSalesWorkflowSet[] | undefined = polkSalesWorkflowSets;

        if (tab === TABS.ADVERTISER_REPORTED_SALES) {
            workflowSets = advertiserSalesWorkflowSets;
        }

        if (workflowSets) {
            _adShares.push(
                ...workflowSets
                    .flatMap(
                        (workflowSet: PolkSalesWorkflowSet) => workflowSet.opportunityWorkflow?.workflowResults ?? []
                    )
                    .map((workflowResult: PolkSalesWorkflowResult) => {
                        return {
                            polkDealer: workflowResult.polkDealer,
                            dealerName: workflowResult.polkDealer.dealerName,
                            value: workflowResult.distinctUserCount,
                        };
                    })
            );

            _adShares.sort((asA: AdShare, asB: AdShare) => asB.value - asA.value);

            _adShares.unshift(
                ...workflowSets
                    .flatMap((workflowSet: PolkSalesWorkflowSet) => workflowSet.salesWorkflow?.workflowResults ?? [])
                    .map((workflowResult: PolkSalesWorkflowResult) => {
                        return {
                            polkDealer: workflowResult.polkDealer,
                            dealerName: workflowResult.polkDealer.dealerName,
                            value: workflowResult.distinctUserCount,
                        };
                    })
            );
        }

        return _adShares;
    }, [advertiserSalesWorkflowSets, polkSalesWorkflowSets, tab]);

    const adSharesTotal: number = useMemo(() => {
        return adShares.map((as: AdShare) => as.value).reduce((a: number, b: number) => a + b, 0);
    }, [adShares]);

    return {
        marketShares,
        marketSharesTotal,

        adShares,
        adSharesTotal,

        polkSalesAnalysis,
        setPolkSalesAnalysis,

        polkSalesAnalysisSets,
        setPolkSalesAnalysisSets,
        fetchPolkSalesAnalysisSets,
    };
}
