import React, { useEffect, useState } from 'react';
import { AlertColor, Box, Button, Modal, TextField, Tooltip, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import AccessGuard from '../../components/AccessGuard';
import TabPanel from '../../components/TabPanel';
import { USER_GROUP_ADMIN } from '../../types/User';
import ApiService from '../../ApiService';
import { DspMacro, DspMacroOptions } from '../../types/DspEntity';
import theme from '../../theme';
import Column from '../../components/Column';
import Row from '../../components/Row';
import { ITab } from './DspAdGroupDictsTab';
import CDGrid, { CustomPagination } from '../../components/CDGrid';
import { GridColumns, GridFooterContainer, GridRenderEditCellParams, GridSortModel } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { InfoMessage, InfoMessageProps } from '../../components/InfoMessage';
import { AxiosPageableResponse } from '../../types/AxiosPageableResponse';
import { AxiosResponse } from 'axios';

function DspMacrosGridFooter(props: { totalElements: number; numberOfElements: number }) {
    return (
        <GridFooterContainer>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px' }}>
                <CustomPagination />
                <span>
                    Showing {props.numberOfElements} of {props.totalElements} records
                </span>
            </div>
        </GridFooterContainer>
    );
}

const DspMacrosTab = ({ tab, activeTab }: { tab: ITab; activeTab: number }) => {
    const defaultPageSize: number = 50;
    const pageValueArray = [10, 20, 50, 75, 100];
    const [page, setPage] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [numberOfElements, setNumberOfElements] = useState(0);
    const [list, setList] = useState<DspMacro[] | null>(null);
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [open, setOpen] = useState(false);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'id', sort: 'desc' }]);

    const [formData, setFormData] = useState<{
        cognitionMacro: string;
        dsp: string;
        dspMacro: string;
        macroDescription: string;
        options: DspMacroOptions | null;
    }>({
        cognitionMacro: '',
        dsp: '',
        dspMacro: '',
        macroDescription: '',
        options: null, // To store all dropdown options
    });

    // **Function to update form state**
    const handleFormChange = (field: keyof typeof formData, value: string | number) => {
        setFormData((prev) => ({
            ...prev,
            [field]: value,
        }));
    };

    // **Reset function**
    const resetForm = () => {
        setFormData((prev) => ({
            ...prev,
            cognitionMacro: '',
            dsp: '',
            dspMacro: '',
            macroDescription: '',
        }));
    };

    // **Fetch dropdown options and update state**
    const fetchOptions = async () => {
        const response: AxiosResponse<DspMacroOptions> = await ApiService.getDspMacroOptions();
        if (response.status === 200) {
            setFormData((prev) => ({
                ...prev,
                options: response.data, // Store dropdown options
            }));
        }
    };

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = parseInt(event?.target?.value);
        if (pageValueArray.includes(value)) {
            setPageSize(value);
        } else {
            // Handle invalid value or reset to default
            setPageSize(defaultPageSize);
        }
    };

    function dspMacroSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        ApiService.addDspMacro({
            dsp: formData.dsp,
            cognitionMacro: formData.cognitionMacro,
            dspMacro: formData.dspMacro,
            macroDescription: formData.macroDescription,
        }).then((newItem) => {
            if (!list) return;
            setList([newItem.data, ...list]);
            fetchOptions();
            showInfoMessage('success', 'Dsp Macro has been successfully created.');
        });
        handleClose();
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    const handleClose = () => {
        resetForm();
        setOpen(false);
    };

    const handleOpen = () => {
        resetForm();
        setOpen(true);
    };

    const editModalStyles = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        borderRadius: '8px',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    function onPageChange(page: number) {
        setPage(page);
    }

    const fetchData = async () => {
        const response: AxiosPageableResponse<DspMacro> = await ApiService.getDspMacros(page, pageSize, sortModel);
        if (response.status === 200) {
            setList(response.data.content);
            setTotalElements(response.data.totalElements);
            setNumberOfElements(response.data.numberOfElements);
        }
    };

    useEffect(() => {
        fetchData();
        fetchOptions();
    }, [page, pageSize, sortModel]);

    const columns: GridColumns = [
        {
            flex: 1,
            minWidth: 100,
            field: 'id',
            headerName: 'ID',
        },
        {
            flex: 2,
            minWidth: 100,
            field: 'cognitionMacro',
            headerName: 'Cognition Macro',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        Cognition Macro
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            renderEditCell: (params: GridRenderEditCellParams) => (
                <Select
                    fullWidth
                    label="Cognition Macro"
                    value={params.value}
                    variant="outlined"
                    size="small"
                    required
                    onChange={(event) =>
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: event.target.value,
                        })
                    }
                >
                    {formData.options?.cognitionMacros?.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'dsp',
            headerName: 'DSP Platform',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        DSP Platform
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            renderEditCell: (params: GridRenderEditCellParams) => (
                <Select
                    fullWidth
                    label="DSP Platform"
                    value={params.value}
                    variant="outlined"
                    size="small"
                    required
                    onChange={(event) =>
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: event.target.value,
                        })
                    }
                >
                    {formData.options?.dspPlatforms?.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'dspMacro',
            headerName: 'DSP Macro',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        DSP Macro
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
            renderEditCell: (params: GridRenderEditCellParams) => (
                <Select
                    fullWidth
                    label="DSP Macro"
                    value={params.value}
                    variant="outlined"
                    size="small"
                    required
                    onChange={(event) =>
                        params.api.setEditCellValue({
                            id: params.id,
                            field: params.field,
                            value: event.target.value,
                        })
                    }
                >
                    {formData.options?.dspMacros?.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            ),
        },
        {
            flex: 3,
            minWidth: 100,
            field: 'macroDescription',
            headerName: 'Macro Description',
            editable: true,
            renderHeader: () => (
                <Tooltip title="Double click the value to edit it" arrow>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: theme.palette.text.secondary,
                            fontWeight: theme.typography.fontWeightMedium,
                        }}
                    >
                        Macro Description
                        <EditOutlinedIcon fontSize="small" style={{ marginLeft: 2 }} />
                    </div>
                </Tooltip>
            ),
        },
    ];

    return (
        <AccessGuard accessGroup={USER_GROUP_ADMIN}>
            <TabPanel index={tab.id} value={activeTab} sx={{ p: 0 }}>
                <InfoMessage {...infoMessage} />
                <Column gap={2}>
                    <Row justifyContent="space-between" alignItems="center">
                        <Row gap={0} sx={{ width: '100%' }}>
                            <Typography component="span" color="text.primary" variant="subtitle2" fontWeight={500}>
                                Instruction:{' '}
                                <Typography
                                    component="span"
                                    color="text.secondary"
                                    variant="subtitle2"
                                    fontWeight={500}
                                >
                                    {tab.description}
                                </Typography>
                            </Typography>
                        </Row>
                        <Row flex={0} justifyContent="flex-end">
                            <Button variant="text" onClick={handleOpen} sx={{ marginTop: '-1%' }}>
                                Add Macro
                            </Button>
                            <FormControl sx={{ minWidth: 120 }} size="small">
                                <InputLabel id="demo-select-small-label">Rows Per Page</InputLabel>
                                <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={pageSize.toString()}
                                    label="Rows Per Page"
                                    onChange={handleChange}
                                >
                                    {pageValueArray?.map((value) => {
                                        return (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Row>
                    </Row>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={editModalStyles}>
                            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                                Add DSP Macro
                            </Typography>
                            <form onSubmit={dspMacroSubmit} style={{ width: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: 2,
                                        mt: 2,
                                        width: '100%',
                                    }}
                                >
                                    <FormControl fullWidth variant="outlined" size="small" required>
                                        <InputLabel>Cognition Macro</InputLabel>
                                        <Select
                                            value={formData.cognitionMacro}
                                            onChange={(e) => handleFormChange('cognitionMacro', e.target.value)}
                                            label="Cognition Macro"
                                        >
                                            {formData.options?.cognitionMacros?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="outlined" size="small" required>
                                        <InputLabel>DSP</InputLabel>
                                        <Select
                                            value={formData.dsp}
                                            onChange={(e) => handleFormChange('dsp', e.target.value)}
                                            label="DSP Platform"
                                        >
                                            {formData.options?.dspPlatforms?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        fullWidth
                                        label="DSP Macro"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e) => handleFormChange('dspMacro', e.target.value)}
                                    />
                                    <TextField
                                        fullWidth
                                        label="Macro Description"
                                        variant="outlined"
                                        size="small"
                                        required
                                        onChange={(e) => handleFormChange('macroDescription', e.target.value)}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 4 }}>
                                    <Button
                                        variant="contained"
                                        sx={{ width: '120px' }}
                                        startIcon={<SaveIcon />}
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        sx={{ width: '120px' }}
                                        onClick={handleClose}
                                        startIcon={<CloseIcon />}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Modal>
                    <CDGrid
                        id="dspMacros"
                        variant="contained"
                        pageSize={pageSize}
                        rowCount={totalElements}
                        page={page}
                        columns={columns}
                        onPageChange={onPageChange}
                        paginationMode={'server'}
                        sortingMode={'server'}
                        onSortModelChange={setSortModel}
                        experimentalFeatures={{ newEditingApi: true }}
                        rows={list === null ? [] : list}
                        processRowUpdate={(newRow) => {
                            ApiService.updateDspMacro(newRow);
                            setList((prevRows) =>
                                prevRows === null
                                    ? []
                                    : prevRows.map((row) => {
                                          if (row.id === newRow.id) {
                                              return newRow;
                                          } else {
                                              return row;
                                          }
                                      })
                            );
                            return newRow;
                        }}
                        onProcessRowUpdateError={() => {}}
                        components={{
                            Footer: DspMacrosGridFooter,
                        }}
                        componentsProps={{
                            footer: {
                                pageSize,
                                totalElements,
                                numberOfElements,
                            },
                        }}
                    />
                </Column>
            </TabPanel>
        </AccessGuard>
    );
};

export default DspMacrosTab;
