import { Box, BoxProps } from '@mui/material';

interface BlockProps extends BoxProps {
    disableGutters?: boolean;
}

const Block = ({ disableGutters = false, ...props }: BlockProps) => {
    return <Box px={disableGutters ? 0 : 3} py={disableGutters ? 0 : 2} {...props} />;
};

export type { BlockProps };
export default Block;
