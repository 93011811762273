import { Alert, AlertProps } from '@mui/material';

interface ReportingMediaTypeTabAlertProps extends AlertProps {
    mediaType: string;
}

const ReportingMediaTypeTabAlert = ({ mediaType, ...props }: ReportingMediaTypeTabAlertProps) => {
    return (
        <Alert severity="warning" {...props}>
            No {mediaType} campaign found for the selected Advertiser or Date Range. Contact your rep to launch one
            today!
        </Alert>
    );
};

export type { ReportingMediaTypeTabAlertProps };
export default ReportingMediaTypeTabAlert;
