import Heading from '../../components/Heading';
import { ChangeEvent, useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import { Chip, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { User, USER_GROUP_ADMIN } from '../../types/User';
import { GridActionsCellItem, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid';
import { Link, useNavigate } from 'react-router-dom';
import { ClearOutlined, LoginOutlined, SearchOutlined } from '@mui/icons-material';
import { useUserImpersonation } from '../../hooks/Users/UserImpersonation';
import { LinkButton } from '../../components/LinkButton';
import AgencySelector from '../../components/AgencySelector';
import DealerSelector from '../../components/DealerSelector';
import CDGrid from '../../components/CDGrid';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Row from '../../components/Row';

import { useUserListFilter } from '../../hooks/Filters/UserListFilter';

const UserManagement = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const { startUserImpersonation } = useUserImpersonation();
    const { filteredUsers, userListCriteria, setUserListCriteria, resetUserListCriteria } = useUserListFilter(users);

    useEffect(() => {
        setLoading(true);
        ApiService.getUsers()
            .then(function (response) {
                setUsers(response.data);
                setLoading(false);
            })
            .catch(() => {
                // no-op
            });
    }, []);

    function search(event: ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setUserListCriteria({ ...userListCriteria, searchText: event.target.value });
    }

    function clearSearch() {
        resetUserListCriteria();
    }

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Heading>User Management</Heading>

                        <Row justifyContent="flex-end">
                            <LinkButton href="new" variant="contained" color="primary">
                                Add User
                            </LinkButton>
                        </Row>
                    </Row>
                </Column>
            </Block>

            <Block flex={1}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <Row justifyContent="space-between">
                        <Row>
                            <AgencySelector
                                required={false}
                                variant="outlined"
                                label="Agency"
                                agencySelected={(agencyId: number | null) => {
                                    setUserListCriteria({ ...userListCriteria, agencyId: agencyId });
                                }}
                                agencyId={userListCriteria.agencyId}
                                sx={{ width: 224 }}
                            />

                            <DealerSelector
                                required={false}
                                variant="outlined"
                                label="Advertiser"
                                agencyId={userListCriteria.agencyId}
                                dealerId={userListCriteria.advertiserId}
                                dealerSelected={(advertiserId: number | null) => {
                                    setUserListCriteria({ ...userListCriteria, advertiserId: advertiserId });
                                }}
                                sx={{ width: 224 }}
                                showLabel={true}
                            />
                        </Row>

                        <TextField
                            inputRef={(input) => input && input.focus()}
                            id="searchText"
                            size="small"
                            value={userListCriteria.searchText}
                            label="Search"
                            onChange={search}
                            InputProps={{
                                startAdornment: <SearchOutlined color="action" fontSize="small" />,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => clearSearch()} edge="end">
                                            <ClearOutlined color="action" fontSize="small" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Row>

                    <CDGrid
                        columns={[
                            {
                                width: 110,
                                field: 'id',
                                type: 'actions',
                                headerName: 'Login',
                                headerClassName: 'grid-header',
                                getActions: (params: GridRowParams) => {
                                    if (params.row.group === USER_GROUP_ADMIN) {
                                        return [];
                                    } else {
                                        return [
                                            <GridActionsCellItem
                                                icon={
                                                    <Tooltip title="Login as this user">
                                                        <LoginOutlined color="info" />
                                                    </Tooltip>
                                                }
                                                label=""
                                                onClick={() => startUserImpersonation(params.row)}
                                                showInMenu={undefined}
                                            />,
                                        ];
                                    }
                                },
                            },
                            {
                                width: 200,
                                field: 'name',
                                headerName: 'Name',
                                headerClassName: 'grid-header',
                                renderCell: (params) => {
                                    return params.row.readOnly ? params.value : <b>{params.value}</b>;
                                },
                            },
                            {
                                width: 160,
                                field: 'userName',
                                headerName: 'Username',
                                headerClassName: 'grid-header',
                                renderCell: (params) => {
                                    return <Link to={`/useredit/${params.row.id}`}>{params.value}</Link>;
                                },
                            },
                            {
                                width: 150,
                                field: 'group',
                                headerName: 'User Type',
                                headerClassName: 'grid-header',
                                align: 'center',
                                headerAlign: 'center',
                                valueFormatter: (params: GridValueFormatterParams) => {
                                    let value = params.value as string;
                                    if (value) {
                                        value = value.replace('DEALER', 'advertiser');
                                        value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
                                    }
                                    return value;
                                },
                                renderCell: (params) => {
                                    let value = params.value as string;
                                    if (value) {
                                        value = value.replace('DEALER', 'advertiser');
                                        value = value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
                                    } else {
                                        value = 'Unknown';
                                    }
                                    return (
                                        <Chip
                                            label={value}
                                            size="small"
                                            variant={value === 'Admin' ? 'filled' : 'outlined'}
                                            color={value === 'Admin' ? 'success' : 'info'}
                                        />
                                    );
                                },
                            },
                            {
                                width: 200,
                                field: 'emailAddress',
                                headerName: 'Email',
                                headerClassName: 'grid-header',
                                flex: 1,
                            },
                        ]}
                        loading={loading}
                        autoHeight={false}
                        autoPageSize={true}
                        onRowClick={(params) => {
                            navigate('' + params.row.id);
                        }}
                        rows={filteredUsers}
                    />
                </Column>
            </Block>
        </Column>
    );
};

export default UserManagement;
