import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AutocompleteInputChangeReason } from '@mui/material';
import { useInfiniteQuery, useQuery } from 'react-query';
import axios from 'axios';

import { AdvertiserCriteria } from '../types/Criteria/AdvertiserCriteria';
import { Dealer as Advertiser } from '../types/Dealer';
import { InfiniteAutocompleteProps } from './InfiniteAutocomplete';
import { AxiosInfinitePageableResponse, PageableRequest, PageableResponse } from '../types/AxiosPageableResponse';
import { PAGEABLE_SIZE } from '../types/AxiosPageableResponse';
import InfiniteAutocomplete from './InfiniteAutocomplete';
import Utils from './Utils';

/**
 * Advertiser Autocomplete Utilities
 */

const getOptionKey = (option: Advertiser | any): string | number => {
    return option?.id as number;
};

const getOptionLabel = (option: Advertiser | any): string => {
    return option?.dealerName || '';
};

/**
 * Advertiser Autocomplete Requests
 */

const defaultPageable: PageableRequest = {
    page: 1,
    size: PAGEABLE_SIZE,
    sort: 'asc',
    sortBy: 'dealerName',
};

const fetch = async (
    criteria: AdvertiserCriteria,
    { page = 1, ...pageable }: PageableRequest
): Promise<PageableResponse<Advertiser>> => {
    const response = await axios.get('/api/advertiser/all', {
        headers: { XBusy: 'false' },
        params: {
            ...criteria,
            ...pageable,
            page: page - 1,
            agencyIds: criteria?.agencyIds?.length ? criteria.agencyIds.join(',') : undefined,
            ids: criteria?.ids?.length ? criteria.ids.join(',') : undefined,
        },
    });
    return response.data;
};

const fetchInitial = async (ids: number[] | number | null | undefined): Promise<any> => {
    const _ids: number[] = Array.isArray(ids) ? ids : ids != null ? [ids] : [];

    if (_ids.length === 0) {
        return [];
    }

    const { content } = await fetch({ ids: _ids }, { page: 1, size: 1000 });

    return content;
};

const fetchInfinite = async (
    criteria: AdvertiserCriteria,
    { page = 1, ...pageable }: PageableRequest
): Promise<AxiosInfinitePageableResponse<Advertiser>> => {
    const { content } = await fetch(criteria, { page, ...pageable });

    return {
        items: content,
        nextPage: content.length === pageable.size ? page + 1 : undefined,
    };
};

const fetchMass = async (criteria: AdvertiserCriteria): Promise<any> => {
    const { content } = await fetch(criteria, { page: 1, size: 1000 });

    return content;
};

/**
 * Advertiser Autocomplete
 */

interface AdvertiserAutocompleteProps<
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
> extends Omit<
        InfiniteAutocompleteProps<Advertiser, Multiple, DisableClearable, FreeSolo>,
        'options' | 'value' | 'getOptionKey' | 'getOptionLabel'
    > {
    onLoading?: () => void;
    criteria?: AdvertiserCriteria;
    value: Multiple extends true ? number[] : number | null;
}

const AdvertiserAutocomplete = <
    Multiple extends boolean | undefined = undefined,
    DisableClearable extends boolean | undefined = undefined,
    FreeSolo extends boolean | undefined = undefined
>({
    onLoading = () => {},
    onChange,
    ...props
}: AdvertiserAutocompleteProps<Multiple, DisableClearable, FreeSolo>) => {
    const { debounce } = Utils;

    const [options, setOptions] = useState<Advertiser[]>([]);
    const [value, setValue] = useState<
        InfiniteAutocompleteProps<Advertiser, Multiple, DisableClearable, FreeSolo>['value']
    >(
        (props.multiple ? [] : null) as InfiniteAutocompleteProps<
            Advertiser,
            Multiple,
            DisableClearable,
            FreeSolo
        >['value']
    );
    const [inputValue, setInputValue] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isMassSelected, setIsMassSelected] = useState<boolean>(false);

    const criteria = useMemo((): AdvertiserCriteria => {
        return {
            agencyIds: props?.criteria?.agencyIds || [],
            name: inputValue,
        };
    }, [props.criteria?.agencyIds, inputValue]);

    // Fetch initial value if IDs are provided
    const { data: initialValue, isLoading: isLoadingInitialValue } = useQuery({
        queryKey: ['infinite/advertisers/initial', props.value],
        queryFn: () => fetchInitial(props.value),
        enabled: true,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (initialValue && initialValue.length > 0) {
            const filteredInitialValue: Advertiser[] = initialValue.filter((_value: Advertiser) => {
                if (criteria?.agencyIds && criteria.agencyIds.length > 0) {
                    return criteria?.agencyIds?.includes(_value.agencyId as number);
                }
                return true;
            });

            if (props.multiple) {
                setValue((filteredInitialValue || []) as any);

                if (JSON.stringify(filteredInitialValue) !== JSON.stringify(value)) {
                    onChange(filteredInitialValue as any);
                }
            } else {
                setValue((filteredInitialValue[0] || null) as any);

                if (filteredInitialValue[0] !== value) {
                    onChange(filteredInitialValue[0] as any);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialValue, criteria, props.multiple]);

    const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage, refetch } = useInfiniteQuery({
        queryKey: ['infinite/advertisers', criteria],
        queryFn: ({ pageParam }) => fetchInfinite(criteria, { page: pageParam || 1, size: defaultPageable.size }),
        getNextPageParam: (lastPage) => lastPage.nextPage,
        // staleTime: 1000 * 60 * 5,
        enabled: isOpen,
        refetchOnWindowFocus: false,
    });

    const { refetch: refetchMass, isLoading: isLoadingMass } = useQuery({
        queryKey: ['infinite/advertisers/mass', criteria],
        queryFn: () => fetchMass(criteria),
        enabled: false,
        refetchOnWindowFocus: false,
    });

    const handleOnChange = useCallback(
        (
            newValue: InfiniteAutocompleteProps<Advertiser, Multiple, DisableClearable, FreeSolo>['value'] | undefined
        ): void => {
            setValue(newValue);
            onChange(newValue as any);
        },
        [onChange]
    );

    const debouncedOnInputChange = useRef(
        debounce((newInputValue: string): void => {
            refetch();
        }, 1000)
    ).current;

    const isLoading = useMemo((): boolean => {
        return isFetching || isFetchingNextPage || isLoadingInitialValue || isLoadingMass;
    }, [isFetching, isFetchingNextPage, isLoadingInitialValue, isLoadingMass]);

    const handleOnInputChange = (
        event: React.SyntheticEvent,
        newInputValue: string,
        reason: AutocompleteInputChangeReason
    ): void => {
        if (newInputValue.length > 0) {
            setInputValue(newInputValue);
            debouncedOnInputChange(newInputValue);
        }
    };

    const handleOnScroll = useCallback((): void => {
        if (!isMassSelected) {
            if (hasNextPage && !isFetchingNextPage) {
                fetchNextPage();
            }
        }
    }, [fetchNextPage, hasNextPage, isFetchingNextPage, isMassSelected]);

    useEffect((): void => {
        setOptions(data?.pages.flatMap((page) => page.items) || []);
    }, [data]);

    useEffect((): void => {
        if (isLoading === true) {
            onLoading();
        }
    }, [isLoading, onLoading]);

    useEffect(() => {
        if (isOpen === false) {
            setInputValue('');
        }
    }, [isOpen]);

    useEffect(() => {
        setIsMassSelected(false);
    }, [criteria]);

    const handleOnFetchMass = async (): Promise<ReadonlyArray<Advertiser>> => {
        const { data } = await refetchMass();

        setIsMassSelected(true);

        return data;
    };

    return (
        <InfiniteAutocomplete
            disableCloseOnSelect={props.multiple}
            openOnFocus={props.multiple}
            selectOnFocus={props.multiple}
            blurOnSelect={false}
            clearOnBlur={false}
            label="Advertiser"
            {...props}
            getOptionKey={getOptionKey}
            getOptionLabel={getOptionLabel}
            options={options}
            value={value}
            loading={isLoading}
            onChange={handleOnChange}
            inputValue={inputValue}
            onInputChange={handleOnInputChange}
            onScroll={handleOnScroll}
            onFetchMass={handleOnFetchMass}
            onOpen={(): void => setIsOpen(true)}
            onClose={(): void => setIsOpen(false)}
        />
    );
};

export type { AdvertiserAutocompleteProps };
export { defaultPageable as defaultAdvertiserPageable };
export default AdvertiserAutocomplete;
