import { Box } from '@mui/material';
import MetricCard from '../../components/Metrics/MetricCard';
import { useEffect, useState } from 'react';
import { formatMetricChangeLabel } from '../../components/Metrics/MetricCard';
import axios, { CancelTokenSource } from 'axios';

import ApiService from '../../ApiService';

interface ISaleData {
    newusedind: string;
    previous_month_count: number;
    current_month_count: number;
}

export default function CardList({ dbFilters = '' }: { dbFilters?: string }) {
    const [sales, setSales] = useState<ISaleData[]>([]);
    const [isFetchingSales, setIsFetchingSales] = useState<boolean>(false);
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

    useEffect(() => {
        if (!dbFilters) return;
        if (cancelTokenSource) {
            cancelTokenSource.cancel('Operation canceled due to new request.');
        }

        const newCancelTokenSource: CancelTokenSource = axios.CancelToken.source();
        setCancelTokenSource(newCancelTokenSource);

        setIsFetchingSales(true);

        ApiService.getSalesData(dbFilters, {
            cancelToken: newCancelTokenSource.token,
        })
            .then((response) => {
                if (response?.data?.length) {
                    setSales(response?.data);
                } else {
                    setSales([]);
                }
            })
            .catch((error) => {
                // no-op
            })
            .finally(() => {
                setIsFetchingSales(false);
            });

        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel('Component unmounted or criteria changed.');
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dbFilters]);

    const newSales = sales?.find((sale) => sale.newusedind === 'N')?.current_month_count || 0;
    const previewNewSales = sales?.find((sale) => sale.newusedind === 'N')?.previous_month_count || 0;
    const oldSales = sales?.find((sale) => sale.newusedind === 'U')?.current_month_count || 0;
    const previewOldSales = sales?.find((sale) => sale.newusedind === 'U')?.previous_month_count || 0;

    return (
        <Box
            sx={{
                display: 'flex',
                gap: 2,
                mt: 1.5,
                mb: 3,
            }}
        >
            <Box sx={{ width: '100%' }}>
                <MetricCard
                    loading={isFetchingSales}
                    metric={{
                        format: 'number',
                        value: newSales + oldSales,
                        change: newSales + oldSales - previewNewSales - previewOldSales,
                        changeLabel: formatMetricChangeLabel({
                            format: 'number',
                            value: newSales + oldSales,
                            change:
                                previewNewSales + previewOldSales
                                    ? (newSales + oldSales - previewNewSales - previewOldSales) /
                                      (previewNewSales + previewOldSales)
                                    : newSales + oldSales - previewNewSales - previewOldSales
                                    ? 100
                                    : 0,
                        }),
                        changePeriodLabel: 'Previous Period',
                    }}
                    label="Total Sales"
                    tooltip="Total Advertiser Sales as reported by Advertiser"
                />
            </Box>
            <Box sx={{ width: '100%' }}>
                <MetricCard
                    loading={isFetchingSales}
                    metric={{
                        format: 'number',
                        value: newSales,
                        change: newSales - previewNewSales,
                        changeLabel: formatMetricChangeLabel({
                            format: 'number',
                            value: newSales,
                            change: previewNewSales
                                ? (newSales - previewNewSales) / previewNewSales
                                : newSales - previewNewSales
                                ? 100
                                : 0,
                        }),
                        changePeriodLabel: 'Previous Period',
                    }}
                    label="New Sales"
                    tooltip="New Sales"
                />
            </Box>
            <Box sx={{ width: '100%' }}>
                <MetricCard
                    loading={isFetchingSales}
                    metric={{
                        format: 'number',
                        value: oldSales,
                        change: oldSales - previewOldSales,
                        changeLabel: formatMetricChangeLabel({
                            format: 'number',
                            value: oldSales,
                            change: previewOldSales
                                ? (oldSales - previewOldSales) / previewOldSales
                                : oldSales - previewOldSales
                                ? 100
                                : 0,
                        }),
                        changePeriodLabel: 'Previous Period',
                    }}
                    label="Used Sales"
                    tooltip="Used Sales"
                />
            </Box>
        </Box>
    );
}
