import { MouseEvent, useContext } from 'react';
import { Alert, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { UserContext } from '../../App';
import { useUserImpersonation } from '../../hooks/Users/UserImpersonation';

const UserImpersonationAlert = () => {
    const navigate = useNavigate();
    const { userContext } = useContext(UserContext);
    const { isUserImpersonating, stopUserImpersonation } = useUserImpersonation();

    const handleClick = (event: MouseEvent<HTMLElement>): void => {
        stopUserImpersonation();
        navigate('/users');
    };

    if (!isUserImpersonating()) {
        return null;
    }

    return (
        <Alert
            severity="warning"
            action={
                <Button color="inherit" size="small" onClick={handleClick}>
                    Stop impersonating
                </Button>
            }
        >
            You are impersonating <strong>{userContext ? userContext.name : ''}</strong>.
        </Alert>
    );
};

export default UserImpersonationAlert;
