import { SyntheticEvent } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import Row from '../../components/Row';
import { Dealer as Advertiser } from '../../types/Dealer';
import { PrintContext } from '../../hooks/usePrint';
import { ReportingDashboardPageProvider } from '../../hooks/useReportingDashboardPage';
import { useReportingDashboardPage } from '../../hooks/useReportingDashboardPage';
import usePrint from '../../hooks/usePrint';
import ReportingCriteriaForm from './partials/Reporting/ReportingCriteriaForm';
import ReportingMediaTabs, { TABS as MEDIA_TABS } from './partials/Reporting/Tabs/ReportingMediaTabs';
import ReportingPerformanceTabPanels from './partials/Reporting/Tabs/ReportingPerformanceTabPanels';

import ReportingAdvertiserCountLabel from './partials/Reporting/ReportingAdvertiserCountLabel';
import ReportingMediaTypeTabAlert from './partials/Reporting/Alerts/ReportingMediaTypeTabAlert';
import ReportingPerformanceExportMenuButton from './partials/Reporting/ReportingPerformanceExportMenuButton';
import ReportingPerformanceMetrics from './partials/Reporting/ReportingPerformanceMetrics';
import { AttributionPageProvider, useAttributionPage } from '../../hooks/useAttributionPage';

export default function ReportingDashboardPage() {
    const theme = useTheme();
    const print = usePrint();
    const reportingDashboardPage = useReportingDashboardPage();
    const attributionPage = useAttributionPage();

    const {
        isActiveMediaType,
        isFetchingPerformances,
        performances,
        reportingCriteria,
        setReportingCriteria,
        mediaTabs,
    } = reportingDashboardPage;

    const [activeAdvertiserCount, setActiveAdvertiserCount] = useState<number>(0);
    const [totalAdvertiserCount, setTotalAdvertiserCount] = useState<number>(0);
    const [totalActiveAdvertiserCount, setTotalActiveAdvertiserCount] = useState<number>(0);

    const handleChangeMediaTab = useCallback(
        (event: SyntheticEvent, newActiveTab: number) => {
            mediaTabs.handleChangeTab(event, newActiveTab);

            let mediaType: string = '';

            switch (newActiveTab) {
                case MEDIA_TABS.ALL:
                    mediaType = '';
                    break;
                case MEDIA_TABS.DISPLAY:
                    mediaType = 'Display';
                    break;
                case MEDIA_TABS.AUDIO:
                    mediaType = 'Audio';
                    break;
                case MEDIA_TABS.ONLINE_VIDEO:
                    mediaType = 'Online Video';
                    break;
                case MEDIA_TABS.STREAMING_TV:
                    mediaType = 'Streaming TV';
                    break;
                // Note: Legacy media type
                case MEDIA_TABS.VIDEO:
                    mediaType = 'Video';
                    break;
            }

            setReportingCriteria({
                ...reportingCriteria,
                mediaType: mediaType,
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mediaTabs]
    );

    const showMediaTypeTabAlert = useMemo((): boolean => {
        if (reportingCriteria.mediaType === 'Video') {
            return false;
        }
        return reportingCriteria.mediaType.length > 0 && !isActiveMediaType(reportingCriteria.mediaType);
    }, [reportingCriteria.mediaType, isActiveMediaType]);

    const isFetchingAdvertiserCount = useMemo(() => {
        return isFetchingPerformances;
    }, [isFetchingPerformances]);

    const setupAdvertiserCounts = useCallback(() => {
        const distinctAdvertiserIds = [...new Set(performances.map((performance: any) => performance.dealerId))];
        setActiveAdvertiserCount(distinctAdvertiserIds.length);

        if (totalActiveAdvertiserCount > 0) {
            setTotalAdvertiserCount(totalActiveAdvertiserCount);
        } else {
            setTotalAdvertiserCount(distinctAdvertiserIds.length);
        }

        if (reportingCriteria.dealerIds.length > 0) {
            setTotalAdvertiserCount(reportingCriteria.dealerIds.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingCriteria.dealerIds, performances, totalActiveAdvertiserCount]);

    useEffect(() => {
        setupAdvertiserCounts();
    }, [setupAdvertiserCounts]);

    return (
        <PrintContext.Provider value={print}>
            <ReportingDashboardPageProvider value={reportingDashboardPage}>
                <Column gap={0} flex={1}>
                    <Block>
                        <Column gap={1}>
                            <Row spacing={2} justifyContent="space-between" alignItems="center">
                                <Heading>Reporting</Heading>

                                <Row justifyContent="flex-end"></Row>
                            </Row>
                        </Column>
                    </Block>

                    <Block py={0}>
                        <Row spacing={2} justifyContent="space-between" alignItems="center">
                            <Row spacing={2} alignItems="center">
                                <Box
                                    sx={{
                                        '&': {
                                            width: '100%',

                                            [theme.breakpoints.up('xl')]: {
                                                width: 1200,
                                            },
                                        },
                                    }}
                                >
                                    <ReportingCriteriaForm
                                        reportingCriteria={reportingCriteria}
                                        onChangeReportingCriteria={setReportingCriteria}
                                        onFetchAdvertisers={(advertisers: Advertiser[]): void => {
                                            setTotalActiveAdvertiserCount(
                                                advertisers.filter(
                                                    (advertiser: { activeStatus: boolean }) => advertiser.activeStatus
                                                ).length
                                            );
                                        }}
                                    />
                                </Box>
                            </Row>
                        </Row>
                    </Block>

                    <Block disableGutters>
                        <ReportingMediaTabs value={mediaTabs.activeTab} onChange={handleChangeMediaTab} />
                    </Block>

                    <Block>
                        <Column>
                            {showMediaTypeTabAlert && (
                                <ReportingMediaTypeTabAlert mediaType={reportingCriteria.mediaType} />
                            )}

                            <Row justifyContent="space-between">
                                <ReportingAdvertiserCountLabel
                                    loading={isFetchingAdvertiserCount}
                                    activeAdvertiserCount={activeAdvertiserCount}
                                    totalAdvertiserCount={totalAdvertiserCount}
                                />

                                <AttributionPageProvider value={attributionPage}>
                                    <ReportingPerformanceExportMenuButton disabled={false} />
                                </AttributionPageProvider>
                            </Row>
                        </Column>
                    </Block>

                    <Block>
                        <Column>
                            <Box>
                                <ReportingPerformanceMetrics />
                            </Box>
                        </Column>

                        <Column>
                            <ReportingPerformanceTabPanels />
                        </Column>
                    </Block>
                </Column>
            </ReportingDashboardPageProvider>
        </PrintContext.Provider>
    );
}
