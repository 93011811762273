import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Alert, Backdrop, Box, CircularProgress, Snackbar } from '@mui/material';
import ConfirmDialog, { ConfirmDialogProps } from './ConfirmDialog';
import { BrowserUpdatedOutlined } from '@mui/icons-material';

export default function ApiInterceptor() {
    const [error, setError] = useState<string | null>(null);
    const [inProgress, setInProgress] = useState(0);
    const [appVersion] = useState(localStorage.getItem('appVersion'));
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [confirmDialogProps, setConfirmDialogProps] = useState<ConfirmDialogProps | null>(null);

    const showDialog = useCallback((serverVersion: string) => {
        setConfirmDialogProps({
            title: 'Application Update',
            text: 'A new version of the application is available.',
            onOk: () => updateApp(serverVersion),
            okColor: 'info',
            onCancel: cancel,
            okText: 'Update',
            okIcon: <BrowserUpdatedOutlined />,
        });
    }, []);

    useEffect(() => {
        const reqInterceptor = axios.interceptors.request.use(
            function (request) {
                const aliasUser = localStorage.getItem('X-COGNITION-USER');
                if (aliasUser) {
                    request.headers['X-COGNITION-USER'] = aliasUser;
                }
                if (request.headers.XBusy) {
                    return request;
                }
                setError(null);
                setInProgress((value) => value + 1);
                return request;
            },
            function (error) {
                setError(error.response);
                setInProgress((value) => (value > 0 ? value - 1 : 0));
                return Promise.reject(error);
            }
        );

        const resInterceptor = axios.interceptors.response.use(
            function (response) {
                setInProgress((value) => (value > 0 ? value - 1 : 0));
                const serverVersion = response.headers['x-app-version'];
                if (appVersion === null && serverVersion !== undefined) {
                    localStorage.setItem('appVersion', serverVersion);
                }
                if (appVersion !== null && appVersion !== serverVersion && serverVersion !== undefined) {
                    setUpdateAvailable(true);
                    localStorage.setItem('showReleaseNotes', 'true');
                    showDialog(serverVersion);
                }
                return response;
            },
            function (error) {
                // If the request was cancelled, skip further error handling.
                if (axios.isCancel(error)) {
                    return Promise.reject(error);
                }
                setInProgress((value) => (value > 0 ? value - 1 : 0));
                if (error.response?.status === 404) {
                    setError('[cognition] is currently unavailable or experiencing technical difficulties.');
                } else {
                    // Check for custom error handler override
                    if (error.config?.headers['XCustomErrorHandler'] !== 'true') {
                        if (error.response?.data) {
                            setError(
                                typeof error.response?.data === 'string'
                                    ? error.response.data
                                    : JSON.stringify(error.response.data)
                            );
                        }
                    }
                }
                return Promise.reject(error);
            }
        );

        return () => {
            axios.interceptors.request.eject(reqInterceptor);
            axios.interceptors.response.eject(resInterceptor);
        };
    }, [appVersion, showDialog]);

    function updateApp(serverVersion: string) {
        localStorage.setItem('appVersion', serverVersion);
        window.location.reload();
    }

    function cancel() {
        setConfirmDialogProps(null);
    }

    return (
        <>
            {inProgress > 0 && (
                <Backdrop
                    id="backdrop"
                    sx={{
                        color: '#fff',
                        zIndex: (theme) => theme.zIndex.modal + 1,
                    }}
                    open
                >
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                </Backdrop>
            )}
            {error && (
                <Snackbar open={Boolean(error)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert
                        className="alert-error"
                        severity="error"
                        onClose={() => {
                            setError(null);
                        }}
                    >
                        {error}
                    </Alert>
                </Snackbar>
            )}
            {updateAvailable && confirmDialogProps && (
                <ConfirmDialog
                    title={confirmDialogProps.title}
                    text={confirmDialogProps.text}
                    okText={confirmDialogProps.okText}
                    okIcon={confirmDialogProps.okIcon}
                    okColor={confirmDialogProps.okColor}
                    onCancel={confirmDialogProps.onCancel}
                    onOk={confirmDialogProps.onOk}
                />
            )}
        </>
    );
}
