import { useEffect, useState } from 'react';
import { Autocomplete, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GridRowParams } from '@mui/x-data-grid';
import { useQuery } from 'react-query';

import { AmcInstance } from '../../../types/AmcInstance';
import { AmcAdvertiserWorkflowCriteria } from '../../../types/Criteria/AmcAdvertiserWorkflowCriteria';
import { useAdvertiserContext } from '../../../hooks/Contexts/AdvertiserContext';
import { useAxiosCancelToken } from '../../../hooks/AxiosCancelToken';
import { CDTextField } from '../../../components/CDTextField';
import ApiService from '../../../ApiService';
import Block from '../../../components/Block';
import CDGrid from '../../../components/CDGrid';
import Column from '../../../components/Column';
import Row from '../../../components/Row';

import AmcPageHeader from '../partials/AmcPageHeader';

export default function AmcWorkflowsPage() {
    const { advertiserContext } = useAdvertiserContext();
    const navigate = useNavigate();
    const withAxiosCancelTokenRequestConfig = useAxiosCancelToken();

    const [amcInstanceId, setAmcInstanceId] = useState<number | null>(() => {
        if (localStorage.getItem('amcInstanceId')) {
            return parseInt(localStorage.getItem('amcInstanceId') as string);
        }

        return null;
    });
    const [amcInstance, setAmcInstance] = useState<AmcInstance | null>(null);

    const [criteria, setCriteria] = useState<AmcAdvertiserWorkflowCriteria>({
        searchText: '',
        agencyId: null,
        advertiserId: null,
    });

    const {
        data: amcInstances,
        isLoading: isLoadingAmcInstances,
        refetch: refetchAmcInstances,
    } = useQuery({
        queryKey: ['amcInstances', criteria],
        queryFn: async () => {
            const { data } = await ApiService.getAmcInstanceList(
                {
                    agencyId: criteria.agencyId || null,
                    advertiserId: criteria.advertiserId || null,
                    searchText: criteria.searchText || '',
                },
                withAxiosCancelTokenRequestConfig()
            );
            return data;
        },
        enabled: false,
    });

    useEffect(() => {
        refetchAmcInstances();
    }, [criteria, refetchAmcInstances]);

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['amcAdvertiserWorkflows', amcInstanceId],
        queryFn: async () => {
            if (amcInstanceId) {
                const { data } = await ApiService.getAmcWorkflows(
                    amcInstanceId as number,
                    withAxiosCancelTokenRequestConfig()
                );
                return data;
            }
            return null;
        },
        enabled: false,
    });

    useEffect(() => {
        refetch();
    }, [amcInstanceId, refetch]);

    function onSelectInstance(_event: any, newValue: AmcInstance | null) {
        if (newValue) {
            let amcInstanceId = newValue.id;
            let amcInstance = amcInstances?.find((i) => i.id === amcInstanceId);
            setAmcInstance(amcInstance || null);
            localStorage.setItem('amcInstanceId', amcInstanceId + '');
            setAmcInstanceId(amcInstanceId);
            refetch();
        }
    }

    function handleAdd() {
        navigate('new', {
            state: {
                id: null,
                sqlQuery: '',
                isNew: true,
                amcInstance: amcInstance,
            },
        });
    }

    function handleEditWorkflow(params: GridRowParams) {
        if (params && params.row) {
            navigate(params.row.workflowId, {
                state: {
                    ...params.row,
                    isNew: false,
                    amcInstance: amcInstance,
                },
            });
        }
    }

    useEffect(() => {
        const assignedAmcInstance: AmcInstance | undefined = amcInstances?.find(
            (_amcInstance: AmcInstance) => _amcInstance.id === advertiserContext?.dealer?.amcInstanceId
        );

        setAmcInstance(assignedAmcInstance || null);
        setAmcInstanceId(assignedAmcInstance?.id || null);
    }, [advertiserContext, amcInstances]);

    return (
        <Column gap={0} flex={1}>
            <AmcPageHeader criteria={criteria} setCriteria={setCriteria} />

            <Block sx={{ height: '100%' }}>
                <Column gap={2}>
                    <Row justifyContent="space-between">
                        <Box sx={{ minWidth: 300 }}>
                            <Autocomplete
                                loading={isLoadingAmcInstances}
                                options={amcInstances || []}
                                value={amcInstance}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.name}
                                    </li>
                                )}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                size="small"
                                fullWidth={false}
                                onChange={onSelectInstance}
                                renderInput={(params) => (
                                    <CDTextField
                                        style={{ width: '100%' }}
                                        color="primary"
                                        shrinkLabel={false}
                                        required={true}
                                        variant="outlined"
                                        {...params}
                                        label="Instance"
                                        value={amcInstanceId}
                                    />
                                )}
                            />
                        </Box>

                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={handleAdd}
                            disabled={amcInstanceId === null}
                        >
                            Add Advertiser Workflow
                        </Button>
                    </Row>

                    <CDGrid
                        loading={isLoading}
                        getRowId={(row) => row.workflowId}
                        columns={[
                            {
                                width: 400,
                                field: 'workflowId',
                                headerName: 'Workflow ID',
                                renderCell: (params) => (
                                    <Button
                                        onClick={() => {
                                            handleEditWorkflow(params.value);
                                        }}
                                    >
                                        {params.value}
                                    </Button>
                                ),
                            },
                            {
                                width: 200,
                                field: 'sqlQuery',
                                headerName: 'SQL',
                                flex: 1,
                            },
                        ]}
                        rows={data || []}
                        onRowClick={handleEditWorkflow}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Column>
            </Block>
        </Column>
    );
}
