import AdvertiserAdComponent from '../../../components/AdvertiserAdComponent';
import { Campaign } from '../../../types/Campaign';

export default function CreativeTab(props: { campaign: Campaign }) {
    const { campaign } = props;

    return (
        <>
            <AdvertiserAdComponent dealerId={campaign.dealerId} campaign={campaign} />
        </>
    );
}
