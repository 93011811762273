import { AlertColor, Backdrop, Box, Button, CircularProgress, Grid, Stack, Tab, Tabs, TextField } from '@mui/material';
import { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/theme-sqlserver';
import { CloseOutlined, SaveOutlined } from '@mui/icons-material';
import ApiService from '../../../ApiService';
import { InfoMessage, InfoMessageProps } from '../../../components/InfoMessage';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Block from '../../../components/Block';
import { TabsContainer } from '../../../components/Tabs';
import TabPanel from '../../../components/TabPanel';
import { Workflow } from '../../../types/Workflow';
import { useLocation, useNavigate } from 'react-router-dom';

type WorkflowFormProps = {
    save?: boolean;
    size?: 'lg' | 'md' | 'sm';
    hideActions?: boolean;
    workflow: Workflow;
    onValidate?: (validated: boolean) => void;
    onSave: (workflow: Workflow) => void;
};

export default function WorkflowForm(props: WorkflowFormProps) {
    const { onValidate } = props;

    const location = useLocation();
    const navigate = useNavigate();
    const [workflow, setWorkflow] = useState<Workflow>(props.workflow ?? location.state);
    const [infoMessage, setInfoMessage] = useState<InfoMessageProps>({
        message: null,
    });
    const [busy, setBusy] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const validated: boolean | undefined = useMemo(() => {
        if (workflow) {
            if (workflow?.workflowId?.length === 0) {
                return false;
            }

            if (workflow?.sqlQuery?.length === 0) {
                return false;
            }

            return true;
        }

        return false;
    }, [workflow]);

    useEffect(() => {
        if (props.save === true) {
            submit();
        }
    }, [props.save]);

    useEffect(() => {
        if (onValidate) {
            onValidate(validated);
        }
    }, [onValidate, validated]);

    const showActions: boolean | undefined = useMemo(() => {
        return props.hideActions ? false : true;
    }, [props.hideActions]);

    const isEditing: boolean | undefined = useMemo(() => {
        return workflow.id !== undefined && workflow.id > 0;
    }, [workflow]);

    function submit() {
        ApiService.saveWorkflow(workflow)
            .then((response) => {
                setWorkflow(response.data);
                setBusy(false);
                showInfoMessage('success', 'Workflow has been saved.');

                props.onSave({ ...response.data });
            })
            .catch(() => {
                setBusy(false);
            });
    }

    function handleEditSQL(value: string) {
        setWorkflow({ ...workflow, sqlQuery: value });
    }

    function handleEditWorkflowId(event: ChangeEvent<HTMLInputElement>) {
        setWorkflow({ ...workflow, workflowId: event.target.value });
    }

    function handleSave() {
        setBusy(true);
        submit();
    }

    function handleDelete() {
        setBusy(true);
        ApiService.deleteWorkflow(workflow)
            .then(() => {
                setBusy(false);
                showInfoMessage('success', 'Workflow has been deleted.');
                setTimeout(() => {
                    navigate(-1);
                }, 3000);
            })
            .catch(() => {
                setBusy(false);
            });
    }

    function showInfoMessage(alertColor: AlertColor, message: string) {
        setInfoMessage({
            message: message,
            severity: alertColor,
            onClose: () => {
                setInfoMessage({ message: null });
            },
        });
    }

    function selectTab(event: SyntheticEvent, value: any) {
        setSelectedTab(value);
    }

    let busyMessage;
    if (busy) {
        busyMessage = (
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open
            >
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </Backdrop>
        );
    }

    return (
        <Box>
            <Block py={0}>
                {busyMessage}
                <Grid container spacing={2} sx={{ paddingTop: '15px', paddingBottom: '25px' }}>
                    <Grid item xs={props.size === 'md' ? 5 : 7}>
                        <TextField
                            label="Workflow ID"
                            size="small"
                            variant="outlined"
                            fullWidth={true}
                            value={workflow.workflowId}
                            required={true}
                            disabled={isEditing}
                            onChange={handleEditWorkflowId}
                        />
                    </Grid>
                </Grid>
            </Block>

            <TabsContainer>
                <Tabs value={selectedTab} onChange={selectTab}>
                    <Tab label="SQL" style={{ textTransform: 'none' }} />
                </Tabs>
            </TabsContainer>

            <Block>
                <TabPanel value={selectedTab} index={0}>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <AceEditor
                                    mode="sql"
                                    theme="sqlserver"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.08)',
                                    }}
                                    width="100%"
                                    height="300px"
                                    value={workflow.sqlQuery}
                                    onChange={handleEditSQL}
                                />
                            </Grid>
                            {showActions && (
                                <Grid item md={12}>
                                    <Stack direction="row" spacing={2}>
                                        <Button
                                            startIcon={<SaveOutlined />}
                                            onClick={handleSave}
                                            disabled={
                                                workflow.workflowId.length === 0 || workflow.sqlQuery.length === 0
                                            }
                                            variant="contained"
                                        >
                                            Save
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            onClick={() => navigate(-1)}
                                            color="error"
                                            startIcon={<CloseOutlined />}
                                        >
                                            Cancel
                                        </Button>
                                        <div style={{ flex: 1 }} />
                                        <Button
                                            startIcon={<CloseOutlined />}
                                            onClick={handleDelete}
                                            variant="outlined"
                                            color="error"
                                        >
                                            Delete
                                        </Button>
                                    </Stack>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </TabPanel>
            </Block>

            <InfoMessage {...infoMessage}></InfoMessage>
        </Box>
    );
}
