import { useEffect, useState } from 'react';
import { Button, Chip } from '@mui/material';
import { GridValueGetterParams, GridValueFormatterParams, GridValidRowModel } from '@mui/x-data-grid';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import { AmcAttributionCriteria } from '../../types/Criteria/AmcAttributionCriteria';
import { useAxiosCancelToken } from '../../hooks/AxiosCancelToken';
import { useDataGridRowCount, useDataGridPageable } from '../../hooks/DataGrid';
import ApiService from '../../ApiService';
import Block from '../../components/Block';
import CDGrid from '../../components/CDGridPro';
import Column from '../../components/Column';
import Row from '../../components/Row';
import Utils from '../../components/Utils';

import AmcAttributionCreateFormDialog from './AmcAttributionCreateFormDialog';
import AmcPageHeader from '../amc/partials/AmcPageHeader';

export default function AmcAttributionList() {
    const navigate = useNavigate();
    const withAxiosCancelTokenRequestConfig = useAxiosCancelToken();

    const [amcAttributionFormDialogOpen, setAmcAttributionFormDialogOpen] = useState<boolean>(false);

    const [criteria, setCriteria] = useState<AmcAttributionCriteria>({
        agencyId: null,
        advertiserId: null,
    });

    const { pageable, paginationModel, sortModel, setPaginationModel, setSortModel } = useDataGridPageable();

    const { data, isFetching, isLoading, refetch } = useQuery({
        queryKey: ['amcAttributions', criteria, pageable],
        queryFn: async () => {
            try {
                const { data } = await ApiService.getAmcAttributions(
                    criteria.agencyId || null,
                    criteria.advertiserId || null,
                    pageable,
                    withAxiosCancelTokenRequestConfig()
                );
                return data;
            } catch (error) {
                return null;
            }
        },
        enabled: false,
        refetchOnWindowFocus: false,
    });

    const rowCount = useDataGridRowCount(data?.totalElements);

    useEffect(() => {
        refetch();
    }, [criteria, pageable, refetch]);

    // Reset page to 0 when critera changes
    useEffect(() => {
        setPaginationModel((prev: GridPaginationModel) => ({ ...prev, page: 0 }));
    }, [criteria, setPaginationModel]);

    return (
        <>
            <AmcPageHeader criteria={criteria} setCriteria={setCriteria} />

            <Block sx={{ height: '100%' }}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <Row justifyContent="flex-end">
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            onClick={() => {
                                setAmcAttributionFormDialogOpen(true);
                            }}
                        >
                            Add Offline Attribution
                        </Button>
                    </Row>

                    <CDGrid
                        paginationMode="server"
                        sortingMode="server"
                        rowCount={rowCount}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        sortModel={sortModel}
                        onSortModelChange={setSortModel}
                        loading={isFetching || isLoading}
                        columns={[
                            {
                                filterable: false,
                                width: 110,
                                field: 'date',
                                headerName: 'Workflow Date',
                                headerClassName: 'grid-header',
                                valueGetter: (value: GridValueGetterParams, row: GridValidRowModel) => {
                                    return new Date(row?.date).toLocaleDateString('en-US', {
                                        month: 'short',
                                        year: 'numeric',
                                    });
                                },
                            },
                            {
                                filterable: false,
                                flex: 1,
                                minWidth: 200,
                                field: 'salesDataUpload.beginDate',
                                headerName: 'Date Range',
                                valueGetter: (value: GridValueGetterParams, row: GridValidRowModel) => {
                                    const dateRanges: string[] = [];

                                    if (row?.salesDataUpload?.beginDate) {
                                        dateRanges.push(Utils.formatDate(row.salesDataUpload.beginDate));
                                    }

                                    if (row?.salesDataUpload?.endDate) {
                                        dateRanges.push(Utils.formatDate(row.salesDataUpload.endDate));
                                    }

                                    return dateRanges.join(' - ');
                                },
                                valueFormatter: (params: GridValueFormatterParams, row: GridValidRowModel) => {
                                    return params.value;
                                },
                            },
                            {
                                filterable: false,
                                width: 120,
                                field: 'status',
                                headerName: 'Status',
                                headerClassName: 'grid-header',
                                renderCell: (params) => (
                                    <Chip
                                        label={params.value}
                                        size="small"
                                        variant={params.value === 'Published' ? 'filled' : 'outlined'}
                                        color={params.value === 'Published' ? 'success' : 'warning'}
                                    />
                                ),
                            },
                            {
                                filterable: false,
                                width: 160,
                                field: 'dealer.dealerName',
                                headerName: 'Advertiser',
                                headerClassName: 'grid-header',
                                valueGetter: (params: GridValueGetterParams, row) => {
                                    return row?.dealer?.dealerName;
                                },
                            },
                            {
                                filterable: false,
                                width: 150,
                                field: 'campaignName',
                                headerName: 'Campaign Name',
                                headerClassName: 'grid-header',
                            },
                            {
                                filterable: false,
                                width: 110,
                                field: 'dataSetName',
                                headerName: 'Data Type',
                                headerClassName: 'grid-header',
                            },
                            {
                                filterable: false,
                                width: 150,
                                field: 'recordsSubmitted',
                                headerName: 'Records Submitted',
                                headerClassName: 'grid-header',
                            },
                            {
                                filterable: false,
                                width: 150,
                                field: 'matchedAmazonRecords',
                                headerName: 'Matched Amazon Records',
                                headerClassName: 'grid-header',
                            },
                            {
                                filterable: false,
                                width: 150,
                                field: 'attributedUserRecords',
                                headerName: 'Attributed User Records',
                                headerClassName: 'grid-header',
                            },
                            {
                                filterable: false,
                                width: 110,
                                field: 'adBudget',
                                type: 'number',
                                headerName: 'Ad Budget',
                                headerClassName: 'grid-header',
                            },
                            {
                                filterable: false,
                                width: 110,
                                field: 'adSpend',
                                type: 'number',
                                headerName: 'Ad Spend',
                                headerClassName: 'grid-header',
                            },
                            {
                                filterable: false,
                                width: 110,
                                field: 'gross',
                                type: 'number',
                                headerName: 'Gross',
                                headerClassName: 'grid-header',
                            },
                        ]}
                        autoHeight={false}
                        autoPageSize={true}
                        rows={data?.content || []}
                        onRowClick={(params) => {
                            navigate('' + params.row.id);
                        }}
                        slots={{
                            toolbar: null,
                        }}
                    />
                </Column>
            </Block>

            {amcAttributionFormDialogOpen && (
                <AmcAttributionCreateFormDialog
                    open={amcAttributionFormDialogOpen}
                    onClose={() => {
                        refetch();
                        setAmcAttributionFormDialogOpen(false);
                    }}
                />
            )}
        </>
    );
}
