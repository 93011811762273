import { MouseEvent, useEffect, useState } from 'react';
import { ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import { GridViewOutlined, ViewListOutlined } from '@mui/icons-material';
import { useQuery } from 'react-query';

import { AdvertiserDataSetSummaryCriteria } from '../../../types/Criteria/AdvertiserDataSetSummaryCriteria';
import { USER_GROUP_ADMIN, USER_GROUP_AGENCY } from '../../../types/User';
import { useAxiosCancelToken } from '../../../hooks/AxiosCancelToken';
import { useUserContext } from '../../../hooks/Contexts/UserContext';
import AccessGuard from '../../../components/AccessGuard';
import ApiService from '../../../ApiService';
import Block from '../../../components/Block';
import Column from '../../../components/Column';
import Row from '../../../components/Row';

import AmcPageHeader from '../partials/AmcPageHeader';
import DataSetCardView from './DataSetCardView';
import DataSetGridView from './DataSetGridView';

export default function DataSetPage() {
    const { userContext } = useUserContext();
    const withAxiosCancelTokenRequestConfig = useAxiosCancelToken();

    const [view, setView] = useState<'grid' | 'list' | string>('list');

    const [criteria, setCriteria] = useState<AdvertiserDataSetSummaryCriteria>({
        searchText: '',
        agencyId: null,
        advertiserId: null,
    });

    const { data, isLoading, refetch } = useQuery({
        queryKey: ['advertiserDataSetSummaries', criteria],
        queryFn: async () => {
            const { data } = await ApiService.getDataSetSummary(
                criteria.agencyId || null,
                criteria.advertiserId || null,
                withAxiosCancelTokenRequestConfig()
            );
            return data;
        },
        enabled: false,
    });

    const handleView = (event: MouseEvent<HTMLElement>, newView: string): void => {
        setView(newView ? newView : 'list');
    };

    useEffect(() => {
        refetch();
    }, [criteria, refetch]);

    return (
        <Column gap={0} flex={1}>
            <AmcPageHeader criteria={criteria} setCriteria={setCriteria} />

            <Block flex={1}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <AccessGuard accessGroup={USER_GROUP_ADMIN}>
                        <>
                            <Row alignItems="center" justifyContent="space-between">
                                <Typography component="span" color="text.primary" variant="subtitle2" fontWeight={500}>
                                    Note:{' '}
                                    <Typography
                                        component="span"
                                        color="text.secondary"
                                        variant="subtitle2"
                                        fontWeight={500}
                                    >
                                        Data sets are created using Advertiser-Submitted First-Party Data.
                                    </Typography>
                                </Typography>

                                <ToggleButtonGroup
                                    size="small"
                                    value={view}
                                    onChange={handleView}
                                    aria-label="view"
                                    exclusive={true}
                                >
                                    <Tooltip title="List View">
                                        <ToggleButton value="list" aria-label="list view">
                                            <ViewListOutlined />
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title="Grid View">
                                        <ToggleButton value="grid" aria-label="grid view">
                                            <GridViewOutlined />
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Row>

                            {view === 'list' && (
                                <>
                                    <DataSetGridView
                                        isLoading={isLoading}
                                        dataSetSummaryList={data || []}
                                        agencyId={criteria.agencyId}
                                        userContext={userContext}
                                        onCreateAudience={() => {
                                            refetch();
                                        }}
                                    />
                                </>
                            )}

                            {view === 'grid' && (
                                <DataSetCardView
                                    dataSetSummaryList={data || []}
                                    agencyId={criteria.agencyId}
                                    userContext={userContext}
                                />
                            )}
                        </>
                    </AccessGuard>
                    <AccessGuard accessGroup={USER_GROUP_AGENCY}>
                        <>
                            <DataSetCardView
                                dataSetSummaryList={data || []}
                                agencyId={criteria.agencyId}
                                userContext={userContext}
                            />
                        </>
                    </AccessGuard>
                </Column>
            </Block>
        </Column>
    );
}
