import { AdvertiserDataSetSummary } from '../../../types/DataSet';
import {
    Alert,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    IconButton,
    Stack,
    Tooltip,
    useTheme,
} from '@mui/material';
import { useState } from 'react';
import DataSetDetailsDialog from './DataSetDetailsDialog';
import { ShowChartOutlined } from '@mui/icons-material';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { UserContextValue } from '../../../App';

export default function DataSetCardView(props: {
    dataSetSummaryList: AdvertiserDataSetSummary[];
    agencyId: number | null;
    userContext: UserContextValue;
}) {
    const { dataSetSummaryList, agencyId } = props;
    const [dataSetSummary, setDataSetSummary] = useState<AdvertiserDataSetSummary | null>(null);

    // interface DataSetTypes {
    //     [key: string]: React.ReactElement;
    // }
    // const dataSetTypes: DataSetTypes = {
    //     Leads: <PermContactCalendarOutlined color="secondary" />,
    //     Sales: <AttachMoneyOutlined color="success" />,
    //     Parts: <BuildOutlined />,
    //     Service: <BuildOutlined color="warning" />,
    //     'Store Visits': <BuildOutlined color="primary" />,
    // };
    //
    const theme = useTheme();

    const chartOptions: ApexOptions = {
        chart: {
            type: 'pie',
            background: 'transparent',
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: false,
        },
        stroke: {
            width: 1,
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return Number(val).toFixed(0) + ' %';
            },
        },
        theme: {
            mode: theme.palette.mode,
        },
        colors: [theme.palette.info.dark, theme.palette.success.dark],

        labels: ['Submitted', 'Identified'],
    };

    return (
        <>
            <Grid container spacing={2}>
                {!agencyId && (
                    <Grid item sm={12}>
                        <Alert severity="info">Please select an Agency.</Alert>
                    </Grid>
                )}
                {agencyId &&
                    dataSetSummaryList
                        .filter((ds) => ds.agencyId === agencyId)
                        .map((dataSetSummary) => (
                            <Grid key={dataSetSummary.id} item sm={12} md={4} lg={3} xl={2}>
                                <Card elevation={4}>
                                    <CardHeader
                                        sx={{ padding: '10px' }}
                                        title={
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                <span
                                                    style={{
                                                        fontSize: '0.7em',
                                                    }}
                                                >
                                                    {dataSetSummary.dealerName}
                                                </span>
                                                <div style={{ flex: 1 }} />
                                                <Tooltip title="Display 6 Month History">
                                                    <IconButton
                                                        onClick={() => {
                                                            setDataSetSummary(dataSetSummary);
                                                        }}
                                                    >
                                                        <ShowChartOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        }
                                        titleTypographyProps={{ variant: 'h6' }}
                                        subheader={
                                            <>
                                                <Stack direction="row" spacing={1}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: '0.9em',
                                                                paddingLeft: '3px',
                                                            }}
                                                        >
                                                            {dataSetSummary.identifiedRecords.toLocaleString()}{' '}
                                                            {dataSetSummary.dataSetName}
                                                        </span>
                                                    </div>
                                                </Stack>
                                                <Divider />
                                            </>
                                        }
                                    ></CardHeader>
                                    <CardContent>
                                        <Chart
                                            options={chartOptions}
                                            series={[dataSetSummary.submittedRecords, dataSetSummary.identifiedRecords]}
                                            type="pie"
                                        ></Chart>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
            </Grid>

            <DataSetDetailsDialog dataSetSummary={dataSetSummary} onClose={() => setDataSetSummary(null)} />
        </>
    );
}
