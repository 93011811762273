import { AppBar, Box, Divider, Typography } from '@mui/material';
import { CheckCircleOutlined as CheckCircleOutlinedIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import CampaignWizardSaveConfirmButton from './CampaignWizard/CampaignWizardSaveConfirmButton';
import Column from '../../../components/Column';
import Row from '../../../components/Row';
import { SectionHeading } from '../../../components/SectionHeading';
import { useCampaignWizardContext } from '../../../hooks/useCampaignWizard';
import { useInfoMessage } from '../../../hooks/useInfoMessage';

export default function CampaignPageHeader() {
    const { campaign, deleteDraftCampaign, isNewCampaign, updateCampaign, updateDraftCampaign } =
        useCampaignWizardContext();
    const { showInfoMessage } = useInfoMessage();
    const navigate = useNavigate();

    const modifiedDate: Date | null = campaign.statusDate ? campaign.statusDate : campaign?.modifiedDate;

    const handleSaveCampaignConfirm = (): void => {
        if (campaign.id === undefined || campaign.deliveryStatusId === 'DRAFT') {
            updateDraftCampaign().then((response: any) => {
                showInfoMessage('success', 'Campaign Saved as Draft');
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            });
        } else {
            updateCampaign().then((response: any) => {
                showInfoMessage('success', 'Campaign Saved & Updated');
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            });
        }
    };

    const handleSaveCampaignCancel = (): void => {
        if (campaign.deliveryStatusId === 'DRAFT') {
            deleteDraftCampaign().then((response: any) => {
                showInfoMessage('info', 'The new campaign has been discarded.');
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            });
        } else {
            navigate(campaign?.id ? `/campaignManager/${campaign.id}` : '/campaignManager');
        }
    };

    const handleSaveCampaignClose = (): void => {
        // no-op
    };

    return (
        <>
            <AppBar
                position="sticky"
                color="transparent"
                sx={{ backgroundColor: (theme) => theme.palette.common.white }}
            >
                <Row justifyContent="space-between" alignItems="center" spacing={2} height={64} sx={{ px: 3, py: 2 }}>
                    <Box>
                        <SectionHeading divider={false}>
                            <Row spacing={1} alignItems="center">
                                {isNewCampaign && <Typography variant="h6">New Campaign</Typography>}
                                {!isNewCampaign && (
                                    <Column gap={0}>
                                        <Typography variant="h6">{campaign.campaignName}</Typography>

                                        {modifiedDate && (
                                            <Row gap={0.5} alignItems="center">
                                                <CheckCircleOutlinedIcon color="success" fontSize="small" />
                                                <Typography variant="caption">
                                                    Last saved at {moment(modifiedDate).format('M/D/YYYY [at] h:mm a')}
                                                </Typography>
                                            </Row>
                                        )}
                                    </Column>
                                )}
                            </Row>
                        </SectionHeading>
                    </Box>

                    <Box>
                        <Row spacing={1}>
                            <CampaignWizardSaveConfirmButton
                                campaign={campaign}
                                onConfirm={handleSaveCampaignConfirm}
                                onCancel={handleSaveCampaignCancel}
                                onClose={handleSaveCampaignClose}
                            />
                            {/*<IconButton aria-label="close" size="small">
                                <CloseIcon fontSize="inherit" />
                            </IconButton>*/}
                        </Row>
                    </Box>
                </Row>

                <Divider />
            </AppBar>
        </>
    );
}
