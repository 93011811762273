import { ChangeEvent, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    Typography,
} from '@mui/material';

import { CreativePixel, CreativePixelCriteria } from '../../../../types/HAT/CreativePixel';
import { Dealer } from '../../../../types/Dealer';
import { getMediaTypeLabel, identifyPlatforms, identifyMediaTypes } from '../../../../utils/CreativePixel';
import { useCreativePixelListQuery } from '../../../../hooks/Queries/CreativePixelListQuery';
import { useTabs } from '../../../../hooks/Tabs';
import { TabsContainer } from '../../../../components/Tabs';
import CreativePixelCard from './CreativePixelCard';
import Column from '../../../../components/Column';
import Row from '../../../../components/Row';

interface CreativePixelTabsCardProps {
    advertiser: Dealer;
}

const CreativePixelTabsCard = (props: CreativePixelTabsCardProps) => {
    const { advertiser } = props;
    const { data: creativePixels = [], refetch: fetchCreativePixels } = useCreativePixelListQuery(
        advertiser.id || 0,
        advertiser.tfApiToken || ''
    );

    const platforms: string[] = identifyPlatforms(creativePixels);
    const mediaTypes: string[] = identifyMediaTypes(creativePixels);

    const tabs = useTabs();

    const [criteria, setCritera] = useState<CreativePixelCriteria>(() => {
        return {
            platform: platforms[0] || 'Amazon',
            mediaType: mediaTypes[0] || 'Display',
        };
    });

    const filteredCreativePixels = useMemo((): CreativePixel[] => {
        return creativePixels.filter((creativePixel: CreativePixel) => {
            const matchesPlatform = criteria.platform && creativePixel.platform === criteria.platform;
            const matchesMediaType = criteria.mediaType && creativePixel.mediaType === criteria.mediaType;

            return matchesPlatform && matchesMediaType;
        });
    }, [creativePixels, criteria]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setCritera((_criteria: CreativePixelCriteria) => ({ ..._criteria, [event.target.name]: event.target.value }));
    };

    const handleChangeTab = (event: SyntheticEvent, newActiveTab: number): void => {
        tabs.handleChangeTab(event, newActiveTab);

        if (newActiveTab in mediaTypes) {
            setCritera((_criteria: CreativePixelCriteria) => ({ ..._criteria, mediaType: mediaTypes[newActiveTab] }));
        }
    };

    useEffect((): void => {
        fetchCreativePixels();
    }, [fetchCreativePixels]);

    return (
        <Card variant="outlined">
            <CardHeader
                title={
                    <Row alignItems="center" sx={{ height: 40 }}>
                        <Typography variant="subtitle2" color="text.primary" fontWeight={500}>
                            Creative Pixels
                        </Typography>
                        {platforms && platforms.length > 0 && (
                            <FormControl sx={{ width: '150px', my: -1 }}>
                                <TextField
                                    label="Platform"
                                    name="platform"
                                    select
                                    required={true}
                                    size="small"
                                    SelectProps={{ native: false }}
                                    value={criteria.platform}
                                    onChange={handleChange}
                                >
                                    {platforms.map((platform: string) => {
                                        return (
                                            <MenuItem key={platform} value={platform}>
                                                {platform}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </FormControl>
                        )}
                    </Row>
                }
            />

            <Divider />

            {filteredCreativePixels.length > 0 && (
                <TabsContainer>
                    <Tabs value={tabs.activeTab} onChange={handleChangeTab}>
                        {mediaTypes.map((mediaType: string, index: number) => {
                            return <Tab key={index} value={index} label={getMediaTypeLabel(mediaType)} />;
                        })}
                    </Tabs>
                </TabsContainer>
            )}

            <CardContent sx={{ p: 3 }}>
                <Column gap={3}>
                    {filteredCreativePixels.length > 0 ? (
                        <>
                            <Typography variant="body2" color="text.secondary">
                                Add both Impressions and View-Through tracking pixels to monitor ad displays and track
                                user actions effectively.
                            </Typography>

                            <Grid container spacing={2}>
                                {filteredCreativePixels.map((creativePixel: CreativePixel) => {
                                    return (
                                        <Grid key={creativePixel.type} item xs={6}>
                                            <CreativePixelCard creativePixel={creativePixel} />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </>
                    ) : (
                        <Typography variant="body2" color="text.secondary">
                            No creative pixels found.
                        </Typography>
                    )}
                </Column>
            </CardContent>
        </Card>
    );
};

export type { CreativePixelTabsCardProps };
export default CreativePixelTabsCard;
