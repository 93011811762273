import { styled } from '@mui/material';
import { Box } from '@mui/material';

import { TabPanelProps } from '../../../../../components/TabPanel';
import { usePrintContext } from '../../../../..//hooks/usePrint';
import { useReportingDashboardPageContext } from '../../../../../hooks/useReportingDashboardPage';
import ReportingPerformanceMonitorTab from './ReportingPerformanceMonitorTab';
import ReportingPerformanceOnSiteInteractionTab from './ReportingPerformanceOnSiteInteractionTab';
import ReportingPerformanceOverviewTab from './ReportingPerformanceOverviewTab';
import ReportingPerformancePropertySpendTab from './ReportingPerformancePropertySpendTab';
import ReportingPerformanceTabs, { TABS as PERFORMANCE_TABS } from './ReportingPerformanceTabs';
import TabPanel from '../../../../../components/TabPanel';

const OffsetTabPanel = styled((props: TabPanelProps) => {
    const { print } = usePrintContext();

    return (
        <TabPanel
            // Negative top margin offset to be inline with view button group
            sx={{ mt: print ? 0 : -6.5 }}
            {...props}
        />
    );
})(({ theme }) => ({}));

export default function ReportingPerformanceTabPanels() {
    const { reportingCriteria, performanceTabs } = useReportingDashboardPageContext();
    const { activeTab, handleChangeTab } = performanceTabs;

    if (!reportingCriteria) {
        return <></>;
    }

    return (
        <Box>
            <ReportingPerformanceTabs value={activeTab} onChange={handleChangeTab} />

            <Box>
                <OffsetTabPanel index={PERFORMANCE_TABS.PERFORMANCE_OVERVIEW} value={activeTab}>
                    <ReportingPerformanceOverviewTab />
                </OffsetTabPanel>
                <TabPanel index={PERFORMANCE_TABS.PERFORMANCE_MONITOR} value={activeTab}>
                    <ReportingPerformanceMonitorTab reportingCriteria={reportingCriteria} />
                </TabPanel>
                <TabPanel index={PERFORMANCE_TABS.PROPERTY_SPEND} value={activeTab}>
                    <ReportingPerformancePropertySpendTab reportingCriteria={reportingCriteria} />
                </TabPanel>
                <TabPanel index={PERFORMANCE_TABS.ON_SITE_INTERACTION} value={activeTab}>
                    <ReportingPerformanceOnSiteInteractionTab reportingCriteria={reportingCriteria} />
                </TabPanel>
            </Box>
        </Box>
    );
}
