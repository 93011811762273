import moment from 'moment-timezone';

import { useCallback } from 'react';
import { MediaPerformance } from '../types/MediaPerformance';
import { Performance } from '../types/CampaignPerformance';
import { AppBySpendBySiteNameSummary as PropertySpend } from '../types/Analytics/AppBySpendSummary';
import { ConversionByConversionNameSummary as OnSiteInteraction } from '../types/Analytics/ConversionSummary';
import { TABS as MEDIA_TABS } from '../pages/analytics/partials/Reporting/Tabs/ReportingMediaTabs';
import { TABS as PERFORMANCE_TABS } from '../pages/analytics/partials/Reporting/Tabs/ReportingPerformanceTabs';
import { useReportingDashboardPageContext } from './useReportingDashboardPage';
import { useAdvertiserContext } from './Contexts/AdvertiserContext';
import { useAgencyContext } from './Contexts/AgencyContext';
import { useReportingMediaPerformances } from './ReportingMediaPerformances';
import Utils from '../components/Utils';

export function useMediaReportingPerformanceCsvExporter() {
    const {
        attributionMetrics,
        mediaTabs,
        onSiteInteractions,
        performances,
        performanceTabs,
        propertySpends,
        reportingCriteria,
    } = useReportingDashboardPageContext();

    const { mediaPerformances } = useReportingMediaPerformances({ reportingCriteria, performances });

    const getCsvData = () => {
        let rows = [];

        rows.push(['Reporting Analytics - Overall Performance']);

        switch (mediaTabs.activeTab) {
            case MEDIA_TABS.ALL:
                rows.push(
                    ...[
                        ['Total Investment', 'Total Exposures', 'Average CPM', 'Household Reach'],
                        [
                            Utils.formatValue(attributionMetrics?.totalSpend?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalImpressions?.value),
                            Utils.formatValue(attributionMetrics?.averageCPM?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalDailyReach?.value),
                        ],
                    ]
                );
                break;

            case MEDIA_TABS.DISPLAY:
                rows.push(
                    ...[
                        [
                            'Total Investment',
                            'Total Exposures',
                            'Average CPM',
                            'Household Reach',
                            'Average Viewability',
                            'Total Clicks',
                            'Average CTR',
                        ],
                        [
                            Utils.formatValue(attributionMetrics?.totalSpend?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalImpressions?.value),
                            Utils.formatValue(attributionMetrics?.averageCPM?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalDailyReach?.value),
                            Utils.formatValue(attributionMetrics?.averageViewabilityRate?.value, 'percent'),
                            Utils.formatValue(attributionMetrics?.totalClickthroughs?.value),
                            Utils.formatValue(attributionMetrics?.averageCTR?.value, 'percent'),
                        ],
                    ]
                );
                break;

            case MEDIA_TABS.ONLINE_VIDEO:
                rows.push(
                    ...[
                        [
                            'Total Investment',
                            'Total Exposures',
                            'Average CPM',
                            'Household Reach',
                            'Average Viewability',
                            'Total Clicks',
                            'Average CTR',
                            'Video Completion Rate (VCR)',
                            'Cost Per Completed Video (CPCV)',
                            'Time Spent With Brand (Hours)',
                        ],
                        [
                            Utils.formatValue(attributionMetrics?.totalSpend?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalImpressions?.value),
                            Utils.formatValue(attributionMetrics?.averageCPM?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalDailyReach?.value),
                            Utils.formatValue(attributionMetrics?.averageViewabilityRate?.value, 'percent'),
                            Utils.formatValue(attributionMetrics?.totalClickthroughs?.value),
                            Utils.formatValue(attributionMetrics?.averageCTR?.value, 'percent'),
                            Utils.formatValue(attributionMetrics?.videoCompleteRate?.value, 'percent'),
                            Utils.formatValue(attributionMetrics?.costPerCompletedVideo?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.timeSpent?.value),
                        ],
                    ]
                );
                break;

            case MEDIA_TABS.STREAMING_TV:
                rows.push(
                    ...[
                        [
                            'Total Investment',
                            'Total Exposures',
                            'Average CPM',
                            'Household Reach',
                            'Cost Per Completed Video (CPCV)',
                            'Time Spent With Brand (Hours)',
                        ],
                        [
                            Utils.formatValue(attributionMetrics?.totalSpend?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalImpressions?.value),
                            Utils.formatValue(attributionMetrics?.averageCPM?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalDailyReach?.value),
                            Utils.formatValue(attributionMetrics?.costPerCompletedVideo?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.timeSpent?.value),
                        ],
                    ]
                );
                break;

            case MEDIA_TABS.AUDIO:
                rows.push(
                    ...[
                        [
                            'Total Investment',
                            'Total Exposures',
                            'Average CPM',
                            'Household Reach',
                            'Companion Banner Clicks',
                            'Average CTR',
                        ],
                        [
                            Utils.formatValue(attributionMetrics?.totalSpend?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalImpressions?.value),
                            Utils.formatValue(attributionMetrics?.averageCPM?.value, 'dollar'),
                            Utils.formatValue(attributionMetrics?.totalDailyReach?.value),
                            Utils.formatValue(attributionMetrics?.totalClickthroughs?.value),
                            Utils.formatValue(attributionMetrics?.averageCTR?.value, 'percent'),
                        ],
                    ]
                );
                break;
        }

        if (performances.length > 0) {
            switch (performanceTabs.activeTab) {
                case PERFORMANCE_TABS.PERFORMANCE_OVERVIEW:
                    rows.push(
                        ...[
                            [], // An empty row.
                            ['Performance Overview'],
                            [
                                'Media Type',
                                'Total Investment',
                                'Total Exposures',
                                'Average CPM',
                                'Household Reach',
                                'Total Clicks',
                                'Conversions',
                            ],
                        ]
                    );

                    rows.push(
                        ...mediaPerformances.map((mediaPerformance: MediaPerformance) => {
                            return [
                                mediaPerformance.mediaType,
                                Utils.formatValue(mediaPerformance.totalCost, 'dollar'),
                                Utils.formatValue(mediaPerformance.impressions),
                                Utils.formatValue(mediaPerformance.CPM, 'dollar'),
                                Utils.formatValue(mediaPerformance.dailyReach),
                                Utils.formatValue(mediaPerformance.clickthroughs),
                                Utils.formatValue(mediaPerformance.offAmazonConversions),
                            ];
                        })
                    );
                    break;

                case PERFORMANCE_TABS.PERFORMANCE_MONITOR:
                    switch (mediaTabs.activeTab) {
                        case MEDIA_TABS.STREAMING_TV:
                            rows.push(
                                ...[
                                    [], // An empty row.
                                    ['Performance Monitor'],
                                    [
                                        'Agency',
                                        'Advertiser',
                                        'Campaign',
                                        'Media Type',
                                        'Total Investment',
                                        'Total Exposures',
                                        'Average CPM',
                                        'Household Reach',
                                        'Total Clicks',
                                        'Average CTR',
                                        'Conversions',
                                    ],
                                ]
                            );

                            rows.push(
                                ...performances.map((performance: Performance) => {
                                    return [
                                        performance.agencyName,
                                        performance.dealerName,
                                        performance.campaignName,
                                        performance.mediaType,
                                        Utils.formatValue(performance.totalCost, 'dollar'),
                                        Utils.formatValue(performance.impressions),
                                        Utils.formatValue(performance.CPM, 'dollar'),
                                        Utils.formatValue(performance.dailyReach),
                                        Utils.formatValue(performance.clickthroughs),
                                        Utils.formatValue(performance.CTR, 'percent'),
                                        Utils.formatValue(performance.offAmazonConversions),
                                    ];
                                })
                            );
                            break;

                        default:
                            rows.push(
                                ...[
                                    [], // An empty row.
                                    ['Performance Monitor'],
                                    [
                                        'Agency',
                                        'Advertiser',
                                        'Campaign',
                                        'Media Type',
                                        'Total Investment',
                                        'Total Exposures',
                                        'Average CPM',
                                        'Household Reach',
                                        'Average Viewability',
                                        'Total Clicks',
                                        'Average CTR',
                                        'Conversions',
                                    ],
                                ]
                            );

                            rows.push(
                                ...performances.map((performance: Performance) => {
                                    return [
                                        performance.agencyName,
                                        performance.dealerName,
                                        performance.campaignName,
                                        performance.mediaType,
                                        Utils.formatValue(performance.totalCost, 'dollar'),
                                        Utils.formatValue(performance.impressions),
                                        Utils.formatValue(performance.CPM, 'dollar'),
                                        Utils.formatValue(performance.dailyReach),
                                        Utils.formatValue(performance.viewabilityRate, 'percent'),
                                        Utils.formatValue(performance.clickthroughs),
                                        Utils.formatValue(performance.CTR, 'percent'),
                                        Utils.formatValue(performance.offAmazonConversions),
                                    ];
                                })
                            );
                    }
                    break;

                case PERFORMANCE_TABS.PROPERTY_SPEND:
                    rows.push(
                        ...[
                            [], // An empty row.
                            ['Property Spend'],
                            ['Site Name', 'Total Spend', 'Total Exposures'],
                        ]
                    );

                    if (propertySpends) {
                        rows.push(
                            ...propertySpends.map((propertySpend: PropertySpend) => {
                                return [
                                    propertySpend.siteName,
                                    Utils.formatValue(propertySpend.totalCost, 'dollar'),
                                    Utils.formatValue(propertySpend.impressions),
                                ];
                            })
                        );
                    }
                    break;

                case PERFORMANCE_TABS.ON_SITE_INTERACTION:
                    rows.push(
                        ...[
                            [], // An empty row.
                            ['On-Site Interactions'],
                            ['Interaction', 'Attributed Traffic'],
                        ]
                    );

                    if (onSiteInteractions) {
                        rows.push(
                            ...onSiteInteractions.map((onSiteInteraction: OnSiteInteraction) => {
                                return [
                                    onSiteInteraction.conversionName,
                                    Utils.formatValue(onSiteInteraction.conversions),
                                ];
                            })
                        );
                    }
                    break;
            }
        }

        return rows;
    };

    return { getCsvData };
}

export default function useReportingPerformanceExport() {
    const agency = useAgencyContext();
    const { advertiserContext } = useAdvertiserContext();
    const { dealer: advertiser } = advertiserContext;

    const { reportingCriteria = null, performanceTabs } = useReportingDashboardPageContext();

    const generateExportFilename = useCallback(
        async (format = '{id}'): Promise<string> => {
            let keywords: string[] = [];

            if (agency) {
                keywords.push(agency.name);
            }

            if (advertiser) {
                keywords.push(advertiser.dealerName);
            }

            if (performanceTabs !== null) {
                switch (performanceTabs.activeTab) {
                    case PERFORMANCE_TABS.PERFORMANCE_OVERVIEW:
                        keywords.push('Performance Overview');
                        break;
                    case PERFORMANCE_TABS.PERFORMANCE_MONITOR:
                        keywords.push('Performance Monitor');
                        break;
                    case PERFORMANCE_TABS.PROPERTY_SPEND:
                        keywords.push('Property Spend');
                        break;
                    case PERFORMANCE_TABS.ON_SITE_INTERACTION:
                        keywords.push('On-Site Interactions');
                        break;
                }
            }

            if (reportingCriteria) {
                if (reportingCriteria.startDate) {
                    keywords.push(moment(reportingCriteria.startDate).format('YYYYMMDD'));
                }

                if (reportingCriteria.endDate) {
                    keywords.push(moment(reportingCriteria.endDate).format('YYYYMMDD'));
                }
            }

            return format.replace('{id}', keywords.join(' - '));
        },
        [agency, advertiser, reportingCriteria, performanceTabs]
    );

    return {
        generateExportFilename,
    };
}
