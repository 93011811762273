import { FormControlLabel, Grid, InputAdornment, Switch } from '@mui/material';
import { CDTextField } from '../../components/CDTextField';
import { AttachMoneyOutlined, PercentOutlined } from '@mui/icons-material';
import { ChangeEvent, useState } from 'react';

export default function FixedVariableFeeComponent(props: {
    label: string;
    fixedFee: number | null;
    variableFee: number | null;
    incrementalCPM: number | null;
    editMode: boolean;
    allowVariable: boolean;
    onChange(fixedFee: number | null, variableFee: number | null, incrementalCPM: number | null): void;
}) {
    const { label, allowVariable, fixedFee, variableFee, incrementalCPM, onChange, editMode } = props;
    const [inputMode, setInputMode] = useState<'fixed' | 'variable'>(fixedFee != null ? 'fixed' : 'variable');

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        let value = Number(event.target.value);
        if (event.target.name === 'incrementalCPM') {
            onChange(fixedFee, variableFee, value);
        } else {
            onChange(
                !allowVariable || inputMode === 'fixed' ? value : null,
                inputMode === 'variable' ? value : null,
                incrementalCPM
            );
        }
    }

    return (
        <>
            <Grid item xs={2}>
                {allowVariable && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={inputMode === 'fixed'}
                                onClick={() => {
                                    if (inputMode === 'variable') {
                                        onChange(0, null, 0);
                                        setInputMode('fixed');
                                    } else {
                                        onChange(null, 0, null);
                                        setInputMode('variable');
                                    }
                                }}
                            />
                        }
                        label={inputMode === 'fixed' ? 'Fixed' : 'Variable'}
                    />
                )}
            </Grid>
            <Grid item xs={1}>
                {label}
            </Grid>

            <Grid item xs={2}>
                <CDTextField
                    label={inputMode === 'fixed' ? 'CPM' : 'CD Variable Fee'}
                    value={inputMode === 'fixed' ? fixedFee : variableFee}
                    fullWidth
                    onChange={handleChange}
                    disabled={!editMode}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {inputMode === 'fixed' && (
                                    <AttachMoneyOutlined fontSize="small" color={editMode ? 'primary' : 'action'} />
                                )}
                                {inputMode === 'variable' && (
                                    <PercentOutlined fontSize="small" color={editMode ? 'primary' : 'action'} />
                                )}
                            </InputAdornment>
                        ),
                    }}
                    inputProps={{ inputMode: 'text', pattern: '[0-9.]{1,5}' }}
                />
            </Grid>

            <Grid item xs={3}>
                {inputMode === 'fixed' && (
                    <CDTextField
                        sx={{ width: '175px' }}
                        name="incrementalCPM"
                        value={incrementalCPM}
                        label="Agency Incremental CPM"
                        onChange={handleChange}
                        disabled={!editMode}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AttachMoneyOutlined fontSize="small" color={editMode ? 'primary' : 'action'} />
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{ inputMode: 'text', pattern: '[0-9.]{1,5}' }}
                    />
                )}
            </Grid>
        </>
    );
}
