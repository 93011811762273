import { Link } from 'react-router-dom';

import Column from '../Column';
import Row from '../Row';

const SidebarHeader = () => {
    return (
        <Column gap={0}>
            <Row alignItems="center" justifyContent="center" sx={{ px: 3, py: 2 }}>
                <Row alignItems="center" justifyContent="center" height={32}>
                    <Link
                        to="campaignManager"
                        style={{
                            display: 'flex',
                            alignContent: 'center',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img src="/Logo_Black_iCAN.svg" />
                    </Link>
                </Row>
            </Row>
        </Column>
    );
};

export default SidebarHeader;
