import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Divider,
    FormControlLabel,
    Grid,
    Paper,
    Stack,
    Switch,
    Tooltip,
    Typography,
} from '@mui/material';
import GridDemo from './GridDemo';
import theme from '../../theme';
import { CDTextField } from '../../components/CDTextField';
import CDDatePicker from '../../components/CDDatePicker';
import CDDateRangePicker from '../../components/CDDateRangePicker';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import Row from '../../components/Row';

function Header(props: { title: string }) {
    return (
        <>
            <Typography variant="h3" sx={{ mt: '20px' }}>
                {props.title}
            </Typography>
            <Divider sx={{ m: 2 }} />
        </>
    );
}

export default function StyleGuide() {
    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Heading>Style Guide</Heading>
                    </Row>
                </Column>
            </Block>

            <Block>
                <Header title="Typography" />
                <Typography variant="h1">H1 Heading</Typography>
                <Typography variant="h2">H2 Heading</Typography>
                <Typography variant="h3">H3 Heading</Typography>
                <Typography variant="h4">H4 Heading</Typography>
                <Typography variant="h5">H5 Heading</Typography>
                <Typography variant="h6">H6 Heading</Typography>
                <Typography variant="body1">
                    body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde
                    suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos
                    laborum fugiat deleniti? Eum quasi quidem quibusdam.
                </Typography>
                <Typography variant="body2">
                    body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde
                    suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos
                    laborum fugiat deleniti? Eum quasi quidem quibusdam.
                </Typography>

                <Header title="Font Weights" />
                <Typography fontWeight={theme.typography.fontWeightLight}>
                    {theme.typography.fontWeightLight} Light{' '}
                </Typography>
                <Typography fontWeight={theme.typography.fontWeightRegular}>
                    {theme.typography.fontWeightRegular} Regular{' '}
                </Typography>
                <Typography fontWeight={theme.typography.fontWeightMedium}>
                    {theme.typography.fontWeightMedium} Medium
                </Typography>
                <Typography fontWeight={theme.typography.fontWeightBold}>
                    {theme.typography.fontWeightBold} Bold
                </Typography>

                <Header title="Button" />
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Primary
                </Typography>

                <Stack direction="row" spacing={2}>
                    <Button variant="text" color="primary">
                        Text
                    </Button>
                    <Button variant="contained" color="primary">
                        Contained
                    </Button>
                    <Button variant="outlined" color="primary">
                        Outlined
                    </Button>
                </Stack>
                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Primary Disabled
                </Typography>

                <Stack direction="row" spacing={2}>
                    <Button variant="text" color="primary" disabled>
                        Text
                    </Button>
                    <Button variant="contained" color="primary" disabled>
                        Contained
                    </Button>
                    <Button variant="outlined" color="primary" disabled>
                        Outlined
                    </Button>
                </Stack>

                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Secondary
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Button variant="text" color="secondary">
                        Text
                    </Button>
                    <Button variant="contained" color="secondary">
                        Contained
                    </Button>
                    <Button variant="outlined" color="secondary">
                        Outlined
                    </Button>
                </Stack>

                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Secondary Disabled
                </Typography>

                <Stack direction="row" spacing={2}>
                    <Button variant="text" color="secondary" disabled>
                        Text
                    </Button>
                    <Button variant="contained" color="secondary" disabled>
                        Contained
                    </Button>
                    <Button variant="outlined" color="secondary" disabled>
                        Outlined
                    </Button>
                </Stack>

                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Colors
                </Typography>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" color="success">
                        Success
                    </Button>
                    <Button variant="contained" color="info">
                        Info
                    </Button>
                    <Button variant="contained" color="warning">
                        Warning
                    </Button>
                    <Button variant="contained" color="error">
                        Error
                    </Button>
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Button variant="outlined" color="success">
                        Success
                    </Button>
                    <Button variant="outlined" color="info">
                        Info
                    </Button>
                    <Button variant="outlined" color="warning">
                        Warning
                    </Button>
                    <Button variant="outlined" color="error">
                        Error
                    </Button>
                </Stack>

                <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                    Sizes
                </Typography>

                <Button sx={{ m: 1 }} variant="contained" size="small" color="primary">
                    Small
                </Button>
                <Button sx={{ m: 1 }} variant="contained" size="medium" color="primary">
                    Medium
                </Button>
                <Button sx={{ m: 1 }} variant="contained" size="large" color="primary">
                    Large
                </Button>

                <Header title="Chip" />
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Chip label="Default" variant="filled" color="default" />
                    <Chip label="Primary" variant="filled" color="primary" />
                    <Chip label="Success" variant="filled" color="success" />
                    <Chip label="Warning" variant="filled" color="warning" />
                    <Chip label="Error" variant="filled" color="error" />
                </Stack>
                <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                    <Chip label="Default" variant="outlined" color="default" />
                    <Chip label="Primary" variant="outlined" color="primary" />
                    <Chip label="Success" variant="outlined" color="success" />
                    <Chip label="Warning" variant="outlined" color="warning" />
                    <Chip label="Error" variant="outlined" color="error" />
                </Stack>

                <Header title="Form Inputs" />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <CDTextField label="Text Field" shrinkLabel={true}></CDTextField>
                    </Grid>
                    <Grid item xs={3}>
                        <CDDatePicker
                            value={new Date()}
                            onChange={(value) => {}}
                            showError={false}
                            label="Date Picker"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CDDateRangePicker
                            label="Date Range"
                            startDate={new Date()}
                            endDate={new Date()}
                            onChange={function (startDate?: Date | undefined, endDate?: Date | undefined): void {}}
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>

                    <Grid item xs={3}>
                        <FormControlLabel
                            labelPlacement="start"
                            control={
                                <>
                                    <Tooltip title="Set the Active Status for this Agency.  Inactivating hides this Agency from selection for Advertiser assignment, Pacing Report, etc.">
                                        <Switch />
                                    </Tooltip>
                                </>
                            }
                            label="Switch"
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}></Grid>
                </Grid>

                <Header title="Paper" />
                <Grid item xs={6}>
                    <Box
                        sx={{
                            p: 2,
                            bgcolor: 'background.default',
                            display: 'grid',
                            gridTemplateColumns: { md: '1fr 1fr' },
                            gap: 2,
                        }}
                    >
                        {[0, 1, 2, 3, 4, 6, 8, 12, 16, 24].map((elevation) => (
                            <Paper
                                sx={{
                                    textAlign: 'center',
                                    lineHeight: '60px',
                                }}
                                key={elevation}
                                elevation={elevation}
                            >
                                {`elevation=${elevation}`}
                            </Paper>
                        ))}
                    </Box>
                </Grid>

                <Header title="Card" />
                <Card variant="elevation" elevation={4}>
                    <CardHeader title="Card Header" />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Card Content
                        </Typography>
                        <Typography variant="body2">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde
                            suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam
                            dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.{' '}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button variant="contained" size="small" color="primary">
                            Card Action
                        </Button>
                    </CardActions>
                </Card>

                <Divider />
                <Typography variant="h3" sx={{ marginTop: '30px' }}>
                    Grid
                </Typography>
                <GridDemo />
            </Block>
        </Column>
    );
}
