import { Link, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ApiService from '../ApiService';
import { BuildInfo } from '../types/BuildInfo';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import Row from './Row';

export default function AppFooter() {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    const [apiBuildInfo, setApiBuildInfo] = useState<BuildInfo>(new BuildInfo());
    const location = useLocation();

    useEffect(() => {
        ApiService.getUiBuildConfig()
            .then((response) => {
                setApiBuildInfo(response.data);
            })
            .catch(() => {});
    }, []);

    const termsOfServiceLink = (
        <Link href={'https://www.cognitionads.com/terms-of-service'} target="_blank" rel="noreferrer">
            terms of service
        </Link>
    );

    let footer: JSX.Element;

    switch (location.pathname) {
        default:
            footer = (
                <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                    <Row gap={2} justifyContent="center">
                        <Typography variant="body2">
                            &copy; {currentYear} CognitionDigital.io, Inc. By using Cognition's platform you are
                            agreeing to our {termsOfServiceLink}.
                        </Typography>
                    </Row>
                    <Row flex={1} gap={0.25} justifyContent="center" alignItems="center">
                        <AdminPanelSettingsIcon color="action" fontSize="small" />
                        <Typography variant="body2">
                            {`Build Info: branch: ${apiBuildInfo.branch} | buildNumber: ${apiBuildInfo.buildNumber} | commitSha: ${apiBuildInfo.commitSha} `}
                        </Typography>
                    </Row>
                </div>
            );
            break;
    }

    return <>{footer}</>;
}
