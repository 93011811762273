import { useEffect, useState } from 'react';
import { Autocomplete, TextField, Box, Alert } from '@mui/material';
import ApiService from '../ApiService';
import Masonry from '@mui/lab/Masonry';
import { AdCreative, AssetFileMetadata } from '../types/AmazonAds';
import { Campaign, CampaignAdGroup } from '../types/Campaign';
import CDMonthDatePicker from './CDMonthDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import CreativePreview from './Creative/CreativePreview';

export interface AdvertiserAdComponentProps {
    campaign?: Campaign;
    dealerId?: number;
    disabled?: boolean;
}

export default function AdvertiserAdComponent(props: AdvertiserAdComponentProps) {
    const [adCreativeMap, setAdCreativeMap] = useState<Map<string, AdCreative[]>>(new Map());
    const [adGroups, setAdGroups] = useState<CampaignAdGroup[]>([]);
    const [adGroup, setAdGroup] = useState<CampaignAdGroup | null>(null);
    const [adGroupsByDate, setAdGroupsByDate] = useState<Map<string, CampaignAdGroup[]>>(new Map());
    const [selectedDate, setSelectedDate] = useState<Dayjs | Date | string>(dayjs(new Date()));
    const [adCreativeList, setAdCreativList] = useState<AdCreative[]>([]);

    const fetchVastXML = async (vastUrl: string) => {
        try {
            const response = await fetch(vastUrl);
            const xmlText = await response.text();
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(xmlText, 'text/xml');
            // Extract the first MediaFile URL
            const mediaFile = xmlDoc.querySelector('MediaFile');
            if (mediaFile && mediaFile.textContent) {
                return mediaFile.textContent.trim();
            } else {
                return '';
            }
        } catch (err) {
            console.log('cannot parse the file');
            return '';
        }
    };

    const dateAndMonthRange = (startDate: Date, endDate: Date) => {
        endDate.setUTCHours(12);
        const dates = [];
        while (startDate <= endDate) {
            const displayMonth = startDate.getUTCMonth() + 1;
            dates.push([startDate.getUTCFullYear(), displayMonth].join('-'));
            startDate = new Date(startDate.setUTCMonth(displayMonth));
        }
        return dates;
    };

    const generateAdGroupsMap = () => {
        const adGroupsMapByDate: Map<string, CampaignAdGroup[]> = new Map();
        props.campaign?.adGroups.forEach((adGroup: CampaignAdGroup) => {
            let startDate = new Date();
            let endDate = new Date();
            if (adGroup.startDateTime) {
                startDate = new Date(adGroup.startDateTime);
            }
            if (adGroup.endDateTime) {
                endDate = new Date(adGroup.endDateTime);
            }
            const adGroupRange = dateAndMonthRange(startDate, endDate);

            adGroupRange.forEach((yearMonth) => {
                let adGroups = adGroupsMapByDate.get(yearMonth);
                if (!adGroups) {
                    adGroups = [];
                }
                adGroups?.push(adGroup);
                adGroupsMapByDate.set(yearMonth, adGroups);
            });
        });
        setAdGroupsByDate(adGroupsMapByDate);
        return adGroupsMapByDate;
    };

    const getAssetFileMetadata = (type: string) => {
        const metadata: AssetFileMetadata = {
            contentType: type,
            durationInSeconds: 0,
            extension: '',
            height: 0,
            width: 0,
            resolutionHeight: 0,
            resolutionWidth: 0,
            sizeInBytes: 0,
            audioSampleRateInHz: 0,
            aspectRatio: '',
        };
        return metadata;
    };

    const fetchAdCreatives = async (dealerId: number, adGroupsMap: Map<string, CampaignAdGroup[]>) => {
        const creativeMap: Map<string, AdCreative[]> = new Map();
        ApiService.getDealerDspAdCreatives(dealerId).then(async (response) => {
            for (const creative of response.data) {
                for (const asset of creative.assets) {
                    if (asset.assetSubTypes === 'THIRD_PARTY_VAST_VPAID') {
                        const metadata = getAssetFileMetadata('video/mp4');
                        const file = await fetchVastXML(asset.fileMetadata);
                        if (!file) {
                            continue;
                        }
                        asset.fileMetadataObject = metadata;
                        asset.url = file;
                    } else if (asset.assetSubTypes !== 'THIRD_PARTY_DISPLAY') {
                        const parsedMetadata: AssetFileMetadata = JSON.parse(asset.fileMetadata);
                        asset.fileMetadataObject = parsedMetadata;
                    } else {
                        const metadata = getAssetFileMetadata('HTML');
                        asset.fileMetadataObject = metadata;
                    }
                }

                for (const association of creative.dspCreativeAssociations) {
                    let creatives = creativeMap.get(association.dspAdGroupId);
                    if (!creatives) {
                        creatives = [];
                    }
                    if (!creatives.includes(creative)) {
                        creatives?.push(creative);
                    }
                    creativeMap.set(association.dspAdGroupId, creatives);
                }
            }
            setAdCreativeMap(creativeMap);
            setInitialPreview(creativeMap, adGroupsMap);
        });
    };

    const getSortedDistinctCreatives = (allCreatives: AdCreative[]) => {
        const distinctCreatives = allCreatives.filter(distictOnly);
        distinctCreatives?.sort(
            (a, b) => new Date(b.lastUpdatedDateTime).getTime() - new Date(a.lastUpdatedDateTime).getTime()
        );
        const creativeList: AdCreative[] = [];
        distinctCreatives?.forEach((adCreative, index) => {
            if (adCreative.assets && adCreative.assets.length > 0) {
                creativeList.push(adCreative);
            }
        });
        return creativeList;
    };

    useEffect(() => {
        const adGroupsMap = generateAdGroupsMap();
        if (props.dealerId) {
            fetchAdCreatives(props.dealerId, adGroupsMap);
        }
    }, []);

    const setInitialPreview = (creativeMap: Map<string, AdCreative[]>, adGroupsMap: Map<string, CampaignAdGroup[]>) => {
        const date = dayjs(new Date());
        const month = date.toDate().getMonth() + 1;
        const year = date.toDate().getFullYear();
        const monthYear = `${year}-${month}`;
        let adGroupList = adGroupsMap.get(monthYear);
        const allCreatives: any[] = [];
        adGroupList?.forEach((_adGroup) => {
            if (_adGroup?.dspAdGroupId) {
                const creatives = creativeMap.get(_adGroup?.dspAdGroupId);
                if (creatives) {
                    allCreatives.push(...creatives);
                }
            }
        });

        const allAdGroup: CampaignAdGroup = {
            id: 0,
            name: 'All',
        };
        if (adGroupList && adGroupList?.indexOf(allAdGroup) < 0) {
            adGroupList?.push(allAdGroup);
        }
        const creativeList = getSortedDistinctCreatives(allCreatives);
        setAdGroups(adGroupList ? (adGroupList as CampaignAdGroup[]) : []);
        setAdGroup(allAdGroup);
        setAdCreativList(creativeList);
    };

    function distictOnly(value: any, index: any, array: any[]) {
        return array.indexOf(value) === index;
    }

    const setCreatives = (monthYear: string) => {
        const allCreatives: any[] = [];
        if (adGroup && adGroup.name === 'All') {
            const allAdGroups = adGroupsByDate.get(monthYear);
            allAdGroups?.forEach((_adGroup) => {
                if (_adGroup?.dspAdGroupId) {
                    const creatives = adCreativeMap.get(_adGroup?.dspAdGroupId);
                    if (creatives) {
                        allCreatives.push(...creatives);
                    }
                }
            });
        }

        if (adGroup?.dspAdGroupId) {
            const creatives = adCreativeMap.get(adGroup?.dspAdGroupId);
            if (creatives) {
                allCreatives.push(...creatives);
            }
        }

        const creativeList = getSortedDistinctCreatives(allCreatives);
        setAdCreativList(creativeList);
    };

    const handleChange = (newDate: Dayjs | null): void => {
        if (newDate) {
            const month = newDate.toDate().getMonth() + 1;
            const year = newDate.toDate().getFullYear();
            const allAdGroup: CampaignAdGroup = {
                id: 0,
                name: 'All',
            };
            const monthYear = `${year}-${month}`;
            let adGroupList = adGroupsByDate.get(monthYear);

            if (adGroupList && adGroupList?.indexOf(allAdGroup) < 0) {
                adGroupList?.push(allAdGroup);
            }

            if (!adGroupList) {
                adGroupList = [];
            }
            adGroupList.sort((a, b) => {
                if (a.name && b.name) {
                    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                }
                return 1;
            });
            setSelectedDate(newDate);
            setAdGroups(adGroupList);
            setAdGroup(allAdGroup);
            setCreatives(monthYear);
        }
    };

    return (
        <Box>
            {props.campaign?.mediaType?.toLowerCase() === 'audio' && (
                <Alert severity="warning">Audio creative previews are not supported at this time</Alert>
            )}
            {props.campaign?.mediaType?.toLowerCase() !== 'audio' && (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <CDMonthDatePicker
                            label="Campaign Month"
                            value={selectedDate}
                            onChange={handleChange}
                            sx={{ width: 200 }}
                        />

                        <Autocomplete
                            sx={{
                                width: '400px',
                                marginLeft: '10px',
                            }}
                            value={adGroup}
                            size="small"
                            options={adGroups}
                            id="select-adGroup"
                            // disabled={enabled}
                            getOptionLabel={(option) => option?.name || ''}
                            renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                    {option.name}
                                </li>
                            )}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            onChange={(e, value) => {
                                setAdGroup(value);
                            }}
                            renderInput={(params) => <TextField {...params} label="Select Ad Group" />}
                        />
                    </Box>
                    <Box sx={{ m: 5 }} />
                    <Masonry columns={1} spacing={2} defaultSpacing={1}>
                        <CreativePreview adCreatives={adCreativeList} parseXML={fetchVastXML} />
                    </Masonry>
                </>
            )}
        </Box>
    );
}
