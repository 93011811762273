import { CreativePixel } from '../types/HAT/CreativePixel';

const getMediaTypeLabel = (mediaType: string): string => {
    switch (mediaType) {
        case 'OLV':
            return 'Online Video';
        case 'STV':
            return 'Streaming TV';
        default:
            return mediaType;
    }
};

const identifyPlatforms = (creativePixels: CreativePixel[]): string[] => {
    return creativePixels
        .map((creativePixel: CreativePixel) => {
            return creativePixel.platform;
        })
        .filter((value: string, index: number, self: string[]) => {
            return self.indexOf(value) === index;
        });
};

const identifyMediaTypes = (creativePixels: CreativePixel[]): string[] => {
    return creativePixels
        .map((creativePixel: CreativePixel) => {
            return creativePixel.mediaType;
        })
        .filter((value: string, index: number, self: string[]) => {
            return self.indexOf(value) === index;
        });
};

export { getMediaTypeLabel, identifyPlatforms, identifyMediaTypes };
