import { useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import QuickSightDashboard from '../../components/QuickSightDashboard';
import Heading from '../../components/Heading';
import Block from '../../components/Block';
import Column from '../../components/Column';
import Row from '../../components/Row';
import { LinkButton } from '../../components/LinkButton';

export default function QuickSightDashboardPage() {
    const [dashboardId, setDashboardId] = useState<string | null>(null);
    const [apiUser, setApiUser] = useState(false);

    useEffect(() => {
        ApiService.getQuickSightDashboard().then((response) => {
            setApiUser(response.data.apiUser);
            setDashboardId(response.data.dashboardId);
        });
    }, []);

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Heading>Reporting Suite</Heading>
                {apiUser && (
                    <Row justifyContent="flex-end">
                        <LinkButton href="/analyticsapi" variant="outlined">
                            Analytics API
                        </LinkButton>
                    </Row>
                )}
            </Block>

            {dashboardId && <QuickSightDashboard dashboardId={dashboardId} />}
        </Column>
    );
}
