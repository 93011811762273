import * as React from 'react';

import { useContext, useEffect } from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { AppBar, Box, Button, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material';
import {
    Close as CloseIcon,
    FileDownload as FileDownloadIcon,
    SendOutlined as SendOutlinedIcon,
} from '@mui/icons-material';
import { toJpeg } from 'html-to-image';
import { jsPDF } from 'jspdf';

import {
    ATTRIBUTION_EXPORT_METHOD_DOWNLOAD,
    ATTRIBUTION_EXPORT_METHOD_EMAIL,
    // ATTRIBUTION_EXPORT_TYPE_CSV,
    // ATTRIBUTION_EXPORT_TYPE_PDF,
    AttributionExport,
} from '../../types/AttributionExport';

import { InfoMessageContext } from '../../components/GlobalInfoMessage';
import ApiService from '../../ApiService';

import { TABS, useAttributionPageContext } from '../../hooks/useAttributionPage';
import useAttributionExport from '../../hooks/useAttributionExport';
import { usePrintContext } from '../../hooks/usePrint';
import AdvertiserMatchbackTab from './AdvertiserMatchbackTab';
import PolkMatchbackTab from './PolkMatchbackTab';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export type AttributionExportDialogProps = {
    onClose(): void;
    open: boolean;
    attributionExport: AttributionExport;
};

export default function AttributionExportDialog(props: AttributionExportDialogProps) {
    const { onClose, open, attributionExport } = props;

    const { showMessage } = useContext(InfoMessageContext);
    const { setPrint } = usePrintContext();
    const { attributionDate, dealer, tab } = useAttributionPageContext();
    const { generateExportFilename } = useAttributionExport();

    const exportAsPdf = () => {
        let filename = '';

        switch (tab) {
            case TABS.POLK_REPORTED_SALES:
                filename = generateExportFilename('{id}-polk-attribution.pdf');
                break;

            case TABS.ADVERTISER_REPORTED_SALES:
                filename = generateExportFilename('{id}-advertiser-attribution.pdf');
                break;

            default:
                filename = generateExportFilename('{id}-attribution.pdf');
                break;
        }

        const toImageElement = document.getElementById('attribution-export');

        if (toImageElement !== null) {
            const toImageOptions: any = {
                quality: 1.0,
                fetchRequestInit: {
                    cache: 'no-cache',
                },
                style: {
                    backgroundColor: 'white',
                    overflow: 'hidden',
                },
            };

            toJpeg(toImageElement, toImageOptions).then(function (dataUrl) {
                const pdf = new jsPDF();
                const imageProps = pdf.getImageProperties(dataUrl);

                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imageProps.height * pdfWidth) / imageProps.width;
                const pdfImageScaleRatio = 0.9;

                pdf.addImage(dataUrl, 'JPEG', 10, 10, pdfWidth * pdfImageScaleRatio, pdfHeight * pdfImageScaleRatio);

                if (attributionExport.exportMethod === ATTRIBUTION_EXPORT_METHOD_EMAIL) {
                    const formData = new FormData();

                    if (dealer) {
                        formData.append('dealerId', dealer.id as any);
                    }

                    formData.append('exportMethod', attributionExport.exportMethod);
                    formData.append('exportType', attributionExport.exportType);

                    formData.append('attributionType', attributionExport.attributionType);
                    formData.append('attributionDate', attributionDate.toISOString());
                    formData.append('file', pdf.output('blob'), filename);

                    ApiService.processAttributionExport(formData)
                        .then(() => {
                            showMessage('success', 'Your report has been emailed to you!');
                        })
                        .then(onClose);
                } else {
                    pdf.save(filename, {
                        returnPromise: true,
                    })
                        .then(() => {
                            showMessage('success', 'Downloading your report file.');
                        })
                        .then(onClose);
                }
            });
        }
    };

    useEffect(() => {
        setPrint(open);

        if (open === true) {
            setTimeout(() => {
                // Trigger this to rerender Apex Charts
                window.dispatchEvent(new Event('resize'));
            }, 1000);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Dialog maxWidth="xl" open={open} onClose={onClose} TransitionComponent={Transition}>
            <AppBar sx={{ position: 'relative', paddingRight: '0 !important' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        sx={{ color: (theme) => theme.palette.action.active }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        {attributionExport.exportMethod === ATTRIBUTION_EXPORT_METHOD_DOWNLOAD && 'Export PDF'}
                        {attributionExport.exportMethod === ATTRIBUTION_EXPORT_METHOD_EMAIL && 'Email PDF'}
                    </Typography>
                    {attributionExport.exportMethod === ATTRIBUTION_EXPORT_METHOD_DOWNLOAD && (
                        <Button
                            autoFocus
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                exportAsPdf();
                            }}
                            startIcon={<FileDownloadIcon />}
                        >
                            Download PDF
                        </Button>
                    )}
                    {attributionExport.exportMethod === ATTRIBUTION_EXPORT_METHOD_EMAIL && (
                        <Button
                            autoFocus
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                exportAsPdf();
                            }}
                            startIcon={<SendOutlinedIcon />}
                        >
                            Send PDF via Email
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <DialogContent
                dividers
                sx={{
                    pt: 3,
                    pb: 5,
                    // maxWidth: 1250,
                }}
            >
                <Box id="attribution-export">
                    {tab === TABS.POLK_REPORTED_SALES && <PolkMatchbackTab showExportButton={false} />}
                    {tab === TABS.ADVERTISER_REPORTED_SALES && <AdvertiserMatchbackTab showExportButton={false} />}
                </Box>
            </DialogContent>
        </Dialog>
    );
}
