import { useEffect, useState } from 'react';
import { HatCriteria } from '../../types/Criteria/HatCriteria';

const LOCAL_STORAGE_CRITERIA_KEY = 'analytics.hat.criteria';

interface HatCriteriaState {
    isHatCriteriaSetup: boolean;
    setIsHatCriteriaSetup: (isHatCriteriaSetup: boolean) => void;
    defaultHatCriteria: HatCriteria;
    setDefaultHatCriteria: (hatCriteria: HatCriteria) => void;
    hatCriteria: HatCriteria;
    setHatCriteria: (hatCriteria: HatCriteria) => void;
    resetHatCriteria: () => void;
}

interface HatCriteriaProps {
    isHatCriteriaSetup?: boolean;
    hatCriteria?: HatCriteria;
}

const useHatCriteria = (props?: HatCriteriaProps): HatCriteriaState => {
    const [isHatCriteriaSetup, setIsHatCriteriaSetup] = useState<boolean>(props?.isHatCriteriaSetup || false);
    const [defaultHatCriteria, setDefaultHatCriteria] = useState<HatCriteria>(() => {
        if (props?.hatCriteria) {
            return props.hatCriteria;
        }

        return {
            agencyId: null,
            advertiserId: null,
            startDate: new Date(),
            endDate: new Date(),
        };
    });
    const [hatCriteria, setHatCriteria] = useState<HatCriteria>(() => {
        const rawHatCriteria: string | null = localStorage.getItem(LOCAL_STORAGE_CRITERIA_KEY);

        if (rawHatCriteria) {
            return { ...JSON.parse(rawHatCriteria) };
        }

        return defaultHatCriteria;
    });

    const resetHatCriteria = (): void => {
        setHatCriteria(defaultHatCriteria);
    };

    useEffect((): void => {
        localStorage.setItem(LOCAL_STORAGE_CRITERIA_KEY, JSON.stringify(hatCriteria));
    }, [hatCriteria]);

    return {
        isHatCriteriaSetup,
        setIsHatCriteriaSetup,
        defaultHatCriteria,
        setDefaultHatCriteria,
        setHatCriteria,
        hatCriteria,
        resetHatCriteria,
    };
};

export type { HatCriteriaProps, HatCriteriaState };
export { useHatCriteria };
