import { useMemo } from 'react';

import { MediaPerformance } from '../types/MediaPerformance';
import { Performance } from '../types/CampaignPerformance';
import { ReportingCriteria } from '../types/Criteria/ReportingCriteria';
import { isPerformanceMediaType } from '../hooks/useCampaignPerformance';
import { useUserContext } from '../hooks/Contexts/UserContext';
// import Utils from '../components/Utils';

interface UseProps {
    reportingCriteria: ReportingCriteria;
    performances: Performance[];
}

interface UseState {
    mediaTypes: string[];
    mediaPerformances: MediaPerformance[];
}

const useReportingMediaPerformances = ({ reportingCriteria, performances }: UseProps): UseState => {
    const { userContext } = useUserContext();

    const mediaTypes = useMemo((): string[] => {
        if (reportingCriteria.mediaType) {
            return [reportingCriteria.mediaType];
        }

        if (userContext.isAdmin()) {
            return ['Display', 'Video', 'Online Video', 'Streaming TV', 'Audio'];
        }

        return ['Display', 'Online Video', 'Streaming TV', 'Audio'];
    }, [reportingCriteria.mediaType, userContext]);

    const mediaPerformances = useMemo((): MediaPerformance[] => {
        return mediaTypes.map((mediaType: string) => {
            let item: MediaPerformance = {
                mediaType: mediaType,
                totalCost: 0,
                impressions: 0,
                CPM: 0,
                dailyReach: 0,
                clickthroughs: 0,
                offAmazonConversions: 0,
            };

            const mediaTypePerformances: Performance[] = performances.filter((perfomance: Performance): boolean => {
                return isPerformanceMediaType(perfomance, mediaType);
            });

            if (mediaTypePerformances.length > 0) {
                mediaTypePerformances.forEach((perfomance: Performance) => {
                    item.totalCost += perfomance.totalCost;
                    item.impressions += perfomance.impressions;
                    item.CPM += perfomance.CPM;
                    item.dailyReach += perfomance.dailyReach;
                    item.clickthroughs += perfomance.clickthroughs;
                    item.offAmazonConversions += perfomance.offAmazonConversions;
                });

                item.CPM /= mediaTypePerformances.length;
            }

            return item;
        });
    }, [mediaTypes, performances]);

    return { mediaTypes, mediaPerformances };
};

export type { UseProps as UseReportingMediaPerformanceProps, UseState as UseReportingMediaPerformanceState };
export { useReportingMediaPerformances };
