import { useEffect, useState } from 'react';
import { AttributionCriteria } from '../../types/Criteria/AttributionCriteria';

interface AttributionCriteriaState {
    isAttributionCriteriaSetup: boolean;
    setIsAttributionCriteriaSetup: (isAttributionCriteriaSetup: boolean) => void;
    defaultAttributionCriteria: AttributionCriteria;
    setDefaultAttributionCriteria: (attributionCriteria: AttributionCriteria) => void;
    attributionCriteria: AttributionCriteria;
    setAttributionCriteria: (attributionCriteria: AttributionCriteria) => void;
    resetAttributionCriteria: () => void;
}

interface AttributionCriteriaProps {
    isAttributionCriteriaSetup?: boolean;
    attributionCriteria?: AttributionCriteria;
}

const useAttributionCriteria = (props?: AttributionCriteriaProps): AttributionCriteriaState => {
    const [isAttributionCriteriaSetup, setIsAttributionCriteriaSetup] = useState<boolean>(
        props?.isAttributionCriteriaSetup || false
    );
    const [defaultAttributionCriteria, setDefaultAttributionCriteria] = useState<AttributionCriteria>(() => {
        if (props?.attributionCriteria) {
            return props.attributionCriteria;
        }

        return {
            agencyIds: [],
            advertiserIds: [],

            agencies: [],
            advertisers: [],
        };
    });
    const [attributionCriteria, setAttributionCriteria] = useState<AttributionCriteria>(() => {
        const rawAttributionCriteria: string | null = localStorage.getItem('attribution.criteria');

        if (rawAttributionCriteria) {
            return { ...JSON.parse(rawAttributionCriteria), strategyType: '' };
        }

        return defaultAttributionCriteria;
    });

    const resetAttributionCriteria = (): void => {
        setAttributionCriteria(defaultAttributionCriteria);
    };

    useEffect((): void => {
        localStorage.setItem(
            'attribution.criteria',
            JSON.stringify({
                ...attributionCriteria,
                agencies: [],
                advertisers: [],
            })
        );
    }, [attributionCriteria]);

    return {
        isAttributionCriteriaSetup,
        setIsAttributionCriteriaSetup,
        defaultAttributionCriteria,
        setDefaultAttributionCriteria,
        setAttributionCriteria,
        attributionCriteria,
        resetAttributionCriteria,
    };
};

export type { AttributionCriteriaProps, AttributionCriteriaState };
export { useAttributionCriteria };
