import { styled, tableCellClasses, tableRowClasses } from '@mui/material';
import {
    // Table,
    // TableBody,
    TableCell,
    // TableContainer,
    // TableHead,
    // TableRow,
    Tooltip,
} from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';

import { Table as BaseTable } from '../../components/Table';
import Printable from '../../components/Printable';
import { RowProps } from '../../components/Row';
import Row from '../../components/Row';

export const Table = styled(BaseTable)(({ theme }) => ({
    '&': {
        tableLayout: 'fixed',
    },
    [`& .${tableRowClasses.root} .${tableCellClasses.root}:nth-of-type(1)`]: {
        textAlign: 'left',
        width: '15vw',
    },
    [`& .${tableRowClasses.root} .${tableCellClasses.root}`]: {
        textAlign: 'right',
        width: '10vw',
    },
}));

export const TablePrimaryCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightMedium,
    },
}));

export type TableCellTextProps = RowProps & {
    children: React.ReactElement | string;
    tooltip: string;
};

export function TableCellText({ children, tooltip, ...props }: TableCellTextProps) {
    return (
        <Row gap={0.5} alignItems="center" justifyContent="flex-end" {...props}>
            {children}

            <Printable show={false}>
                <Tooltip title={tooltip}>
                    <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                </Tooltip>
            </Printable>
        </Row>
    );
}
