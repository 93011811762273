import { Tab, Tabs, TabsProps, Tooltip } from '@mui/material';
import { HelpOutlineOutlined as HelpOutlineOutlinedIcon } from '@mui/icons-material';
import { TabsContainer, TabsContainerProps } from '../../../../../components/Tabs';
import Row from '../../../../../components/Row';

export const TABS = {
    ALL: 0,
    DISPLAY: 1,
    ONLINE_VIDEO: 2,
    STREAMING_TV: 3,
    AUDIO: 4,
    VIDEO: 5,
};

type ReportingMediaTabsProps = {
    slotProps?: {
        tabsContainer?: TabsContainerProps;
    };
} & TabsProps;

export default function ReportingMediaTabs(props: ReportingMediaTabsProps) {
    const { slotProps = {}, ...tabsProps } = props;

    return (
        <TabsContainer sx={{ mt: 2 }} {...slotProps?.tabsContainer}>
            <Tabs {...tabsProps}>
                <Tab value={TABS.ALL} label="All Media" />
                <Tab value={TABS.DISPLAY} label="Display" />
                <Tab value={TABS.ONLINE_VIDEO} label="Online Video" />
                <Tab value={TABS.STREAMING_TV} label="Streaming TV" />
                <Tab value={TABS.AUDIO} label="Audio" />
                <Tab
                    value={TABS.VIDEO}
                    label={
                        <Row gap={1} alignItems="center" width="auto">
                            Video
                            <Tooltip title="Video is a legacy media type that may contain a combination of one or many Online Video and or Streaming TV campaigns.">
                                <HelpOutlineOutlinedIcon color="action" sx={{ fontSize: 16 }} />
                            </Tooltip>
                        </Row>
                    }
                />
            </Tabs>
        </TabsContainer>
    );
}
