enum SimpleCountType {
    TOTAL_AD_INFLUENCED_CONVERSIONS_CLICK_TO_CALLS = 'total-ad-influenced-conversions-click-to-calls',
    TOTAL_AD_INFLUENCED_CONVERSIONS_IDENTIFIED_VISITORS = 'total-ad-influenced-conversions-identified-visitors',
    TOTAL_AD_INFLUENCED_CONVERSIONS_SERVICE_PAGE_VIEWS = 'total-ad-influenced-conversions-service-page-views',
    TOTAL_AD_INFLUENCED_CONVERSIONS_SPECIALS_PAGE_VISITS = 'total-ad-influenced-conversions-specials-page-visits',
    TOTAL_AD_INFLUENCED_CONVERSIONS_SRP_VIEWS = 'total-ad-influenced-conversions-srp-views',
    TOTAL_AD_INFLUENCED_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS = 'total-ad-influenced-conversions-total-hours-and-directions-views',
    TOTAL_AD_INFLUENCED_CONVERSIONS_VDP_VIEWS = 'total-ad-influenced-conversions-vdp-views',
    TOTAL_AD_INFLUENCED_CONVERSIONS = 'total-ad-influenced-conversions',
    TOTAL_AD_INFLUENCED_TRAFFIC_DIRECT = 'total-ad-influenced-traffic-direct',
    TOTAL_AD_INFLUENCED_TRAFFIC_ORGANIC_SEARCH = 'total-ad-influenced-traffic-organic-search',
    TOTAL_AD_INFLUENCED_TRAFFIC_PAID_SEARCH = 'total-ad-influenced-traffic-paid-search',
    TOTAL_AD_INFLUENCED_TRAFFIC_REFERRAL = 'total-ad-influenced-traffic-referral',
    TOTAL_AD_INFLUENCED_TRAFFIC_SOCIAL = 'total-ad-influenced-traffic-social',
    TOTAL_AD_INFLUENCED_TRAFFIC_TOTAL_VISITS = 'total-ad-influenced-traffic-total-visits',
    TOTAL_AD_INFLUENCED_TRAFFIC = 'total-ad-influenced-traffic',
    TOTAL_CONVERSIONS_AMAZON_DIRECT = 'total-conversions-amazon-direct',
    TOTAL_CONVERSIONS_CLICK_TO_CALLS = 'total-conversions-click-to-calls',
    TOTAL_CONVERSIONS_DIRECT = 'total-conversions-direct',
    TOTAL_CONVERSIONS_IDENTIFIED_VISITORS = 'total-conversions-identified-visitors',
    TOTAL_CONVERSIONS_ORGANIC = 'total-conversions-organic',
    TOTAL_CONVERSIONS_PAID_SEARCH = 'total-conversions-paid-search',
    TOTAL_CONVERSIONS_REFERRAL = 'total-conversions-referral',
    TOTAL_CONVERSIONS_SERVICE_PAGE_VIEWS = 'total-conversions-service-page-views',
    TOTAL_CONVERSIONS_SOCIAL = 'total-conversions-social',
    TOTAL_CONVERSIONS_SPECIALS_PAGE_VISITS = 'total-conversions-specials-page-visits',
    TOTAL_CONVERSIONS_SRP_VIEWS = 'total-conversions-srp-views',
    TOTAL_CONVERSIONS_TOTAL_HOURS_AND_DIRECTIONS_VIEWS = 'total-conversions-total-hours-and-directions-views',
    TOTAL_CONVERSIONS_VDP_VIEWS = 'total-conversions-vdp-views',
    TOTAL_CONVERSIONS = 'total-conversions',
    TOTAL_UNIQUE_TRAFFIC_DIRECT = 'total-unique-traffic-direct',
    TOTAL_UNIQUE_TRAFFIC_ORGANIC_SEARCH = 'total-unique-traffic-organic-search',
    TOTAL_UNIQUE_TRAFFIC_PAID_SEARCH = 'total-unique-traffic-paid-search',
    TOTAL_UNIQUE_TRAFFIC_REFERRAL = 'total-unique-traffic-referral',
    TOTAL_UNIQUE_TRAFFIC_SOCIAL = 'total-unique-traffic-social',
    TOTAL_UNIQUE_TRAFFIC_TOTAL_VISITS = 'total-unique-traffic-total-visits',
    TOTAL_UNIQUE_TRAFFIC = 'total-unique-traffic',
}

export { SimpleCountType };
