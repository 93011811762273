import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Alert, Divider, FormControlLabel, Stack, Switch, Tooltip } from '@mui/material';
import { Dealer } from '../../types/Dealer';
import { TrackingStatus, Tracking } from '../../types/HAT/Tracking';
import ApiService from '../../ApiService';
import Block from '../../components/Block';
import Column from '../../components/Column';
import ContentCard from './partials/ContentCard';
import CreativePixelTabsCard from './partials/HAT/CreativePixelTabsCard';
import TrackingStatusChip from './partials/HAT/TrackingStatusChip';

export default function HAT(props: { dealer: Dealer; handleChange: (event: ChangeEvent<HTMLInputElement>) => void }) {
    const { dealer, handleChange } = props;

    const [tracking, setTracking] = useState<Tracking | null>(null);

    const fetchTracking = useCallback((advertiserId: number) => {
        ApiService.getHatTracking(advertiserId).then((response) => {
            setTracking(response.data);
        });
    }, []);

    useEffect(() => {
        if (dealer?.id) {
            fetchTracking(dealer.id);
        }
    }, [fetchTracking]);

    const snippetData = {
        title: getTitleElement('Tracking Code'),
        mainContent: dealer.tfSnippet.replaceAll('Lassoo Start', 'HAT Start').replaceAll('Lassoo End', 'HAT End'),
        description:
            tracking && tracking.status === TrackingStatus.DEAD ? (
                <Alert severity="error">Ensure your Tracking Code is installed correctly.</Alert>
            ) : (
                "Below is the Website Tag for this advertiser. Copy and paste it in the code of every page of your website, immediately after the 'head' element. This can be done through a Tag Manager like GTM. Don’t add more than one tag to each page."
            ),
    };

    const apiKeyData = {
        title: 'API Token',
        mainContent: dealer.tfApiToken,
        description: 'Below is the API Token for this advertiser. Use it when sending events programmatically.',
    };

    function getTitleElement(title: string) {
        return (
            <>
                <Stack direction="row" sx={{ alignItems: 'center' }}>
                    <span>{title}</span>
                    <span style={{ flex: 1 }} />
                    <TrackingStatusChip tracking={tracking} />
                </Stack>
            </>
        );
    }

    return (
        <>
            <Block>
                <Tooltip
                    arrow
                    title={
                        dealer.agency?.tfUserIsActive
                            ? ''
                            : 'Agency does not have HAT enabled for this Advertiser. Contact your account rep for help.'
                    }
                >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={dealer.tfTokenIsActive}
                                onChange={handleChange}
                                name={'tfTokenIsActive'}
                                disabled={dealer.agency?.tfUserIsActive ? false : true}
                            />
                        }
                        label="Enable HAT Integration"
                    />
                </Tooltip>
            </Block>

            <Divider />

            <Block py={3}>
                <Column gap={4}>
                    {!dealer.tfTokenIsActive && (
                        <Alert severity="info">
                            HAT Integration is currently disabled. Please enable it to view tracking code, API token and
                            creative pixels.
                        </Alert>
                    )}

                    {dealer.tfTokenIsActive && dealer.tfApiToken && (
                        <>
                            <ContentCard cardData={snippetData} />

                            <CreativePixelTabsCard advertiser={dealer} />

                            <ContentCard cardData={apiKeyData} />
                        </>
                    )}
                </Column>
            </Block>
        </>
    );
}
