import { FC } from 'react';
import { Grid } from '@mui/material';
import Column from '../../../../components/Column';
import HatConversionByActionPieChartCard from '../Charts/HatConversionByActionPieChartCard';
import HatConversionByTypePieChartCard from '../Charts/HatConversionByTypePieChartCard';

const HatConversionTrafficChartView: FC = (): JSX.Element => {
    return (
        <Column gap={3}>
            <Grid container spacing={2}>
                <Grid item xl={6} xs={12}>
                    <HatConversionByTypePieChartCard />
                </Grid>

                <Grid item xl={6} xs={12}>
                    <HatConversionByActionPieChartCard />
                </Grid>
            </Grid>
        </Column>
    );
};

export default HatConversionTrafficChartView;
