import { useEffect, useMemo, useRef, useState } from 'react';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { PageableRequest } from '../types/AxiosPageableResponse';
import { PAGEABLE_SIZE } from '../types/AxiosPageableResponse';

const useDataGridRowCount = (totalElements?: number): number => {
    const rowCountRef = useRef<number>(totalElements || 0);

    const rowCount = useMemo((): number => {
        if (totalElements !== undefined) {
            rowCountRef.current = totalElements;
        }
        return rowCountRef.current;
    }, [totalElements]);

    return rowCount;
};

const useDataGridPageable = (initialPageable: Partial<PageableRequest> = {}) => {
    const [pageable, setPageable] = useState<PageableRequest>({
        page: initialPageable.page ?? 0,
        size: initialPageable.size ?? PAGEABLE_SIZE,
        sort: initialPageable.sort ?? 'desc',
        sortBy: initialPageable.sortBy ?? 'date',
    });

    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        page: pageable.page,
        pageSize: pageable.size,
    });

    const [sortModel, setSortModel] = useState<GridSortModel>((): GridSortModel => {
        return [
            {
                field: pageable.sortBy as string,
                sort: pageable.sort,
            },
        ];
    });

    // Update pageable when pagination or sort changes
    useEffect(() => {
        setPageable((prev) => ({
            ...prev,
            page: paginationModel.page,
            size: paginationModel.pageSize,
            sortBy: sortModel?.[0]?.field || 'date',
            sort: sortModel?.[0]?.sort === 'desc' ? 'desc' : 'asc',
        }));
    }, [paginationModel, sortModel]);

    // Reset page to 0 when sort changes
    useEffect(() => {
        setPaginationModel((prev: GridPaginationModel) => ({ ...prev, page: 0 }));
    }, [sortModel]);

    return {
        pageable,
        paginationModel,
        sortModel,
        setPaginationModel,
        setSortModel,
    };
};

export { useDataGridRowCount, useDataGridPageable };
