import { FC, useEffect, useState } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import {
    InsightsOutlined as InsightsOutlinedIcon,
    TableChartOutlined as TableChartOutlinedIcon,
} from '@mui/icons-material';

import { SectionHeading } from '../../../../components/SectionHeading';
import ButtonGroup from '../../../../components/Dashboard/ButtonGroup';
import Column from '../../../../components/Column';
import Printable from '../../../../components/Printable';
import Row from '../../../../components/Row';
import Utils from '../../../../components/Utils';

import HatConversionTrafficChartView from './HatConversionTrafficChartView';
import HatConversionTrafficTableView from './HatConversionTrafficTableView';

const LOCAL_STORAGE_VIEW_KEY = 'analytics.hat.conversions.view';

interface HatConversionTrafficViewsProps {
    heading: React.ReactElement | string | null | undefined;
}

const HatConversionTrafficViews: FC<HatConversionTrafficViewsProps> = (
    props: HatConversionTrafficViewsProps
): JSX.Element => {
    const { heading } = props;

    const [view, setView] = useState<'chart' | 'table'>(() => {
        return Utils.localStorage(LOCAL_STORAGE_VIEW_KEY, 'chart');
    });

    useEffect(() => {
        localStorage.setItem(LOCAL_STORAGE_VIEW_KEY, view);
    }, [view]);

    const getButtonClassName = (currentView: string): string => {
        return currentView === view ? 'MuiButton-selected' : '';
    };

    return (
        <Column gap={3} sx={{ mt: 1.5 }}>
            <Box>
                <Row justifyContent="space-between" alignItems="center" spacing={2}>
                    <Column gap={0.5}>
                        {heading && (
                            <Row gap={0.5} alignItems="center">
                                <SectionHeading divider={false}>{heading}</SectionHeading>
                            </Row>
                        )}
                    </Column>

                    <Printable show={false}>
                        <Row width="auto">
                            <ButtonGroup color="inherit" variant="outlined" size="large">
                                <Tooltip title="Chart View">
                                    <Button onClick={() => setView('chart')} className={getButtonClassName('chart')}>
                                        <InsightsOutlinedIcon />
                                    </Button>
                                </Tooltip>

                                <Tooltip title="Table View">
                                    <Button onClick={() => setView('table')} className={getButtonClassName('table')}>
                                        <TableChartOutlinedIcon />
                                    </Button>
                                </Tooltip>
                            </ButtonGroup>
                        </Row>
                    </Printable>
                </Row>
            </Box>

            <Box>
                {view === 'chart' && <HatConversionTrafficChartView />}
                {view === 'table' && <HatConversionTrafficTableView />}
            </Box>
        </Column>
    );
};

export type { HatConversionTrafficViewsProps };
export default HatConversionTrafficViews;
