import { Alert, Button, Container } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import Block from '../../components/Block';
import Column from '../../components/Column';
import Row from '../../components/Row';
import Heading from '../../components/Heading';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Row spacing={2} justifyContent="space-between" alignItems="center">
                        <Heading>Page Not Found</Heading>

                        <Row justifyContent="flex-end"></Row>
                    </Row>
                </Column>
            </Block>

            <Container maxWidth="sm" sx={{ mx: 0 }}>
                <Column gap={2} sx={{ height: '100%' }}>
                    <Alert severity="error">The specified page could not be found.</Alert>
                    <Button
                        variant="outlined"
                        color="info"
                        startIcon={<ArrowBackOutlined />}
                        onClick={() => navigate('/')}
                    >
                        Back
                    </Button>
                </Column>
            </Container>
        </Column>
    );
};

export default NotFoundPage;
