import { useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import moment from 'moment';

import { PolkSalesWorkflowSet } from '../../types/Polk';
import ApiService from '../../ApiService';

import { Metric } from '../../types/Analytics/Metric';
import { setupMetricChange } from '../../components/Metrics/MetricCard';
import { useAttributionPageContext } from '../../hooks/useAttributionPage';
import { useAmcReachAndFrequencyList } from '../../hooks/useAmcReachAndFrequencyList';
import MetricCard from '../../components/Metrics/MetricCard';

export default function PolkMatchbackMetrics() {
    const {
        attributionCriteria,
        attributionDate,

        polkSalesWorkflowSets,
        isLoadingPolkSalesWorkflowSets,
        previousPolkSalesWorkflowSets,
        isLoadingPreviousPolkSalesWorkflowSets,

        attributionMetrics,
        setAttributionMetrics,
    } = useAttributionPageContext();

    const [metrics, setMetrics] = useState<any[]>([]);
    const [previousMetrics, setPreviousMetrics] = useState<any[]>([]);

    const [isFetchingMetrics, setIsFetchingMetrics] = useState<boolean>(false);
    const [isFetchingPreviousMetrics, setisFetchingPreviousMetrics] = useState<boolean>(false);

    const {
        amcReachAndFrequencyList,
        fetchAmcReachAndFrequencyList,
        setAmcReachAndFrequencyList,
        isFetchingAmcReachAndFrequencyList,
    } = useAmcReachAndFrequencyList();

    const {
        amcReachAndFrequencyList: previousAmcReachAndFrequencyList,
        fetchAmcReachAndFrequencyList: fetchPreviousAmcReachAndFrequencyList,
        setAmcReachAndFrequencyList: setPreviousAmcReachAndFrequencyList,
        isFetchingAmcReachAndFrequencyList: isFetchingPreviousAmcReachAndFrequencyList,
    } = useAmcReachAndFrequencyList();

    function getSumTotalCampaignPerformanceMetric(_metrics: any[], key: string): number {
        return _metrics.map((_metric: any) => _metric?.[key] ?? 0).reduce((_total, _subtotal) => _total + _subtotal, 0);
    }

    const getMetricByPolkSalesWorkflowSet = (
        dataSet: 'polk_sales' | 'polk_opportunities',
        target: string,
        format: 'number' | 'dollar' | 'decimal' | 'percent'
    ): Metric => {
        let _metric: Metric = {
            format: format,
            value: 0,
        };

        switch (dataSet) {
            case 'polk_sales':
                if (polkSalesWorkflowSets && polkSalesWorkflowSets.length > 0) {
                    _metric.value = getSumTotalCampaignPerformanceMetric(
                        polkSalesWorkflowSets.flatMap(
                            (workflowSet: PolkSalesWorkflowSet) => workflowSet.salesWorkflow?.workflowResults ?? []
                        ),
                        target
                    );
                }

                if (previousPolkSalesWorkflowSets && previousPolkSalesWorkflowSets.length > 0) {
                    _metric.previousValue = getSumTotalCampaignPerformanceMetric(
                        previousPolkSalesWorkflowSets.flatMap(
                            (workflowSet: PolkSalesWorkflowSet) => workflowSet.salesWorkflow?.workflowResults ?? []
                        ),
                        target
                    );
                }
                break;

            case 'polk_opportunities':
                if (polkSalesWorkflowSets && polkSalesWorkflowSets.length > 0) {
                    _metric.value = getSumTotalCampaignPerformanceMetric(
                        polkSalesWorkflowSets.flatMap(
                            (workflowSet: PolkSalesWorkflowSet) =>
                                workflowSet.opportunityWorkflow?.workflowResults ?? []
                        ),
                        target
                    );
                }

                if (previousPolkSalesWorkflowSets && previousPolkSalesWorkflowSets.length > 0) {
                    _metric.previousValue = getSumTotalCampaignPerformanceMetric(
                        previousPolkSalesWorkflowSets.flatMap(
                            (workflowSet: PolkSalesWorkflowSet) =>
                                workflowSet.opportunityWorkflow?.workflowResults ?? []
                        ),
                        target
                    );
                }
                break;
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    };

    function getMetricByCampaignPerformance(
        target: string,
        format: 'number' | 'dollar' | 'decimal' | 'percent'
    ): Metric {
        let _metric: Metric = {
            format: format,
            value: 0,
        };

        if (metrics.length > 0) {
            _metric.value = getSumTotalCampaignPerformanceMetric(metrics, target);
        }

        if (previousMetrics.length > 0) {
            _metric.previousValue = getSumTotalCampaignPerformanceMetric(previousMetrics, target);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    }

    const totalSales = useMemo(() => {
        return getMetricByPolkSalesWorkflowSet('polk_sales', 'salesCount', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflowSets, previousPolkSalesWorkflowSets]);

    const attributedSales = useMemo(() => {
        return getMetricByPolkSalesWorkflowSet('polk_sales', 'distinctUserCount', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflowSets, previousPolkSalesWorkflowSets]);

    const totalMarketShares = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        const salesWorlkflowMetric = getMetricByPolkSalesWorkflowSet('polk_sales', 'salesCount', 'number');
        const opportunityWorlkflowMetric = getMetricByPolkSalesWorkflowSet(
            'polk_opportunities',
            'salesCount',
            'number'
        );

        _metric.value = (salesWorlkflowMetric.value as number) + (opportunityWorlkflowMetric.value as number);
        _metric.previousValue =
            (salesWorlkflowMetric.previousValue as number) + (opportunityWorlkflowMetric.previousValue as number);

        _metric = setupMetricChange(_metric);

        return _metric;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflowSets, previousPolkSalesWorkflowSets]);

    const totalAdShares = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        const salesWorlkflowMetric = getMetricByPolkSalesWorkflowSet('polk_sales', 'distinctUserCount', 'number');

        const opportunityWorlkflowMetric = getMetricByPolkSalesWorkflowSet(
            'polk_opportunities',
            'distinctUserCount',
            'number'
        );

        _metric.value = (salesWorlkflowMetric.value as number) + (opportunityWorlkflowMetric.value as number);
        _metric.previousValue =
            (salesWorlkflowMetric.previousValue as number) + (opportunityWorlkflowMetric.previousValue as number);

        _metric = setupMetricChange(_metric);

        return _metric;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [polkSalesWorkflowSets, previousPolkSalesWorkflowSets]);

    const totalImpressions: Metric = useMemo(() => {
        return getMetricByCampaignPerformance('impressions', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metrics, previousMetrics]);

    const totalClickthroughs: Metric = useMemo(() => {
        return getMetricByCampaignPerformance('clickthroughs', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metrics, previousMetrics]);

    const totalDailyReach: Metric = useMemo(() => {
        return getMetricByCampaignPerformance('dailyReach', 'number');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metrics, previousMetrics]);

    const totalImpressionFrequency: Metric = useMemo(() => {
        let _metric: Metric = {
            format: 'number',
            value: 0,
        };

        if (amcReachAndFrequencyList.length > 0) {
            _metric.value = amcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.frequency as number)
                .reduce((a: number, b: number) => a + b, 0);
            _metric.value = Math.round(_metric.value / amcReachAndFrequencyList.length);
        }

        if (previousAmcReachAndFrequencyList.length > 0) {
            _metric.previousValue = previousAmcReachAndFrequencyList
                .map((amcReachAndFrequency: any) => amcReachAndFrequency?.frequency as number)
                .reduce((a: number, b: number) => a + b, 0);
            _metric.previousValue = Math.round(_metric.previousValue / previousAmcReachAndFrequencyList.length);
        }

        _metric = setupMetricChange(_metric);

        return _metric;
    }, [amcReachAndFrequencyList, previousAmcReachAndFrequencyList]);

    // const totalSpendMetricLabel: string = useMemo(() => {
    //     if (attributionDate) {
    //         return `${moment(attributionDate).format('MMMM')} Investment`;
    //     }

    //     return 'Total Investment';
    // }, [attributionDate]);

    // const costPerSoldTooltip: string = useMemo(() => {
    //     if (attributionDate) {
    //         return '{month} Investment divided by Total Ad Exposed Sales'.replace(
    //             '{month}',
    //             moment(attributionDate).format('MMMM')
    //         );
    //     }

    //     return 'Total Investment divided by Total Ad Exposed Sales';
    // }, [attributionDate]);

    useEffect(() => {
        if (attributionCriteria.advertiserIds.length > 0 && attributionDate) {
            const startDate: Date = moment(attributionDate).startOf('month').toDate();
            const endDate: Date = moment(attributionDate).endOf('month').toDate();

            const previousStartDate: Date = moment(attributionDate).subtract(1, 'month').startOf('month').toDate();
            const previousEndDate: Date = moment(attributionDate).subtract(1, 'month').endOf('month').toDate();

            setIsFetchingMetrics(true);
            setisFetchingPreviousMetrics(true);

            ApiService.getCampaignPerformanceList({
                dealerIds: attributionCriteria.advertiserIds,
                startDate: startDate,
                endDate: endDate,
            }).then((response) => {
                setIsFetchingMetrics(false);
                setMetrics(response.data);
            });

            ApiService.getCampaignPerformanceList({
                dealerIds: attributionCriteria.advertiserIds,
                startDate: previousStartDate,
                endDate: previousEndDate,
            }).then((response) => {
                setisFetchingPreviousMetrics(false);
                setPreviousMetrics(response.data);
            });

            fetchAmcReachAndFrequencyList({
                dealerIds: attributionCriteria.advertiserIds,
                campaignName: 'Global',
                startDate: startDate,
                endDate: endDate,
            });

            fetchPreviousAmcReachAndFrequencyList({
                dealerIds: attributionCriteria.advertiserIds,
                campaignName: 'Global',
                startDate: previousStartDate,
                endDate: previousEndDate,
            });
        } else {
            setMetrics([]);
            setPreviousMetrics([]);
            setAmcReachAndFrequencyList([]);
            setPreviousAmcReachAndFrequencyList([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attributionCriteria, attributionDate, fetchAmcReachAndFrequencyList, fetchPreviousAmcReachAndFrequencyList]);

    useEffect(() => {
        setAttributionMetrics({
            ...attributionMetrics,
            ...{
                totalSales,
                attributedSales,
                // totalSpend,
                // costPerSold,
                totalImpressions,
                totalClickthroughs,
                totalImpressionFrequency,
                totalDailyReach,
                totalMarketShares,
                totalAdShares,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        totalSales,
        attributedSales,
        // totalSpend,
        // costPerSold,
        totalImpressions,
        totalClickthroughs,
        totalImpressionFrequency,
        totalDailyReach,
        totalMarketShares,
        totalAdShares,
    ]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <MetricCard
                    loading={isLoadingPolkSalesWorkflowSets || isLoadingPreviousPolkSalesWorkflowSets}
                    metric={totalSales}
                    label="Total Sales"
                    tooltip="Total advertiser sales as reported by Polk by S&P Mobility"
                />
            </Grid>

            <Grid item xs={4}>
                <MetricCard
                    loading={isLoadingPolkSalesWorkflowSets || isLoadingPreviousPolkSalesWorkflowSets}
                    metric={attributedSales}
                    label="Total Ad Exposed Sales"
                    tooltip="Total households that were exposed to an advertisement and made a purchase for the selected time period"
                />
            </Grid>

            {/*<Grid item xs={3}>
                <MetricCard
                    metric={totalSpend}
                    label={totalSpendMetricLabel}
                    tooltip="Total advertiser ad spend for the selected time period"
                />
            </Grid>*/}

            {/*<Grid item xs={3}>
                <MetricCard
                    metric={costPerSold}
                    label="Cost Per Sale"
                    tooltip={costPerSoldTooltip}
                />
            </Grid>*/}

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingMetrics || isFetchingPreviousMetrics}
                    metric={totalImpressions}
                    label="Total Exposures"
                    tooltip="Total number of ad exposures or impressions for the selected advertiser for the selected time period"
                />
            </Grid>

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingMetrics || isFetchingPreviousMetrics}
                    metric={totalClickthroughs}
                    label="Total Clicks"
                    tooltip="Total number of clicks on an advertisement for the selected time period"
                />
            </Grid>

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingAmcReachAndFrequencyList || isFetchingPreviousAmcReachAndFrequencyList}
                    metric={totalDailyReach}
                    label="Household Reach"
                    tooltip="Total number of households exposed to an advertisement"
                />
            </Grid>

            <Grid item xs={4}>
                <MetricCard
                    loading={isFetchingAmcReachAndFrequencyList || isFetchingPreviousAmcReachAndFrequencyList}
                    metric={totalImpressionFrequency}
                    label="Avg. Household Frequency"
                    tooltip="Avg. number of times a household saw advertisements across all media types and channels"
                    inverseColor={true}
                />
            </Grid>
        </Grid>
    );
}
