import { FC, useMemo } from 'react';

import { PieChartSlice } from '../../../../types/Chart';
import Utils from '../../../../components/Utils';

import { SimpleCountType } from '../../../../services/trialfire/types/SimpleCountType';
import { useHatDashboardPageContext } from '../../../../hooks/Pages/HatDashboardPage';
import PieChartCard from '../../../../components/Metrics/PieChartCard';

const HatConversionByTypePieChartCard: FC = (): JSX.Element => {
    const { simpleCounts, metrics } = useHatDashboardPageContext();

    const conversionTypes = [
        { label: 'Conversions', type: SimpleCountType.TOTAL_CONVERSIONS },
        { label: 'Influenced Conversions', type: SimpleCountType.TOTAL_AD_INFLUENCED_CONVERSIONS },
    ];

    const queries = conversionTypes.map(({ type }) => simpleCounts[type]);

    const isLoading = useMemo(() => queries.some((q) => q?.isFetching || q?.isLoading), [queries]);

    const slices = useMemo((): PieChartSlice[] => {
        return [
            { label: 'Conversions', value: metrics?.TOTAL_CONVERSIONS?.value || 0 },
            { label: 'Influenced Conversions', value: metrics?.TOTAL_AD_INFLUENCED_CONVERSIONS?.value || 0 },
        ];
    }, [metrics?.TOTAL_CONVERSIONS, metrics?.TOTAL_AD_INFLUENCED_CONVERSIONS]);

    const slicesTotal = useMemo(() => metrics?.TOTAL_CONVERSIONS?.value || 0, [metrics?.TOTAL_CONVERSIONS]);

    return (
        <PieChartCard
            loading={isLoading}
            label="Conversion by Type"
            chartOptions={{
                dataLabels: { enabled: false },
                plotOptions: {
                    pie: {
                        donut: {
                            labels: {
                                total: {
                                    label: 'Total Conversions',
                                    formatter: () => Utils.formatNumber(slicesTotal) as string,
                                },
                                value: {
                                    formatter: (value) =>
                                        value.startsWith('$') ? value : `${value ? `${value}` : '0'}%`,
                                },
                            },
                        },
                    },
                },
                labels: slices.map(({ label }) => label),
                legend: {
                    horizontalAlign: 'center',
                    offsetX: -64,
                    offsetY: 64,
                },
            }}
            chartSeries={slices.map(({ value }) => (slicesTotal > 0 ? Math.round((value / slicesTotal) * 100) : 0))}
        />
    );
};

export default HatConversionByTypePieChartCard;
