import { useMemo } from 'react';
import { Campaign, CampaignChangeRequest } from '../../../types/Campaign';
import { useCampaignChangeGracePeriod } from './CampaignChangeGracePeriod';

interface UseProps {
    campaign: Campaign;
    campaignChangeRequest: CampaignChangeRequest | null;
}

interface UseState {
    isCampaignChangeable: boolean;
}

const useCampaignChange = ({ campaign, campaignChangeRequest }: UseProps): UseState => {
    const { hasCampaignChangeGracePeriodExpired } = useCampaignChangeGracePeriod({ campaign });

    const isCampaignChangeable = useMemo((): boolean => {
        if (campaignChangeRequest !== null && campaignChangeRequest.status === 'Pending') {
            return false;
        }

        if (campaign !== null && campaign.deliveryStatusId === 'PENDING_COGNITION') {
            return false;
        }

        if (hasCampaignChangeGracePeriodExpired) {
            return false;
        }

        return true;
    }, [campaign, campaignChangeRequest, hasCampaignChangeGracePeriodExpired]);

    return {
        isCampaignChangeable,
    };
};

export type { UseProps as UseCampaignChangeProps };
export type { UseState as UseCampaignChangeState };
export { useCampaignChange };
