import { useTabs } from '../../../hooks/Tabs';
import Block from '../../../components/Block';
import Column from '../../../components/Column';
import Heading from '../../../components/Heading';

import AmcPageFilter from './AmcPageFilter';
import AmcPageTabs from './AmcPageTabs';

interface AmcPageHeaderProps<T> {
    criteria: T;
    setCriteria: (criteria: T) => void;
}

const AmcPageHeader = <T extends { agencyId: number | null; advertiserId: number | null }>(
    props: AmcPageHeaderProps<T>
) => {
    const { criteria, setCriteria } = props;
    const tabs = useTabs();

    return (
        <>
            <Block>
                <Column gap={1}>
                    <Heading>Amazon Marketing Cloud</Heading>
                </Column>
            </Block>

            <Block pt={0}>
                <AmcPageFilter criteria={criteria} setCriteria={setCriteria} />
            </Block>

            <AmcPageTabs {...tabs} />
        </>
    );
};

export default AmcPageHeader;
