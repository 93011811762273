import { useLocation } from 'react-router-dom';
import { useUserContext } from '../hooks/Contexts/UserContext';
import { SidebarMenuItem } from '../components/Sidebar/index';

interface UseState {
    isSidebarMenuItemActive: () => boolean;
    isSidebarMenuItemCollapsable: () => boolean;
    isSidebarMenuItemTargetable: () => boolean;
    shouldSidebarMenuItemCollapse: () => boolean;

    hasUrl: () => boolean;
    getUrl: () => string;
    getTarget: () => string;
}

const useSidebarMenuItem = (item: SidebarMenuItem): UseState => {
    const { userContext } = useUserContext();
    const location = useLocation();

    const isCurrentUrl = (url: string): boolean => {
        let isActive: boolean = false;

        if (url) {
            if (url === '/') {
                isActive = location.pathname === '/';
            } else {
                isActive = new RegExp(`^${url}/?`).test(location.pathname);
            }
        }

        return isActive;
    };

    const hasUrl = (): boolean => {
        if (item?.getUrl) {
            return true;
        }

        return item?.url ? true : false;
    };

    const getUrl = (): string => {
        if (item?.getUrl) {
            return item.getUrl(item, userContext);
        }

        return item.url as string;
    };

    const getTarget = (): string => {
        if (item?.getTarget) {
            return item.getTarget(item, userContext);
        }

        return item?.target || '_self';
    };

    const _isSidebarMenuItemActive = (_item: SidebarMenuItem): boolean => {
        let isActive: boolean = false;

        if (_item?.url) {
            isActive = isCurrentUrl(_item.url);
        }

        if (_item?.isActive) {
            isActive = _item.isActive(_item);
        }

        return isActive;
    };

    const isSidebarMenuItemActive = (): boolean => {
        return _isSidebarMenuItemActive(item);
    };

    const isSidebarMenuItemCollapsable = (): boolean => {
        let isCollapsable: boolean = item?.children?.length ? true : false;
        isCollapsable = item?.collapsable || isCollapsable;
        return isCollapsable;
    };

    const isSidebarMenuItemTargetable = (): boolean => {
        return getTarget() === '_blank' ? true : false;
    };

    const shouldSidebarMenuItemCollapse = (): boolean => {
        if (isSidebarMenuItemCollapsable()) {
            if (item?.children?.length) {
                return item.children.some((_item: SidebarMenuItem) => _isSidebarMenuItemActive(_item));
            }
        }

        return true;
    };

    return {
        isSidebarMenuItemActive,
        isSidebarMenuItemCollapsable,
        isSidebarMenuItemTargetable,
        shouldSidebarMenuItemCollapse,

        hasUrl,
        getUrl,
        getTarget,
    };
};

export type { UseState as UseSidebarMenuItemState };
export { useSidebarMenuItem };
