import Block from '../../components/Block';
import Column from '../../components/Column';
import Heading from '../../components/Heading';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// import { Agency } from '../../types/Agency';
import { Dealer } from '../../types/Dealer';
import { AdvertiserContext } from '../../AdvertiserContext';

import { TABS } from '../../pages/dealers/CRMDataTab';
import ApiService from '../../ApiService';
import CRMDataTab from '../../pages/dealers/CRMDataTab';

export default function SalesDataUploadPage() {
    const { advertiserContext, setAdvertiserContext } = useContext(AdvertiserContext);
    const params = useParams();

    const [agencyId, setAgencyId] = useState<number | null>(null);
    const [dealer, setDealer] = useState<Dealer | null | undefined>(null);

    useEffect(() => {
        if (advertiserContext?.dealer) {
            setDealer(advertiserContext.dealer);
        }

        if (advertiserContext?.agencyId) {
            setAgencyId(advertiserContext.agencyId);
        }
    }, [advertiserContext]);

    useEffect(() => {
        const advertiserId: number = parseInt(params?.advertiserId as string);

        if (advertiserId > 0) {
            ApiService.getDealer(advertiserId).then((response) => {
                setAdvertiserContext(response.data, response.data.agencyId || null);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Column gap={0} flex={1}>
            <Block>
                <Column gap={1}>
                    <Heading>First Party Data Upload</Heading>
                </Column>
            </Block>

            <Column gap={2} sx={{ height: '100%' }}>
                <CRMDataTab
                    role="primary"
                    agencyId={agencyId}
                    dealer={dealer}
                    defaultSelectedTab={TABS.UPLOAD_DATA}
                    dealerDefaultSelect={false}
                    showDealerSelector={true}
                    showHeading={true}
                />
            </Column>
        </Column>
    );
}
